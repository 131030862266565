import { fork } from "redux-saga/effects";

import { appSaga } from "./modules/app/sagas";
import { userSaga } from "./modules/user/sagas";
import { shopSaga } from "./modules/shop/sagas";
import { salesSaga } from "./modules/sales/sagas";

export function* rootSaga() {
  yield fork(appSaga);
  yield fork(userSaga);
  yield fork(shopSaga);
  yield fork(salesSaga);
}
