export type Option = Readonly<{
  shop_option_id: number;
  option_name: string;
  option_name_en: string;
  price: number;
  delivery_price: number;
  delivery_sale_price: number;
  delivery_sale_flag: boolean;
  option_sort: number;
}>;

const initialState: Option = {
  shop_option_id: 0,
  option_name: "",
  option_name_en: "",
  price: 0,
  delivery_price: 0,
  delivery_sale_price: 0,
  delivery_sale_flag: false,
  option_sort: 0,
};

const create = (args: Partial<Option> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const Option = Object.freeze({
  initialState,
  create,
});
