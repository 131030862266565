import React from "react";
import styled from "styled-components";
import { SvgNoImage } from "./Icons/SvgNoImage";
import { colorsConst } from "../../styles/const";
import { fontSize } from "../../styles/mixins";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right bottom, #f8f8f8 50%, #f3f3f3 50%);
`;

const SvgContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
`;

const Text = styled.div`
  color: ${colorsConst.TEXT.TERTIARY};
  ${fontSize.XSMALL};
`;

type Props = {};

export const NoImage: React.FC<Props> = React.memo(() => {
  return (
    <Container>
      <SvgContainer>
        <SvgNoImage />
        <Text>まだ写真がありません</Text>
      </SvgContainer>
    </Container>
  );
});
