import React, { useCallback, useState, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { DateUtils } from "../../utils/DateUtils";
import { userSubmittedReservationOpenDate } from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { Button } from "../atoms/Button";
import { FormDivContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { Input } from "../atoms/FormParts/TextInput";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const ShopReservationOpenEditTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const todayString = DateUtils.getDateString(new Date());

  const [draftDate, setDraftDate] = useState<string>(todayString);

  const isValidDateString = useMemo(() => {
    return DateUtils.isValidDateString(draftDate);
  }, [draftDate]);

  const dispatch = useDispatch();

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftDate(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(userSubmittedReservationOpenDate(shopId, draftDate));
  }, [dispatch, shopId, draftDate]);

  const handleBack = useCallback(() => {
    dispatch(push(`/shopDetail/${shopId}`));
  }, [dispatch, shopId]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="店舗詳細に戻る" handleClick={handleBack} />
        <PageTitle>公開希望日登録</PageTitle>
        <FormDivContainer>
          <FormInner>
            <FormFieldGroup>
              <FormField>
                <FormLabel htmlFor="preferred_open_date">公開希望日</FormLabel>
                <Input
                  type="date"
                  name="preferred_open_date"
                  id="preferred_open_date"
                  min={todayString}
                  onChange={handleChange}
                  value={draftDate}
                />
              </FormField>
            </FormFieldGroup>
          </FormInner>
          <ButtonContainer>
            <Button
              type="button"
              appearance="primary"
              display="responsive"
              disabled={!isValidDateString}
              onClick={handleSubmit}
            >
              保存
            </Button>
          </ButtonContainer>
        </FormDivContainer>
      </Page>
    </Auth>
  );
});

export default withRouter(ShopReservationOpenEditTemplate);
