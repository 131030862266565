import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback } from "react";
import { connect, batch } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userSubmittedDraftShopAddress,
  userSubmittedDraftShopImage,
  userSubmittedShopRegister,
  userAccessedToPageThatNeedsCategoryTagList,
  systemInitDraftShopAddress,
  systemInitDraftShopImage,
} from "../../modules/shop/actions";
import {
  ShopRegisterForm,
  initialShopRegisterForm,
  shopRegisterFormValidationSchema,
} from "../../forms/shopRegister";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { Auth } from "../organisms/Auth";
import { ShopFormFields } from "../organisms/ShopFormFields";

const mapStateToProps = (state: ReduxModel) => ({
  shopData: state.shop.currentShopData,
  categoryTagList: state.shop.categoryTagList,
  draftShopLatLng: state.shop.draftShopLatLng,
  draftShopImage: state.shop.draftShopImage,
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopRegisterForm) {
    dispatch(userSubmittedShopRegister(formValue));
  },
  handleBack() {
    dispatch(push("/home"));
  },
  userAccessedShopRegisterPage() {
    batch(() => {
      dispatch(systemInitDraftShopAddress());
      dispatch(systemInitDraftShopImage(""));
      dispatch(userAccessedToPageThatNeedsCategoryTagList());
    });
  },
  updateDraftShopLatLng(address: string) {
    dispatch(userSubmittedDraftShopAddress(address));
  },
  updateDraftShopImage(files: FileList | null) {
    dispatch(userSubmittedDraftShopImage(files));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopRegisterForm> &
  PropsBase & {
    children?: never;
  };

const ShopRegisterTemplate: React.FC<Props> = React.memo(
  ({
    categoryTagList,
    updateDraftShopLatLng,
    updateDraftShopImage,
    userAccessedShopRegisterPage,
    handleBack,
    draftShopLatLng,
    draftShopImage,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    const handleClickBack = useCallback(() => {
      handleBack();
    }, [handleBack]);

    const handleFileChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        updateDraftShopImage(files);
      },
      [updateDraftShopImage],
    );

    const handleAddressChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        updateDraftShopLatLng(event.target.value);
        handleChange(event);
      },
      [updateDraftShopLatLng, handleChange],
    );

    useEffect(() => {
      userAccessedShopRegisterPage();
    }, [userAccessedShopRegisterPage]);

    useEffect(() => {
      setFieldValue("latitude", draftShopLatLng.latitude);
      setFieldValue("longitude", draftShopLatLng.longitude);
    }, [draftShopLatLng, setFieldValue]);

    useEffect(() => {
      setFieldValue("header_image_path", draftShopImage);
    }, [draftShopImage, setFieldValue]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="店舗一覧に戻る" handleClick={handleClickBack} />
          <PageTitle>店舗を追加</PageTitle>

          <FormContainer onSubmit={handleSubmit}>
            <FormInner>
              <ShopFormFields
                values={values}
                handleChange={handleChange}
                categoryTagList={categoryTagList}
                handleAddressChange={handleAddressChange}
                handleFileChange={handleFileChange}
              />
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                登録
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopRegisterForm>({
  enableReinitialize: true,
  mapPropsToValues: (): ShopRegisterForm => initialShopRegisterForm,
  validationSchema: shopRegisterFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopRegisterTemplate));
