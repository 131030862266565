import React, { useRef, useCallback, useMemo, useState, useLayoutEffect } from "react";
import {
  AutoSizer,
  Index,
  List as RVList,
  ListRowProps,
  Size,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { CellMeasurerChildProps } from "react-virtualized/dist/es/CellMeasurer";
import styled from "styled-components";
import { spaceConst } from "../../styles/const";
import { media } from "../../styles/mixins/media";
import { ShopData } from "../../records/ShopData";
import { ShopListHeader } from "../molecules/ShopListHeader";
import { ShopListItem } from "./ShopListItem";

const Container = styled.div`
  padding: ${`0 ${spaceConst.PADDING.PAGE.HORIZONTAL.DEFAULT}px`};
  ${media.tablet} {
    padding: ${`0 ${spaceConst.PADDING.PAGE.HORIZONTAL.TABLET}px`};
  }
  ${media.desktop} {
    max-width: ${`${768 + spaceConst.PADDING.PAGE.HORIZONTAL.DESKTOP * 2}px`};
  }
`;

type Props = {
  list: ShopData[];
};

export const ShopList: React.FC<Props> = React.memo(({ list }) => {
  const listStyle: React.CSSProperties = useMemo(() => {
    return {
      paddingBottom: spaceConst.PADDING.PAGE.VERTICAL.DESKTOP,
    };
  }, []);

  const renderCache = useRef<CellMeasurerCache>(
    new CellMeasurerCache({
      fixedWidth: true,
    }),
  );

  const renderListRef = useRef<RVList>(null);

  const [mostRecentWidth, setMostRecentWidth] = useState(0);

  const isRowLoaded = useCallback(
    ({ index }: Index) => {
      return !!list[index];
    },
    [list],
  );

  const renderRow = useCallback(
    ({ index, key, parent, style }: ListRowProps) => {
      const item = list[index];
      return (
        <CellMeasurer
          key={key}
          cache={renderCache.current}
          index={index}
          columnIndex={0}
          parent={parent}
        >
          {({ measure }: CellMeasurerChildProps) => (
            <Container style={style}>
              {isRowLoaded({ index }) ? (
                <ShopListVirtualItem key={item.id} measure={measure} index={index} item={item} />
              ) : undefined}
            </Container>
          )}
        </CellMeasurer>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, renderCache.current, isRowLoaded],
  );

  const reSizeAllRender = useCallback(() => {
    renderCache.current.clearAll();
    if (renderListRef.current) {
      renderListRef.current.measureAllRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderCache.current, renderListRef.current]);

  const onResize = useCallback(
    ({ width }: Size) => {
      if (mostRecentWidth && mostRecentWidth !== width) {
        process.nextTick(reSizeAllRender);
      }
      setMostRecentWidth(width);
    },
    [mostRecentWidth, reSizeAllRender],
  );

  return (
    <AutoSizer onResize={onResize}>
      {({ width, height }) => (
        <RVList
          deferredMeasurementCache={renderCache.current}
          width={width}
          height={height}
          rowRenderer={renderRow}
          rowCount={list.length}
          rowHeight={renderCache.current.rowHeight}
          style={listStyle}
        />
      )}
    </AutoSizer>
  );
});

const ShopListVirtualItem: React.FC<{
  item: ShopData;
  index: number;
  measure: () => void;
}> = React.memo((props) => {
  const { item, index, measure } = props;

  useLayoutEffect(() => {
    measure();
  }, [measure]);

  return (
    <>
      {index === 0 ? <ShopListHeader /> : undefined}
      <ShopListItem shop={item} />
    </>
  );
});
