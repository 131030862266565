import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { GetUserDataRequest, GetUserDataResponse } from "../../../apis/getUserData";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { updateUser } from "../actions";

export function* systemGetUserDataByTokenSaga() {
  try {
    const params: GetUserDataRequest = {};
    const commonApiTask = yield fork(commonApiSaga, API_KEY.LOGIN, params);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: GetUserDataResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateUser(result.user_data));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "ログインに失敗しました。",
      }),
    );
  }
}
