import { MonthlySalesReport } from "./MonthlySalesReport";
import { TotalReport } from "./TotalReport";

export type MonthlySalesReports = Readonly<{
  total: TotalReport;
  reports: MonthlySalesReport[];
}>;

const initialState: MonthlySalesReports = {
  total: TotalReport.create(),
  reports: [],
};

const create = (args: Partial<MonthlySalesReports> = {}) => {
  return {
    ...initialState,
    total: args.total ? TotalReport.create(args.total) : TotalReport.create(),
    reports: args.reports ? args.reports.map((i) => MonthlySalesReport.create(i)) : [],
    ...args,
  };
};

export const MonthlySalesReports = Object.freeze({
  initialState,
  create,
});
