import React, { useCallback, useState, useMemo } from "react";
import styled from "styled-components";
import { colorsConst } from "../../styles/const";
import { Utility } from "../../utils/Utility";
import { Button } from "../atoms/Button";
import { inputStyle } from "../atoms/FormParts/TextInput";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.5em;
`;
const InputContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const Input = styled.input`
  ${inputStyle};
  min-width: 8em;
  border: 1px solid ${colorsConst.BORDER.THICK};
  box-sizing: border-box;
`;
const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.5em;
`;

type Props = {
  staffId: number;
  handleSubmit: (staffId: number, passward: string) => void;
};

export const ShopStaffPasswordField: React.FC<Props> = React.memo(({ staffId, handleSubmit }) => {
  const [draftPassword, setDraftPassword] = useState<string>("");

  const canSubmit = useMemo(() => {
    return Utility.validPassword(draftPassword);
  }, [draftPassword]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftPassword(event.target.value);
  }, []);

  const handleSubmitWrap = useCallback(() => {
    handleSubmit(staffId, draftPassword);
    setDraftPassword("");
  }, [handleSubmit, staffId, draftPassword]);

  return (
    <Container>
      <InputContainer>
        <Input type="password" value={draftPassword} onChange={handleChange} />
      </InputContainer>
      <ButtonContainer>
        <Button
          type="button"
          appearance="primary"
          size="sm"
          onClick={handleSubmitWrap}
          disabled={!canSubmit}
        >
          変更
        </Button>
      </ButtonContainer>
    </Container>
  );
});
