import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import {
  ShopOptionSetRegisterForm,
  initialShopOptionSetRegisterForm,
  getInitialValues,
  shopOptionSetRegisterFormValidationSchema,
} from "../../forms/shopOptionSetRegister";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userSubmittedShopOptionSetRegister,
  userAccessedToPageThatNeedsOptionSetList,
} from "../../modules/shop/actions";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Paragraph } from "../atoms/Paragraph";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { Auth } from "../organisms/Auth";
import { ShopOptionSetFormFields } from "../organisms/ShopOptionSetFormFields";

const mapStateToProps = (state: ReduxModel, ownProps: RouteComponentProps<{ shopId: string }>) => ({
  shopId: parseInt(ownProps.match.params.shopId, 10),
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopOptionSetRegisterForm) {
    dispatch(userSubmittedShopOptionSetRegister(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopOptionSet/${shopId}`));
  },
  userAccessedOptionSetRegisterPage(shopId: number) {
    dispatch(userAccessedToPageThatNeedsOptionSetList(shopId));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopOptionSetRegisterForm> &
  PropsBase & {
    children?: never;
  };

const ShopOptionSetRegisterTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    handleBack,
    userAccessedOptionSetRegisterPage,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    console.log(values);
    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    useEffect(() => {
      userAccessedOptionSetRegisterPage(shopId);
    }, [shopId, userAccessedOptionSetRegisterPage]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer
            linkText="オプションセット一覧に戻る"
            handleClick={handleClickBack}
          />
          <PageTitle>オプションセットの編集</PageTitle>

          <Paragraph>
            保存したオプションセットは、商品登録の際に「オプションセット」の選択肢に追加されます。
          </Paragraph>

          <FormContainer onSubmit={handleSubmit}>
            <input type="hidden" name="shop_id" value={values.shop_id} />

            <FormInner>
              <ShopOptionSetFormFields
                shopId={shopId}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                更新
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopOptionSetRegisterForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopId }): ShopOptionSetRegisterForm => getInitialValues(shopId),
  mapPropsToErrors: () =>
    getInitialErrors(shopOptionSetRegisterFormValidationSchema, initialShopOptionSetRegisterForm),
  validationSchema: shopOptionSetRegisterFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopOptionSetRegisterTemplate)),
);
