import React from "react";
import { ShopBusiness } from "../../../records/ShopBusiness";

export type ShopBusinessContextValue = {
  shopId: number;
  shopBusiness: ShopBusiness;
};

export const ShopBusinessContext = React.createContext<ShopBusinessContextValue>(
  {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
);
