import * as Yup from "yup";
import { OptionSet } from "../records/OptionSet";
import { Option } from "../records/Option";
import { ShopOptionRegisterForm } from "./shopOptionRegister";

export type ShopOptionEditForm = ShopOptionRegisterForm & {
  shop_option_id: number;
};

export const initialShopOptionEditForm: ShopOptionEditForm = {
  shop_id: 0,
  shop_option_set_id: 0,
  shop_option_set_name: "",
  shop_option_id: 0,
  shop_option_name: "",
  price: 0,
  delivery_price: 0,
  option_sort: 0,
};

export const getInitialValues = (
  shopId: number,
  optionSet?: OptionSet,
  option?: Option,
): ShopOptionEditForm => {
  return typeof optionSet !== "undefined"
    ? typeof option !== "undefined"
      ? {
          ...initialShopOptionEditForm,
          shop_id: shopId,
          shop_option_set_id: optionSet.option_set_id,
          shop_option_set_name: optionSet.option_set_name,
          shop_option_id: option.shop_option_id,
          shop_option_name: option.option_name,
          price: option.price,
          delivery_price: option.delivery_price,
          option_sort: option.option_sort,
        }
      : {
          ...initialShopOptionEditForm,
          shop_id: shopId,
          shop_option_set_id: optionSet.option_set_id,
          shop_option_set_name: optionSet.option_set_name,
        }
    : {
        ...initialShopOptionEditForm,
        shop_id: shopId,
      };
};

export const shopOptionEditFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  shop_option_set_id: Yup.number(),
  shop_option_set_name: Yup.string(),
  shop_option_id: Yup.number(),
  shop_option_name: Yup.string().required("入力してください"),
  price: Yup.number().required("入力してください"),
  delivery_price: Yup.number().required("入力してください"),
  option_sort: Yup.number().required("入力してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
