import { ShopData } from "src/records/ShopData";
import { ShopItemCategory } from "src/records/ShopItemCategory";
import { ShopItem } from "src/records/ShopItem";
import { CategoryTag } from "src/records/CategoryTag";
import { OptionSet } from "src/records/OptionSet";
import { BankBranch } from "src/records/BankBranch";
import { ShopStaff } from "src/records/ShopStaff";
import { LatLng } from "src/records/LatLng";
import { DirectionType } from "./model";
import { ShopRegisterForm } from "../../forms/shopRegister";
import { ShopEditForm } from "../../forms/shopEdit";
import { ShopItemCategoryRegisterForm } from "../../forms/shopItemCategoryRegister";
import { ShopItemCategoryEditForm } from "../../forms/shopItemCategoryEdit";
import { ShopItemRegisterForm } from "../../forms/shopItemRegister";
import { ShopItemEditForm } from "../../forms/shopItemEdit";
import { ShopOptionSetRegisterForm } from "../../forms/shopOptionSetRegister";
import { ShopOptionSetEditForm } from "../../forms/shopOptionSetEdit";
import { ShopOptionRegisterForm } from "../../forms/shopOptionRegister";
import { ShopOptionEditForm } from "../../forms/shopOptionEdit";
import { CookingTimeEditForm } from "../../forms/cookingTimeEdit";
import { BusinessTimeEditForm } from "../../forms/businessTimeEdit";
import { BankAccountEditForm } from "../../forms/bankAccountEdit";
import { ShopLicenseEditForm } from "../../forms/shopLicenseEdit";
import { ShopStaffRegisterForm } from "../../forms/shopStaffRegister";

export enum ActionTypes {
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_LIST = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_LIST",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_SHOP_DATA = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_SHOP_DATA",
  U_TOUCHED_SHOP_JUDGE_REQUEST_BUTTON = "shop/U_TOUCHED_SHOP_JUDGE_REQUEST_BUTTON",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_LIST = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_LIST",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_OPTION_SET_LIST = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_OPTION_SET_LIST",
  U_SUBMITTED_SHOP_REGISTER = "shop/U_SUBMITTED_SHOP_REGISTER",
  U_SUBMITTED_SHOP_EDIT = "shop/U_SUBMITTED_SHOP_EDIT",
  U_SUBMITTED_SHOP_ITEM_CATEGORY_REGISTER = "shop/U_SUBMITTED_SHOP_ITEM_CATEGORY_REGISTER",
  U_SUBMITTED_SHOP_ITEM_CATEGORY_EDIT = "shop/U_SUBMITTED_SHOP_ITEM_CATEGORY_EDIT",
  U_SUBMITTED_SHOP_ITEM_REGISTER = "shop/U_SUBMITTED_SHOP_ITEM_REGISTER",
  U_SUBMITTED_SHOP_ITEM_EDIT = "shop/U_SUBMITTED_SHOP_ITEM_EDIT",
  U_TOUCHED_CHANGE_ITEM_RELEASE_STATE_BUTTON = "shop/U_TOUCHED_CHANGE_ITEM_RELEASE_STATE_BUTTON",
  U_SUBMITTED_SHOP_OPTION_SET_REGISTER = "shop/U_SUBMITTED_SHOP_OPTION_SET_REGISTER",
  U_SUBMITTED_SHOP_OPTION_SET_EDIT = "shop/U_SUBMITTED_SHOP_OPTION_SET_EDIT",
  U_SUBMITTED_SHOP_OPTION_REGISTER = "shop/U_SUBMITTED_SHOP_OPTION_REGISTER",
  U_SUBMITTED_SHOP_OPTION_EDIT = "shop/U_SUBMITTED_SHOP_OPTION_EDIT",
  U_SUBMITTED_COOKING_TIME = "shop/U_SUBMITTED_COOKING_TIME",
  U_SUBMITTED_BUSINESS_TIME = "shop/U_SUBMITTED_BUSINESS_TIME",
  U_SUBMITTED_UPDATE_BUSINESS_HOLIDAY = "shop/U_SUBMITTED_UPDATE_BUSINESS_HOLIDAY",
  U_SUBMITTED_DELETE_BUSINESS_HOLIDAY = "shop/U_SUBMITTED_DELETE_BUSINESS_HOLIDAY",
  U_SUBMITTED_DRAFT_SHOP_ADDRESS = "shop/U_SUBMITTED_DRAFT_SHOP_ADDRESS",
  U_SUBMITTED_DRAFT_SHOP_IMAGE = "shop/U_SUBMITTED_DRAFT_SHOP_IMAGE",
  U_SUBMITTED_DRAFT_SHOP_ITEM_IMAGE = "shop/U_SUBMITTED_DRAFT_SHOP_ITEM_IMAGE",
  U_CHANGED_DRAFT_BANK_CODE = "shop/U_CHANGED_DRAFT_BANK_CODE",
  U_SUBMITTED_BANK_ACCOUNT = "shop/U_SUBMITTED_BANK_ACCOUNT",
  U_SUBMITTED_SHOP_LICENSE_EDIT = "shop/U_SUBMITTED_SHOP_LICENSE_EDIT",
  U_SUBMITTED_DRAFT_LICENSE_IMAGE = "shop/U_SUBMITTED_DRAFT_LICENSE_IMAGE",
  U_SUBMITTED_RESERVATION_OPEN_DATE = "shop/U_SUBMITTED_RESERVATION_OPEN_DATE",
  U_TOUCED_SORT_SHOP_ITEM_BUTTON = "shop/U_TOUCED_SORT_SHOP_ITEM_BUTTON",
  U_TOUCED_SORT_SHOP_ITEM_CATEGORY_BUTTON = "shop/U_TOUCED_SORT_SHOP_ITEM_CATEGORY_BUTTON",
  U_TOUCED_SORT_SHOP_OPTION_SET_BUTTON = "shop/U_TOUCED_SORT_SHOP_OPTION_SET_BUTTON",
  U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_STAFF_LIST = "shop/U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_STAFF_LIST",
  U_SUBMITTED_UPDATE_SHOP_STAFF_PASSWORD = "shop/U_SUBMITTED_UPDATE_SHOP_STAFF_PASSWORD",
  U_SUBMITTED_REGISTER_SHOP_STAFF = "shop/U_SUBMITTED_REGISTER_SHOP_STAFF",
  U_SUBMITTED_DELETE_SHOP_STAFF = "shop/U_SUBMITTED_DELETE_SHOP_STAFF",

  S_INIT_DRAFT_SHOP_ADDRESS = "shop/S_INIT_DRAFT_SHOP_ADDRESS",
  S_INIT_DRAFT_SHOP_IMAGE = "shop/S_INIT_DRAFT_SHOP_IMAGE",
  S_INIT_DRAFT_SHOP_ITEM_IMAGE = "shop/S_INIT_DRAFT_SHOP_ITEM_IMAGE",
  S_INIT_DRAFT_LICENSE_IMAGE = "shop/S_INIT_DRAFT_LICENSE_IMAGE",

  R_UPDATE_SHOP_LIST = "shop/R_UPDATE_SHOP_LIST",
  R_UPDATE_CURRENT_SHOP_DATA = "shop/R_UPDATE_CURRENT_SHOP_DATA",
  R_UPDATE_SHOP_ITEM_CATEGORY_LIST = "shop/R_UPDATE_SHOP_ITEM_CATEGORY_LIST",
  R_UPDATE_CURRENT_SHOP_ITEM = "shop/R_UPDATE_CURRENT_SHOP_ITEM",
  R_UPDATE_CATEGORY_TAG_LIST = "shop/R_UPDATE_CATEGORY_TAG_LIST",
  R_SHOP_ITEM_OPTION_SET_LIST = "shop/R_SHOP_ITEM_OPTION_SET_LIST",
  R_UPDATE_DRAFT_SHOP_LAT_LNG = "shop/R_UPDATE_DRAFT_SHOP_LAT_LNG",
  R_UPDATE_DRAFT_SHOP_IMAGE = "shohp/R_UPDATE_DRAFT_SHOP_IMAGE",
  R_UPDATE_DRAFT_SHOP_ITEM_IMAGE = "shop/R_UPDATE_DRAFT_SHOP_ITEM_IMAGE",
  R_UPDATE_DRAFT_BANK_NAME = "shop/R_UPDATE_DRAFT_BANK_NAME",
  R_UPDATE_DRAFT_BANK_BRANCH_LIST = "shop/R_UPDATE_DRAFT_BANK_BRANCH_LIST",
  R_UPDATE_DRAFT_LICENSE_IMAGE = "shop/R_UPDATE_DRAFT_LICENSE_IMAGE",
  R_UPDATE_SHOP_STAFF_LIST = "shop/R_UPDATE_SHOP_STAFF_LIST",
}

export const userAccessedToPageThatNeedsShopList = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_LIST,
  } as const);
export type UserAccessedToPageThatNeedsShopListAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopList
>;

export const userAccessedToPageThatNeedsCurrentShopData = (shopId: number) =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_SHOP_DATA,
    payload: {
      shopId,
    },
  } as const);
export type UserAccessedToPageThatNeedsCurrentShopDataAction = ReturnType<
  typeof userAccessedToPageThatNeedsCurrentShopData
>;

export const userTouchedShopJudgeRequestButton = (shopId: number) =>
  ({
    type: ActionTypes.U_TOUCHED_SHOP_JUDGE_REQUEST_BUTTON,
    payload: {
      shopId,
    },
  } as const);
export type UserTouchedShopJudgeRequestButtonAction = ReturnType<
  typeof userTouchedShopJudgeRequestButton
>;

export const userAccessedToPageThatNeedsShopItemList = (shopId: number) =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_LIST,
    payload: {
      shopId,
    },
  } as const);
export type UserAccessedToPageThatNeedsShopItemListAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopItemList
>;

export const userAccessedToPageThatNeedsCategoryTagList = () =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST,
  } as const);
export type UserAccessedToPageThatNeedsCategoryTagListAction = ReturnType<
  typeof userAccessedToPageThatNeedsCategoryTagList
>;

export const userAccessedToPageThatNeedsOptionSetList = (shopId: number) =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_OPTION_SET_LIST,
    payload: {
      shopId,
    },
  } as const);
export type UserAccessedToPageThatNeedsOptionSetListAction = ReturnType<
  typeof userAccessedToPageThatNeedsOptionSetList
>;

export const userSubmittedDraftShopAddress = (address: string) =>
  ({
    type: ActionTypes.U_SUBMITTED_DRAFT_SHOP_ADDRESS,
    payload: {
      address,
    },
  } as const);
export type UserSubmittedDraftShopAddressAction = ReturnType<typeof userSubmittedDraftShopAddress>;

export const userSubmittedDraftShopImage = (files: FileList | null) =>
  ({
    type: ActionTypes.U_SUBMITTED_DRAFT_SHOP_IMAGE,
    payload: {
      files,
    },
  } as const);
export type UserSubmittedDraftShopImageAction = ReturnType<typeof userSubmittedDraftShopImage>;

export const userSubmittedDraftShopItemImage = (files: FileList | null) =>
  ({
    type: ActionTypes.U_SUBMITTED_DRAFT_SHOP_ITEM_IMAGE,
    payload: {
      files,
    },
  } as const);
export type UserSubmittedDraftShopItemImageAction = ReturnType<
  typeof userSubmittedDraftShopItemImage
>;

export const userSubmittedShopRegister = (payload: ShopRegisterForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_REGISTER,
    payload,
  } as const);
export type UserSubmittedShopRegisterAction = ReturnType<typeof userSubmittedShopRegister>;

export const userSubmittedShopEdit = (payload: ShopEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_EDIT,
    payload,
  } as const);
export type UserSubmittedShopEditAction = ReturnType<typeof userSubmittedShopEdit>;

export const userSubmittedShopItemCategoryRegister = (payload: ShopItemCategoryRegisterForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_ITEM_CATEGORY_REGISTER,
    payload,
  } as const);
export type UserSubmittedShopItemCategoryRegisterAction = ReturnType<
  typeof userSubmittedShopItemCategoryRegister
>;

export const userSubmittedShopItemCategoryEdit = (payload: ShopItemCategoryEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_ITEM_CATEGORY_EDIT,
    payload,
  } as const);
export type UserSubmittedShopItemCategoryEditAction = ReturnType<
  typeof userSubmittedShopItemCategoryRegister
>;

export const userSubmittedShopItemRegister = (payload: ShopItemRegisterForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_ITEM_REGISTER,
    payload,
  } as const);
export type UserSubmittedShopItemRegisterAction = ReturnType<typeof userSubmittedShopItemRegister>;

export const userSubmittedShopItemEdit = (payload: ShopItemEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_ITEM_EDIT,
    payload,
  } as const);
export type UserSubmittedShopItemEditAction = ReturnType<typeof userSubmittedShopItemEdit>;

export const userTouchedChangeItemReleaseStateButton = (
  shopId: number,
  categoryId: number,
  itemId: number,
) =>
  ({
    type: ActionTypes.U_TOUCHED_CHANGE_ITEM_RELEASE_STATE_BUTTON,
    payload: {
      shopId,
      categoryId,
      itemId,
    },
  } as const);
export type UserTouchedChangeItemReleaseStateButtonAction = ReturnType<
  typeof userTouchedChangeItemReleaseStateButton
>;

export const userSubmittedShopOptionSetRegister = (payload: ShopOptionSetRegisterForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_OPTION_SET_REGISTER,
    payload,
  } as const);
export type UserSubmittedShopOptionSetRegisterAction = ReturnType<
  typeof userSubmittedShopOptionSetRegister
>;

export const userSubmittedShopOptionSetEdit = (payload: ShopOptionSetEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_OPTION_SET_EDIT,
    payload,
  } as const);
export type UserSubmittedShopOptionSetEditAction = ReturnType<
  typeof userSubmittedShopOptionSetEdit
>;

export const userSubmittedShopOptionRegister = (payload: ShopOptionRegisterForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_OPTION_REGISTER,
    payload,
  } as const);
export type UserSubmittedShopOptionRegisterAction = ReturnType<
  typeof userSubmittedShopOptionRegister
>;

export const userSubmittedShopOptionEdit = (payload: ShopOptionEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_OPTION_EDIT,
    payload,
  } as const);
export type UserSubmittedShopOptionEditAction = ReturnType<typeof userSubmittedShopOptionEdit>;

export const userSubmittedCookingTime = (payload: CookingTimeEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_COOKING_TIME,
    payload,
  } as const);
export type UserSubmittedCookingTimeAction = ReturnType<typeof userSubmittedCookingTime>;

export const userSubmittedBusinessTime = (payload: BusinessTimeEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_BUSINESS_TIME,
    payload,
  } as const);
export type UserSubmittedBusinessTimeAction = ReturnType<typeof userSubmittedBusinessTime>;

export const userSubmittedUpdateBusinessHoliday = (shopId: number, holiday: string) =>
  ({
    type: ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_HOLIDAY,
    payload: {
      shopId,
      holiday,
    },
  } as const);
export type UserSubmittedUpdateBusinessHolidayAction = ReturnType<
  typeof userSubmittedUpdateBusinessHoliday
>;

export const userSubmittedDeleteBusinessHoliday = (shopId: number, holiday: string) =>
  ({
    type: ActionTypes.U_SUBMITTED_DELETE_BUSINESS_HOLIDAY,
    payload: {
      shopId,
      holiday,
    },
  } as const);
export type UserSubmittedDeleteBusinessHolidayAction = ReturnType<
  typeof userSubmittedDeleteBusinessHoliday
>;

export const userChangedDraftBankCode = (bankCode: string) =>
  ({
    type: ActionTypes.U_CHANGED_DRAFT_BANK_CODE,
    payload: {
      bankCode,
    },
  } as const);
export type UserChangedDraftBankCodeAction = ReturnType<typeof userChangedDraftBankCode>;

export const userSubmittedBankAccount = (payload: BankAccountEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_BANK_ACCOUNT,
    payload,
  } as const);
export type UserSubmittedBankAccountAction = ReturnType<typeof userSubmittedBankAccount>;

export const userSubmittedShopLicenseEdit = (payload: ShopLicenseEditForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_SHOP_LICENSE_EDIT,
    payload,
  } as const);
export type UserSubmittedShopLicenseEditAction = ReturnType<typeof userSubmittedShopLicenseEdit>;

export const userSubmittedDraftLicenseImage = (files: FileList | null) =>
  ({
    type: ActionTypes.U_SUBMITTED_DRAFT_LICENSE_IMAGE,
    payload: {
      files,
    },
  } as const);
export type UserSubmittedDraftLicenseImageAction = ReturnType<typeof userSubmittedDraftShopImage>;

export const userSubmittedReservationOpenDate = (shopId: number, date: string) =>
  ({
    type: ActionTypes.U_SUBMITTED_RESERVATION_OPEN_DATE,
    payload: {
      shopId,
      date,
    },
  } as const);
export type UserSubmittedReservationOpenDateAction = ReturnType<
  typeof userSubmittedReservationOpenDate
>;

export const userTouchedSortShopItemButton = (
  shopId: number,
  categoryId: number,
  itemId: number,
  direction: DirectionType,
  itemIds: number[],
) =>
  ({
    type: ActionTypes.U_TOUCED_SORT_SHOP_ITEM_BUTTON,
    payload: {
      shopId,
      categoryId,
      itemId,
      direction,
      itemIds,
    },
  } as const);
export type UserTouchedSortShopItemButtonAction = ReturnType<typeof userTouchedSortShopItemButton>;

export const userTouchedSortShopItemCategoryButton = (
  shopId: number,
  categoryId: number,
  direction: DirectionType,
  categoryIds: number[],
) =>
  ({
    type: ActionTypes.U_TOUCED_SORT_SHOP_ITEM_CATEGORY_BUTTON,
    payload: {
      shopId,
      categoryId,
      direction,
      categoryIds,
    },
  } as const);
export type UserTouchedSortShopItemCategoryButtonAction = ReturnType<
  typeof userTouchedSortShopItemCategoryButton
>;

export const userTouchedSortShopOptionSetButton = (
  shopId: number,
  optionSetId: number,
  direction: DirectionType,
  optionSetIds: number[],
) =>
  ({
    type: ActionTypes.U_TOUCED_SORT_SHOP_OPTION_SET_BUTTON,
    payload: {
      shopId,
      optionSetId,
      direction,
      optionSetIds,
    },
  } as const);
export type UserTouchedSortShopOptionSetButtonAction = ReturnType<
  typeof userTouchedSortShopOptionSetButton
>;

export const userAccessedToPageThatNeedsShopStaffList = (shopId: number) =>
  ({
    type: ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_STAFF_LIST,
    payload: {
      shopId,
    },
  } as const);
export type UserAccessedToPageThatNeedsShopStaffListAction = ReturnType<
  typeof userAccessedToPageThatNeedsShopStaffList
>;

export const userSubmittedUpdateShopStaffPassword = (
  shopId: number,
  staffId: number,
  password: string,
) =>
  ({
    type: ActionTypes.U_SUBMITTED_UPDATE_SHOP_STAFF_PASSWORD,
    payload: {
      shopId,
      staffId,
      password,
    },
  } as const);
export type UserSubmittedUpdateShopStaffPasswordAction = ReturnType<
  typeof userSubmittedUpdateShopStaffPassword
>;

export const userSubmittedRegisterShopStaff = (payload: ShopStaffRegisterForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_REGISTER_SHOP_STAFF,
    payload,
  } as const);
export type UserSubmittedRegisterShopStaffAction = ReturnType<
  typeof userSubmittedRegisterShopStaff
>;

export const userSubmittedDeleteShopStaff = (shopId: number, staffId: number) =>
  ({
    type: ActionTypes.U_SUBMITTED_DELETE_SHOP_STAFF,
    payload: {
      shopId,
      staffId,
    },
  } as const);
export type UserSubmittedDeleteShopStaffAction = ReturnType<typeof userSubmittedDeleteShopStaff>;

export const systemInitDraftShopAddress = () =>
  ({
    type: ActionTypes.S_INIT_DRAFT_SHOP_ADDRESS,
  } as const);
export type SystemInitDraftShopAddressAction = ReturnType<typeof systemInitDraftShopAddress>;

export const systemInitDraftShopImage = (imagePath: string) =>
  ({
    type: ActionTypes.S_INIT_DRAFT_SHOP_IMAGE,
    payload: {
      imagePath,
    },
  } as const);
export type SystemInitDraftShopImageAction = ReturnType<typeof systemInitDraftShopImage>;

export const systemInitDraftShopItemImage = (imagePath: string) =>
  ({
    type: ActionTypes.S_INIT_DRAFT_SHOP_ITEM_IMAGE,
    payload: {
      imagePath,
    },
  } as const);
export type SystemInitDraftShopItemImageAction = ReturnType<typeof systemInitDraftShopItemImage>;

export const systemInitDraftLicenseImage = (imagePath: string) =>
  ({
    type: ActionTypes.S_INIT_DRAFT_LICENSE_IMAGE,
    payload: {
      imagePath,
    },
  } as const);
export type SystemInitDraftLicenseImageAction = ReturnType<typeof systemInitDraftLicenseImage>;

export const updateShopList = (shopList: ShopData[]) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_LIST,
    payload: {
      shopList,
    },
  } as const);
export type UpdateShopListAction = ReturnType<typeof updateShopList>;

export const updateCurrentShopData = (shopData: ShopData) =>
  ({
    type: ActionTypes.R_UPDATE_CURRENT_SHOP_DATA,
    payload: {
      shopData,
    },
  } as const);
export type UpdateCurrentShopDataAction = ReturnType<typeof updateCurrentShopData>;

export const updateShopItemCategoryList = (shopItem: ShopItemCategory[]) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_ITEM_CATEGORY_LIST,
    payload: {
      shopItem,
    },
  } as const);
export type UpdateShopItemCategoryListAction = ReturnType<typeof updateShopItemCategoryList>;

export const updateCurrentShopItem = (shopItem: ShopItem) =>
  ({
    type: ActionTypes.R_UPDATE_CURRENT_SHOP_ITEM,
    payload: {
      shopItem,
    },
  } as const);
export type UpdateCurrentShopItemAction = ReturnType<typeof updateCurrentShopItem>;

export const updateCategoryTagList = (categoryTagList: CategoryTag[]) =>
  ({
    type: ActionTypes.R_UPDATE_CATEGORY_TAG_LIST,
    payload: {
      categoryTagList,
    },
  } as const);
export type UpdateCategoryTagListAction = ReturnType<typeof updateCategoryTagList>;

export const updateShopItemOptionSetList = (optionSetList: OptionSet[]) =>
  ({
    type: ActionTypes.R_SHOP_ITEM_OPTION_SET_LIST,
    payload: {
      optionSetList,
    },
  } as const);
export type UpdateShopItemOptionSetListAction = ReturnType<typeof updateShopItemOptionSetList>;

export const updateDraftShopLatLng = (latLng: LatLng) =>
  ({
    type: ActionTypes.R_UPDATE_DRAFT_SHOP_LAT_LNG,
    payload: {
      latLng,
    },
  } as const);
export type UpdateDraftShopLatLngAction = ReturnType<typeof updateDraftShopLatLng>;

export const updateDraftShopImage = (imagePath?: string) =>
  ({
    type: ActionTypes.R_UPDATE_DRAFT_SHOP_IMAGE,
    payload: {
      imagePath,
    },
  } as const);
export type UpdateDraftShopImageAction = ReturnType<typeof updateDraftShopImage>;

export const updateDraftShopItemImage = (imagePath?: string) =>
  ({
    type: ActionTypes.R_UPDATE_DRAFT_SHOP_ITEM_IMAGE,
    payload: {
      imagePath,
    },
  } as const);
export type UpdateDraftShopItemImageAction = ReturnType<typeof updateDraftShopItemImage>;

export const updateDraftBankName = (bankName: string) =>
  ({
    type: ActionTypes.R_UPDATE_DRAFT_BANK_NAME,
    payload: {
      bankName,
    },
  } as const);
export type UpdateDraftBankNameAction = ReturnType<typeof updateDraftBankName>;

export const updateDraftBankBranchList = (branchList: BankBranch[]) =>
  ({
    type: ActionTypes.R_UPDATE_DRAFT_BANK_BRANCH_LIST,
    payload: {
      branchList,
    },
  } as const);
export type UpdateDraftBankBranchListAction = ReturnType<typeof updateDraftBankBranchList>;

export const updateDraftLicenseImage = (imagePath?: string) =>
  ({
    type: ActionTypes.R_UPDATE_DRAFT_LICENSE_IMAGE,
    payload: {
      imagePath,
    },
  } as const);
export type UpdateDraftLicenseImageAction = ReturnType<typeof updateDraftLicenseImage>;

export const updateShopStaffList = (staffList: ShopStaff[]) =>
  ({
    type: ActionTypes.R_UPDATE_SHOP_STAFF_LIST,
    payload: {
      staffList,
    },
  } as const);
export type UpdateShopStaffListAction = ReturnType<typeof updateShopStaffList>;

export type ShopActions =
  | UserAccessedToPageThatNeedsShopListAction
  | UserAccessedToPageThatNeedsCurrentShopDataAction
  | UserTouchedShopJudgeRequestButtonAction
  | UserAccessedToPageThatNeedsShopItemListAction
  | UserAccessedToPageThatNeedsCategoryTagListAction
  | UserAccessedToPageThatNeedsOptionSetListAction
  | UserSubmittedDraftShopAddressAction
  | UserSubmittedDraftShopImageAction
  | UserSubmittedDraftShopItemImageAction
  | UserSubmittedShopRegisterAction
  | UserSubmittedShopEditAction
  | UserSubmittedShopItemCategoryRegisterAction
  | UserSubmittedShopItemRegisterAction
  | UserSubmittedShopItemEditAction
  | UserTouchedChangeItemReleaseStateButtonAction
  | UserSubmittedShopOptionSetRegisterAction
  | UserSubmittedShopOptionSetEditAction
  | UserSubmittedShopOptionRegisterAction
  | UserSubmittedShopOptionEditAction
  | UserSubmittedCookingTimeAction
  | UserSubmittedBusinessTimeAction
  | UserSubmittedUpdateBusinessHolidayAction
  | UserSubmittedDeleteBusinessHolidayAction
  | UserChangedDraftBankCodeAction
  | UserSubmittedBankAccountAction
  | UserSubmittedShopLicenseEditAction
  | UserSubmittedDraftLicenseImageAction
  | UserSubmittedReservationOpenDateAction
  | UserTouchedSortShopItemButtonAction
  | UserTouchedSortShopItemCategoryButtonAction
  | UserTouchedSortShopOptionSetButtonAction
  | UserAccessedToPageThatNeedsShopStaffListAction
  | UserSubmittedUpdateShopStaffPasswordAction
  | UserSubmittedRegisterShopStaffAction
  | UserSubmittedDeleteShopStaffAction
  | SystemInitDraftShopAddressAction
  | SystemInitDraftShopImageAction
  | SystemInitDraftShopItemImageAction
  | SystemInitDraftLicenseImageAction
  | UpdateShopListAction
  | UpdateCurrentShopDataAction
  | UpdateCategoryTagListAction
  | UpdateShopItemCategoryListAction
  | UpdateCurrentShopItemAction
  | UpdateShopItemOptionSetListAction
  | UpdateDraftShopLatLngAction
  | UpdateDraftShopImageAction
  | UpdateDraftShopItemImageAction
  | UpdateDraftBankNameAction
  | UpdateDraftBankBranchListAction
  | UpdateDraftLicenseImageAction
  | UpdateShopStaffListAction;
