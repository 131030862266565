import { useField } from "formik";
import React from "react";
import styled from "styled-components";
import { colorsConst } from "../../../styles/const";
import { Textarea } from "./TextInput";

const Container = styled.div``;
const ErrorContainer = styled.div`
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
};

export const TextareaField: React.FC<Props> = React.memo(({ name, ...rest }) => {
  const [input, meta] = useField(name);
  return (
    <Container>
      <Textarea {...input} {...rest} />
      {meta.touched && meta.error ? <ErrorContainer>{meta.error}</ErrorContainer> : undefined}
    </Container>
  );
});
