import { fork, join, cancel, put, select } from "redux-saga/effects";
// import { replace } from "connected-react-router";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { replaceAndAddAlertSaga } from "../../app/sagas/replaceAndAddAlertSaga";
import {
  UpdateCookingTimeRequest,
  UpdateCookingTimeResponse,
} from "../../../apis/updateCookingTime";
import { updateCurrentShopData, UserSubmittedCookingTimeAction } from "../actions";
import { currentShopDataSelector } from "../selectors";

export function* userSubmittedCookingTimeSaga(action: UserSubmittedCookingTimeAction) {
  try {
    const params: UpdateCookingTimeRequest = action.payload;
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.UPDATE_COOKING_TIME, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: UpdateCookingTimeResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      const currentShopData: ReturnType<typeof currentShopDataSelector> = yield select(
        currentShopDataSelector,
      );
      if (currentShopData.id === result.shop_data.id) {
        yield put(updateCurrentShopData(result.shop_data));
      }
      yield fork(() =>
        replaceAndAddAlertSaga(`/shopBusiness/${result.shop_data.id}`, {
          type: ALERT_TYPE.SUCCESS,
          message: "調理時間を更新しました。",
        }),
      );
      // yield put(replace(`/shopBusiness/${result.shop_data.id}`));
      // yield put(
      //   systemAddedAlert({
      //     type: ALERT_TYPE.SUCCESS,
      //     message: ["調理時間を更新しました。"],
      //   }),
      // );
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: ["調理時間の更新に失敗しました。"],
      }),
    );
  }
}
