import { useField } from "formik";
import React, { useMemo } from "react";
import styled from "styled-components";
import { BusinessTime } from "../../records/ShopBusiness";
import { Utility } from "../../utils/Utility";
import { colorsConst } from "../../styles/const";
import { Select } from "../atoms/FormParts/TextInput";

const HOUR_OPTIONS = Array(24)
  .fill("")
  .map((_, i) => (
    <option key={i} value={Utility.zeroPadding(i + 6, 2)}>
      {Utility.zeroPadding(i + 6, 2)}
    </option>
  ));

const MINUTE_OPTIONS = Array(4)
  .fill("")
  .map((_, i) => (
    <option key={i} value={Utility.zeroPadding(i * 15, 2)}>
      {Utility.zeroPadding(i * 15, 2)}
    </option>
  ));

const Container = styled.div`
  & + & {
    margin-top: 0.5em;
  }
`;

const TimeHead = styled.div`
  margin-bottom: 0.25em;
  color: ${colorsConst.TEXT.SECONDARY};
`;

const TimeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TimeField = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TimeSelectField = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const TimeSelect = styled(Select)`
  border: 1px solid ${colorsConst.BORDER.THICK};
`;

const TimeSeparator = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.25em;
`;

const ErrorContainer = styled.div`
  margin-top: 0.25em;
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = {
  index: number;
  week: number;
  businessTime: BusinessTime;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleChange: (e: React.ChangeEvent<any>) => void;
};

export const BusinessTimeField: React.FC<Props> = React.memo(
  ({ index, week, businessTime, handleChange }) => {
    const [input, meta] = useField(`week_time[${week}].business_times[${index}]`);

    const idPrefix = useMemo(() => {
      return input.name.replace(".", "_");
    }, [input]);

    return (
      <Container>
        <TimeHead>営業時間{String.fromCharCode(9312 + index)}</TimeHead>
        <TimeRow>
          <TimeField>
            <TimeSelectField>
              <TimeSelect
                name={`${input.name}.start.hour`}
                id={`${idPrefix}_start_hour`}
                onChange={handleChange}
                value={businessTime.start.hour}
              >
                {HOUR_OPTIONS}
              </TimeSelect>
            </TimeSelectField>
            <TimeSeparator>：</TimeSeparator>
            <TimeSelectField>
              <TimeSelect
                name={`${input.name}.start.minute`}
                id={`${idPrefix}_start_minute`}
                onChange={handleChange}
                value={businessTime.start.minute}
              >
                {MINUTE_OPTIONS}
              </TimeSelect>
            </TimeSelectField>
          </TimeField>
          <TimeSeparator>〜</TimeSeparator>
          <TimeField>
            <TimeSelectField>
              <TimeSelect
                name={`${input.name}.end.hour`}
                id={`${idPrefix}_end_hour`}
                onChange={handleChange}
                value={businessTime.end.hour}
              >
                {HOUR_OPTIONS}
              </TimeSelect>
            </TimeSelectField>
            <TimeSeparator>：</TimeSeparator>
            <TimeSelectField>
              <TimeSelect
                name={`${input.name}.end.minute`}
                id={`${idPrefix}_end_minute`}
                onChange={handleChange}
                value={businessTime.end.minute}
              >
                {MINUTE_OPTIONS}
              </TimeSelect>
            </TimeSelectField>
          </TimeField>
        </TimeRow>
        {meta.error ? <ErrorContainer>{Object.values(meta.error)[0]}</ErrorContainer> : undefined}
      </Container>
    );
  },
);
