import React, { useState, useCallback, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { Utility } from "../../utils/Utility";
import { ShopItem } from "../../records/ShopItem";
import { DIRECTION_TYPE, DirectionType } from "../../modules/shop/model";
import { colorsConst, fontWeightsConst } from "../../styles/const";
import { fontSize } from "../../styles/mixins";
import { media } from "../../styles/mixins/media";
import { LinkButton } from "../atoms/LinkButton";
import { SortButton } from "./SortButton";

const Container = styled.div`
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  padding: 0.5em;
  & + & {
    margin-top: 0.5em;
  }
`;

const HeadRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  ${media.tablet} {
    min-height: 90px;
  }
`;

// const ImgContainer = styled.div`
//   flex-grow: 0;
//   flex-shrink: 0;
//   width: 60px;
//   height: 60px;
//   ${media.tablet} {
//     width: 90px;
//     height: 90px;
//   }
// `;

// const ItemImg = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: center;
// `;

const ItemName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 0.5em;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  /* align-self: center; */
`;

const SortButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionContainer = styled.div`
  margin-top: 0.5em;
  padding-top: 0.5em;
  border-top: 1px solid ${colorsConst.BORDER.DEFAULT};
`;

const CollapseContainer = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  transition: height 0.5s ease;
`;

const CollapseInner = styled.div``;

const OptionSetContainer = styled.div`
  & + & {
    margin-top: 0.5em;
  }
`;

const OptionSetName = styled.div`
  ${fontSize.SMALL};
  font-weight: ${fontWeightsConst.MEDIUM};
`;

const OptionListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.25em;
`;

const OptionName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  ${fontSize.SMALL};
`;

const OptionPriceContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const OptionPriceItem = styled.div`
  ${fontSize.SMALL};
  padding-left: 1em;
`;

const CollapseButtonContainer = styled.div`
  text-align: right;
`;

const CollapseButton = styled.a`
  cursor: pointer;
`;

type Props = {
  shopId: number;
  item: ShopItem;
  index: number;
  length: number;
  handleSort: (itemId: number, direction: DirectionType) => void;
};

export const ShopItemListItem: React.FC<Props> = React.memo(
  ({ shopId, item, index, length, handleSort }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [collapseHeight, setCollapseHeight] = useState<number>(0);

    const collapseRef = useRef<HTMLDivElement | null>(null);

    const handleClickSortButton = useCallback(
      (direction: DirectionType) => {
        handleSort(item.shop_item_id, direction);
      },
      [item, handleSort],
    );

    const handleCollapse = useCallback(() => {
      setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    useLayoutEffect(() => {
      if (collapseRef.current !== null) {
        setCollapseHeight(isOpen ? collapseRef.current.clientHeight : 0);
      }
    }, [isOpen]);

    return (
      <Container>
        <HeadRow>
          {/*
          <ImgContainer>
            <ItemImg src={`${Utility.getImageDomain()}/${item.image_path}`} alt="" />
          </ImgContainer>
          */}
          <ItemName>{item.item_name}</ItemName>
          <ButtonContainer>
            <LinkButton
              appearance="primary"
              size="sm"
              to={`/shopItemEdit/${shopId}/${item.shop_item_id}`}
            >
              編集
            </LinkButton>
          </ButtonContainer>
          <SortButtonContainer>
            <SortButton
              index={index}
              length={length}
              direction={DIRECTION_TYPE.UP}
              handleClick={handleClickSortButton}
            />
            <SortButton
              index={index}
              length={length}
              direction={DIRECTION_TYPE.DOWN}
              handleClick={handleClickSortButton}
            />
          </SortButtonContainer>
        </HeadRow>
        {ShopItem.existsOptionSet(item.option_set_list) ? (
          <OptionContainer>
            <CollapseContainer isOpen={isOpen} style={{ height: collapseHeight }}>
              <CollapseInner ref={collapseRef}>
                {item.option_set_list.map((optionSet) => (
                  <OptionSetContainer key={`${item.shop_item_id}-${optionSet.option_set_id}`}>
                    <OptionSetName>{optionSet.option_set_name}</OptionSetName>
                    {optionSet.option_list.map((option) => (
                      <OptionListItem
                        key={`${item.shop_item_id}-${optionSet.option_set_id}-${option.shop_option_id}`}
                      >
                        <OptionName>{option.option_name}</OptionName>
                        <OptionPriceContainer>
                          <OptionPriceItem>
                            {`テイクアウト ${Utility.getPresentationPrice(option.price)}`}
                          </OptionPriceItem>
                          <OptionPriceItem>
                            {`配達 ${Utility.getPresentationPrice(option.delivery_price)}`}
                          </OptionPriceItem>
                        </OptionPriceContainer>
                      </OptionListItem>
                    ))}
                  </OptionSetContainer>
                ))}
              </CollapseInner>
            </CollapseContainer>
            <CollapseButtonContainer>
              <CollapseButton onClick={handleCollapse}>
                {isOpen ? "閉じる" : "オプションの確認"}
              </CollapseButton>
            </CollapseButtonContainer>
          </OptionContainer>
        ) : undefined}
      </Container>
    );
  },
);
