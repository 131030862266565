import React from "react";
import styled from "styled-components";
import { colorsConst } from "../../../styles/const";
import { fontSize } from "../../../styles/mixins";

const Container = styled.label`
  display: block;
  margin-bottom: 0.5em;
  ${fontSize.XSMALL};
  color: ${colorsConst.TEXT.SECONDARY};
`;

const Badge = styled.span`
  display: inline-block;
  margin-right: 0.5em;
  padding: 0 0.25em;
  color: ${colorsConst.TEXT.WHITE};
  background-color: ${colorsConst.BUTTON.BACKGROUND.DANGER};
  border-radius: 2px;
`;

type Props = React.LabelHTMLAttributes<HTMLLabelElement> & {
  children: React.ReactNode;
  required?: boolean;
};

export const FormLabel: React.FC<Props> = React.memo(({ children, required, htmlFor }) => {
  return (
    <Container htmlFor={htmlFor}>
      {required && <Badge>必須</Badge>}
      {children}
    </Container>
  );
});
