import * as Yup from "yup";
import { emailValidation } from "./validations";
import { PriceRangeType, NotificationFlagType, NOTIFICATION_FLAG } from "../records/ShopData";

export type ShopRegisterForm = {
  shop_name: string;
  shop_name_ruby: string;
  address: string;
  latitude: string;
  longitude: string;
  tel: string;
  tel_notification_flag: NotificationFlagType;
  category_tag: number[];
  price_range: PriceRangeType;
  person_in_charge_name: string;
  person_in_charge_name_ruby: string;
  mail: string;
  header_image_path: string;
};

export const initialShopRegisterForm: ShopRegisterForm = {
  shop_name: "",
  shop_name_ruby: "",
  address: "",
  latitude: "",
  longitude: "",
  tel: "",
  tel_notification_flag: NOTIFICATION_FLAG.FALSE,
  category_tag: [],
  price_range: 1,
  person_in_charge_name: "",
  person_in_charge_name_ruby: "",
  mail: "",
  header_image_path: "",
};

export const shopRegisterFormValidationSchema = Yup.object({
  shop_name: Yup.string().required("入力してください"),
  shop_name_ruby: Yup.string().required("入力してください"),
  address: Yup.string().required("入力してください"),
  latitude: Yup.string().required(),
  longitude: Yup.string().required(),
  tel: Yup.string().required("入力してください"),
  tel_notification_flag: Yup.mixed<NotificationFlagType>().oneOf(Object.values(NOTIFICATION_FLAG)),
  category_tag: Yup.array().of(Yup.number()).min(1, "カテゴリーを選択してください").max(2),
  price_range: Yup.mixed<PriceRangeType>(),
  person_in_charge_name: Yup.string().required("入力してください"),
  person_in_charge_name_ruby: Yup.string().required("入力してください"),
  mail: emailValidation,
  header_image_path: Yup.string().required("画像を選択してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
