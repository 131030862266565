import React from "react";
import styled from "styled-components";
import { Link, LinkProps } from "react-router-dom";
import { colorsConst, fontWeightsConst } from "../../styles/const";
import {
  ContainerProps,
  AppearanceType,
  SizeType,
  DisplayType,
  getSizeStyle,
  getDisplayStyle,
  getAppearanceStyle,
} from "./Button";

const Container = styled(Link)<ContainerProps>`
  color: ${colorsConst.BUTTON.TEXT.PRIMARY};
  ${(p) => getSizeStyle(p.size)};
  font-weight: ${fontWeightsConst.MEDIUM};
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(p) => getDisplayStyle(p.display)};
  ${getAppearanceStyle};
`;

type Props = LinkProps & {
  to: string;
  children: React.ReactNode;
  appearance?: AppearanceType;
  size?: SizeType;
  invert?: boolean;
  display?: DisplayType;
  style?: React.CSSProperties;
};

export const LinkButton: React.FC<Props> = React.memo(
  ({ children, style, appearance = "default", to, ...rest }) => {
    return (
      <Container to={to} appearance={appearance} style={style} {...rest}>
        {children}
      </Container>
    );
  },
);
