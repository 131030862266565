import { put, call } from "redux-saga/effects";
import { geocode, GeocodeResult } from "../../../utils/MapsApiUtils";
import { ALERT_TYPE } from "../../../records/Alert";
import { LatLng } from "../../../records/LatLng";
import { systemAddedAlert } from "../../app/actions";
import { updateDraftShopLatLng } from "../actions";

export function* updateShopDraftLatLngSaga(address?: string) {
  try {
    if (typeof address === "undefined" || address === "") {
      yield put(updateDraftShopLatLng(LatLng.create()));
    } else {
      const geocodeResult: GeocodeResult = yield call(geocode, address);
      if (geocodeResult.status === (("OK" as unknown) as google.maps.GeocoderStatus)) {
        if (geocodeResult.results.length > 0) {
          const result = geocodeResult.results[0];
          const latlngLiteral = (result.geometry.location as unknown) as google.maps.LatLngLiteral;
          yield put(
            updateDraftShopLatLng({
              latitude: `${latlngLiteral.lat.toFixed(6)}`,
              longitude: `${latlngLiteral.lng.toFixed(6)}`,
            }),
          );
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    }
  } catch (error) {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "緯度経度の取得に失敗しました。",
      }),
    );
  }
}
