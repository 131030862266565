import { fork, join, cancel, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import {
  UpdateShopOptionSetRequest,
  UpdateShopOptionSetResponse,
} from "../../../apis/updateShopOptionSet";
import { updateShopItemOptionSetList, UserSubmittedShopOptionSetEditAction } from "../actions";

export function* userSubmittedShopOptionSetEditSaga(action: UserSubmittedShopOptionSetEditAction) {
  try {
    const params: UpdateShopOptionSetRequest = action.payload;
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.UPDATE_SHOP_OPTION_SET, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: UpdateShopOptionSetResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopItemOptionSetList(result.option_set_list));
      yield put(replace(`/shopOptionSet/${action.payload.shop_id}`));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "店舗情報の更新に失敗しました。",
      }),
    );
  }
}
