import { select, fork, join, cancel, put, delay } from "redux-saga/effects";
import { loginTokenSelector } from "../selectors";
import { setProcessingFlag, removeProcessingFlag } from "../../app/actions";
import { PROCESSING_FLAG } from "../../app/model";
import { loginByTokenSaga } from "./loginByTokenSaga";

export function* systemPreLoginSaga() {
  try {
    yield put(setProcessingFlag(PROCESSING_FLAG.AUTO_LOGIN));
    const token: ReturnType<typeof loginTokenSelector> = yield select(loginTokenSelector);
    if (!token) {
      yield cancel();
    } else {
      const loginByTokenTask = yield fork(loginByTokenSaga);
      yield join(loginByTokenTask);
      if (loginByTokenTask.isCancelled()) {
        yield cancel();
      }
    }
  } finally {
    yield delay(2000);
    yield put(removeProcessingFlag(PROCESSING_FLAG.AUTO_LOGIN));
  }
}
