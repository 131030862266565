import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import { userAccessedToPageThatNeedsShopListSaga } from "./sagas/userAccessedToPageThatNeedsShopListSaga";
import { userAccessedToPageThatNeedsCurrentShopDataSaga } from "./sagas/userAccessedToPageThatNeedsCurrentShopDataSaga";
import { userTouchedShopJudgeRequestButtonSaga } from "./sagas/userTouchedShopJudgeRequestButtonSaga";
import { userAccessedToPageThatNeedsShopItemListSaga } from "./sagas/userAccessedToPageThatNeedsShopItemListSaga";
import { userAccessedToPageThatNeedsCategoryTagListSaga } from "./sagas/userAccessedToPageThatNeedsCategoryTagListSaga";
import { userAccessedToPageThatNeedsOptionSetListSaga } from "./sagas/userAccessedToPageThatNeedsOptionSetListSaga";
import { userSubmittedDraftShopAddressSaga } from "./sagas/userSubmittedDraftShopAddressSaga";
import { userSubmittedDraftShopImageSaga } from "./sagas/userSubmittedDraftShopImageSaga";
import { userSubmittedDraftShopItemImageSaga } from "./sagas/userSubmittedDraftShopItemImageSaga";
import { userSubmittedShopRegisterSaga } from "./sagas/userSubmittedShopRegisterSaga";
import { userSubmittedShopEditSaga } from "./sagas/userSubmittedShopEditSaga";
import { userSubmittedShopItemCategoryRegisterSaga } from "./sagas/userSubmittedShopItemCategoryRegisterSaga";
import { userSubmittedShopItemCategoryEditSaga } from "./sagas/userSubmittedShopItemCategoryEditSaga";
import { userSubmittedShopItemRegisterSaga } from "./sagas/userSubmittedShopItemRegisterSaga";
import { userSubmittedShopItemEditSaga } from "./sagas/userSubmittedShopItemEditSaga";
import { userTouchedChangeItemReleaseStateButtonSaga } from "./sagas/userTouchedChangeItemReleaseStateButtonSaga";
import { userSubmittedShopOptionSetRegisterSaga } from "./sagas/userSubmittedShopOptionSetRegisterSaga";
import { userSubmittedShopOptionSetEditSaga } from "./sagas/userSubmittedShopOptionSetEditSaga";
import { userSubmittedShopOptionRegisterSaga } from "./sagas/userSubmittedShopOptionRegisterSaga";
import { userSubmittedShopOptionEditSaga } from "./sagas/userSubmittedShopOptionEditSaga";
import { userSubmittedCookingTimeSaga } from "./sagas/userSubmittedCookingTimeSaga";
import { userSubmittedBusinessTimeSaga } from "./sagas/userSubmittedBusinessTimeSaga";
import { userSubmittedUpdateBusinessHolidaySaga } from "./sagas/userSubmittedUpdateBusinessHolidaySaga";
import { userSubmittedDeleteBusinessHolidaySaga } from "./sagas/userSubmittedDeleteBusinessHolidaySaga";
import { userChangedDraftBankCodeSaga } from "./sagas/userChangedDraftBankCodeSaga";
import { userSubmittedBankAccountSaga } from "./sagas/userSubmittedBankAccountSaga";
import { userSubmittedShopLicenseEditSaga } from "./sagas/userSubmittedShopLicenseEditSaga";
import { userSubmittedDraftLicenseImageSaga } from "./sagas/userSubmittedDraftLicenseImageSaga";
import { userSubmittedReservationOpenDateSaga } from "./sagas/userSubmittedReservationOpenDateSaga";
import { userTouchedSortShopItemButtonSaga } from "./sagas/userTouchedSortShopItemButtonSaga";
import { userTouchedSortShopItemCategoryButtonSaga } from "./sagas/userTouchedSortShopItemCategoryButtonSaga";
import { userTouchedSortShopOptionSetButtonSaga } from "./sagas/userTouchedSortShopOptionSetButtonSaga";
import { userAccessedToPageThatNeedsShopStaffListSaga } from "./sagas/userAccessedToPageThatNeedsShopStaffListSaga";
import { userSubmittedUpdateShopStaffPasswordSaga } from "./sagas/userSubmittedUpdateShopStaffPasswordSaga";
import { userSubmittedRegisterShopStaffSaga } from "./sagas/userSubmittedRegisterShopStaffSaga";
import { userSubmittedDeleteShopStaffSaga } from "./sagas/userSubmittedDeleteShopStaffSaga";

import { systemInitDraftShopAddressSaga } from "./sagas/systemInitDraftShopAddressSaga";
import { systemInitDraftShopImageSaga } from "./sagas/systemInitDraftShopImageSaga";
import { systemInitDraftShopItemImageSaga } from "./sagas/systemInitDraftShopItemImageSaga";
import { systemInitDraftLicenseImageSaga } from "./sagas/systemInitDraftLicenseImageSaga";

function* watchUserAccessedToPageThatNeedsShopListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_LIST,
    userAccessedToPageThatNeedsShopListSaga,
  );
}

function* watchUserAccessedToPageThatNeedsCurrentShopDataSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CURRENT_SHOP_DATA,
    userAccessedToPageThatNeedsCurrentShopDataSaga,
  );
}

function* watchUserTouchedShopJudgeRequestButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_SHOP_JUDGE_REQUEST_BUTTON,
    userTouchedShopJudgeRequestButtonSaga,
  );
}

function* watchUserAccessedToPageThatNeedsShopItemListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_ITEM_LIST,
    userAccessedToPageThatNeedsShopItemListSaga,
  );
}

function* watchUserAccessedToPageThatNeedsCategoryTagListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_CATEGORY_TAG_LIST,
    userAccessedToPageThatNeedsCategoryTagListSaga,
  );
}

function* watchUserAccessedToPageThatNeedsOptionSetListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_OPTION_SET_LIST,
    userAccessedToPageThatNeedsOptionSetListSaga,
  );
}

function* watchUserSubmittedDraftShopAddressSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_DRAFT_SHOP_ADDRESS, userSubmittedDraftShopAddressSaga);
}

function* watchUserSubmittedDraftShopImageSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_DRAFT_SHOP_IMAGE, userSubmittedDraftShopImageSaga);
}

function* watchUserSubmittedDraftShopItemImageSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_DRAFT_SHOP_ITEM_IMAGE,
    userSubmittedDraftShopItemImageSaga,
  );
}

function* watchUserSubmittedShopRegisterSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_SHOP_REGISTER, userSubmittedShopRegisterSaga);
}

function* watchUserSubmittedShopEditSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_SHOP_EDIT, userSubmittedShopEditSaga);
}

function* watchUserSubmittedShopItemCategoryRegisterSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SHOP_ITEM_CATEGORY_REGISTER,
    userSubmittedShopItemCategoryRegisterSaga,
  );
}

function* watchUserSubmittedShopItemCategoryEditSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SHOP_ITEM_CATEGORY_EDIT,
    userSubmittedShopItemCategoryEditSaga,
  );
}

function* watchUserSubmittedShopItemRegisterSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_SHOP_ITEM_REGISTER, userSubmittedShopItemRegisterSaga);
}

function* watchUserSubmittedShopItemEditSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_SHOP_ITEM_EDIT, userSubmittedShopItemEditSaga);
}

function* watchUserTouchedChangeItemReleaseStateButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_CHANGE_ITEM_RELEASE_STATE_BUTTON,
    userTouchedChangeItemReleaseStateButtonSaga,
  );
}

function* watchUserSubmittedShopOptionSetRegisterSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SHOP_OPTION_SET_REGISTER,
    userSubmittedShopOptionSetRegisterSaga,
  );
}

function* watchUserSubmittedShopOptionSetEditSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SHOP_OPTION_SET_EDIT,
    userSubmittedShopOptionSetEditSaga,
  );
}

function* watchUserSubmittedShopOptionRegisterSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_SHOP_OPTION_REGISTER,
    userSubmittedShopOptionRegisterSaga,
  );
}

function* watchUserSubmittedShopOptionEditSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_SHOP_OPTION_EDIT, userSubmittedShopOptionEditSaga);
}

function* watchUserSubmittedCookingTimeSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_COOKING_TIME, userSubmittedCookingTimeSaga);
}

function* watchUserSubmittedBusinessTimeSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_BUSINESS_TIME, userSubmittedBusinessTimeSaga);
}

function* watchUserSubmittedUpdateBusinessHolidaySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_BUSINESS_HOLIDAY,
    userSubmittedUpdateBusinessHolidaySaga,
  );
}

function* watchUserSubmittedDeleteBusinessHolidaySaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_DELETE_BUSINESS_HOLIDAY,
    userSubmittedDeleteBusinessHolidaySaga,
  );
}

function* watchUserChangedDraftBankCodeSaga() {
  yield takeLatest(ActionTypes.U_CHANGED_DRAFT_BANK_CODE, userChangedDraftBankCodeSaga);
}

function* watchUserSubmittedBankAccountSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_BANK_ACCOUNT, userSubmittedBankAccountSaga);
}

function* watchUserSubmittedShopLicenseEditSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_SHOP_LICENSE_EDIT, userSubmittedShopLicenseEditSaga);
}

function* watchUserSubmittedDraftLicenseImageSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_DRAFT_LICENSE_IMAGE, userSubmittedDraftLicenseImageSaga);
}

function* watchUserSubmittedReservationOpenDateSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_RESERVATION_OPEN_DATE,
    userSubmittedReservationOpenDateSaga,
  );
}

function* watchUserTouchedSortShopItemButtonSaga() {
  yield takeLatest(ActionTypes.U_TOUCED_SORT_SHOP_ITEM_BUTTON, userTouchedSortShopItemButtonSaga);
}

function* watchUserTouchedSortShopItemCategoryButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCED_SORT_SHOP_ITEM_CATEGORY_BUTTON,
    userTouchedSortShopItemCategoryButtonSaga,
  );
}

function* watchUserTouchedSortShopOptionSetButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCED_SORT_SHOP_OPTION_SET_BUTTON,
    userTouchedSortShopOptionSetButtonSaga,
  );
}

function* watchUserAccessedToPageThatNeedsShopStaffListSaga() {
  yield takeLatest(
    ActionTypes.U_ACCESSED_TO_PAGE_THAT_NEEDS_SHOP_STAFF_LIST,
    userAccessedToPageThatNeedsShopStaffListSaga,
  );
}

function* watchUserSubmittedUpdateShopStaffPasswordSaga() {
  yield takeLatest(
    ActionTypes.U_SUBMITTED_UPDATE_SHOP_STAFF_PASSWORD,
    userSubmittedUpdateShopStaffPasswordSaga,
  );
}

function* watchUserSubmittedRegisterShopStaffSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_REGISTER_SHOP_STAFF, userSubmittedRegisterShopStaffSaga);
}

function* watchUserSubmittedDeleteShopStaffSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_DELETE_SHOP_STAFF, userSubmittedDeleteShopStaffSaga);
}

function* watchSystemInitDraftShopAddressSaga() {
  yield takeLatest(ActionTypes.S_INIT_DRAFT_SHOP_ADDRESS, systemInitDraftShopAddressSaga);
}

function* watchSystemInitDraftShopImageSaga() {
  yield takeLatest(ActionTypes.S_INIT_DRAFT_SHOP_IMAGE, systemInitDraftShopImageSaga);
}

function* watchSystemInitDraftShopItemImageSaga() {
  yield takeLatest(ActionTypes.S_INIT_DRAFT_SHOP_ITEM_IMAGE, systemInitDraftShopItemImageSaga);
}

function* watchSystemInitDraftLicenseImageSaga() {
  yield takeLatest(ActionTypes.S_INIT_DRAFT_LICENSE_IMAGE, systemInitDraftLicenseImageSaga);
}

export function* shopSaga() {
  yield fork(watchUserAccessedToPageThatNeedsShopListSaga);
  yield fork(watchUserAccessedToPageThatNeedsCurrentShopDataSaga);
  yield fork(watchUserTouchedShopJudgeRequestButtonSaga);
  yield fork(watchUserAccessedToPageThatNeedsShopItemListSaga);
  yield fork(watchUserAccessedToPageThatNeedsCategoryTagListSaga);
  yield fork(watchUserAccessedToPageThatNeedsOptionSetListSaga);
  yield fork(watchUserSubmittedDraftShopAddressSaga);
  yield fork(watchUserSubmittedDraftShopImageSaga);
  yield fork(watchUserSubmittedDraftShopItemImageSaga);
  yield fork(watchUserSubmittedShopRegisterSaga);
  yield fork(watchUserSubmittedShopEditSaga);
  yield fork(watchUserSubmittedShopItemCategoryRegisterSaga);
  yield fork(watchUserSubmittedShopItemCategoryEditSaga);
  yield fork(watchUserSubmittedShopItemRegisterSaga);
  yield fork(watchUserSubmittedShopItemEditSaga);
  yield fork(watchUserTouchedChangeItemReleaseStateButtonSaga);
  yield fork(watchUserSubmittedShopOptionSetRegisterSaga);
  yield fork(watchUserSubmittedShopOptionSetEditSaga);
  yield fork(watchUserSubmittedShopOptionRegisterSaga);
  yield fork(watchUserSubmittedShopOptionEditSaga);
  yield fork(watchUserSubmittedCookingTimeSaga);
  yield fork(watchUserSubmittedBusinessTimeSaga);
  yield fork(watchUserSubmittedUpdateBusinessHolidaySaga);
  yield fork(watchUserSubmittedDeleteBusinessHolidaySaga);
  yield fork(watchUserChangedDraftBankCodeSaga);
  yield fork(watchUserSubmittedBankAccountSaga);
  yield fork(watchUserSubmittedShopLicenseEditSaga);
  yield fork(watchUserSubmittedDraftLicenseImageSaga);
  yield fork(watchUserSubmittedReservationOpenDateSaga);
  yield fork(watchUserTouchedSortShopItemButtonSaga);
  yield fork(watchUserTouchedSortShopItemCategoryButtonSaga);
  yield fork(watchUserTouchedSortShopOptionSetButtonSaga);
  yield fork(watchUserAccessedToPageThatNeedsShopStaffListSaga);
  yield fork(watchUserSubmittedUpdateShopStaffPasswordSaga);
  yield fork(watchUserSubmittedRegisterShopStaffSaga);
  yield fork(watchUserSubmittedDeleteShopStaffSaga);

  yield fork(watchSystemInitDraftShopAddressSaga);
  yield fork(watchSystemInitDraftShopImageSaga);
  yield fork(watchSystemInitDraftShopItemImageSaga);
  yield fork(watchSystemInitDraftLicenseImageSaga);
}
