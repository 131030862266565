import * as Yup from "yup";
import { ShopData, BankAccountType, BANK_ACCOUNT_TYPE } from "../records/ShopData";

export type BankAccountEditForm = {
  shop_id: number;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  account_type: BankAccountType;
  account_number: string;
  account_name: string;
};

export const initialBankAccountEditForm: BankAccountEditForm = {
  shop_id: 0,
  bank_code: "",
  bank_name: "",
  branch_code: "",
  account_type: BANK_ACCOUNT_TYPE.SAVINGS_ACCOUNT,
  account_number: "",
  account_name: "",
};

export const getInitialValues = (shopData: ShopData): BankAccountEditForm => ({
  ...initialBankAccountEditForm,
  shop_id: shopData.id,
  bank_code: shopData.bank_code,
  bank_name: shopData.bank_name,
  branch_code: shopData.branch_code,
  account_type: shopData.bank_type,
  account_number: shopData.bank_number,
  account_name: shopData.bank_account_name,
});

export const bankAccountEditFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  bank_code: Yup.string().required("入力してください"),
  bank_name: Yup.string(),
  branch_code: Yup.string(),
  account_type: Yup.mixed<BankAccountType>().oneOf(Object.values(BANK_ACCOUNT_TYPE)),
  account_number: Yup.string().required("入力してください"),
  account_name: Yup.string().required("入力してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
