import produce from "immer";
import { AppState } from "src/modules/app/model";

export type ModalKey = "SHOULD_UPDATE_RESOURCE" | "COMMON_ALERT";

type ModalPayloadMap = {};

export type ModalPayload<T extends ModalKey> = T extends keyof ModalPayloadMap
  ? ModalPayloadMap[T]
  : {};

type ModalData<T extends ModalKey> = {
  key: T;
  payload: ModalPayload<T>;
};

export type ModalManagerProps = {
  list: Array<ModalData<ModalKey>>;
};

const initialState: ModalManagerProps = {
  list: [],
};

const getPriority = (key: ModalKey) => {
  switch (key) {
    case "SHOULD_UPDATE_RESOURCE":
      return 1;
    default:
      return 99;
  }
};

const sortByPriority = (a: ModalData<ModalKey>, b: ModalData<ModalKey>) => {
  const [pA, pB] = [getPriority(a.key), getPriority(b.key)];
  if (pA === pB) {
    return 0;
  }
  return pA > pB ? 1 : -1;
};

const getDisplayable = (state: AppState) => {
  return state.modalManager.list.slice().sort(sortByPriority)[0];
};

const getList = (state: AppState) => {
  const modal: ModalManagerProps = state.modalManager;
  return modal.list;
};

const canDisplay = (state: AppState, key: ModalKey) => {
  const displayable = getDisplayable(state);
  if (typeof displayable === "undefined") {
    return false;
  }
  return displayable.key === key;
};

const getPayload = <T extends ModalKey>(state: AppState, key: T) => {
  const data = getDisplayable(state);

  if (typeof data === "undefined") {
    return undefined;
  }

  if (data.key !== key) {
    return undefined;
  }

  return data.payload as ModalPayload<T>;
};

const pushKey = <T extends ModalKey>(state: AppState, key: T, payload: ModalPayload<T>) => {
  return produce(state, (draft) => {
    const modalList = getList(draft);
    modalList.push({ key, payload });
    draft.modalManager.list = modalList;
  });
};

const removeAll = (state: AppState, targetKey: ModalKey) => {
  return produce(state, (draft) => {
    draft.modalManager.list = getList(draft).filter((i) => i.key !== targetKey);
  });
};

const removeKey = (state: AppState, targetKey: ModalKey) => {
  return removeAll(state, targetKey);
};

export const ModalManager = {
  initialState,
  canDisplay,
  getPayload,
  pushKey,
  removeKey,
};
