import { FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  ShopItemCategoryEditForm,
  getInitialValues,
  shopItemCategoryEditFormValidationSchema,
} from "../../forms/shopItemCategoryEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import { userSubmittedShopItemCategoryEdit } from "../../modules/shop/actions";
import { ShopModel } from "../../modules/shop/model";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { Auth } from "../organisms/Auth";

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ shopId: string; categoryId: string }>,
) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  const categoryId = parseInt(ownProps.match.params.categoryId, 10);
  return {
    shopId,
    categoryId,
    category: ShopModel.getShopItemCategoryById(state.shop, categoryId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopItemCategoryEditForm) {
    dispatch(userSubmittedShopItemCategoryEdit(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopItemCategory/${shopId}`));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopItemCategoryEditForm> &
  PropsBase & {
    children?: never;
  };

const ShopItemCategoryEditTemplate: React.FC<Props> = React.memo(
  ({ shopId, handleBack, values, handleChange, handleSubmit, isValid }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="商品カテゴリー一覧に戻る" handleClick={handleClickBack} />
          <PageTitle>商品カテゴリーの編集</PageTitle>
          <FormContainer onSubmit={handleSubmit}>
            <input type="hidden" name="shop_id" value={values.shop_id} />
            <input type="hidden" name="category_name_en" value="" />
            <FormInner>
              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="category_name" required>
                    商品カテゴリ名
                  </FormLabel>
                  <TextField
                    type="text"
                    name="category_name"
                    value={values.category_name}
                    onChange={handleChange}
                  />
                </FormField>
              </FormFieldGroup>
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                登録
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopItemCategoryEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopId, category }): ShopItemCategoryEditForm =>
    getInitialValues(shopId, category),
  validationSchema: shopItemCategoryEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopItemCategoryEditTemplate)),
);
