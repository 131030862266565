import styled from "styled-components";
import { colorsConst } from "../../styles/const";
import { media } from "../../styles/mixins/media";

export const SalesReportContainer = styled.div`
  padding: 1em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  border-radius: 4px;
`;

export const SalesReportHeader = styled.div``;

export const SalesReportHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  margin-bottom: 0.5em;
`;

export const SalesReportHeaderKey = styled.div`
  width: 4em;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const SalesReportHeaderInput = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  input {
    padding: 0.25em;
  }
  select {
    padding: 0.5em 0.25em;
    width: 100% !important;
    ${media.tablet} {
      width: auto;
    }
  }
`;

export const SalesReportBody = styled.div`
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid ${colorsConst.BORDER.THICK};
`;

export const SalesReportTable = styled.div``;

export const SalesReportRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
`;

export const RowHeadColumn = styled.div<{ header?: boolean }>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 4em;
  padding: 0.25em;
  display: flex;
  justify-content: center;
  ${(p) => (p.header ? "align-items: flex-end;" : "")}
`;

export const SalesColumn = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export const SalesHeader = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.25em;
  text-align: center;
`;

export const SalesRow = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${media.tablet} {
    flex-direction: row;
  }
`;

export const SalesCell = styled.div<{ header?: boolean }>`
  display: ${(p) => (p.header ? "none;" : "flex")};
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: ${(p) => (p.header ? "center" : "space-between")};
  &::before {
    content: attr(data-label);
    flex-grow: 0;
    flex-shrink: 0;
    width: 7em;
    padding: 0.25em;
  }
  ${media.tablet} {
    width: ${100 / 3}%;
    display: block;
    text-align: ${(p) => (p.header ? "center" : "right")};
    &::before {
      content: none;
    }
  }
`;

export const SalesVal = styled.div`
  padding: 0.25em;
`;
