import { ActionTypes, ShopActions } from "./actions";
import { ShopState, ShopModel } from "./model";

export default function reducer(state: ShopState = ShopModel.initialState, action: ShopActions) {
  switch (action.type) {
    case ActionTypes.R_UPDATE_SHOP_LIST:
      return ShopModel.updateShopList(state, action.payload.shopList);
    case ActionTypes.R_UPDATE_CURRENT_SHOP_DATA:
      return ShopModel.updateCurrentShopData(state, action.payload.shopData);
    case ActionTypes.R_UPDATE_SHOP_ITEM_CATEGORY_LIST:
      return ShopModel.updateShopItemCategoryList(state, action.payload.shopItem);
    case ActionTypes.R_UPDATE_CURRENT_SHOP_ITEM:
      return ShopModel.updateCurrentShopItem(state, action.payload.shopItem);
    case ActionTypes.R_UPDATE_CATEGORY_TAG_LIST:
      return ShopModel.updateCategoryTagList(state, action.payload.categoryTagList);
    case ActionTypes.R_SHOP_ITEM_OPTION_SET_LIST:
      return ShopModel.updateShopItemOptionSetList(state, action.payload.optionSetList);
    case ActionTypes.R_UPDATE_DRAFT_SHOP_LAT_LNG:
      return ShopModel.updateDraftShopLatLng(state, action.payload.latLng);
    case ActionTypes.R_UPDATE_DRAFT_SHOP_IMAGE:
      return ShopModel.updateDraftShopImage(state, action.payload.imagePath);
    case ActionTypes.R_UPDATE_DRAFT_SHOP_ITEM_IMAGE:
      return ShopModel.updateDraftShopItemImage(state, action.payload.imagePath);
    case ActionTypes.R_UPDATE_DRAFT_BANK_NAME:
      return ShopModel.updateDraftBankName(state, action.payload.bankName);
    case ActionTypes.R_UPDATE_DRAFT_BANK_BRANCH_LIST:
      return ShopModel.updateDraftBankBranchList(state, action.payload.branchList);
    case ActionTypes.R_UPDATE_DRAFT_LICENSE_IMAGE:
      return ShopModel.updateDraftLicenseImage(state, action.payload.imagePath);
    case ActionTypes.R_UPDATE_SHOP_STAFF_LIST:
      return ShopModel.updateShopStaffList(state, action.payload.staffList);
    default:
      return state;
  }
}
