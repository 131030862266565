import React from "react";
import styled from "styled-components";
import { colorsConst } from "../../styles/const";

const Container = styled.div`
  margin-bottom: 1em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
`;

export const ShopDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5em 0.75em;
  & + & {
    border-top: 1px solid ${colorsConst.BORDER.DEFAULT};
  }
`;

export const ShopDetailRowInner = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

export const ShopDetailKey = styled.div`
  width: 10em;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${colorsConst.TEXT.SECONDARY};
`;

export const ShopDetailVal = styled.div`
  padding-left: 0.5em;
  flex-grow: 1;
  flex-shrink: 1;
`;

type Props = {
  children: React.ReactNode;
};

export const ShopDetailSection: React.FC<Props> = React.memo(({ children }) => {
  return <Container>{children}</Container>;
});
