import React from "react";
import styled from "styled-components";
import {
  FontSizes,
  TextColors,
  colorsConst,
  FontWeights,
  fontWeightsConst,
} from "../../styles/const";
import { fontSize } from "../../styles/mixins";

const Container = styled.span<{ size?: FontSizes; textColor?: TextColors; weight?: FontWeights }>`
  ${(p) => (typeof p.size !== "undefined" ? fontSize[p.size] : "")};
  ${(p) => (typeof p.textColor !== "undefined" ? `color: ${colorsConst.TEXT[p.textColor]}` : "")};
  ${(p) => (typeof p.weight !== "undefined" ? `font-weight: ${fontWeightsConst[p.weight]}` : "")};
`;

type Props = {
  children: string;
  size?: FontSizes;
  textColor?: TextColors;
  style?: React.CSSProperties;
  weight?: FontWeights;
};

export const Text: React.FC<Props> = React.memo(({ size, textColor, weight, children, style }) => {
  return (
    <Container size={size} textColor={textColor} weight={weight} style={style}>
      {children}
    </Container>
  );
});
