import produce from "immer";
import { ShopData } from "../../records/ShopData";
import { ShopItemCategory } from "../../records/ShopItemCategory";
import { ShopItem } from "../../records/ShopItem";
import { CategoryTag } from "../../records/CategoryTag";
import { OptionSet } from "../../records/OptionSet";
import { Option } from "../../records/Option";
import { BankBranch } from "../../records/BankBranch";
import { ShopLicenseType } from "../../records/ShopLicense";
import { ShopStaff } from "../../records/ShopStaff";
import { LatLng } from "../../records/LatLng";

export const DIRECTION_TYPE = {
  UP: -1,
  DOWN: 1,
} as const;

export type DirectionType = ValueOf<typeof DIRECTION_TYPE>;

export type ShopState = Readonly<{
  shopList: ShopData[];
  currentShopData: ShopData;
  shopItemCategoryList: ShopItemCategory[];
  categoryTagList: CategoryTag[];
  shopItemOptionSetList: OptionSet[];
  draftShopLatLng: LatLng;
  draftShopImage: string;
  draftShopItemImage: string;
  draftBankName: string;
  draftBranchList: BankBranch[];
  draftLicenseImage: string;
  shopStaffList: ShopStaff[];
}>;

const initialState: ShopState = {
  shopList: [],
  currentShopData: ShopData.create(),
  shopItemCategoryList: [],
  categoryTagList: [],
  shopItemOptionSetList: [],
  draftShopLatLng: LatLng.create(),
  draftShopImage: "",
  draftShopItemImage: "",
  draftBankName: "",
  draftBranchList: [],
  draftLicenseImage: "",
  shopStaffList: [],
};

const updateShopList = (state: ShopState, value: ShopData[]) => {
  return produce(state, (draft) => {
    draft.shopList = value.map((i) => ShopData.create(i));
  });
};

const existsShopList = (state: ShopState) => {
  return state.shopList.length > 0;
};

const updateCurrentShopData = (state: ShopState, value: ShopData) => {
  return produce(state, (draft) => {
    draft.currentShopData = ShopData.create(value);
  });
};

const existsCurrentShopData = (state: ShopState) => {
  return state.currentShopData.id !== 0;
};

const existsShopItemCategory = (state: ShopState) => {
  return state.shopItemCategoryList.length > 0;
};

const updateShopItemCategoryList = (state: ShopState, value: ShopItemCategory[]) => {
  return produce(state, (draft) => {
    draft.shopItemCategoryList = value.map((i) => ShopItemCategory.create(i));
  });
};

const updateCurrentShopItem = (state: ShopState, value: ShopItem) => {
  return produce(state, (draft) => {
    draft.shopItemCategoryList = state.shopItemCategoryList.map((category) => {
      return ShopItemCategory.getUpdatedCategoryWithUpdatedShopItemList(category, value);
    });
  });
};

const getShopItemCategoryById = (state: ShopState, id: number): ShopItemCategory | undefined => {
  return state.shopItemCategoryList.find((category) => category.category_id === id);
};

const getShopItemById = (state: ShopState, id: number): ShopItem | undefined => {
  return state.shopItemCategoryList
    .map((category) => category.item_list.find((i) => i.shop_item_id === id))
    .find((result) => typeof result !== "undefined");
};

const updateCategoryTagList = (state: ShopState, value: CategoryTag[]) => {
  return produce(state, (draft) => {
    draft.categoryTagList = value.map((i) => CategoryTag.create(i));
  });
};

const getPresentationCategoryTagNameListByIds = (state: ShopState, ids: number[]) => {
  const categoryList = ids.map((id) => {
    return state.categoryTagList.find((category) => category.id === id);
  });
  return categoryList
    .map((category) =>
      typeof category !== "undefined" ? category.tag_name : "カテゴリーがありません",
    )
    .join(",");
};

const updateShopItemOptionSetList = (state: ShopState, value: OptionSet[]) => {
  return produce(state, (draft) => {
    draft.shopItemOptionSetList = value.map((i) => OptionSet.create(i));
  });
};

const existsShopItemOptionSet = (state: ShopState) => {
  return state.shopItemOptionSetList.length > 0;
};

const getOptionSetById = (state: ShopState, optionSetId: number): OptionSet | undefined => {
  return state.shopItemOptionSetList.find((optionSet) => optionSet.option_set_id === optionSetId);
};

const getOptionById = (state: ShopState, optionId: number): Option | undefined => {
  return state.shopItemOptionSetList
    .map((optionSet) => optionSet.option_list.find((i) => i.shop_option_id === optionId))
    .find((result) => typeof result !== "undefined");
};

const updateDraftShopLatLng = (state: ShopState, value: LatLng) => {
  return produce(state, (draft) => {
    draft.draftShopLatLng = LatLng.create(value);
  });
};

const updateDraftShopImage = (state: ShopState, value?: string) => {
  return produce(state, (draft) => {
    draft.draftShopImage = value || "";
  });
};

const updateDraftShopItemImage = (state: ShopState, value?: string) => {
  return produce(state, (draft) => {
    draft.draftShopItemImage = value || "";
  });
};

const updateDraftBankName = (state: ShopState, value: string) => {
  return produce(state, (draft) => {
    draft.draftBankName = value;
  });
};

const updateDraftBankBranchList = (state: ShopState, value: BankBranch[]) => {
  return produce(state, (draft) => {
    draft.draftBranchList = value.map((i) => BankBranch.create(i));
  });
};

const getCurrentShopLicenseByType = (state: ShopState, licenseType: ShopLicenseType) => {
  return ShopData.getLicenseByType(state.currentShopData, licenseType);
};

const updateDraftLicenseImage = (state: ShopState, value?: string) => {
  return produce(state, (draft) => {
    draft.draftLicenseImage = value || "";
  });
};

const updateShopStaffList = (state: ShopState, value: ShopStaff[]) => {
  return produce(state, (draft) => {
    draft.shopStaffList = value.map((i) => ShopStaff.create(i));
  });
};

const existsShopStaff = (state: ShopState) => {
  return state.shopStaffList.length > 0;
};

export const ShopModel = {
  initialState,
  updateShopList,
  existsShopList,
  updateCurrentShopData,
  existsCurrentShopData,
  updateShopItemCategoryList,
  updateCurrentShopItem,
  getShopItemCategoryById,
  getShopItemById,
  existsShopItemCategory,
  updateShopItemOptionSetList,
  existsShopItemOptionSet,
  getOptionSetById,
  getOptionById,
  updateCategoryTagList,
  getPresentationCategoryTagNameListByIds,
  updateDraftShopLatLng,
  updateDraftShopImage,
  updateDraftShopItemImage,
  updateDraftBankName,
  updateDraftBankBranchList,
  getCurrentShopLicenseByType,
  updateDraftLicenseImage,
  updateShopStaffList,
  existsShopStaff,
};
