import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import {
  UpdateShopItemReleaseStateRequest,
  UpdateShopItemReleaseStateResponse,
} from "../../../apis/updateShopItemReleaseState";
import { updateCurrentShopItem, UserTouchedChangeItemReleaseStateButtonAction } from "../actions";

export function* userTouchedChangeItemReleaseStateButtonSaga(
  action: UserTouchedChangeItemReleaseStateButtonAction,
) {
  try {
    const params: UpdateShopItemReleaseStateRequest = {
      shop_id: action.payload.shopId,
      shop_item_category_id: action.payload.categoryId,
      shop_item_id: action.payload.itemId,
    };
    const commonApiTask = yield fork(() =>
      commonApiSaga(API_KEY.UPDATE_SHOP_ITEM_RELEASE_STATE, params),
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error,
    }: { result: UpdateShopItemReleaseStateResponse; error: unknown } = yield join(commonApiTask);
    if (result && !error) {
      yield put(updateCurrentShopItem(result.shop_item));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "店舗情報の更新に失敗しました。",
      }),
    );
  }
}
