import { Utility } from "./Utility";

const GEOCODER_API_URL = "https://maps.googleapis.com/maps/api/geocode/json";

export type GeocodeResult = {
  results: google.maps.GeocoderResult[];
  status: google.maps.GeocoderStatus;
};

export async function geocode(address: string) {
  const endPoint = [
    `${GEOCODER_API_URL}?key=${Utility.getGoogleMapsApiKey()}`,
    `&address=${address}`,
    `&language=ja`,
    `&components=country:jp`,
  ].join("");
  const response = await fetch(endPoint);
  const result: GeocodeResult = await response.json();

  return result;
}
