import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { darken } from "polished";
import { colorsConst, fontWeightsConst } from "../../styles/const";
import { fontSize } from "../../styles/mixins";
import { media } from "../../styles/mixins/media";
import { Loading } from "./Loading";

export type AppearanceType = "primary" | "secondary" | "default";
export type SizeType = "lg" | "sm";
export type DisplayType = "block" | "inline" | "responsive";

export type ContainerProps = {
  appearance: AppearanceType;
  invert?: boolean;
  size?: SizeType;
  display?: DisplayType;
  disabled?: boolean;
};

export const getSizeStyle = (size?: SizeType): FlattenSimpleInterpolation => {
  switch (size) {
    case "lg":
      return css`
        ${fontSize.XLARGE};
        padding: 8px 1em;
        ${media.tablet} {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      `;
    case "sm":
      return css`
        ${fontSize.DEFAULT};
        padding: 4px 1em;
        ${media.tablet} {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      `;
    default:
      return css`
        ${fontSize.LARGE};
        padding: 6px 1em;
        ${media.tablet} {
          padding-top: 9px;
          padding-bottom: 9px;
        }
      `;
  }
};

export const getDisplayStyle = (display?: DisplayType): FlattenSimpleInterpolation | undefined => {
  switch (display) {
    case "block":
      return css`
        display: block;
        width: 100%;
      `;
    case "inline":
      return css`
        display: inline-block;
        width: auto;
      `;
    case "responsive":
      return css`
        display: block;
        width: 100%;
        ${media.tablet} {
          display: inline-block;
          width: auto;
        }
      `;
    default:
      return undefined;
  }
};

export const getAppearanceStyle = (p: ContainerProps): FlattenSimpleInterpolation => {
  if (p.disabled) {
    return p.invert
      ? css`
          color: ${colorsConst.BUTTON.TEXT.SECONDARY};
          background-color: transparent;/*${colorsConst.BACKGROUND.PRIMARY};*/
          border: 1px solid ${colorsConst.BORDER.THICK};
        `
      : css`
          background-color: ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
        `;
  }
  switch (p.appearance) {
    case "primary":
      return p.invert
        ? css`
            color: ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
            background-color: transparent;/*${colorsConst.BACKGROUND.PRIMARY};*/
            border: 1px solid ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
            &:hover {
              color: ${colorsConst.BUTTON.TEXT.PRIMARY};
              background-color: ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
            }
          `
        : css`
            background-color: ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
            &:hover {
              background-color: ${darken(0.1, colorsConst.BUTTON.BACKGROUND.PRIMARY)};
            }
          `;
    case "secondary":
      return p.invert
        ? css`
            color: ${colorsConst.BUTTON.BACKGROUND.SECONDARY};
            background-color: transparent;/*${colorsConst.BACKGROUND.PRIMARY};*/
            border: 1px solid ${colorsConst.BUTTON.BACKGROUND.SECONDARY};
            &:hover {
              color: ${colorsConst.BUTTON.TEXT.PRIMARY};
              background-color: ${colorsConst.BUTTON.BACKGROUND.SECONDARY};
            }
          `
        : css`
            background-color: ${colorsConst.BUTTON.BACKGROUND.SECONDARY};
            &:hover {
              background-color: ${darken(0.1, colorsConst.BUTTON.BACKGROUND.SECONDARY)};
            }
          `;
    default:
      return p.invert
        ? css`
            color: ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
            background-color: transparent;/*${colorsConst.BACKGROUND.PRIMARY};*/
            border: 1px solid ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
            &:hover {
              color: ${colorsConst.BUTTON.TEXT.PRIMARY};
              background-color: ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
            }
          `
        : css`
            background-color: ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
            &:hover {
              background-color: ${darken(0.1, colorsConst.BUTTON.BACKGROUND.DEFAULT)};
            }
          `;
  }
};

export const defaultStyle = (p: ContainerProps): FlattenSimpleInterpolation => {
  const sizeStyle = getSizeStyle(p.size);
  const displayStyle = getDisplayStyle(p.display);
  const appearanceStyle = getAppearanceStyle(p);
  return css`
    color: ${colorsConst.BUTTON.TEXT.PRIMARY};
    ${sizeStyle};
    font-weight: ${fontWeightsConst.MEDIUM};
    cursor: pointer;
    appearance: none;
    border: none;
    border-radius: 4px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    ${displayStyle};
    ${appearanceStyle};
  `;
};

const Container = styled.button<ContainerProps>`
  ${defaultStyle}
`;

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  appearance?: AppearanceType;
  size?: SizeType;
  loading?: boolean;
  invert?: boolean;
  display?: DisplayType;
  style?: React.CSSProperties;
};

export const Button: React.FC<Props> = React.memo(
  ({ children, style, type = "button", appearance = "default", loading, ...rest }) => {
    return (
      <Container appearance={appearance} style={style} type={type} {...rest}>
        {loading ? <Loading /> : children}
      </Container>
    );
  },
);
