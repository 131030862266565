import { OrderSalesReport } from "./OrderSalesReport";

export type OrderSales = Readonly<{
  target_month: string;
  shop_id: number;
  total: number;
  orders: OrderSalesReport[];
}>;

const initialState: OrderSales = {
  target_month: "",
  shop_id: 0,
  total: 0,
  orders: [],
};

const create = (args: Partial<OrderSales> = {}) => {
  return {
    ...initialState,
    orders: args.orders ? args.orders.map((i) => OrderSalesReport.create(i)) : [],
    ...args,
  };
};

export const OrderSales = Object.freeze({
  initialState,
  create,
});
