import styled from "styled-components";
import { defaultStyle } from "../Button";

export const UploadButton = styled.label`
  ${defaultStyle({
    appearance: "primary",
    display: "responsive",
  })};
  text-align: center;
  input {
    display: none;
  }
`;
