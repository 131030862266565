export type ShopStaff = Readonly<{
  id: number;
  login_id: string;
  display_name: string;
}>;

const initialState: ShopStaff = {
  id: 0,
  login_id: "",
  display_name: "",
};

const create = (args: Partial<ShopStaff> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const ShopStaff = Object.freeze({
  initialState,
  create,
});
