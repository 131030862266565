import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import {
  ShopOptionSetEditForm,
  initialShopOptionSetEditForm,
  getInitialValues,
  shopOptionSetEditFormValidationSchema,
} from "../../forms/shopOptionSetEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userSubmittedShopOptionSetEdit,
  userAccessedToPageThatNeedsOptionSetList,
} from "../../modules/shop/actions";
import { ShopModel } from "../../modules/shop/model";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Paragraph } from "../atoms/Paragraph";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { Auth } from "../organisms/Auth";
import { ShopOptionSetFormFields } from "../organisms/ShopOptionSetFormFields";

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ shopId: string; optionSetId: string }>,
) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  const optionSetId = parseInt(ownProps.match.params.optionSetId, 10);
  return {
    shopId,
    optionSetId,
    optionSet: ShopModel.getOptionSetById(state.shop, optionSetId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopOptionSetEditForm) {
    dispatch(userSubmittedShopOptionSetEdit(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopOptionSet/${shopId}`));
  },
  userAccessedOptionSetEditPage(shopId: number) {
    dispatch(userAccessedToPageThatNeedsOptionSetList(shopId));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopOptionSetEditForm> &
  PropsBase & {
    children?: never;
  };

const ShopOptionSetEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    optionSet,
    handleBack,
    userAccessedOptionSetEditPage,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    useEffect(() => {
      userAccessedOptionSetEditPage(shopId);
    }, [shopId, userAccessedOptionSetEditPage]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer
            linkText="オプションセット一覧に戻る"
            handleClick={handleClickBack}
          />
          <PageTitle>オプションセットの編集</PageTitle>

          <Paragraph>
            保存したオプションセットは、商品登録の際に「オプションセット」の選択肢に追加されます。
          </Paragraph>

          {typeof optionSet !== "undefined" ? (
            <FormContainer onSubmit={handleSubmit}>
              <input type="hidden" name="shop_id" value={values.shop_id} />
              <input type="hidden" name="shop_option_set_id" value={values.shop_option_set_id} />

              <FormInner>
                <ShopOptionSetFormFields
                  shopId={shopId}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </FormInner>

              <ButtonContainer>
                <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                  更新
                </Button>
              </ButtonContainer>
            </FormContainer>
          ) : (
            <div>指定したオプションセットは存在しません。</div>
          )}
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopOptionSetEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopId, optionSet }): ShopOptionSetEditForm =>
    getInitialValues(shopId, optionSet),
  mapPropsToErrors: () =>
    getInitialErrors(shopOptionSetEditFormValidationSchema, initialShopOptionSetEditForm),
  validationSchema: shopOptionSetEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopOptionSetEditTemplate)),
);
