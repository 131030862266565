import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { GetDailySalesRequest, GetDailySalesResponse } from "../../../apis/getDailySales";
import { updateDailySales, UserTouchedUpdateDailySalesButtonAction } from "../actions";

export function* userTouchedUpdateDailySalesButtonSaga(
  action: UserTouchedUpdateDailySalesButtonAction,
) {
  try {
    const params: GetDailySalesRequest = {
      target_month: action.payload.targetMonth,
      shop_id: action.payload.shopId,
    };
    const commonApiTask = yield fork(commonApiSaga, API_KEY.GET_DAILY_SALES, params);
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: GetDailySalesResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateDailySales(result.daily_sales));
    } else {
      throw error;
    }
  } catch (exception) {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "レポートの取得に失敗しました。",
      }),
    );
  }
}
