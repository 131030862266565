import * as Yup from "yup";

export const LICENSE_FLAG = {
  TRUE: 1,
  FALSE: 0,
} as const;

export type LicenseFlagType = ValueOf<typeof LICENSE_FLAG>;

export type ShopItemRegisterForm = {
  shop_item_category_id: number;
  item_name: string;
  is_alcohol: number;
  is_meat: number;
  description: string;
  price: number;
  delivery_price: number;
  options: number[];
  cooking_time: number;
  image_path: string;
  shop_id: number;
};

export const initialShopItemRegisterForm: ShopItemRegisterForm = {
  shop_item_category_id: 0,
  item_name: "",
  is_alcohol: 0,
  is_meat: 0,
  description: "",
  price: 0,
  delivery_price: 0,
  options: [],
  cooking_time: 0,
  image_path: "",
  shop_id: 0,
};

export const shopItemRegisterFormValidationSchema = Yup.object({
  shop_item_category_id: Yup.number().notOneOf([0], "商品カテゴリを選択してください"),
  item_name: Yup.string().required("入力してください"),
  is_alcohol: Yup.mixed<LicenseFlagType>().oneOf(Object.values(LICENSE_FLAG)),
  is_meat: Yup.mixed<LicenseFlagType>().oneOf(Object.values(LICENSE_FLAG)),
  description: Yup.string().required("入力してください"),
  price: Yup.number().min(0, "不正な値です").required("入力してください"),
  delivery_price: Yup.number().min(0, "不正な値です").required("入力してください"),
  options: Yup.array().of(Yup.number()),
  cooking_time: Yup.number().min(0, "不正な値です").required("入力してください"),
  image_path: Yup.string().required("画像を選択してください"),
  shop_id: Yup.number(),
})
  .defined()
  .strict(true)
  .noUnknown();
