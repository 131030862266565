export type BreakPoints = {
  mobile: number;
  tablet: number;
  desktop: number;
};

const sizes: BreakPoints = {
  mobile: 480,
  tablet: 480,
  desktop: 768,
};

type MediaQueryMap = {
  [K in keyof BreakPoints]: string;
};

const customMediaQueryMin = (minWidth: number) => `@media (min-width: ${minWidth + 1}px)`;
const customMediaQueryMax = (minWidth: number) => `@media (max-width: ${minWidth}px)`;

export const media = Object.keys(sizes)
  .map((i) => i as keyof BreakPoints)
  .reduce<MediaQueryMap>((acc, label) => {
    if (label === "mobile") {
      // eslint-disable-next-line no-param-reassign
      acc[label] = customMediaQueryMax(sizes[label]);
    } else {
      // eslint-disable-next-line no-param-reassign
      acc[label] = customMediaQueryMin(sizes[label]);
    }
    return acc;
  }, {} as MediaQueryMap);
