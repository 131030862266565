import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { fontWeightsConst } from "../../styles/const";
import { userTouchedSortShopItemButton } from "../../modules/shop/actions";
import { DirectionType } from "../../modules/shop/model";
import { ShopItemCategory } from "../../records/ShopItemCategory";
import { ShopItemListItem } from "../molecules/ShopItemListItem";

const Container = styled.div`
  margin-top: 1em;
`;

const CategoryName = styled.div`
  margin-bottom: 0.5em;
  font-weight: ${fontWeightsConst.MEDIUM};
`;

type Props = {
  shopId: number;
  category: ShopItemCategory;
};

export const ShopItemListCategory: React.FC<Props> = React.memo(({ shopId, category }) => {
  const itemIds = useMemo(() => {
    return category.item_list.map((item) => item.shop_item_id);
  }, [category]);

  const dispatch = useDispatch();

  const handleSort = useCallback(
    (itemId: number, direction: DirectionType) => {
      dispatch(
        userTouchedSortShopItemButton(shopId, category.category_id, itemId, direction, itemIds),
      );
    },
    [dispatch, shopId, category, itemIds],
  );

  return (
    <Container>
      <CategoryName>{category.category_name}</CategoryName>
      {ShopItemCategory.existShopItem(category.item_list) ? (
        <>
          {category.item_list.map((item, index) => (
            <ShopItemListItem
              shopId={shopId}
              item={item}
              key={`${category.category_id}-${item.shop_item_id}`}
              index={index}
              length={category.item_list.length}
              handleSort={handleSort}
            />
          ))}
        </>
      ) : (
        <div>商品がありません</div>
      )}
    </Container>
  );
});
