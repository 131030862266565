import { fork, takeEvery, takeLatest, spawn } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import { checkUpdateSaga } from "./sagas/checkUpdateSaga";
import { systemAutoLoginSaga } from "./sagas/systemAutoLoginSaga";
import { systemAddedAlertSaga } from "./sagas/systemAddedAlertSaga";
import { systemInitAlertSaga } from "./sagas/systemInitAlertSaga";
import { systemOpenedModalSaga } from "./sagas/systemOpenedModalSaga";
import { systemClosedModalSaga } from "./sagas/systemClosedModalSaga";
import { systemDetectedResourceUpdateSaga } from "./sagas/systemDetectedResourceUpdateSaga";

function* watchSysyemAutoLoginSaga() {
  yield takeLatest(ActionTypes.S_AUTO_LOGIN, systemAutoLoginSaga);
}

function* watchSysyemAddAlertSaga() {
  yield takeLatest(ActionTypes.S_ADDED_ALERT, systemAddedAlertSaga);
}

function* watchSysyemInitAlertSaga() {
  yield takeLatest(ActionTypes.S_INIT_ALERT, systemInitAlertSaga);
}

function* watchSystemOpenedModalSaga() {
  yield takeEvery(ActionTypes.S_OPENED_MODAL, systemOpenedModalSaga);
}

function* watchSystemClosedModalSaga() {
  yield takeEvery(ActionTypes.S_CLOSED_MODAL, systemClosedModalSaga);
}

function* watchSystemDetectedResourceUpdateSaga() {
  yield takeLatest(ActionTypes.S_DETECTED_RESOURCE_UPDATE, systemDetectedResourceUpdateSaga);
}

export function* appSaga() {
  yield spawn(checkUpdateSaga);
  yield fork(watchSysyemAutoLoginSaga);
  yield fork(watchSysyemAddAlertSaga);
  yield fork(watchSysyemInitAlertSaga);
  yield fork(watchSystemOpenedModalSaga);
  yield fork(watchSystemClosedModalSaga);
  yield fork(watchSystemDetectedResourceUpdateSaga);
}
