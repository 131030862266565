import React, { useMemo, useCallback } from "react";
import styled from "styled-components";
import {
  OptionSet,
  OPTION_SELECT_TYPE,
  displayableOptionSelectType,
} from "../../records/OptionSet";
import { ShopOptionSetRegisterForm } from "../../forms/shopOptionSetRegister";
import { ShopOptionSetEditForm } from "../../forms/shopOptionSetEdit";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { SelectField } from "../atoms/FormParts/SelectField";
import { RadioField } from "../atoms/FormParts/RadioField";

const SelectCountRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const SelectCountField = styled(FormField)`
  width: 50%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const Separator = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 0.25em;
  align-self: center;
`;

type Props = {
  shopId: number;
  values: ShopOptionSetRegisterForm | ShopOptionSetEditForm;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  handleChange: (event: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  /* eslint-enable  @typescript-eslint/no-explicit-any */
};

export const ShopOptionSetFormFields: React.FC<Props> = React.memo(
  ({ values, handleChange, setFieldValue }) => {
    const selectTypeValues = displayableOptionSelectType.map((selectType) => {
      return {
        label: OptionSet.getPresentationOptionSelectTypeText(selectType),
        value: selectType,
      };
    });

    const selectableMinValue: number[] = useMemo(() => {
      return values.select_type === OPTION_SELECT_TYPE.REQUIRED
        ? Array.from(new Array(9)).map((v, i) => i + 1)
        : Array.from(new Array(10)).map((v, i) => i);
    }, [values]);

    const handleSelectTypeChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = parseInt(event.currentTarget.value, 10);
        if (Number.isNaN(targetValue)) {
          return;
        }
        if (targetValue === OPTION_SELECT_TYPE.REQUIRED) {
          if (values.select_min_count === 0) {
            setFieldValue("select_min_count", 1);
          }
        }
      },
      [values, setFieldValue],
    );

    return (
      <>
        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="shop_option_set_name" required>
              オプションセット名
            </FormLabel>
            <TextField
              type="text"
              name="shop_option_set_name"
              id="shop_option_set_name"
              onChange={handleChange}
              value={values.shop_option_set_name}
            />
          </FormField>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormLabel required>オプション選択の必須・任意を選びます</FormLabel>
          <RadioField
            name="select_type"
            values={selectTypeValues}
            isNum
            handleChange={handleSelectTypeChange}
          />
        </FormFieldGroup>

        <FormFieldGroup>
          <FormLabel>オプションを選択できる数</FormLabel>
          <SelectCountRow>
            <SelectCountField>
              <FormLabel htmlFor="select_min_count" required>
                最小数
              </FormLabel>
              <SelectField
                name="select_min_count"
                id="select_min_count"
                isNum
                value={values.select_min_count}
              >
                {selectableMinValue.map((i) => (
                  <option value={i} key={i}>
                    {i}
                  </option>
                ))}
              </SelectField>
            </SelectCountField>
            <Separator>〜</Separator>
            <SelectCountField>
              <FormLabel htmlFor="select_max_count" required>
                最大数
              </FormLabel>
              <SelectField
                name="select_max_count"
                id="select_max_count"
                isNum
                value={values.select_max_count}
              >
                {Array(9)
                  .fill("")
                  .map((_, index) => (
                    <option value={index + 1} key={index}>
                      {index + 1}
                    </option>
                  ))}
              </SelectField>
            </SelectCountField>
          </SelectCountRow>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="option_set_sort" required>
              オプションセット表示順
            </FormLabel>
            <TextField
              type="number"
              name="option_set_sort"
              id="option_set_sort"
              onChange={handleChange}
              value={values.option_set_sort}
            />
          </FormField>
          <FormNotice>
            大きいほどアプリ上で上位に表示されます。
            <br />
            0〜1000の間で入力してください。
          </FormNotice>
        </FormFieldGroup>
      </>
    );
  },
);
