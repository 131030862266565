import React, { useEffect, useCallback, useState, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { DateUtils } from "../../utils/DateUtils";
import { currentShopDataSelector } from "../../modules/shop/selectors";
import {
  userAccessedToPageThatNeedsCurrentShopData,
  userSubmittedUpdateBusinessHoliday,
} from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Paragraph } from "../atoms/Paragraph";
import { FormDivContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { Input } from "../atoms/FormParts/TextInput";
import { Button } from "../atoms/Button";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const BusinessHolidayEditTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const shopData = useSelector(currentShopDataSelector);
  const todayString = DateUtils.getDateString(new Date());

  const [draftDate, setDraftDate] = useState<string>(todayString);

  const isValidDateString = useMemo(() => {
    return DateUtils.isValidDateString(draftDate);
  }, [draftDate]);

  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDraftDate(event.target.value);
    },
    [setDraftDate],
  );

  const handleSubmit = useCallback(() => {
    dispatch(userSubmittedUpdateBusinessHoliday(shopId, draftDate));
  }, [dispatch, shopId, draftDate]);

  const handleBack = useCallback(() => {
    dispatch(push(`/shopBusiness/${shopId}`));
  }, [dispatch, shopId]);

  useEffect(() => {
    if (shopData.id !== shopId) {
      dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
    }
  }, [dispatch, shopId, shopData]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="営業時間設定に戻る" handleClick={handleBack} />
        <PageTitle>臨時休業日等の設定</PageTitle>
        <Paragraph>
          曜日別の営業時間設定で設定された休業日以外に、臨時で休業する予定がある日を設定できます。
        </Paragraph>
        <FormDivContainer>
          <FormInner>
            <FormFieldGroup>
              <FormField>
                <FormLabel htmlFor="holiday">休業日の指定</FormLabel>
                <Input
                  type="date"
                  name="holiday"
                  id="holiday"
                  min={todayString}
                  onChange={handleChange}
                  value={draftDate}
                />
              </FormField>
            </FormFieldGroup>
          </FormInner>
          <ButtonContainer>
            <Button
              type="button"
              appearance="primary"
              display="responsive"
              disabled={!isValidDateString}
              onClick={handleSubmit}
            >
              保存
            </Button>
          </ButtonContainer>
        </FormDivContainer>
      </Page>
    </Auth>
  );
});

export default withRouter(BusinessHolidayEditTemplate);
