import React, { useCallback } from "react";
import { push } from "connected-react-router";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { SectionTitle } from "../atoms/SectionTitle";
import { Button } from "../atoms/Button";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.5em;
`;

const SectionTitleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

type Props = {
  title: string;
  link?: string;
  disabled?: boolean;
};

export const ShopDetailSectionTitle: React.FC<Props> = React.memo(
  ({ title, link, disabled = false }) => {
    const dispatch = useDispatch();

    const handleLink = useCallback(() => {
      if (typeof link !== "undefined") {
        dispatch(push(link));
      }
    }, [dispatch, link]);

    return (
      <Container>
        <SectionTitleContainer>
          <SectionTitle>{title}</SectionTitle>
        </SectionTitleContainer>
        {typeof link !== "undefined" && (
          <ButtonContainer>
            <Button disabled={disabled} appearance="primary" size="sm" onClick={handleLink}>
              編集
            </Button>
          </ButtonContainer>
        )}
      </Container>
    );
  },
);
