import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Utility } from "../../utils/Utility";
import { DateUtils } from "../../utils/DateUtils";
import { media } from "../../styles/mixins/media";
import { monthlySalesSelector, existsMonthlySalesSelector } from "../../modules/sales/selectors";
import { userTouchedUpdateMonthlySalesButton } from "../../modules/sales/actions";
import { colorsConst } from "../../styles/const";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import {
  SalesReportContainer,
  SalesReportHeader,
  SalesReportHeaderRow,
  SalesReportHeaderKey,
  SalesReportHeaderInput,
  SalesReportBody,
  SalesReportTable,
  SalesReportRow,
  RowHeadColumn,
  SalesColumn,
  SalesHeader,
  SalesRow,
  SalesCell,
  SalesVal,
} from "../atoms/SalesReport";
import { Button } from "../atoms/Button";
import { Input } from "../atoms/FormParts/TextInput";
import { Auth } from "../organisms/Auth";

const INITIAL_DATE = new Date();
INITIAL_DATE.setDate(INITIAL_DATE.getDate() - 1);
const INITIAL_DATE_STRING = DateUtils.getYearMonthString(INITIAL_DATE);

const DateInput = styled(Input)`
  border: 1px solid ${colorsConst.BORDER.THICK};
`;
const ButtonContainer = styled.div``;
const ShopNameColumn = styled.div<{ header?: boolean }>`
  display: none;
  ${media.tablet} {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    ${(p) =>
      p.header
        ? `align-items: flex-end;
      justify-content: center;
    `
        : ""}
    width: 40%;
    padding: 0.25em;
  }
`;

const ShopNameRow = styled.div`
  padding: 0.25em;
  ${media.tablet} {
    display: none;
  }
`;

type Props = {};

export const MonthlySalesReportTemplate: React.FC<Props> = React.memo(() => {
  const monthlySales = useSelector(monthlySalesSelector);
  const existsMonthlySales = useSelector(existsMonthlySalesSelector);

  const [draftTargetMonth, setDraftTargetMonth] = useState<string>(INITIAL_DATE_STRING);

  const canSubmit = useMemo(() => {
    return draftTargetMonth !== "";
  }, [draftTargetMonth]);

  const dispatch = useDispatch();

  const handleMonthChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftTargetMonth(event.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(userTouchedUpdateMonthlySalesButton(draftTargetMonth));
  }, [dispatch, draftTargetMonth]);

  const initMonthlySales = useCallback(() => {
    dispatch(userTouchedUpdateMonthlySalesButton(INITIAL_DATE_STRING));
  }, [dispatch]);

  useEffect(() => {
    initMonthlySales();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Auth>
      <Page header>
        <PageTitle>月別レポート</PageTitle>
        <SalesReportContainer>
          <SalesReportHeader>
            <SalesReportHeaderRow>
              <SalesReportHeaderKey>集計月</SalesReportHeaderKey>
              <SalesReportHeaderInput>
                <DateInput type="month" onChange={handleMonthChange} value={draftTargetMonth} />
              </SalesReportHeaderInput>
            </SalesReportHeaderRow>
            <ButtonContainer>
              <Button
                onClick={handleSubmit}
                appearance="primary"
                display="responsive"
                disabled={!canSubmit}
              >
                集計
              </Button>
            </ButtonContainer>
          </SalesReportHeader>
          <SalesReportBody>
            {existsMonthlySales ? (
              <SalesReportTable>
                <SalesReportRow>
                  <RowHeadColumn header>店舗ID</RowHeadColumn>
                  <ShopNameColumn header>店舗名</ShopNameColumn>
                  <SalesColumn>
                    <SalesHeader>取扱高</SalesHeader>
                    <SalesRow>
                      <SalesCell header>テイクアウト</SalesCell>
                      <SalesCell header>デリバリー</SalesCell>
                      <SalesCell header>合計</SalesCell>
                    </SalesRow>
                  </SalesColumn>
                </SalesReportRow>
                <SalesReportRow>
                  <RowHeadColumn>合計</RowHeadColumn>
                  <ShopNameColumn />
                  <SalesColumn>
                    <SalesRow>
                      <SalesCell data-label="デリバリー">
                        <SalesVal>
                          {Utility.getPresentationPrice(monthlySales.sales.total.delivery)}
                        </SalesVal>
                      </SalesCell>
                      <SalesCell data-label="テイクアウト">
                        <SalesVal>
                          {Utility.getPresentationPrice(monthlySales.sales.total.takeout)}
                        </SalesVal>
                      </SalesCell>
                      <SalesCell data-label="合計">
                        <SalesVal>
                          {Utility.getPresentationPrice(monthlySales.sales.total.total)}
                        </SalesVal>
                      </SalesCell>
                    </SalesRow>
                  </SalesColumn>
                </SalesReportRow>
                {monthlySales.sales.reports.map((report) => (
                  <SalesReportRow key={report.shop_id}>
                    <RowHeadColumn>{report.shop_id}</RowHeadColumn>
                    <ShopNameColumn>{report.shop_name}</ShopNameColumn>
                    <SalesColumn>
                      <ShopNameRow>{report.shop_name}</ShopNameRow>
                      <SalesRow>
                        <SalesCell data-label="デリバリー">
                          <SalesVal>{Utility.getPresentationPrice(report.delivery)}</SalesVal>
                        </SalesCell>
                        <SalesCell data-label="テイクアウト">
                          <SalesVal>{Utility.getPresentationPrice(report.takeout)}</SalesVal>
                        </SalesCell>
                        <SalesCell data-label="合計">
                          <SalesVal>{Utility.getPresentationPrice(report.total)}</SalesVal>
                        </SalesCell>
                      </SalesRow>
                    </SalesColumn>
                  </SalesReportRow>
                ))}
              </SalesReportTable>
            ) : (
              <div>レポートがありません</div>
            )}
          </SalesReportBody>
        </SalesReportContainer>
      </Page>
    </Auth>
  );
});
