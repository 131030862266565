import { OrderItemOption } from "./OrderItemOption";

export type OrderItem = Readonly<{
  name: string;
  price: number;
  count: number;
  total_price: number;
  options: OrderItemOption[];
}>;

const initialState: OrderItem = {
  name: "",
  price: 0,
  count: 0,
  total_price: 0,
  options: [],
};

const create = (args: Partial<OrderItem> = {}) => {
  return {
    ...initialState,
    options: args.options ? args.options.map((i) => OrderItemOption.create(i)) : [],
    ...args,
  };
};

export const OrderItem = Object.freeze({
  initialState,
  create,
});
