import React from "react";
import { ShopData } from "../../../records/ShopData";

export type ShopDetailContextValue = {
  shopData: ShopData;
};

export const ShopDetailContext = React.createContext<ShopDetailContextValue>(
  {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
);
