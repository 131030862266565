import { ActionTypes, AppActions } from "./actions";
import { AppState, AppModel } from "./model";

export default function reducer(state: AppState = AppModel.initialState, action: AppActions) {
  switch (action.type) {
    case ActionTypes.R_OPEN_MODAL:
      return AppModel.addModal(state, action.payload.key, action.payload.payload);
    case ActionTypes.R_CLOSE_MODAL:
      return AppModel.removeModal(state, action.payload.key);
    case ActionTypes.R_UPDATE_ALERT:
      return AppModel.updateAlert(state, action.payload.alertProps);
    case ActionTypes.R_SET_PROCESSING_FLAG:
      return AppModel.setProcessingFlag(state, action.payload.key);
    case ActionTypes.R_REMOVE_PROCESSING_FLAG:
      return AppModel.removeProcessingFlag(state, action.payload.key);
    case ActionTypes.R_SET_SHOULD_UPDATE_RESOURCE_FLAG:
      return AppModel.setShouldUpdateResource(state);
    default:
      return state;
  }
}
