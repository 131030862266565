import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";

import { userTouchedUpdateDailySalesButtonSaga } from "./sagas/userTouchedUpdateDailySalesButtonSaga";
import { userTouchedUpdateMonthlySalesButtonSaga } from "./sagas/userTouchedUpdateMonthlySalesButtonSaga";
import { userTouchedUpdateOrderSalesButtonSaga } from "./sagas/userTouchedUpdateOrderSalesButtonSaga";

function* watchUserTouchedUpdateDailySalesButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_UPDATE_DAILY_SALES_BUTTON,
    userTouchedUpdateDailySalesButtonSaga,
  );
}

function* watchUserTouchedUpdateMonthlySalesButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_UPDATE_MONTHLY_SALES_BUTTON,
    userTouchedUpdateMonthlySalesButtonSaga,
  );
}

function* watchUserTouchedUpdateOrderSalesButtonSaga() {
  yield takeLatest(
    ActionTypes.U_TOUCHED_UPDATE_ORDER_SALES_BUTTON,
    userTouchedUpdateOrderSalesButtonSaga,
  );
}

export function* salesSaga() {
  yield fork(watchUserTouchedUpdateDailySalesButtonSaga);
  yield fork(watchUserTouchedUpdateMonthlySalesButtonSaga);
  yield fork(watchUserTouchedUpdateOrderSalesButtonSaga);
}
