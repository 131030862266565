import React, { useCallback } from "react";
import styled from "styled-components";
import { BusinessType } from "../../records/ShopBusiness";

const Container = styled.div``;

type Props = React.HTMLAttributes<HTMLInputElement> & {
  children: string;
  index: number;
  name: string;
  value: BusinessType;
  checked: boolean;
  id: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
};

export const BusinessTypeRadioField: React.FC<Props> = React.memo(
  ({ children, index, name, id, handleChange, ...rest }) => {
    const handleChangeWrap = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e, index);
      },
      [index, handleChange],
    );
    return (
      <Container>
        <label htmlFor={id}>
          <input type="radio" name={name} id={id} onChange={handleChangeWrap} {...rest} />
          {children}
        </label>
      </Container>
    );
  },
);
