import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { GetBankInfoRequest, GetBankInfoResponse } from "../../../apis/getBankInfo";
import {
  updateDraftBankName,
  updateDraftBankBranchList,
  UserChangedDraftBankCodeAction,
} from "../actions";

export function* userChangedDraftBankCodeSaga(action: UserChangedDraftBankCodeAction) {
  try {
    const params: GetBankInfoRequest = { bank_code: action.payload.bankCode };
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.GET_BANK_INFO, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: GetBankInfoResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateDraftBankName(result.bank_name));
      yield put(updateDraftBankBranchList(result.branch_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "金融機関情報の取得に失敗しました。",
      }),
    );
  }
}
