import { useField } from "formik";
import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getPresentationCategoryTagNameListByIdsSelectorFactory } from "../../modules/shop/selectors";
import { CategoryTag } from "../../records/CategoryTag";
import { colorsConst } from "../../styles/const";
import { Button } from "../atoms/Button";
import { Input } from "../atoms/FormParts/TextInput";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
`;

const CategoryTagInputField = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const CategoryTagInput = styled(Input)`
  flex-grow: 1;
  flex-shrink: 1;
  background-color: ${colorsConst.BACKGROUND.SECONDARY};
`;

const CategoryTagListContainer = styled.div`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
`;

const CategoryTagList = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 1;
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  max-height: 75vh;
  overflow: auto;
  border: 1px solid ${colorsConst.BORDER.THIN};
`;

const CategoryTagListButton = styled.button`
  padding: 0.25em;
  text-align: left;
  border: none;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  cursor: pointer;
  appearance: none;
  &:hover {
    color: ${colorsConst.TEXT.WHITE};
    background-color: ${colorsConst.BUTTON.BACKGROUND.PRIMARY};
  }
  & + & {
    border-top: 1px solid ${colorsConst.BORDER.THIN};
  }
`;

const CategoryTagButton = styled(Button)`
  flex-grow: 0;
  flex-shrink: 0;
  & + & {
    margin-left: 0.25em;
  }
`;

const ErrorContainer = styled.div`
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = {
  value: number[];
  categoryTagList: CategoryTag[];
};

export const ShopFormCategoryTagField: React.FC<Props> = React.memo(
  ({ value, categoryTagList }) => {
    const [, meta, helper] = useField("category_tag");

    const temporaryCategoryTagName = useSelector(
      getPresentationCategoryTagNameListByIdsSelectorFactory(value),
    );

    const [isActiveCategoryList, setIsActiveCategoryList] = useState<boolean>(false);

    const addCategoryTagButtonStyle = useMemo(() => {
      return {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      };
    }, []);

    const closeCategoryTagList = useCallback(() => {
      setIsActiveCategoryList(false);
      document.removeEventListener("click", closeCategoryTagList);
    }, []);

    const showCategoryList = useCallback(() => {
      setIsActiveCategoryList(true);
      document.addEventListener("click", closeCategoryTagList);
    }, [closeCategoryTagList]);

    const canPushCategoryTag = useMemo(() => {
      return value.length < 2;
    }, [value]);

    const addCategoryTagList = useCallback(
      (categoryTagId: number) => {
        helper.setValue(value.concat(categoryTagId));
        helper.setTouched(true);
      },
      [helper, value],
    );

    const removeCategoryTag = useCallback(() => {
      helper.setValue([]);
      helper.setTouched(true);
    }, [helper]);

    return (
      <>
        <Container>
          <CategoryTagInputField>
            <CategoryTagInput
              type="text"
              name="temporaryCategoryTagName"
              value={temporaryCategoryTagName}
              readOnly
            />
            <CategoryTagListContainer>
              <CategoryTagList active={isActiveCategoryList}>
                {categoryTagList.map((categoryTag) => {
                  return (
                    <CategoryTagListButton
                      key={categoryTag.id}
                      type="button"
                      onClick={() => addCategoryTagList(categoryTag.id)}
                    >
                      {categoryTag.tag_name}
                    </CategoryTagListButton>
                  );
                })}
              </CategoryTagList>
            </CategoryTagListContainer>
          </CategoryTagInputField>
          <CategoryTagButton
            size="sm"
            appearance="primary"
            type="button"
            onClick={showCategoryList}
            disabled={!canPushCategoryTag}
            style={addCategoryTagButtonStyle}
          >
            追加
          </CategoryTagButton>
          <CategoryTagButton
            size="sm"
            appearance="default"
            type="button"
            onClick={removeCategoryTag}
          >
            全削除
          </CategoryTagButton>
        </Container>
        {meta.touched && meta.error ? <ErrorContainer>{meta.error}</ErrorContainer> : undefined}
      </>
    );
  },
);
