import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { replaceAndAddAlertSaga } from "../../app/sagas/replaceAndAddAlertSaga";
import {
  RegisterShopStaffRequest,
  RegisterShopStaffResponse,
} from "../../../apis/registerShopStaff";
import { updateShopStaffList, UserSubmittedRegisterShopStaffAction } from "../actions";

export function* userSubmittedRegisterShopStaffSaga(action: UserSubmittedRegisterShopStaffAction) {
  try {
    const params: RegisterShopStaffRequest = action.payload;
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.REGISTER_SHOP_STAFF, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: RegisterShopStaffResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopStaffList(result.staff_list));
      yield fork(() =>
        replaceAndAddAlertSaga(`/shopStaff/${action.payload.shop_id}`, {
          type: ALERT_TYPE.SUCCESS,
          message: "アカウントを登録しました。",
        }),
      );
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "アカウントの削除に失敗しました。",
      }),
    );
  }
}
