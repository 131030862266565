import { ActionTypes, UserActions } from "./actions";
import { UserState, UserModel } from "./model";

export default function reducer(state: UserState = UserModel.initialState, action: UserActions) {
  switch (action.type) {
    case ActionTypes.R_UPDATE_LOGIN_TOKEN:
      return UserModel.updateLoginToken(state, action.payload.token);
    case ActionTypes.R_UPDATE_USER:
      return UserModel.updateUserData(state, action.payload.data);
    default:
      return state;
  }
}
