import { ShopItem } from "./ShopItem";

export type ShopItemCategory = Readonly<{
  category_id: number;
  category_name: string;
  item_list: ShopItem[];
}>;

const initialState: ShopItemCategory = {
  category_id: 0,
  category_name: "",
  item_list: [],
};

const create = (args: Partial<ShopItemCategory> = {}) => {
  return {
    ...initialState,
    item_list: args.item_list ? args.item_list.map((i) => ShopItem.create(i)) : [],
    ...args,
  };
};

const existShopItem = (itemList: ShopItem[]) => {
  return itemList.length > 0;
};

const getUpdatedCategoryWithUpdatedShopItemList = (
  category: ShopItemCategory,
  item: ShopItem,
): ShopItemCategory => {
  return {
    ...category,
    item_list: category.item_list.map((i) =>
      i.shop_item_id === item.shop_item_id ? ShopItem.create(item) : i,
    ),
  };
};

export const ShopItemCategory = Object.freeze({
  initialState,
  create,
  existShopItem,
  getUpdatedCategoryWithUpdatedShopItemList,
});
