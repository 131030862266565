import * as React from "react";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { Helmet } from "react-helmet";
import { history } from "./store";

import { BaseStyle } from "./styles/global/base";

import TopTemplate from "./components/templates/TopTemplate";
import { HomeTemplate } from "./components/templates/HomeTemplate";
import ShopDetailTemplate from "./components/templates/ShopDetailTemplate";

import ShopBusinessTemplate from "./components/templates/ShopBusinessTemplate";
import ShopItemTemplate from "./components/templates/ShopItemTemplate";
import ShopItemCategoryTemplate from "./components/templates/ShopItemCategoryTemplate";
import ShopOptionSetTemplate from "./components/templates/ShopOptionSetTemplate";

import ShopRegisterTemplate from "./components/templates/ShopRegisterTemplate";
import ShopEditTemplate from "./components/templates/ShopEditTemplate";

import ShopItemCategoryRegisterTemplate from "./components/templates/ShopItemCategoryRegisterTemplate";
import ShopItemCategoryEditTemplate from "./components/templates/ShopItemCategoryEditTemplate";
import ShopItemRegisterTemplate from "./components/templates/ShopItemRegisterTemplate";
import ShopItemEditTemplate from "./components/templates/ShopItemEditTemplate";
import ShopOptionSetRegisterTemplate from "./components/templates/ShopOptionSetRegisterTemplate";
import ShopOptionSetEditTemplate from "./components/templates/ShopOptionSetEditTemplate";
import ShopOptionRegisterTemplate from "./components/templates/ShopOptionRegisterTemplate";
import ShopOptionEditTemplate from "./components/templates/ShopOptionEditTemplate";

import ShopReservationOpenEditTemplate from "./components/templates/ShopReservationOpenEditTemplate";
import CookingTimeEditTemplate from "./components/templates/CookingTimeEditTemplate";
import BusinessTimeEditTemplate from "./components/templates/BusinessTimeEditTemplate";
import BusinessHolidayEditTemplate from "./components/templates/BusinessHolidayEditTemplate";
import BankAccountEditTemplate from "./components/templates/BankAccountEditTemplate";
import ShopLicenseEditTemplate from "./components/templates/ShopLicenseEditTemplate";
import ShopStaffTemplate from "./components/templates/ShopStaffTemplate";
import ShopStaffRegisterTemplate from "./components/templates/ShopStaffRegisterTemplate";

import { ShopApplicationManualTemplate } from "./components/templates/ShopApplicationManualTemplate";
import { StoreManagerManualTemplate } from "./components/templates/StoreManagerManualTemplate";
import { ShopApplicationLinkTemplate } from "./components/templates/ShopApplicationLinkTemplate";

import { DailySalesReportTemplate } from "./components/templates/DailySalesReportTemplate";
import { MonthlySalesReportTemplate } from "./components/templates/MonthlySalesReportTemplate";
import { OrderSalesReportTemplate } from "./components/templates/OrderSalesReportTemplate";

export default (
  <ConnectedRouter history={history}>
    <Helmet htmlAttributes={{ lang: "ja" }}>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, viewport-fit=cover" />
      <title>{process.env.REACT_APP_APP_TITLE}</title>
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&amp;display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <BaseStyle />
    <Switch>
      <Route exact path="/" component={TopTemplate} />
      <Route exact path="/home" component={HomeTemplate} />

      <Route exact path="/shopDetail/:shopId" component={ShopDetailTemplate} />
      <Route exact path="/shopBusiness/:shopId" component={ShopBusinessTemplate} />
      <Route exact path="/shopItem/:shopId" component={ShopItemTemplate} />
      <Route exact path="/shopItemCategory/:shopId" component={ShopItemCategoryTemplate} />
      <Route exact path="/shopOptionSet/:shopId" component={ShopOptionSetTemplate} />

      <Route exact path="/shopRegister" component={ShopRegisterTemplate} />
      <Route exact path="/shopEdit/:shopId" component={ShopEditTemplate} />

      <Route
        exact
        path="/shopItemCategoryRegister/:shopId"
        component={ShopItemCategoryRegisterTemplate}
      />
      <Route
        exact
        path="/shopItemCategoryEdit/:shopId/:categoryId"
        component={ShopItemCategoryEditTemplate}
      />
      <Route exact path="/shopItemRegister/:shopId" component={ShopItemRegisterTemplate} />
      <Route exact path="/shopItemEdit/:shopId/:itemId" component={ShopItemEditTemplate} />
      <Route
        exact
        path="/shopOptionSetRegister/:shopId/"
        component={ShopOptionSetRegisterTemplate}
      />
      <Route
        exact
        path="/shopOptionSetEdit/:shopId/:optionSetId"
        component={ShopOptionSetEditTemplate}
      />
      <Route
        exact
        path="/shopOptionRegister/:shopId/:optionSetId"
        component={ShopOptionRegisterTemplate}
      />
      <Route
        exact
        path="/shopOptionEdit/:shopId/:optionSetId/:optionId"
        component={ShopOptionEditTemplate}
      />

      <Route
        exact
        path="/shopReservationOpenEdit/:shopId"
        component={ShopReservationOpenEditTemplate}
      />
      <Route exact path="/cookingTimeEdit/:shopId" component={CookingTimeEditTemplate} />
      <Route exact path="/businessTimeEdit/:shopId" component={BusinessTimeEditTemplate} />
      <Route exact path="/businessHolidayEdit/:shopId" component={BusinessHolidayEditTemplate} />
      <Route exact path="/bankAccountEdit/:shopId" component={BankAccountEditTemplate} />
      <Route
        exact
        path="/shopLicenseEdit/:shopId/:licenseType"
        component={ShopLicenseEditTemplate}
      />
      <Route exact path="/shopStaff/:shopId" component={ShopStaffTemplate} />
      <Route exact path="/shopStaffRegister/:shopId" component={ShopStaffRegisterTemplate} />

      <Route exact path="/shopApplicationManual" component={ShopApplicationManualTemplate} />
      <Route exact path="/storeManagerManual" component={StoreManagerManualTemplate} />
      <Route exact path="/shopApplicationLink" component={ShopApplicationLinkTemplate} />

      <Route exact path="/dailySalesReport" component={DailySalesReportTemplate} />
      <Route exact path="/monthlySalesReport" component={MonthlySalesReportTemplate} />
      <Route exact path="/orderSalesReport" component={OrderSalesReportTemplate} />
    </Switch>
  </ConnectedRouter>
);
