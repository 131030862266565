import React, { useCallback, useMemo } from "react";
import { darken } from "polished";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { DIRECTION_TYPE, DirectionType } from "../../modules/shop/model";
import { colorsConst } from "../../styles/const";

const Container = styled.div`
  width: 28px;
  height: 28px;
  margin-left: 0.5em;
`;

const Button = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: inherit;
  cursor: pointer;
  appearance: none;
  color: ${colorsConst.TEXT.WHITE};
  background-color: ${colorsConst.BUTTON.BACKGROUND.DEFAULT};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background-color: ${darken(0.1, colorsConst.BUTTON.BACKGROUND.DEFAULT)};
  }
`;

type Props = {
  index: number;
  length: number;
  direction: DirectionType;
  handleClick: (direction: DirectionType) => void;
};

export const SortButton: React.FC<Props> = React.memo(
  ({ index, length, direction, handleClick }) => {
    const canDisplay = useMemo(() => {
      return direction === DIRECTION_TYPE.UP ? index > 0 : index < length - 1;
    }, [index, length, direction]);

    const handleButtonClick = useCallback(() => {
      handleClick(direction);
    }, [direction, handleClick]);
    return (
      <Container>
        {canDisplay && (
          <Button onClick={handleButtonClick}>
            <FontAwesomeIcon icon={direction === DIRECTION_TYPE.UP ? faArrowUp : faArrowDown} />
          </Button>
        )}
      </Container>
    );
  },
);
