import styled from "styled-components";
import { colorsConst } from "../../../styles/const";
import { fontSize } from "../../../styles/mixins";

type FormFieldProps = {
  readOnly?: boolean;
  disabled?: boolean;
};

export const FormField = styled.div<FormFieldProps>`
  padding: 5px 10px;
  ${fontSize.DEFAULT};
  border: 1px solid ${colorsConst.BORDER.THICK};
  border-radius: 4px;
  background-color: ${(p) =>
    p.disabled
      ? colorsConst.BACKGROUND.DISABLED
      : p.readOnly
      ? colorsConst.BACKGROUND.SECONDARY
      : colorsConst.BACKGROUND.PRIMARY};
`;
