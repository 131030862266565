import { colorsConst } from "../styles/const";

export const ALERT_TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "denger",
} as const;

export type AlertType = ValueOf<typeof ALERT_TYPE>;

export type AlertProps = Readonly<{
  type: AlertType;
  title?: string;
  message?: string | string[];
}>;

export type Alert = AlertProps &
  Readonly<{
    id: string;
  }>;

const initialValues: Alert = {
  id: "",
  type: ALERT_TYPE.SUCCESS,
};

const create = (args: Partial<AlertProps> = {}): Alert => {
  return {
    ...initialValues,
    ...args,
  };
};

const existsTitle = (alert: Alert) => {
  return alert.title && alert.title !== "";
};

const existsMessage = (alert: Alert) => {
  return (
    typeof alert.message !== "undefined" &&
    (Array.isArray(alert.message) ? alert.message.length > 0 : alert.message !== "")
  );
};

const existsAlert = (alert: Alert) => {
  return existsTitle(alert) || existsMessage(alert);
};

const getAlertBackgroundColor = (type: AlertType) => {
  switch (type) {
    case ALERT_TYPE.SUCCESS:
      return colorsConst.BACKGROUND.SUCCESS;
    case ALERT_TYPE.WARNING:
      return colorsConst.BACKGROUND.WARNING;
    case ALERT_TYPE.DANGER:
      return colorsConst.BACKGROUND.DANGER;
    // skip default case
  }
};

export const Alert = Object.freeze({
  create,
  existsTitle,
  existsMessage,
  existsAlert,
  getAlertBackgroundColor,
});
