import React, { useContext } from "react";
import styled from "styled-components";
import { ShopBusinessContext } from "./ShopBusinessContext";
import { BusinessTimeList } from "../../molecules/BusinessTimeList";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import { ShopDetailSection } from "../../molecules/ShopDetailSection";

const Container = styled.div``;

type Props = {};

export const BusinessTime: React.FC<Props> = React.memo(() => {
  const { shopId, shopBusiness } = useContext(ShopBusinessContext);
  return (
    <Container>
      <ShopDetailSectionTitle title="営業時間" link={`/businessTimeEdit/${shopId}`} />
      <ShopDetailSection>
        <BusinessTimeList weekTimes={shopBusiness.business_week_time} />
      </ShopDetailSection>
    </Container>
  );
});
