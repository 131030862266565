import React from "react";
import styled from "styled-components";
import { Utility } from "../../utils/Utility";
import { ShopData } from "../../records/ShopData";
import { NoImage } from "../atoms/NoImage";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(175 / 330) * 100}%;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

type Props = {
  imagePath: string;
};

export const ImageField: React.FC<Props> = React.memo(({ imagePath }) => {
  return (
    <Container>
      {ShopData.existsImage(imagePath) ? (
        <Image src={Utility.getFullImagePath(imagePath)} alt="" />
      ) : (
        <NoImage />
      )}
    </Container>
  );
});
