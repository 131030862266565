import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { isConnectedApiSelectorFactory } from "../../modules/app/selectors";
import { API_KEY } from "../../apis";
import { userAccessedToPageThatNeedsShopList } from "../../modules/shop/actions";
import { shopListSelector, existsShopListSelector } from "../../modules/shop/selectors";
import { Page } from "../atoms/Page";
import { LoadingContainer } from "../molecules/LoadingContainer";
import { Auth } from "../organisms/Auth";
import { ShopList } from "../organisms/ShopList";
import { Intoroduction } from "../organisms/Intoroduction";

const ListContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const loadingSelector = isConnectedApiSelectorFactory(API_KEY.GET_SHOP_LIST);

type Props = {};

export const HomeTemplate: React.FC<Props> = React.memo(() => {
  const shopList = useSelector(shopListSelector);
  const existsShopList = useSelector(existsShopListSelector);
  const loading = useSelector(loadingSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopList());
  }, [dispatch]);

  return (
    <Auth>
      <Page header canScroll={!existsShopList}>
        {existsShopList ? (
          <ListContainer>
            {loading ? <LoadingContainer /> : <ShopList list={shopList} />}
          </ListContainer>
        ) : (
          <Intoroduction />
        )}
      </Page>
    </Auth>
  );
});
