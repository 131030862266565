import { OptionSet } from "./OptionSet";

export type ShopItem = Readonly<{
  shop_item_id: number;
  item_name: string;
  item_name_en: string;
  description: string;
  description_en: string;
  unit_price: number;
  delivery_price: number;
  delivery_sale_price: number;
  delivery_campaign_text: string;
  delivery_sale_flag: boolean;
  image_path: string;
  instruction_text_flag: number;
  sold_out_flag: number;
  main_flag: boolean;
  cooking_time: number;
  option_set_list: OptionSet[];
  shop_item_category_id: number;
  is_alcohol: boolean;
  is_meat: boolean;
  pending_shop_item_id: number;
  is_released: boolean;
}>;

const initialState: ShopItem = {
  shop_item_id: 0,
  item_name: "",
  item_name_en: "",
  description: "",
  description_en: "",
  unit_price: 0,
  delivery_price: 0,
  delivery_sale_price: 0,
  delivery_campaign_text: "",
  delivery_sale_flag: false,
  image_path: "",
  instruction_text_flag: 0,
  sold_out_flag: 0,
  main_flag: false,
  cooking_time: -1,
  option_set_list: [],
  shop_item_category_id: 0,
  is_alcohol: false,
  is_meat: false,
  pending_shop_item_id: 0,
  is_released: false,
};

const create = (args: Partial<ShopItem> = {}) => {
  return {
    ...initialState,
    option_set_list: args.option_set_list
      ? args.option_set_list.map((i) => OptionSet.create(i))
      : [],
    ...args,
  };
};

const existsOptionSet = (optionSetList: OptionSet[]) => {
  return optionSetList.length > 0;
};

export const ShopItem = Object.freeze({
  initialState,
  create,
  existsOptionSet,
});
