import React, { useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { media } from "../../styles/mixins/media";
import { API_KEY } from "../../apis";
import { isConnectedApiSelectorFactory } from "../../modules/app/selectors";
import {
  shopItemCategoryListSelector,
  existsShopItemCategorySelector,
} from "../../modules/shop/selectors";
import { userAccessedToPageThatNeedsShopItemList } from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Paragraph } from "../atoms/Paragraph";
import { LinkButton } from "../atoms/LinkButton";
import { LoadingContainer } from "../molecules/LoadingContainer";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";
import { ShopItemListCategory } from "../organisms/ShopItemListCategory";

const ButtonList = styled.div`
  ${media.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`;

const ButtonContainer = styled.div`
  & + & {
    margin-top: 1em;
  }
  ${media.tablet} {
    & + & {
      margin-top: 0;
      margin-left: 1em;
    }
  }
`;

const loadingSelector = isConnectedApiSelectorFactory(API_KEY.GET_SHOP_ITEM);

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const ShopItemTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const shopItemCategoryList = useSelector(shopItemCategoryListSelector);
  const existsShopItemCategory = useSelector(existsShopItemCategorySelector);
  const loading = useSelector(loadingSelector);

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(push("/home"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopItemList(shopId));
  }, [dispatch, shopId]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="店舗一覧に戻る" handleClick={handleBack} />
        <PageTitle>商品の登録・更新</PageTitle>
        <Paragraph>販売する商品(メニュー)の登録を行います。</Paragraph>
        <Paragraph>
          商品の追加や変更時には弊社による審査のため、公開までお時間をいただく場合があります。また、初回公開時には店舗審査と商品審査を同時に行わせていただきます。
        </Paragraph>
        <ButtonList>
          <ButtonContainer>
            <LinkButton
              to={`/shopItemCategory/${shopId}`}
              appearance="primary"
              display="responsive"
            >
              商品カテゴリ一覧
            </LinkButton>
          </ButtonContainer>
          <ButtonContainer>
            <LinkButton
              to={`/shopItemRegister/${shopId}`}
              appearance="primary"
              display="responsive"
            >
              商品の登録
            </LinkButton>
          </ButtonContainer>
          <ButtonContainer>
            <LinkButton to={`/shopOptionSet/${shopId}`} appearance="primary" display="responsive">
              オプション一覧
            </LinkButton>
          </ButtonContainer>
        </ButtonList>
        {loading ? (
          <LoadingContainer />
        ) : (
          <>
            {existsShopItemCategory ? (
              <>
                {shopItemCategoryList.map((category) => (
                  <ShopItemListCategory
                    shopId={shopId}
                    category={category}
                    key={category.category_id}
                  />
                ))}
              </>
            ) : (
              <div>商品がありません</div>
            )}
          </>
        )}
      </Page>
    </Auth>
  );
});

export default withRouter(ShopItemTemplate);
