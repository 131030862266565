import * as Yup from "yup";
import { OPTION_SELECT_TYPE, OptionSelectType } from "../records/OptionSet";

export type ShopOptionSetRegisterForm = {
  shop_id: number;
  shop_option_set_name: string;
  select_type: OptionSelectType;
  select_min_count: number;
  select_max_count: number;
  option_set_sort: number;
};

export const initialShopOptionSetRegisterForm: ShopOptionSetRegisterForm = {
  shop_id: 0,
  shop_option_set_name: "",
  select_type: OPTION_SELECT_TYPE.OPTIONAL,
  select_min_count: 0,
  select_max_count: 1,
  option_set_sort: 0,
};

export const getInitialValues = (shopId: number): ShopOptionSetRegisterForm => {
  return {
    ...initialShopOptionSetRegisterForm,
    shop_id: shopId,
  };
};

export const shopOptionSetRegisterFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  shop_option_set_name: Yup.string().required("入力してください"),
  select_type: Yup.mixed<OptionSelectType>().oneOf(Object.values(OPTION_SELECT_TYPE)),
  select_min_count: Yup.number(),
  select_max_count: Yup.number().min(
    Yup.ref("select_min_count"),
    "最小数以上の数を指定してください",
  ),
  option_set_sort: Yup.number().required("入力してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
