import * as Yup from "yup";
import { ShopItemCategory } from "../records/ShopItemCategory";
import { ShopItemCategoryRegisterForm } from "./shopItemCategoryRegister";

export type ShopItemCategoryEditForm = ShopItemCategoryRegisterForm & {
  category_id: number;
};

export const initialShopItemCategoryEditForm: ShopItemCategoryEditForm = {
  shop_id: 0,
  category_id: 0,
  category_name: "",
  category_name_en: "",
};

export const getInitialValues = (
  shop_id: number,
  category?: ShopItemCategory,
): ShopItemCategoryEditForm => {
  return typeof category !== "undefined"
    ? {
        ...initialShopItemCategoryEditForm,
        shop_id,
        category_id: category.category_id,
        category_name: category.category_name,
      }
    : {
        ...initialShopItemCategoryEditForm,
        shop_id,
      };
};

export const shopItemCategoryEditFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  category_id: Yup.number(),
  category_name: Yup.string().required("入力してください"),
  category_name_en: Yup.string(),
})
  .defined()
  .strict(true)
  .noUnknown();
