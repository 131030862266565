import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import {
  SortShopOptionSetRequest,
  SortShopOptionSetResponse,
} from "../../../apis/sortShopOptionSet";
import { updateShopItemOptionSetList, UserTouchedSortShopOptionSetButtonAction } from "../actions";
import { Utility } from "../../../utils/Utility";

export function* userTouchedSortShopOptionSetButtonSaga(
  action: UserTouchedSortShopOptionSetButtonAction,
) {
  try {
    const { shopId, optionSetId, direction, optionSetIds } = action.payload;
    const params: SortShopOptionSetRequest = {
      shop_id: shopId,
      option_set_id_list: Utility.sortIds(optionSetId, optionSetIds, direction),
    };
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.SORT_SHOP_OPTION_SET, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: SortShopOptionSetResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopItemOptionSetList(result.option_set_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "オプションセットの並び替えが失敗しました。",
      }),
    );
  }
}
