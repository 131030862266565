import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootSaga } from "./sagas";
import { createRootReducer } from "./reducer";

export const history = createBrowserHistory();

export function configureStore(initialState = {}) {
  console.log(process.env.NODE_ENV);
  const sagaMiddleware =
    process.env.NODE_ENV !== "production"
      ? createSagaMiddleware({ sagaMonitor: window["__SAGA_MONITOR_EXTENSION__"] })
      : createSagaMiddleware({});
  const middlewares =
    process.env.NODE_ENV !== "production"
      ? composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware))
      : applyMiddleware(routerMiddleware(history), sagaMiddleware);
  const store = createStore(createRootReducer(history), initialState, middlewares);
  sagaMiddleware.run(rootSaga);
  return store;
}
