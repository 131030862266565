import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  animation: ${animation} infinite 1.5s linear;
`;

type Props = {
  size?: SizeProp;
};

export const Loading: React.FC<Props> = React.memo(({ size = "1x" }) => {
  return (
    <Container>
      <FontAwesomeIcon icon={faSpinner} size={size} />
    </Container>
  );
});
