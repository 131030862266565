import * as Yup from "yup";
import { Utility } from "../../utils/Utility";

export const emailValidation = Yup.string()
  .matches(Utility.validEmailRegex, "メールアドレスの形式が正しくありません")
  .required("メールアドレスを入力してください");

export const passwordValidation = Yup.string()
  .min(8, "8文字以上入力してください")
  .matches(Utility.validPasswordRegex, "半角英数記号のみ使用可能です")
  .required("入力してください");
