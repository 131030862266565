import React from "react";
import { BusinessWeekTime } from "../../records/ShopBusiness";
import { ShopBusinessWeekRow } from "./ShopBusinessWeekRow";

type Props = {
  weekTimes: BusinessWeekTime[];
};

export const BusinessTimeList: React.FC<Props> = React.memo(({ weekTimes }) => {
  return (
    <>
      {weekTimes.map((weekTime) => {
        return <ShopBusinessWeekRow key={weekTime.week} weekTime={weekTime} />;
      })}
    </>
  );
});
