import { useField } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";
import { colorsConst } from "../../../styles/const";
import { Select } from "./TextInput";

const Container = styled.div``;
const ErrorContainer = styled.div`
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  name: string;
  children: React.OptionHTMLAttributes<HTMLOptionElement>[];
  isNum?: boolean;
};

export const SelectField: React.FC<Props> = React.memo(({ name, children, isNum, ...rest }) => {
  const [input, meta, helper] = useField(name);

  const handleChangeWrap = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (!isNum) {
        helper.setValue(event.currentTarget.value);
      } else {
        const targetValue = parseInt(event.currentTarget.value, 10);
        if (Number.isNaN(targetValue)) {
          return;
        }
        helper.setValue(targetValue);
      }
    },
    [isNum, helper],
  );

  return (
    <Container>
      <Select {...input} {...rest} onChange={handleChangeWrap}>
        {children}
      </Select>
      {meta.touched && meta.error ? <ErrorContainer>{meta.error}</ErrorContainer> : undefined}
    </Container>
  );
});
