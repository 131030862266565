import "core-js/es/map";
import "core-js/es/set";
import "babel-polyfill";
import "react-virtualized/styles.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import route from "./route";
import { configureStore } from "./store";
import { AppModel } from "./modules/app/model";
import * as serviceWorker from "./serviceWorker";

// アップデートフラグが立っていたらブラウザをハードリロード
AppModel.hardReloadIfShouldUpdateResource();

const startApp = () => {
  ReactDOM.render(
    <Provider store={configureStore()}>{route}</Provider>,
    document.getElementById("root") as HTMLElement,
  );
  serviceWorker.unregister();
};

startApp();
