import { Action, combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import app from "./modules/app";
import user from "./modules/user";
import shop from "./modules/shop";
import sales from "./modules/sales";

import { AppState } from "./modules/app/model";
import { UserState } from "./modules/user/model";
import { ShopState } from "./modules/shop/model";
import { SalesState } from "./modules/sales/model";

import { AppActions } from "./modules/app/actions";
import { UserActions } from "./modules/user/actions";
import { ShopActions } from "./modules/shop/actions";
import { SalesActions } from "./modules/sales/actions";

export const createRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    app,
    user,
    shop,
    sales,
  });
};

export type ReduxModel = {
  router: RouterState;
  app: AppState;
  user: UserState;
  shop: ShopState;
  sales: SalesState;
};

export type ReduxAction = AppActions | UserActions | ShopActions | SalesActions | Action;
