import React from "react";
import styled from "styled-components";
import { GoogleApiWrapper, Map, Marker, IProvidedProps } from "google-maps-react";
import { Utility } from "../../utils/Utility";

const Container = styled.div`
  position: relative;
  padding-top: 100%;
`;

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type Props = IProvidedProps & {
  latitude: number;
  longitude: number;
};

const MapViewer: React.FC<Props> = React.memo(({ latitude, longitude }) => {
  return (
    <Container>
      <MapContainer>
        <Map
          google={google}
          center={{ lat: latitude, lng: longitude }}
          initialCenter={{ lat: latitude, lng: longitude }}
          draggable={false}
          disableDefaultUI={true}
        >
          <Marker mapCenter={{ lat: latitude, lng: longitude }} />
        </Map>
      </MapContainer>
    </Container>
  );
});

export default GoogleApiWrapper({
  apiKey: Utility.getGoogleMapsApiKey(),
})(MapViewer);
