import React from "react";
import { BusinessTime } from "./BusinessTime";
import { CookingTime } from "./CookingTime";
import { Holiday } from "./Holiday";

type Props = {};

export const ShopBusiness: React.FC<Props> = React.memo(() => {
  return (
    <>
      <CookingTime />
      <BusinessTime />
      <Holiday />
    </>
  );
});
