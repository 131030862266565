import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import {
  CookingTimeEditForm,
  initialCookingTimeEditForm,
  getInitialValues,
  cookingTimeEditFormValidationSchema,
} from "../../forms/cookingTimeEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userAccessedToPageThatNeedsCurrentShopData,
  userSubmittedCookingTime,
} from "../../modules/shop/actions";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { Auth } from "../organisms/Auth";

const mapStateToProps = (state: ReduxModel, ownProps: RouteComponentProps<{ shopId: string }>) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  return {
    shopId,
    shopData: state.shop.currentShopData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: CookingTimeEditForm) {
    dispatch(userSubmittedCookingTime(formValue));
  },
  handleBack(id: number) {
    dispatch(push(`/shopBusiness/${id}`));
  },
  updateCurrentShopData(shopId: number) {
    dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<CookingTimeEditForm> &
  PropsBase & {
    children?: never;
  };

const CookingTimeEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    shopData,
    handleBack,
    updateCurrentShopData,
    handleSubmit,
    handleChange,
    values,
    isValid,
  }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopData.id);
    }, [handleBack, shopData]);

    useEffect(() => {
      if (shopData.id !== shopId) {
        updateCurrentShopData(shopId);
      }
    }, [shopId, shopData, updateCurrentShopData]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="営業時間設定に戻る" handleClick={handleClickBack} />
          <PageTitle>調理時間</PageTitle>

          <FormContainer onSubmit={handleSubmit}>
            <input type="hidden" id="shop_id" name="shop_id" value={values.shop_id} />
            <FormInner>
              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="cooking_time" required>
                    調理時間（分）
                  </FormLabel>
                  <TextField
                    type="number"
                    name="cooking_time"
                    id="cooking_time"
                    onChange={handleChange}
                    value={values.cooking_time}
                  />
                </FormField>
              </FormFieldGroup>
              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="crow_order_count" required>
                    混雑になる注文商品数（個）
                  </FormLabel>
                  <TextField
                    type="number"
                    name="crow_order_count"
                    id="crow_order_count"
                    onChange={handleChange}
                    value={values.crow_order_count}
                  />
                </FormField>
                <FormNotice>
                  同時に受け付けている注文数が設定した数を超えた場合、店舗が混雑している旨がお客様に表示されます。
                </FormNotice>
              </FormFieldGroup>
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                更新
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, CookingTimeEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopData }): CookingTimeEditForm => getInitialValues(shopData),
  mapPropsToErrors: () =>
    getInitialErrors(cookingTimeEditFormValidationSchema, initialCookingTimeEditForm),
  validationSchema: cookingTimeEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(CookingTimeEditTemplate)),
);
