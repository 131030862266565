import * as Yup from "yup";
import { OptionSet, OptionSelectType, OPTION_SELECT_TYPE } from "../records/OptionSet";
import { ShopOptionSetRegisterForm } from "./shopOptionSetRegister";

export type ShopOptionSetEditForm = ShopOptionSetRegisterForm & {
  shop_option_set_id: number;
};

export const initialShopOptionSetEditForm: ShopOptionSetEditForm = {
  shop_id: 0,
  shop_option_set_id: 0,
  shop_option_set_name: "",
  select_type: OPTION_SELECT_TYPE.OPTIONAL,
  select_min_count: 0,
  select_max_count: 1,
  option_set_sort: 0,
};

export const getInitialValues = (shopId: number, optionSet?: OptionSet): ShopOptionSetEditForm => {
  return typeof optionSet !== "undefined"
    ? {
        ...initialShopOptionSetEditForm,
        shop_id: shopId,
        shop_option_set_id: optionSet.option_set_id,
        shop_option_set_name: optionSet.option_set_name,
        select_type: optionSet.option_select_type,
        select_min_count: optionSet.select_count_min,
        select_max_count: optionSet.select_count_max,
        option_set_sort: optionSet.sort,
      }
    : {
        ...initialShopOptionSetEditForm,
        shop_id: shopId,
      };
};

export const shopOptionSetEditFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  shop_option_set_id: Yup.number(),
  shop_option_set_name: Yup.string().required("入力してください"),
  select_type: Yup.mixed<OptionSelectType>().oneOf(Object.values(OPTION_SELECT_TYPE)),
  select_min_count: Yup.number(),
  select_max_count: Yup.number().min(
    Yup.ref("select_min_count"),
    "最小数以上の数を指定してください",
  ),
  option_set_sort: Yup.number().required("入力してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
