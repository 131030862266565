import { fork, join, cancel, put, select } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import {
  UpdateShopResevationDateRequest,
  UpdateShopResevationDateResponse,
} from "../../../apis/updateShopResevationDate";
import { updateCurrentShopData, UserSubmittedReservationOpenDateAction } from "../actions";
import { currentShopDataSelector } from "../selectors";

export function* userSubmittedReservationOpenDateSaga(
  action: UserSubmittedReservationOpenDateAction,
) {
  try {
    const params: UpdateShopResevationDateRequest = {
      shop_id: action.payload.shopId,
      date: action.payload.date,
    };
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.UPDATE_BUSINESS_HOLIDAY, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error,
    }: { result: UpdateShopResevationDateResponse; error: unknown } = yield join(commonApiTask);
    if (result && !error) {
      const currentShopData: ReturnType<typeof currentShopDataSelector> = yield select(
        currentShopDataSelector,
      );
      if (currentShopData.id === result.shop_data.id) {
        yield put(updateCurrentShopData(result.shop_data));
      }
      yield put(replace(`/shopDetail/${result.shop_data.id}`));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "営業時間の更新に失敗しました。",
      }),
    );
  }
}
