import React from "react";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Auth } from "../organisms/Auth";
import { MovieContainer } from "../organisms/MovieContainer";

type Props = {};

export const StoreManagerManualTemplate: React.FC<Props> = React.memo(() => {
  return (
    <Auth>
      <Page header>
        <PageTitle>ストアマネージャーの使い方</PageTitle>
        <div>
          <div>操作説明動画</div>
          <div>
            <div>STEP01 店舗を新規登録する</div>
            <MovieContainer>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/QV3JPTh-Ins"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </MovieContainer>
          </div>
          <div>
            <div>STEP02 営業日・営業時間を設定する</div>
            <MovieContainer>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/3g3hC8PtWTk"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </MovieContainer>
          </div>
          <div>
            <div>STEP03 商品を登録する</div>
            <MovieContainer>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/hHCSbdAdzGY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </MovieContainer>
          </div>
          <div>
            <div>STEP04 公開設定と審査設定</div>
            <MovieContainer>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/stvMJVWu6e4"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </MovieContainer>
          </div>
        </div>
      </Page>
    </Auth>
  );
});
