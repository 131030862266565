import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DateUtils } from "../../utils/DateUtils";
import {
  ShopLicense,
  ShopLicenseType,
  SHOP_LICENSE_EXPIRE_TYPE,
  displayableShopLicenseExpireType,
} from "../../records/ShopLicense";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import {
  ShopLicenseEditForm,
  initialShopLicenseEditForm,
  getInitialValues,
  shopLicenseEditFormValidationSchema,
} from "../../forms/shopLicenseEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import { ShopModel } from "../../modules/shop/model";
import {
  userAccessedToPageThatNeedsCurrentShopData,
  userSubmittedShopLicenseEdit,
  systemInitDraftLicenseImage,
  userSubmittedDraftLicenseImage,
} from "../../modules/shop/actions";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { Text } from "../atoms/Text";
import { Paragraph } from "../atoms/Paragraph";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { Input } from "../atoms/FormParts/TextInput";
import { UploadButton } from "../atoms/FormParts/UploadButton";
import { ImageField } from "../molecules/ImageField";
import { Auth } from "../organisms/Auth";

const IntoroductionText = styled.div``;

const FormTitle = styled.div``;

const DiscriptionText = styled.div``;

const ExpireDateField = styled.div<{ isLimited: boolean }>`
  display: ${(p) => (p.isLimited ? "block" : "none")};
`;

const UploadButtonContainer = styled.div`
  margin-top: 1em;
`;
const ImageFieldContainer = styled.div`
  margin-top: 1em;
`;

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ shopId: string; licenseType: string }>,
) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  const licenseType = parseInt(ownProps.match.params.licenseType, 10);
  const license = ShopModel.getCurrentShopLicenseByType(state.shop, licenseType as ShopLicenseType);
  return {
    shopId,
    shopData: state.shop.currentShopData,
    licenseType: licenseType as ShopLicenseType,
    license,
    draftLicenseImage: state.shop.draftLicenseImage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopLicenseEditForm) {
    dispatch(userSubmittedShopLicenseEdit(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopDetail/${shopId}`));
  },
  updateCurrentShopData(shopId: number) {
    dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
  },
  initDraftLicenseImage(imagePath: string) {
    dispatch(systemInitDraftLicenseImage(imagePath));
  },
  updateDraftLicenseImage(files: FileList | null) {
    dispatch(userSubmittedDraftLicenseImage(files));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopLicenseEditForm> &
  PropsBase & {
    children?: never;
  };

const ShopLicenseEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    shopData,
    licenseType,
    license,
    draftLicenseImage,
    handleBack,
    updateCurrentShopData,
    initDraftLicenseImage,
    updateDraftLicenseImage,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    const todayString = DateUtils.getDateString(new Date());

    const presentationText = useMemo(() => {
      return ShopLicense.getPresentationLicenseEditPageText(licenseType);
    }, [licenseType]);

    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    const handleExpireTypeChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = parseInt(e.currentTarget.value, 10);
        if (Number.isNaN(targetValue)) {
          return;
        }
        setFieldValue(e.currentTarget.name, targetValue);
      },
      [setFieldValue],
    );

    const handleFileChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        updateDraftLicenseImage(files);
      },
      [updateDraftLicenseImage],
    );

    useEffect(() => {
      if (shopData.id !== shopId) {
        updateCurrentShopData(shopId);
      }
    }, [shopId, shopData, updateCurrentShopData]);

    useEffect(() => {
      initDraftLicenseImage(license.image_path);
    }, [license, initDraftLicenseImage]);

    useEffect(() => {
      setFieldValue("image_path", draftLicenseImage);
    }, [draftLicenseImage, setFieldValue]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="店舗詳細に戻る" handleClick={handleClickBack} />
          <PageTitle>{presentationText.pageTitle}</PageTitle>
          <IntoroductionText>
            {presentationText.introduction.map((text, index) => (
              <Paragraph key={index}>{text}</Paragraph>
            ))}
          </IntoroductionText>
          <FormTitle>
            <Text size="LARGE" weight="BOLD">
              {ShopLicense.getPresentationLicenseTypeText(licenseType)}
            </Text>
          </FormTitle>
          <DiscriptionText>
            <Text size="SMALL">{presentationText.discription}</Text>
          </DiscriptionText>
          <FormContainer onSubmit={handleSubmit}>
            <input type="hidden" id="shop_id" name="shop_id" value={values.shop_id} />
            <input type="hidden" id="license_id" name="license_id" value={values.license_id} />
            <input
              type="hidden"
              id="license_type"
              name="license_type"
              value={values.license_type}
            />

            <FormInner>
              <FormFieldGroup>
                <FormLabel>有効期限の有無</FormLabel>
                <div>
                  {displayableShopLicenseExpireType.map((expireType) => (
                    <label htmlFor={`expire_${expireType}`} key={expireType}>
                      <input
                        type="radio"
                        name="expire"
                        id={`expire_${expireType}`}
                        value={expireType}
                        checked={values.expire === expireType}
                        onChange={handleExpireTypeChange}
                      />
                      {ShopLicense.getPresentationLicenseExpireTypeText(expireType)}
                    </label>
                  ))}
                </div>
              </FormFieldGroup>

              <FormFieldGroup>
                <ExpireDateField isLimited={values.expire === SHOP_LICENSE_EXPIRE_TYPE.LIMITED}>
                  <FormField>
                    <FormLabel htmlFor="expire_date">有効期限</FormLabel>
                    <Input
                      type="date"
                      name="expire_date"
                      id="expire_date"
                      min={todayString}
                      onChange={handleChange}
                      value={values.expire_date}
                    />
                  </FormField>
                </ExpireDateField>
              </FormFieldGroup>

              <FormFieldGroup>
                <div>許可証の写真</div>
                <div>
                  <Paragraph>
                    {ShopLicense.getPresentationLicenseTypeText(licenseType)}
                    が確認できる写真を設定してください
                  </Paragraph>
                  <UploadButtonContainer>
                    <UploadButton htmlFor="upload_field">
                      <input
                        type="file"
                        id="upload_field"
                        name="upload_field"
                        accept="image/*"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleFileChange(event)
                        }
                      />
                      画像をアップロードする
                    </UploadButton>
                  </UploadButtonContainer>
                  <ImageFieldContainer>
                    <ImageField imagePath={values.image_path} />
                  </ImageFieldContainer>
                </div>
                <FormNotice>※営業許可証、許可証等</FormNotice>
              </FormFieldGroup>
              <input type="hidden" value={values.image_path} readOnly />
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                更新
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopLicenseEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopId, licenseType, license }): ShopLicenseEditForm =>
    getInitialValues(shopId, licenseType, license),
  mapPropsToErrors: () =>
    getInitialErrors(shopLicenseEditFormValidationSchema, initialShopLicenseEditForm),
  validationSchema: shopLicenseEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopLicenseEditTemplate)),
);
