import React from "react";
import styled from "styled-components";
import { ShopRegisterForm } from "../../forms/shopRegister";
import { ShopEditForm } from "../../forms/shopEdit";
import { ShopData, displayablePriceRangeType } from "../../records/ShopData";
import { CategoryTag } from "../../records/CategoryTag";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { SelectField } from "../atoms/FormParts/SelectField";
import { UploadButton } from "../atoms/FormParts/UploadButton";
import { ImageField } from "../molecules/ImageField";
import { ShopFormCategoryTagField } from "./ShopFormCategoryTagField";

const UploadButtonContainer = styled.div`
  margin-bottom: 1em;
`;
const ImageFieldContainer = styled.div`
  margin-bottom: 1em;
`;

type Props = {
  shopData?: ShopData;
  isEdit?: boolean;
  categoryTagList: CategoryTag[];
  handleAddressChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: ShopRegisterForm | ShopEditForm;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleChange: (event: React.ChangeEvent<any>) => void;
};

export const ShopFormFields: React.FC<Props> = React.memo(
  ({
    shopData,
    isEdit,
    categoryTagList,
    handleAddressChange,
    handleFileChange,
    values,
    handleChange,
  }) => {
    return (
      <>
        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="person_in_charge_name" required>
              担当者名
            </FormLabel>
            <TextField
              type="text"
              name="person_in_charge_name"
              id="person_in_charge_name"
              onChange={handleChange}
              value={values.person_in_charge_name}
            />
          </FormField>
          <FormNotice>店舗のご担当者のお名前をご記入ください。</FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="person_in_charge_name_ruby" required>
              担当者名（フリガナ）
            </FormLabel>
            <TextField
              type="text"
              name="person_in_charge_name_ruby"
              id="person_in_charge_name_ruby"
              onChange={handleChange}
              value={values.person_in_charge_name_ruby}
            />
          </FormField>
          <FormNotice>カタカナ</FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="shop_name" required>
              店舗名
            </FormLabel>
            <TextField
              type="text"
              name="shop_name"
              id="shop_name"
              onChange={handleChange}
              value={values.shop_name}
            />
          </FormField>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="shop_name_ruby" required>
              店舗名（フリガナ）
            </FormLabel>
            <TextField
              type="text"
              name="shop_name_ruby"
              id="shop_name_ruby"
              onChange={handleChange}
              value={values.shop_name_ruby}
            />
          </FormField>
          <FormNotice>カタカナ</FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="mail" required>
              メールアドレス
            </FormLabel>
            <TextField
              type="text"
              name="mail"
              id="mail"
              onChange={handleChange}
              value={values.mail}
            />
          </FormField>
          <FormNotice>
            重要な情報をお送りしますので、必ず確認のできるメールアドレスをご登録ください。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="tel" required>
              電話番号（ハイフンなし）
            </FormLabel>
            <TextField type="text" name="tel" id="tel" onChange={handleChange} value={values.tel} />
          </FormField>
          <FormNotice>
            店舗へのご連絡に使用する他、注文が入った際にこちらの番号あてに、自動音声によるお電話でお知らせいたします。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel required>カテゴリタグ</FormLabel>
            <ShopFormCategoryTagField
              value={values.category_tag}
              categoryTagList={categoryTagList}
            />
          </FormField>
          <FormNotice>
            「追加」ボタンから、該当する店舗の料理ジャンルを選んでください。2つまで選択できます。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField readOnly={isEdit}>
            <FormLabel htmlFor="address" required>
              住所
            </FormLabel>
            <TextField
              type="text"
              name="address"
              id="address"
              onChange={handleAddressChange}
              value={values.address}
              readOnly={isEdit}
            />
          </FormField>
          {!isEdit ? (
            <FormNotice>
              都道府県から正しくご入力をお願いいたします。誤った住所を入力すると、公開が大きく遅れる場合がございます。
            </FormNotice>
          ) : undefined}
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField readOnly>
            <FormLabel htmlFor="latitude">緯度</FormLabel>
            <TextField type="text" name="latitude" id="latitude" value={values.latitude} readOnly />
          </FormField>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField readOnly>
            <FormLabel htmlFor="latitude">経度</FormLabel>
            <TextField
              type="text"
              name="longitude"
              id="longitude"
              value={values.longitude}
              readOnly
            />
          </FormField>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="price_range" required>
              価格帯
            </FormLabel>
            <SelectField name="price_range" id="price_range" isNum value={values.price_range}>
              {displayablePriceRangeType.map((priceRange) => (
                <option value={priceRange} key={priceRange}>
                  {ShopData.getPresentationPriceRangeText(priceRange)}
                </option>
              ))}
            </SelectField>
          </FormField>
          {typeof shopData !== "undefined" && !shopData.is_set_recommend_item ? (
            <FormNotice>店舗のおすすめ商品が設定されていません。</FormNotice>
          ) : undefined}
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="tel_notification_flag" required>
              電話通知フラグ
            </FormLabel>
            <SelectField
              name="tel_notification_flag"
              id="tel_notification_flag"
              isNum
              value={values.tel_notification_flag}
            >
              <option value={1}>電話通知する</option>
              <option value={0}>電話通知しない</option>
            </SelectField>
          </FormField>
          <FormNotice>注文が入った際に自動音声によるお電話でお知らせいたします。</FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <UploadButtonContainer>
            <UploadButton htmlFor="upload_field">
              <input
                type="file"
                id="upload_field"
                name="upload_field"
                accept="image/*"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
              />
              画像をアップロードする
            </UploadButton>
          </UploadButtonContainer>
          <ImageFieldContainer>
            <ImageField imagePath={values.header_image_path} />
          </ImageFieldContainer>
          <FormField>
            <TextField
              type="text"
              name="header_image_path"
              value={values.header_image_path}
              readOnly
            />
          </FormField>
          <FormNotice>ファイル選択を行うと自動で入力されます。</FormNotice>
        </FormFieldGroup>
      </>
    );
  },
);
