export type OrderItemOption = Readonly<{
  name: string;
  price: number;
}>;

const initialState: OrderItemOption = {
  name: "",
  price: 0,
};

const create = (args: Partial<OrderItemOption> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const OrderItemOption = Object.freeze({
  initialState,
  create,
});
