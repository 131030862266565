import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

type Props = {
  children: React.ReactNode;
};

export const MovieContainer: React.FC<Props> = React.memo(({ children }) => {
  return <Container>{children}</Container>;
});
