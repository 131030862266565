import React from "react";
import styled from "styled-components";
import { SvgLogo } from "./Icons/SvgLogo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoText = styled.div<{ fontSize: number }>`
  font-size: ${(p) => p.fontSize}px;
  letter-spacing: 0.15em;
`;

type Props = {
  size?: number;
  fontSize?: number;
};

export const Logo: React.FC<Props> = React.memo(({ size = 164, fontSize = 16 }) => {
  return (
    <Container>
      <SvgLogo size={size} />
      <LogoText fontSize={fontSize}>ストアマネージャー</LogoText>
    </Container>
  );
});
