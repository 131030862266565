import React, { useContext } from "react";
import styled from "styled-components";
import { displayableShopLicenseType } from "../../../records/ShopLicense";
import { ShopDetailContext } from "./ShopDetailContext";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import { ShopDetailSection } from "../../molecules/ShopDetailSection";
import { ShopLicenseRow } from "./ShopLicenseRow";

const Container = styled.div``;

type Props = {};

export const ShopLicense: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);

  return (
    <Container>
      <ShopDetailSectionTitle title="販売免許または許可証" />
      <ShopDetailSection>
        {displayableShopLicenseType.map((type) => (
          <ShopLicenseRow shopData={shopData} licenseType={type} key={type} />
        ))}
      </ShopDetailSection>
    </Container>
  );
});
