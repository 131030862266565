import produce from "immer";
import { DailySales } from "../../records/DailySales";
import { MonthlySales } from "../../records/MonthlySales";
import { OrderSales } from "../../records/OrderSales";

export type SalesState = Readonly<{
  dailySales: DailySales;
  monthlySales: MonthlySales;
  orderSales: OrderSales;
}>;

const initialState: SalesState = {
  dailySales: DailySales.create(),
  monthlySales: MonthlySales.create(),
  orderSales: OrderSales.create(),
};

const updateDailySales = (state: SalesState, value: DailySales) => {
  return produce(state, (draft) => {
    draft.dailySales = DailySales.create(value);
  });
};

const updateMonthlySales = (state: SalesState, value: MonthlySales) => {
  return produce(state, (draft) => {
    draft.monthlySales = value;
  });
};

const updateOrderSales = (state: SalesState, value: OrderSales) => {
  return produce(state, (draft) => {
    draft.orderSales = value;
  });
};

const existsDailySales = (state: SalesState) => {
  return state.dailySales.sales.reports.length > 0;
};

const existsMonthlySales = (state: SalesState) => {
  return state.monthlySales.sales.reports.length > 0;
};

const existsOrderSales = (state: SalesState) => {
  return state.orderSales.orders.length > 0;
};

export const SalesModel = {
  initialState,
  updateDailySales,
  updateMonthlySales,
  updateOrderSales,
  existsDailySales,
  existsMonthlySales,
  existsOrderSales,
};
