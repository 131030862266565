import * as Yup from "yup";
import { emailValidation } from "./validations";
import { ShopRegisterForm } from "./shopRegister";
import {
  ShopData,
  PriceRangeType,
  NotificationFlagType,
  NOTIFICATION_FLAG,
} from "../records/ShopData";

export type ShopEditForm = ShopRegisterForm & {
  id: number;
};

export const initialShopEditForm: ShopEditForm = {
  id: 0,
  shop_name: "",
  shop_name_ruby: "",
  address: "",
  latitude: "",
  longitude: "",
  tel: "",
  tel_notification_flag: NOTIFICATION_FLAG.FALSE,
  category_tag: [],
  price_range: 1,
  person_in_charge_name: "",
  person_in_charge_name_ruby: "",
  mail: "",
  header_image_path: "",
};

export const getInitialValues = (shopData?: ShopData): ShopEditForm => {
  return typeof shopData !== "undefined"
    ? {
        ...initialShopEditForm,
        id: shopData.id,
        shop_name: shopData.shop_name,
        shop_name_ruby: shopData.shop_name_ruby,
        address: shopData.address,
        latitude: shopData.latitude,
        longitude: shopData.longitude,
        tel: shopData.tel,
        tel_notification_flag: shopData.tel_notification_flag,
        category_tag: shopData.category_tag,
        price_range: shopData.price_range,
        person_in_charge_name: shopData.person_in_charge_name,
        person_in_charge_name_ruby: shopData.person_in_charge_name_ruby,
        mail: shopData.mail,
        header_image_path: shopData.header_image_path,
      }
    : initialShopEditForm;
};

export const shopEditFormValidationSchema = Yup.object({
  id: Yup.number(),
  shop_name: Yup.string().required("入力してください"),
  shop_name_ruby: Yup.string().required("入力してください"),
  address: Yup.string().required("入力してください"),
  latitude: Yup.string().required(),
  longitude: Yup.string().required(),
  tel: Yup.string().required("入力してください"),
  tel_notification_flag: Yup.mixed<NotificationFlagType>().oneOf(Object.values(NOTIFICATION_FLAG)),
  category_tag: Yup.array().of(Yup.number()).min(1, "カテゴリーを選択してください").max(2),
  price_range: Yup.mixed<PriceRangeType>(),
  person_in_charge_name: Yup.string().required("入力してください"),
  person_in_charge_name_ruby: Yup.string().required("入力してください"),
  mail: emailValidation,
  header_image_path: Yup.string().required("画像を選択してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
