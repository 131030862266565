import { spawn, delay, call, put, select } from "redux-saga/effects";
import { LocalStorage } from "../../../utils/LocalStorage";
import {
  AppState,
  AppModel,
  CURRENT_RESOURCE_VERSION_COOKIE_KEY,
  LATEST_RESOURCE_VERSION_COOKIE_KEY,
  INTERVAL_CHECK_UPDATE_RESOURCE,
} from "../model";
import { systemOpenedModal, systemDetectedResourceUpdate } from "../actions";
import { ReduxModel } from "../../../reducer";

function checkResourceVersion() {
  return new Promise((resolve, reject) => {
    fetch("/data/buildVersion.json", {
      cache: "no-cache",
    })
      .then(async (response) => {
        const buildVersion = await response.json();
        if (
          !LocalStorage.hasItem(CURRENT_RESOURCE_VERSION_COOKIE_KEY) ||
          LocalStorage.getItem(CURRENT_RESOURCE_VERSION_COOKIE_KEY) !==
            buildVersion.build.toString()
        ) {
          LocalStorage.setItem(LATEST_RESOURCE_VERSION_COOKIE_KEY, buildVersion.build.toString());
          resolve(true);
        }
        resolve(false);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function* checkUpdateResourceSaga() {
  while (true) {
    try {
      yield delay(INTERVAL_CHECK_UPDATE_RESOURCE);
      const shouldUpdate: boolean = yield call(checkResourceVersion);
      if (shouldUpdate) {
        console.log("UPDATE RESOURCE");
        yield put(systemOpenedModal("SHOULD_UPDATE_RESOURCE", {}));
        yield put(systemDetectedResourceUpdate());
      }
    } catch (e) {
      console.log(e);
    }
  }
}

function* updateResourceSaga() {
  while (true) {
    yield delay(15000);
    const app: AppState = yield select<(state: ReduxModel) => AppState>((state) => state.app);
    if (app["shouldUpdateResource"] && !AppModel.isConnectedAnyApi(app)) {
      // ダイアログを表示してマニュアルでリソースを更新する。
      yield put(systemOpenedModal("SHOULD_UPDATE_RESOURCE", {}));
    }
  }
}

export function* checkUpdateSaga() {
  yield spawn(checkUpdateResourceSaga);
  yield spawn(updateResourceSaga);
}
