import produce from "immer";
import { ApiKey } from "src/apis";
import { LocalStorage } from "../../utils/LocalStorage";
import { Alert, AlertProps } from "../../records/Alert";
import {
  ModalKey,
  ModalPayload,
  ModalManager,
  ModalManagerProps,
} from "../../records/ModalManager";
import {
  MaintenanceInfo,
  initialState as initialMaintenanceInfo,
} from "../../records/MaintenanceInfo";

export const SHOULD_UPDATE_RESOURCE_COOKIE_KEY = "shouldUpdateBuild";
export const CURRENT_RESOURCE_VERSION_COOKIE_KEY = "currentBuildVersion";
export const LATEST_RESOURCE_VERSION_COOKIE_KEY = "latestBuildVersion";
export const INTERVAL_CHECK_UPDATE_RESOURCE = 60000;

export const PROCESSING_FLAG = {
  AUTO_LOGIN: 0,
  USER_LOGGED_IN: 1,
} as const;

export type ProcessingFlag = ValueOf<typeof PROCESSING_FLAG>;

export type AppState = Readonly<{
  launched: boolean;
  alert: Alert;
  shouldUpdateResource: boolean;
  modalManager: ModalManagerProps;
  connectingApiSet: ApiKey[];
  processingFlagSet: ProcessingFlag[];
  maintenanceInfo: MaintenanceInfo;
}>;

const initialState: AppState = {
  launched: false,
  alert: Alert.create(),
  shouldUpdateResource: false,
  modalManager: ModalManager.initialState,
  connectingApiSet: [],
  processingFlagSet: [],
  maintenanceInfo: initialMaintenanceInfo,
};

const startConnect = (state: AppState, apiKey: ApiKey) => {
  return produce(state, (draft) => {
    const { connectingApiSet } = draft;
    connectingApiSet.push(apiKey);
    draft.connectingApiSet = connectingApiSet;
  });
};

const finishConnect = (state: AppState, apiKey: ApiKey) => {
  return produce(state, (draft) => {
    draft.connectingApiSet = draft.connectingApiSet.filter((key) => key !== apiKey);
  });
};

const updateAlert = (state: AppState, alertProps: AlertProps) => {
  return produce(state, (draft) => {
    draft.alert = Alert.create(alertProps);
  });
};

const setShouldUpdateResource = (state: AppState) => {
  return produce(state, (draft) => {
    draft.shouldUpdateResource = true;
  });
};

const getConnectingApiSet = (state: AppState) => {
  return state.connectingApiSet;
};

const isConnectedApi = (
  state: AppState,
  apiKey: ApiKey | ApiKey[],
  type: "some" | "every" = "some",
) => {
  const includes = (_key: ApiKey) => getConnectingApiSet(state).includes(_key);
  if (Array.isArray(apiKey)) {
    return type === "some" ? apiKey.some(includes) : apiKey.every(includes);
  }
  return includes(apiKey);
};

const isConnectedAnyApi = (state: AppState) => {
  return state.connectingApiSet.length !== 0;
};

const getModalManager = (state: AppState) => {
  return state.modalManager;
};

const addModal = <K extends ModalKey>(state: AppState, key: K, payload: ModalPayload<K>) => {
  return ModalManager.pushKey(state, key, payload);
};

const removeModal = (state: AppState, key: ModalKey) => {
  return ModalManager.removeKey(state, key);
};

const getProcessingFlagSet = (state: AppState) => {
  return state.processingFlagSet;
};

const isProcessing = (
  state: AppState,
  key: ProcessingFlag | ProcessingFlag[],
  type: "some" | "every" = "some",
) => {
  const flagSet = getProcessingFlagSet(state);
  const includes = (_key: ProcessingFlag) => flagSet.includes(_key);
  if (Array.isArray(key)) {
    return type === "some" ? key.some(includes) : key.every(includes);
  }
  return includes(key);
};

const setProcessingFlag = (state: AppState, key: ProcessingFlag) => {
  return produce(state, (draft) => {
    const flagSet = getProcessingFlagSet(draft);
    flagSet.push(key);
    draft.processingFlagSet = flagSet;
  });
};

const removeProcessingFlag = (state: AppState, key: ProcessingFlag) => {
  return produce(state, (draft) => {
    draft.processingFlagSet = getProcessingFlagSet(draft).filter((i) => i !== key);
  });
};

const existsAlert = (state: AppState) => {
  return Alert.existsAlert(state.alert);
};

const getAlert = (state: AppState) => {
  return state.alert;
};

const hardReloadIfShouldUpdateResource = () => {
  const { hash } = window.location;
  if (hash === `#${SHOULD_UPDATE_RESOURCE_COOKIE_KEY}`) {
    window.history.replaceState("", document.title, window.location.pathname);
    window.location.reload(true);
  }
};

const updateResource = () => {
  LocalStorage.removeItem(SHOULD_UPDATE_RESOURCE_COOKIE_KEY);
  if (LocalStorage.hasItem(LATEST_RESOURCE_VERSION_COOKIE_KEY)) {
    LocalStorage.setItem(
      CURRENT_RESOURCE_VERSION_COOKIE_KEY,
      LocalStorage.getItem(LATEST_RESOURCE_VERSION_COOKIE_KEY) as string,
    );
    LocalStorage.removeItem(LATEST_RESOURCE_VERSION_COOKIE_KEY);
  }
  if (window.location.pathname === "/") {
    window.location.reload(true);
  } else {
    window.location.href = `/#${SHOULD_UPDATE_RESOURCE_COOKIE_KEY}`;
  }
};

export const AppModel = {
  initialState,
  startConnect,
  finishConnect,
  updateAlert,
  setShouldUpdateResource,
  isConnectedApi,
  isConnectedAnyApi,
  getModalManager,
  addModal,
  removeModal,
  isProcessing,
  setProcessingFlag,
  removeProcessingFlag,
  existsAlert,
  getAlert,
  hardReloadIfShouldUpdateResource,
  updateResource,
};
