import React from "react";
import styled from "styled-components";
import { spaceConst } from "../../styles/const";
import { media } from "../../styles/mixins/media";
import { PageTitle } from "../atoms/PageTitle";
import { LinkButton } from "../atoms/LinkButton";
import { HomeInfomation } from "./HomeInfomation";

const Container = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: ${`${spaceConst.PADDING.PAGE.VERTICAL.DEFAULT}px`};
  ${media.tablet} {
    padding-top: ${`${spaceConst.PADDING.PAGE.VERTICAL.TABLET}px`};
  }
`;

type Props = {};

export const ShopListHeader: React.FC<Props> = React.memo(() => {
  return (
    <Container>
      <PageTitle>店舗一覧</PageTitle>
      <LinkButton to="/shopRegister" appearance="primary" display="responsive">
        ＋店舗を追加
      </LinkButton>
      <HomeInfomation />
    </Container>
  );
});
