import React from "react";
import styled from "styled-components";
import { Loading } from "../atoms/Loading";
import { colorsConst, zIndexConst } from "../../styles/const";

const Container = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: ${zIndexConst.OVERLAY};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${(p) => (p.active ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: ${colorsConst.BACKGROUND.OVERLAY};
`;

type Props = {
  active: boolean;
};

export const LoadingOverlay: React.FC<Props> = React.memo(({ active }) => {
  return (
    <Container active={active}>
      <Loading size="4x" />
    </Container>
  );
});
