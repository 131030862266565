import { fork, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import { userSubmittedLoginSaga } from "./sagas/userSubmittedLoginSaga";
import { systemGetUserDataByTokenSaga } from "./sagas/systemGetUserDataByTokenSaga";

function* watchUserSubmittedLoginSaga() {
  yield takeLatest(ActionTypes.U_SUBMITTED_LOGIN, userSubmittedLoginSaga);
}

function* watchSystemGetUserDataByTokenSaga() {
  yield takeLatest(ActionTypes.S_GET_USER_DATA_BY_TOKEN, systemGetUserDataByTokenSaga);
}

export function* userSaga() {
  yield fork(watchUserSubmittedLoginSaga);
  yield fork(watchSystemGetUserDataByTokenSaga);
}
