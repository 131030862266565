import moment from "moment";

export class DateUtils {
  public static getDateString(date: string | Date) {
    return moment(date).format("YYYY-MM-DD");
  }

  public static getYearMonthString(date: string | Date) {
    return moment(date).format("YYYY-MM");
  }

  public static getMonthDayString(date: string | Date) {
    return moment(date).format("MM/DD");
  }

  public static getDayOfWeekString(weekNumber: number) {
    moment.locale("ja", {
      weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    });
    return moment().weekday(weekNumber).format("dddd");
  }

  public static isPastDate(date: Date | string, now = new Date()) {
    return moment(date).isBefore(moment(now), "day");
  }

  public static isValidDateString(date: string) {
    return moment(date).isValid();
  }
}
