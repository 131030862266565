export type TotalReport = Readonly<{
  takeout: number;
  delivery: number;
  total: number;
}>;

const initialState: TotalReport = {
  takeout: 0,
  delivery: 0,
  total: 0,
};

const create = (args: Partial<TotalReport> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const TotalReport = Object.freeze({
  initialState,
  create,
});
