import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DateUtils } from "../../utils/DateUtils";
import { media, fontSize } from "../../styles/mixins";
import { colorsConst } from "../../styles/const";
import { OrderSalesReport } from "../../records/OrderSalesReport";
import { orderSalesSelector, existsOrderSalesSelector } from "../../modules/sales/selectors";
import { userTouchedUpdateOrderSalesButton } from "../../modules/sales/actions";
import { shopListSelector, existsShopListSelector } from "../../modules/shop/selectors";
import { userAccessedToPageThatNeedsShopList } from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import {
  SalesReportContainer,
  SalesReportHeader,
  SalesReportHeaderRow,
  SalesReportHeaderKey,
  SalesReportHeaderInput,
  SalesReportBody,
} from "../atoms/SalesReport";
import { Button } from "../atoms/Button";
import { Input, Select } from "../atoms/FormParts/TextInput";
import { Auth } from "../organisms/Auth";

const INITIAL_DATE = new Date();
INITIAL_DATE.setDate(INITIAL_DATE.getDate() - 1);
const INITIAL_DATE_STRING = DateUtils.getYearMonthString(INITIAL_DATE);

const DateInput = styled(Input)`
  border: 1px solid ${colorsConst.BORDER.THICK};
`;
const ShopSelect = styled(Select)`
  border: 1px solid ${colorsConst.BORDER.THICK};
`;
const ButtonContainer = styled.div``;

type Props = {};

export const OrderSalesReportTemplate: React.FC<Props> = React.memo(() => {
  const shopList = useSelector(shopListSelector);
  const existsShopList = useSelector(existsShopListSelector);
  const orderSales = useSelector(orderSalesSelector);
  const existsOrderSales = useSelector(existsOrderSalesSelector);

  const [draftTargetMonth, setDraftTargetMonth] = useState<string>(INITIAL_DATE_STRING);
  const [draftTargetShopId, setDraftTargetShopId] = useState<number>(0);

  const canSubmit = useMemo(() => {
    return draftTargetMonth !== "" && draftTargetShopId !== 0;
  }, [draftTargetMonth, draftTargetShopId]);

  const dispatch = useDispatch();

  const handleMonthChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftTargetMonth(event.target.value);
  }, []);

  const handleShopChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setDraftTargetShopId(parseInt(event.target.value, 10));
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(userTouchedUpdateOrderSalesButton(draftTargetMonth, draftTargetShopId));
  }, [dispatch, draftTargetMonth, draftTargetShopId]);

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopList());
  }, [dispatch]);

  return (
    <Auth>
      <Page header>
        <PageTitle>注文別レポート</PageTitle>
        <SalesReportContainer>
          <SalesReportHeader>
            <SalesReportHeaderRow>
              <SalesReportHeaderKey>集計月</SalesReportHeaderKey>
              <SalesReportHeaderInput>
                <DateInput type="month" onChange={handleMonthChange} value={draftTargetMonth} />
              </SalesReportHeaderInput>
            </SalesReportHeaderRow>
            <SalesReportHeaderRow>
              <SalesReportHeaderKey>店舗</SalesReportHeaderKey>
              <SalesReportHeaderInput>
                <ShopSelect onChange={handleShopChange} value={draftTargetShopId}>
                  <option value="0">選択してください</option>
                  {existsShopList &&
                    shopList.map((shop) => (
                      <option key={shop.id} value={shop.id}>
                        {shop.shop_name}
                      </option>
                    ))}
                </ShopSelect>
              </SalesReportHeaderInput>
            </SalesReportHeaderRow>
            <ButtonContainer>
              <Button
                onClick={handleSubmit}
                appearance="primary"
                display="responsive"
                disabled={!canSubmit}
              >
                集計
              </Button>
            </ButtonContainer>
          </SalesReportHeader>
          <SalesReportBody>
            {existsOrderSales ? (
              <ReportTableContainer>
                <ReportTable>
                  <ReportTableHeader>
                    <ReportTableHeaderRow>
                      <OrderNumber>注文No</OrderNumber>
                      <OrderItems>
                        <OrderItemRow>
                          <ItemName header>商品名／オプション名</ItemName>
                          <ItemPrice>単価</ItemPrice>
                          <ItemCount>数量</ItemCount>
                        </OrderItemRow>
                      </OrderItems>
                      <PriceCell>合計金額</PriceCell>
                      <PriceCell>応援金</PriceCell>
                      <PriceCell>調整金額</PriceCell>
                      <ReportTableCell>受取方法</ReportTableCell>
                      <ReportTableCell>承認日時</ReportTableCell>
                      <ReportTableCell>受渡日時</ReportTableCell>
                    </ReportTableHeaderRow>
                  </ReportTableHeader>
                  <ReportTableBody>
                    <ReportTableTotalRow>
                      <ReportTableCell>合計</ReportTableCell>
                      <OrderItems>
                        <OrderItemRow>
                          <ItemName header>-</ItemName>
                          <ItemPrice>-</ItemPrice>
                          <ItemCount>-</ItemCount>
                        </OrderItemRow>
                      </OrderItems>
                      <PriceCell>{orderSales.total}</PriceCell>
                      <PriceCell>-</PriceCell>
                      <PriceCell>-</PriceCell>
                      <ReportTableCell>-</ReportTableCell>
                      <ReportTableCell>-</ReportTableCell>
                      <ReportTableCell>-</ReportTableCell>
                    </ReportTableTotalRow>
                    {orderSales.orders.map((order) => (
                      <ReportTableRow key={order.order_no}>
                        <OrderNumber data-label="注文No：">{order.order_no}</OrderNumber>
                        <OrderItems>
                          <OrderItemTable>
                            <OrderItemHeader>
                              <OrderItemHeaderRow>
                                <OrderItemDetail>
                                  <OrderItem>
                                    <ItemName>商品名／オプション名</ItemName>
                                    <ItemPrice>単価</ItemPrice>
                                  </OrderItem>
                                </OrderItemDetail>
                                <ItemCount>数量</ItemCount>
                              </OrderItemHeaderRow>
                            </OrderItemHeader>
                            <OrderItemTableBody>
                              {order.items.map((item, itemIndex) => (
                                <OrderItemRow key={`${order.order_no}_${itemIndex}`}>
                                  <OrderItemDetail>
                                    <OrderItem>
                                      <ItemName>{item.name}</ItemName>
                                      <ItemPrice>{item.price}</ItemPrice>
                                    </OrderItem>
                                    {item.options.map((option, optionIndex) => (
                                      <OrderItemOption
                                        key={`${order.order_no}_${itemIndex}_${optionIndex}`}
                                      >
                                        <OptionName>{option.name}</OptionName>
                                        <OptionPrice>{option.price}</OptionPrice>
                                      </OrderItemOption>
                                    ))}
                                  </OrderItemDetail>
                                  <ItemCount>{item.count}</ItemCount>
                                </OrderItemRow>
                              ))}
                            </OrderItemTableBody>
                          </OrderItemTable>
                        </OrderItems>
                        <PriceCell data-label="合計金額">{order.price}</PriceCell>
                        <PriceCell data-label="応援金">{order.support_price}</PriceCell>
                        <PriceCell data-label="調整金額">{order.adjust_price}</PriceCell>
                        <ReportTableCell data-label="受け取り方法">
                          {OrderSalesReport.getPresentationReceiveTypeText(order.receive_type)}
                        </ReportTableCell>
                        <ReportTableCell data-label="承認日時">
                          {order.approve_date}
                        </ReportTableCell>
                        <ReportTableCell data-label="受渡日時">{order.finish_date}</ReportTableCell>
                      </ReportTableRow>
                    ))}
                  </ReportTableBody>
                </ReportTable>
              </ReportTableContainer>
            ) : (
              <div>レポートがありません</div>
            )}
          </SalesReportBody>
        </SalesReportContainer>
      </Page>
    </Auth>
  );
});

const ReportTableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

const ReportTable = styled.div`
  width: 100%;
  ${fontSize.SMALL};
  ${media.desktop} {
    display: table;
    border-collapse: collapse;
  }
`;

const ReportTableHeader = styled.div`
  display: none;
  ${media.desktop} {
    display: table-header-group;
  }
`;

const ReportTableHeaderRow = styled.div`
  display: table-row;
  border-bottom: 1px solid ${colorsConst.BORDER.DEFAULT};
`;

const ReportTableBody = styled.div`
  ${media.desktop} {
    display: table-row-group;
  }
`;

const ReportTableRow = styled.div`
  ${media.desktop} {
    display: table-row;
  }
  margin-bottom: 1em;
  padding-bottom: 1em;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colorsConst.BORDER.DEFAULT};
  }
`;

const ReportTableTotalRow = styled(ReportTableRow)`
  display: none;
`;

const ReportTableCell = styled.div`
  ${media.desktop} {
    display: table-cell;
    padding: 0.25em 0.5em;
    white-space: nowrap;
    border-bottom: none;
    &::before {
      content: none !important;
    }
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.25em 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colorsConst.BORDER.THIN};
  }
  &::before {
    content: attr(data-label);
  }
`;

const OrderNumber = styled(ReportTableCell)`
  justify-content: flex-start;
`;

const OrderItems = styled(ReportTableCell)`
  width: 100%;
  display: block;
`;

const OrderItemTable = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const OrderItemHeader = styled.div`
  display: table-header-group;
  ${media.desktop} {
    display: none;
  }
`;

const OrderItemHeaderRow = styled.div`
  display: table-row;
  border-bottom: 1px solid ${colorsConst.BORDER.DEFAULT};
`;

const OrderItemTableBody = styled.div`
  display: table-row-group;
`;

const OrderItemRow = styled.div`
  display: table-row;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${colorsConst.BORDER.THIN};
  }
  ${media.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: none;
  }
`;

const OrderItemDetail = styled.div`
  display: table-cell;
  padding: 0.25em 0;
  ${media.desktop} {
    flex-grow: 1;
    flex-shrink: 1;
    display: block;
    padding: 0;
  }
`;

const OrderItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ItemName = styled.div<{ header?: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 10em;
  text-align: ${(p) => (p.header ? "center" : "left")};
`;

const ItemPrice = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 4em;
  text-align: right;
`;

const ItemCount = styled.div<{ header?: boolean }>`
  display: table-cell;
  text-align: ${(p) => (p.header ? "center" : "right")};
  ${media.desktop} {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    width: 3em;
    &::before {
      content: none;
    }
  }
`;

const OrderItemOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const OptionName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  ${fontSize.XSMALL};
`;

const OptionPrice = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 4em;
  text-align: right;
  ${fontSize.XSMALL};
`;

const PriceCell = styled(ReportTableCell)`
  text-align: right;
`;
