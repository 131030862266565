import { FormikProps, withFormik } from "formik";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { LoginForm, initialLoginForm, loginFormValidationSchema } from "../../forms/login";
import { AppModel, PROCESSING_FLAG } from "../../modules/app/model";
import { systemAutoLogin } from "../../modules/app/actions";
import { userSubmittedLogin } from "../../modules/user/actions";
import { ReduxAction, ReduxModel } from "../../reducer";
import { Page } from "../atoms/Page";
import { Logo } from "../atoms/Logo";
import { Notes } from "../atoms/Notes";
import { Button } from "../atoms/Button";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { spaceConst } from "../../styles/const";
import { media } from "../../styles/mixins/media";

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: ${`0 ${spaceConst.PADDING.PAGE.HORIZONTAL.DEFAULT}px`};
  ${media.tablet} {
    padding: ${`0 ${spaceConst.PADDING.PAGE.HORIZONTAL.TABLET}px`};
  }
  ${media.tablet} {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 50px;
  ${media.tablet} {
    margin-bottom: 70px;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 1em;
`;

const ButtonContainer = styled.div`
  margin-top: ${`${spaceConst.GUTTER.INPUT_FIELD.DEFAULT}px`};
  ${media.tablet} {
    margin-top: ${`${spaceConst.GUTTER.INPUT_FIELD.TABLET}px`};
  }
`;

const NotesContainer = styled.div`
  margin-top: 45px;
  ${media.tablet} {
    margin-top: 60px;
  }
`;

const mapStateToProps = (state: ReduxModel) => ({
  processingAutoLogin: AppModel.isProcessing(state.app, PROCESSING_FLAG.AUTO_LOGIN),
});

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  autoLogin() {
    dispatch(systemAutoLogin());
  },
  dispatchSubmit(formValue: LoginForm) {
    dispatch(userSubmittedLogin(formValue));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<LoginForm> & PropsBase & {};

const TopTemplate: React.FC<Props> = React.memo(
  ({ autoLogin, handleSubmit, values, handleChange, processingAutoLogin }) => {
    useEffect(() => {
      autoLogin();
    }, [autoLogin]);

    return (
      <Page canScroll={false} loadingOverlay={processingAutoLogin}>
        <Container>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <Paragraph>ログインIDとパスワードを入力してください。</Paragraph>
          <form onSubmit={handleSubmit}>
            <FormFieldGroup>
              <FormField>
                <FormLabel>ログインID</FormLabel>
                <TextField type="text" name="mail" onChange={handleChange} value={values.mail} />
              </FormField>
            </FormFieldGroup>
            <FormFieldGroup>
              <FormField>
                <FormLabel>パスワード</FormLabel>
                <TextField
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                />
              </FormField>
            </FormFieldGroup>
            <ButtonContainer>
              <Button type="submit" appearance="primary" display="block">
                ログイン
              </Button>
            </ButtonContainer>
          </form>
          <NotesContainer>
            <Notes>
              ログインIDとパスワードはmenu掲載申し込み時にお送りしているメールに記載されております。メールが見つからない、という方は
              <a
                href="mailto:sup@menu.inc?subject=ストアマネージャーのログイン問い合わせ&amp;body=企業様名と登録メールアドレスを記載してください。%0D%0A企業様名：%0D%0A登録メールアドレス：%0D%0A登録情報と照合した後、ログイン情報をメールにてご連絡致します。%0D%0A@menu.incからのメールを受信可能にしておいて頂けますようお願い致します。"
                rel="noreferrer noopener"
                target="_blank"
              >
                カスタマーサポート
              </a>
              までご連絡ください。
            </Notes>
          </NotesContainer>
        </Container>
      </Page>
    );
  },
);

const connectForm = withFormik<PropsBase, LoginForm>({
  mapPropsToValues: (): LoginForm => initialLoginForm,
  validationSchema: loginFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(connectForm(TopTemplate));
