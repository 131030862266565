import { DailySales } from "../../records/DailySales";
import { MonthlySales } from "../../records/MonthlySales";
import { OrderSales } from "../../records/OrderSales";

export enum ActionTypes {
  U_TOUCHED_UPDATE_DAILY_SALES_BUTTON = "sales/U_TOUCHED_UPDATE_DAILY_SALES_BUTTON",
  U_TOUCHED_UPDATE_MONTHLY_SALES_BUTTON = "sales/U_TOUCHED_UPDATE_MONTHLY_SALES_BUTTON",
  U_TOUCHED_UPDATE_ORDER_SALES_BUTTON = "sales/U_TOUCHED_UPDATE_ORDER_SALES_BUTTON",

  R_UPDATE_DAILY_SALES = "sales/R_UPDATE_DAILY_SALES",
  R_UPDATE_MONTHLY_SALES = "sales/R_UPDATE_MONTHLY_SALES",
  R_UPDATE_ORDER_SALES = "sales/R_UPDATE_ORDER_SALES",
}

export const userTouchedUpdateDailySalesButton = (targetMonth: string, shopId: number) =>
  ({
    type: ActionTypes.U_TOUCHED_UPDATE_DAILY_SALES_BUTTON,
    payload: {
      targetMonth,
      shopId,
    },
  } as const);
export type UserTouchedUpdateDailySalesButtonAction = ReturnType<
  typeof userTouchedUpdateDailySalesButton
>;

export const userTouchedUpdateMonthlySalesButton = (targetMonth: string) =>
  ({
    type: ActionTypes.U_TOUCHED_UPDATE_MONTHLY_SALES_BUTTON,
    payload: {
      targetMonth,
    },
  } as const);
export type UserTouchedUpdateMonthlySalesButtonAction = ReturnType<
  typeof userTouchedUpdateMonthlySalesButton
>;

export const userTouchedUpdateOrderSalesButton = (targetMonth: string, shopId: number) =>
  ({
    type: ActionTypes.U_TOUCHED_UPDATE_ORDER_SALES_BUTTON,
    payload: {
      targetMonth,
      shopId,
    },
  } as const);
export type UserTouchedUpdateOrderSalesButtonAction = ReturnType<
  typeof userTouchedUpdateOrderSalesButton
>;

export const updateDailySales = (dailySales: DailySales) =>
  ({
    type: ActionTypes.R_UPDATE_DAILY_SALES,
    payload: {
      dailySales,
    },
  } as const);
export type UpdateDailySalesAction = ReturnType<typeof updateDailySales>;

export const updateMonthlySales = (monthlySales: MonthlySales) =>
  ({
    type: ActionTypes.R_UPDATE_MONTHLY_SALES,
    payload: {
      monthlySales,
    },
  } as const);
export type UpdateMonthlySalesAction = ReturnType<typeof updateMonthlySales>;

export const updateOrderSales = (orderSales: OrderSales) =>
  ({
    type: ActionTypes.R_UPDATE_ORDER_SALES,
    payload: {
      orderSales,
    },
  } as const);
export type UpdateOrderSalesAction = ReturnType<typeof updateOrderSales>;

export type SalesActions =
  | UserTouchedUpdateDailySalesButtonAction
  | UserTouchedUpdateMonthlySalesButtonAction
  | UserTouchedUpdateOrderSalesButtonAction
  | UpdateDailySalesAction
  | UpdateMonthlySalesAction
  | UpdateOrderSalesAction;
