import * as Yup from "yup";
import { emailValidation } from "./validations";

export type LoginForm = {
  mail: string;
  password: string;
};

export const initialLoginForm: LoginForm = {
  mail: "a@a.com",
  password: "password",
};

export const loginFormValidationSchema = Yup.object({
  mail: emailValidation,
  password: Yup.string().required("パスワードを入力して下さい"),
})
  .strict(true)
  .noUnknown();
