import React, { useEffect, useCallback, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { shopItemCategoryListSelector } from "../../modules/shop/selectors";
import {
  userAccessedToPageThatNeedsShopItemList,
  userTouchedSortShopItemCategoryButton,
} from "../../modules/shop/actions";
import { DirectionType } from "../../modules/shop/model";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { PageIntroduction } from "../atoms/PageIntroduction";
import { Paragraph } from "../atoms/Paragraph";
import { LinkButton } from "../atoms/LinkButton";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";
import { ShopItemCategoryListItem } from "../organisms/ShopItemCategoryListItem";

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const ShopItemCategoryTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const shopItemCategoryList = useSelector(shopItemCategoryListSelector);

  const categoryIds = useMemo(() => {
    return shopItemCategoryList.map((category) => category.category_id);
  }, [shopItemCategoryList]);

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(push(`/shopItem/${shopId}`));
  }, [dispatch, shopId]);

  const handleSort = useCallback(
    (categoryId: number, direction: DirectionType) => {
      dispatch(userTouchedSortShopItemCategoryButton(shopId, categoryId, direction, categoryIds));
    },
    [dispatch, shopId, categoryIds],
  );

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopItemList(shopId));
  }, [dispatch, shopId]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="商品一覧に戻る" handleClick={handleBack} />
        <PageIntroduction>
          <Paragraph>商品カテゴリを登録してください。</Paragraph>
          <Paragraph>
            アプリ上で商品は「商品カテゴリ」ごとに分けて表示されます。アプリに出す商品に合った商品カテゴリを登録してください。
          </Paragraph>
          <LinkButton
            to={`/shopItemCategoryRegister/${shopId}`}
            appearance="primary"
            display="responsive"
          >
            商品カテゴリの新規作成
          </LinkButton>
        </PageIntroduction>
        <PageTitle>商品カテゴリ一覧</PageTitle>
        <Paragraph>矢印を押すと、アプリで表示される順番を変更することができます。</Paragraph>
        {shopItemCategoryList.map((category, index) => (
          <ShopItemCategoryListItem
            key={category.category_id}
            shopId={shopId}
            category={category}
            index={index}
            length={shopItemCategoryList.length}
            handleSort={handleSort}
          />
        ))}
      </Page>
    </Auth>
  );
});

export default withRouter(ShopItemCategoryTemplate);
