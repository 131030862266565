import React from "react";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Auth } from "../organisms/Auth";
import { MovieContainer } from "../organisms/MovieContainer";

type Props = {};

export const ShopApplicationManualTemplate: React.FC<Props> = React.memo(() => {
  return (
    <Auth>
      <Page header>
        <PageTitle>店舗アプリの使い方</PageTitle>
        <div>
          <div>操作マニュアル</div>
          <p>
            店舗アプリの操作マニュアルは
            <a href="/pdf/manual_shop.pdf" target="_blank">
              こちら
            </a>
          </p>
        </div>
        <div>
          <div>操作説明動画</div>
          <MovieContainer>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/QV3JPTh-Ins"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </MovieContainer>
        </div>
      </Page>
    </Auth>
  );
});
