export type FontSizes = "XSMALL" | "SMALL" | "DEFAULT" | "LARGE" | "XLARGE" | "XXLARGE";

export const fontFamilyConst = "'Noto Sans JP', sans-serif";

export const fontSizesConst: { [K in FontSizes]: { default: string; tablet: string } } = {
  XSMALL: {
    default: "1.0rem",
    tablet: "1.2rem",
  },
  SMALL: {
    default: "1.2rem",
    tablet: "1.4rem",
  },
  DEFAULT: {
    default: "1.4rem",
    tablet: "1.6rem",
  },
  LARGE: {
    default: "1.6rem",
    tablet: "2.0rem",
  },
  XLARGE: {
    default: "2.0rem",
    tablet: "2.4rem",
  },
  XXLARGE: {
    default: "2.4rem",
    tablet: "2.8rem",
  },
} as const;

export type FontWeights = "REGULAR" | "MEDIUM" | "BOLD";

export const fontWeightsConst: { [K in FontWeights]: number } = {
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700,
} as const;
