import React, { useCallback } from "react";
import styled from "styled-components";
import { Button } from "../../atoms/Button";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Text = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

type Props = {
  holiday: string;
  handleClick: (holiday: string) => void;
};

export const HolidayRow: React.FC<Props> = React.memo(({ holiday, handleClick }) => {
  const handleClickWrap = useCallback(() => {
    handleClick(holiday);
  }, [holiday, handleClick]);
  return (
    <Container>
      <Text>{holiday}</Text>
      <ButtonContainer>
        <Button size="sm" appearance="secondary" onClick={handleClickWrap}>
          解除
        </Button>
      </ButtonContainer>
    </Container>
  );
});
