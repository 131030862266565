import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxModel } from "../../reducer";
import { Button } from "../atoms/Button";
import { Dialog, DialogInner, DialogBody, DialogFooter } from "../atoms/Dialog";
import { Modal } from "../molecules/Modal";
import { AppModel } from "../../modules/app/model";
import { ModalManager } from "../../records/ModalManager";

type Props = {};

export const ShouldUpdateResourceModal: React.FC<Props> = React.memo(() => {
  const openShouldUpdateResourceModal = useSelector((state: ReduxModel) =>
    ModalManager.canDisplay(state.app, "SHOULD_UPDATE_RESOURCE"),
  );

  const [processingFlag, setProcessingFlag] = useState(false);

  const handleClickUpdateResource = useCallback(() => {
    setProcessingFlag(true);
    AppModel.updateResource();
  }, []);

  return openShouldUpdateResourceModal ? (
    <Modal isOpen={true}>
      <Dialog>
        <DialogInner>
          <DialogBody>
            新しいデータがあります。
            <br />
            画面を読み込み直してください。
          </DialogBody>
          <DialogFooter>
            <Button onClick={handleClickUpdateResource} loading={processingFlag}>
              再読み込み
            </Button>
          </DialogFooter>
        </DialogInner>
      </Dialog>
    </Modal>
  ) : null;
});
