export type MonthlySalesReport = Readonly<{
  shop_id: number;
  shop_name: string;
  takeout: number;
  delivery: number;
  total: number;
}>;

const initialState: MonthlySalesReport = {
  shop_id: 0,
  shop_name: "",
  takeout: 0,
  delivery: 0,
  total: 0,
};

const create = (args: Partial<MonthlySalesReport> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const MonthlySalesReport = Object.freeze({
  initialState,
  create,
});
