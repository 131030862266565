import React, { useContext } from "react";
import styled from "styled-components";
import { ShopData } from "../../../records/ShopData";
import { Text } from "../../atoms/Text";
import { ShopDetailContext } from "./ShopDetailContext";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import {
  ShopDetailSection,
  ShopDetailRow,
  ShopDetailKey,
  ShopDetailVal,
} from "../../molecules/ShopDetailSection";

const Container = styled.div``;
const BankAccountVal = styled.div``;

type Props = {};

export const ShopBankAccount: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);
  return (
    <Container>
      <ShopDetailSectionTitle title="口座情報" link={`/bankAccountEdit/${shopData.id}`} />
      <ShopDetailSection>
        <ShopDetailRow>
          <ShopDetailKey>金融機関</ShopDetailKey>
          <ShopDetailVal>
            <BankAccountVal>
              金融機関名：
              {ShopData.existsBankName(shopData.bank_name) ? (
                shopData.bank_name
              ) : (
                <Text textColor="DANGER">未設定</Text>
              )}
            </BankAccountVal>
            <BankAccountVal>
              金融機関コード：
              {ShopData.existsBankCode(shopData.bank_code) ? (
                shopData.bank_code
              ) : (
                <Text textColor="DANGER">未設定</Text>
              )}
            </BankAccountVal>
            <BankAccountVal>
              支店名：
              {ShopData.existsBranchName(shopData.branch_name) ? (
                shopData.branch_name
              ) : (
                <Text textColor="DANGER">未設定</Text>
              )}
            </BankAccountVal>
            <BankAccountVal>
              支店コード：
              {ShopData.existsBranchCode(shopData.branch_code) ? (
                shopData.branch_code
              ) : (
                <Text textColor="DANGER">未設定</Text>
              )}
            </BankAccountVal>
          </ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>口座</ShopDetailKey>
          <ShopDetailVal>
            <BankAccountVal>
              口座種別：
              {ShopData.existsBankType(shopData.bank_type) ? (
                shopData.bank_type
              ) : (
                <Text textColor="DANGER">未設定</Text>
              )}
            </BankAccountVal>
            <BankAccountVal>
              口座名義：
              {ShopData.existsBankAccountName(shopData.bank_account_name) ? (
                shopData.bank_account_name
              ) : (
                <Text textColor="DANGER">未設定</Text>
              )}
            </BankAccountVal>
            <BankAccountVal>
              口座番号：
              {ShopData.existsBankNumber(shopData.bank_number) ? (
                shopData.bank_number
              ) : (
                <Text textColor="DANGER">未設定</Text>
              )}
            </BankAccountVal>
          </ShopDetailVal>
        </ShopDetailRow>
      </ShopDetailSection>
    </Container>
  );
});
