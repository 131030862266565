import React, { useContext } from "react";
import styled from "styled-components";
import { ShopBusiness, DEFAULT_CROW_ORDER_COUNT } from "../../../records/ShopBusiness";
import { Text } from "../../atoms/Text";
import { ShopBusinessContext } from "./ShopBusinessContext";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import {
  ShopDetailSection,
  ShopDetailRow,
  ShopDetailKey,
  ShopDetailVal,
} from "../../molecules/ShopDetailSection";

const Container = styled.div``;

type Props = {};

export const CookingTime: React.FC<Props> = React.memo(() => {
  const { shopId, shopBusiness } = useContext(ShopBusinessContext);
  return (
    <Container>
      <ShopDetailSectionTitle title="基本情報" link={`/cookingTimeEdit/${shopId}`} />
      <ShopDetailSection>
        <ShopDetailRow>
          <ShopDetailKey>調理時間</ShopDetailKey>
          <ShopDetailVal>
            {ShopBusiness.getPresentationCookingTimeString(shopBusiness.cooking_time)}
          </ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>混雑になる注文商品数</ShopDetailKey>
          <ShopDetailVal>
            {ShopBusiness.existsCrowOrderCount(shopBusiness.crow_order_count) ? (
              `${shopBusiness.crow_order_count}個`
            ) : (
              <>
                {`${DEFAULT_CROW_ORDER_COUNT}個`}
                <br />
                <Text textColor="DANGER" size="SMALL">
                  ※未設定のためデフォルト値が設定されています
                </Text>
              </>
            )}
          </ShopDetailVal>
        </ShopDetailRow>
      </ShopDetailSection>
    </Container>
  );
});
