import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ShopItemCategory } from "../../records/ShopItemCategory";
import { DIRECTION_TYPE, DirectionType } from "../../modules/shop/model";
import { colorsConst } from "../../styles/const";
import { SortButton } from "../molecules/SortButton";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  & + & {
    margin-top: 1em;
  }
`;

const CategoryName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const SortButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  shopId: number;
  category: ShopItemCategory;
  index: number;
  length: number;
  handleSort: (categoryId: number, direction: DirectionType) => void;
};

export const ShopItemCategoryListItem: React.FC<Props> = React.memo(
  ({ shopId, category, index, length, handleSort }) => {
    const handleClickSortButton = useCallback(
      (direction: DirectionType) => {
        handleSort(category.category_id, direction);
      },
      [category, handleSort],
    );
    return (
      <Container>
        <CategoryName>
          <Link to={`/shopItemCategoryEdit/${shopId}/${category.category_id}`}>
            {category.category_name}
          </Link>
        </CategoryName>
        <SortButtonContainer>
          <SortButton
            index={index}
            length={length}
            direction={DIRECTION_TYPE.UP}
            handleClick={handleClickSortButton}
          />
          <SortButton
            index={index}
            length={length}
            direction={DIRECTION_TYPE.DOWN}
            handleClick={handleClickSortButton}
          />
        </SortButtonContainer>
      </Container>
    );
  },
);
