import React, { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ShopData, NOTIFICATION_FLAG } from "../../../records/ShopData";
import { Utility } from "../../../utils/Utility";
import { getPresentationCategoryTagNameListByIdsSelectorFactory } from "../../../modules/shop/selectors";
import { ImageField } from "../../molecules/ImageField";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import {
  ShopDetailSection,
  ShopDetailRow,
  ShopDetailRowInner,
  ShopDetailKey,
  ShopDetailVal,
} from "../../molecules/ShopDetailSection";
import { ShopDetailContext } from "./ShopDetailContext";

const Container = styled.div``;

type Props = {};

export const ShopInfo: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);

  const categoryTagNameList = useSelector(
    getPresentationCategoryTagNameListByIdsSelectorFactory(shopData.category_tag),
  );

  return (
    <Container>
      <ShopDetailSectionTitle title="基本情報" link={`/shopEdit/${shopData.id}`} />
      <ShopDetailSection>
        <ShopDetailRow>
          <ShopDetailRowInner>
            <ShopDetailKey>店舗写真</ShopDetailKey>
            <ImageField imagePath={shopData.header_image_path} />
          </ShopDetailRowInner>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>ID</ShopDetailKey>
          <ShopDetailVal>{shopData.id}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>担当者名</ShopDetailKey>
          <ShopDetailVal>{shopData.person_in_charge_name}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>担当者名（フリガナ）</ShopDetailKey>
          <ShopDetailVal>{shopData.person_in_charge_name_ruby}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>店舗名</ShopDetailKey>
          <ShopDetailVal>{shopData.shop_name}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>店舗名（フリガナ）</ShopDetailKey>
          <ShopDetailVal>{shopData.shop_name_ruby}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>メールアドレス</ShopDetailKey>
          <ShopDetailVal>{shopData.mail}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>電話番号</ShopDetailKey>
          <ShopDetailVal>{shopData.tel}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>カテゴリ</ShopDetailKey>
          <ShopDetailVal>
            {ShopData.existsCategoryTag(shopData) ? categoryTagNameList : "未設定"}
          </ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>住所</ShopDetailKey>
          <ShopDetailVal>{shopData.address}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>価格帯</ShopDetailKey>
          <ShopDetailVal>
            <ul>
              <li>
                {shopData.is_set_price_range
                  ? ShopData.getPresentationPriceRangeText(shopData.price_range)
                  : "未設定"}
              </li>
              {shopData.is_set_recommend_item ? (
                <>
                  {!shopData.is_set_price_range ? (
                    <li>{`店舗のおすすめ商品の最安値が${Utility.getPresentationPrice(
                      shopData.lowest_price,
                    )}のため、初期値は${shopData.recommend_item_price_range}に設定されます`}</li>
                  ) : (
                    <>
                      {shopData.price_range !== shopData.recommend_item_price_range ? (
                        <li>おすすめ商品の最安値と設定している価格帯が異なっています。</li>
                      ) : undefined}
                    </>
                  )}
                  <li>{`店舗のおすすめ商品の最安値：${Utility.getPresentationPrice(
                    shopData.lowest_price,
                  )}`}</li>
                  {!shopData.is_set_delivery_price_range ? (
                    <li>{`※デリバリー価格が設定されていないため、テイクアウト価格${Utility.getPresentationPrice(
                      shopData.lowest_price,
                    )}をデリバリー価格に再計算しています。`}</li>
                  ) : undefined}
                </>
              ) : (
                <li>店舗のおすすめ商品が設定されていません</li>
              )}
            </ul>
          </ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>電話通知フラグ</ShopDetailKey>
          <ShopDetailVal>
            {shopData.tel_notification_flag === NOTIFICATION_FLAG.TRUE
              ? "電話通知する"
              : "電話通知しない"}
          </ShopDetailVal>
        </ShopDetailRow>
      </ShopDetailSection>
    </Container>
  );
});
