export type DailySalesReport = Readonly<{
  day: string;
  takeout: number;
  delivery: number;
  total: number;
}>;

const initialState: DailySalesReport = {
  day: "",
  takeout: 0,
  delivery: 0,
  total: 0,
};

const create = (args: Partial<DailySalesReport> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const DailySalesReport = Object.freeze({
  initialState,
  create,
});
