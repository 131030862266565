import { OrderItem } from "./OrderItem";

export const RECEIVE_TYPE = {
  TAKEOUT: 1,
  DELIVERY: 2,
} as const;

export type ReceiveType = ValueOf<typeof RECEIVE_TYPE>;

export type OrderSalesReport = Readonly<{
  items: OrderItem[];
  price: number;
  support_price: number;
  adjust_price: number;
  order_no: string;
  receive_type: ReceiveType;
  approve_date: string;
  finish_date: string;
  item_count: number;
}>;

const initialState: OrderSalesReport = {
  items: [],
  price: 0,
  support_price: 0,
  adjust_price: 0,
  order_no: "",
  receive_type: RECEIVE_TYPE.TAKEOUT,
  approve_date: "",
  finish_date: "",
  item_count: 0,
};

const create = (args: Partial<OrderSalesReport> = {}) => {
  return {
    ...initialState,
    items: args.items ? args.items.map((i) => OrderItem.create(i)) : [],
    ...args,
  };
};

const getPresentationReceiveTypeText = (receiveType: ReceiveType) => {
  switch (receiveType) {
    case RECEIVE_TYPE.TAKEOUT:
      return "テイクアウト";
    case RECEIVE_TYPE.DELIVERY:
      return "配達";
    // skip default case
  }
};

export const OrderSalesReport = Object.freeze({
  initialState,
  create,
  getPresentationReceiveTypeText,
});
