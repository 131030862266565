import { media } from "./media";
import { fontSizesConst, FontSizes } from "../const/fontsConst";

type MediaQueryMap = {
  [K in FontSizes]: string;
};

export const fontSize = Object.keys(fontSizesConst)
  .map((i) => i)
  .reduce<MediaQueryMap>((acc, label) => {
    // eslint-disable-next-line no-param-reassign
    acc[label] = `font-size: ${fontSizesConst[label].default};
    ${media.tablet} { font-size: ${fontSizesConst[label].tablet}};`;
    return acc;
  }, {} as MediaQueryMap);
