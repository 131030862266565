import Yup from "yup";
import { FormikErrors } from "formik";

export function getInitialErrors<T extends {}>(
  schema: Yup.ObjectSchema<T>,
  values: T,
): FormikErrors<T> {
  try {
    schema.validateSync(values);
    return {};
  } catch (e) {
    const {
      params: { path },
      message,
    } = e;
    return {
      [path]: message,
    } as FormikErrors<T>; // FIXME: should annotate type correctly
  }
}
