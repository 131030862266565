export type LatLng = Readonly<{
  latitude: string;
  longitude: string;
}>;

const initialState = {
  latitude: "",
  longitude: "",
};

const create = (args: Partial<LatLng> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const LatLng = Object.freeze({
  initialState,
  create,
});
