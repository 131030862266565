import React, { useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  shopOptionSetListSelector,
  existsShopOptionSetListSelector,
} from "../../modules/shop/selectors";
import { userAccessedToPageThatNeedsOptionSetList } from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { PageIntroduction } from "../atoms/PageIntroduction";
import { Paragraph } from "../atoms/Paragraph";
import { LinkButton } from "../atoms/LinkButton";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";
import { OptionSetList } from "../organisms/OptionSetList";

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const ShopOptionSetTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const existsShopOptionSetList = useSelector(existsShopOptionSetListSelector);
  const dispatch = useDispatch();
  const shopOptionSetList = useSelector(shopOptionSetListSelector);

  const handleBack = useCallback(() => {
    dispatch(push(`/shopItem/${shopId}`));
  }, [dispatch, shopId]);

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsOptionSetList(shopId));
  }, [dispatch, shopId]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="商品一覧に戻る" handleClick={handleBack} />
        <PageIntroduction>
          <Paragraph>商品カテゴリを登録してください。</Paragraph>
          <Paragraph>
            アプリ上で商品は「商品カテゴリ」ごとに分けて表示されます。アプリに出す商品に合った商品カテゴリを登録してください。
          </Paragraph>
          <LinkButton
            to={`/shopOptionSetRegister/${shopId}`}
            appearance="primary"
            display="responsive"
          >
            オプションセットの新規作成
          </LinkButton>
        </PageIntroduction>
        <PageTitle>オプションセット一覧</PageTitle>
        {existsShopOptionSetList ? (
          <OptionSetList shopId={shopId} list={shopOptionSetList} />
        ) : (
          <div>オプションセットは存在しません。</div>
        )}
      </Page>
    </Auth>
  );
});

export default withRouter(ShopOptionSetTemplate);
