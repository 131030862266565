import { FormikProps, withFormik } from "formik";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  ShopStaffRegisterForm,
  initialShopStaffRegisterForm,
  shopStaffRegisterFormValidationSchema,
} from "../../forms/shopStaffRegister";
import { ReduxAction, ReduxModel } from "../../reducer";
import { userSubmittedRegisterShopStaff } from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";

const mapStateToProps = (state: ReduxModel, ownProps: RouteComponentProps<{ shopId: string }>) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  return {
    shopId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopStaffRegisterForm) {
    dispatch(userSubmittedRegisterShopStaff(formValue));
  },
  handleBack(id: number) {
    dispatch(push(`/shopStaff/${id}`));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopStaffRegisterForm> &
  PropsBase & {
    children?: never;
  };

const ShopStaffRegisterTemplate: React.FC<Props> = React.memo(
  ({ shopId, handleBack, handleSubmit, handleChange, values, isValid }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer
            linkText="ログインアカウント一覧に戻る"
            handleClick={handleClickBack}
          />
          <PageTitle>ログインアカウント追加</PageTitle>
          <FormContainer onSubmit={handleSubmit}>
            <input type="hidden" name="shop_id" value={values.shop_id} />

            <FormInner>
              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="shop_item_category_id" required>
                    アカウント名
                  </FormLabel>
                  <TextField
                    type="text"
                    name="display_name"
                    id="display_name"
                    onChange={handleChange}
                    value={values.display_name}
                  />
                </FormField>
              </FormFieldGroup>

              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="shop_item_category_id" required>
                    ログインID
                  </FormLabel>
                  <TextField
                    type="text"
                    name="login_id"
                    id="login_id"
                    onChange={handleChange}
                    value={values.login_id}
                  />
                </FormField>
              </FormFieldGroup>

              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="shop_item_category_id" required>
                    パスワード
                  </FormLabel>
                  <TextField
                    type="password"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                </FormField>
                <FormNotice>半角英数（大文字／小文字の区別あり）記号8文字以上</FormNotice>
              </FormFieldGroup>
            </FormInner>
            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                更新
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopStaffRegisterForm>({
  enableReinitialize: true,
  mapPropsToValues: (): ShopStaffRegisterForm => initialShopStaffRegisterForm,
  validationSchema: shopStaffRegisterFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopStaffRegisterTemplate)),
);
