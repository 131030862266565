import React, { useMemo, useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch, useSelector, batch } from "react-redux";
import { API_KEY } from "../../apis";
import { isConnectedApiSelectorFactory } from "../../modules/app/selectors";
import { currentShopDataSelector } from "../../modules/shop/selectors";
import {
  userAccessedToPageThatNeedsCurrentShopData,
  userAccessedToPageThatNeedsCategoryTagList,
  userAccessedToPageThatNeedsShopStaffList,
} from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { LoadingContainer } from "../molecules/LoadingContainer";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";
import { ShopDetail } from "../organisms/ShopDetail";
import {
  ShopDetailContext,
  ShopDetailContextValue,
} from "../organisms/ShopDetail/ShopDetailContext";

const loadingSelector = isConnectedApiSelectorFactory(API_KEY.GET_SHOP_DATA);

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const ShopDetailTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const shopData = useSelector(currentShopDataSelector);
  const loading = useSelector(loadingSelector);

  const contextValue = useMemo<ShopDetailContextValue>(
    () => ({
      shopData,
    }),
    [shopData],
  );

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(push("/home"));
  }, [dispatch]);

  useEffect(() => {
    if (shopData.id !== shopId) {
      batch(() => {
        dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
        dispatch(userAccessedToPageThatNeedsCategoryTagList());
        dispatch(userAccessedToPageThatNeedsShopStaffList(shopId));
      });
    } else {
      dispatch(userAccessedToPageThatNeedsCategoryTagList());
      dispatch(userAccessedToPageThatNeedsShopStaffList(shopId));
    }
  }, [dispatch, shopId, shopData]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="一覧に戻る" handleClick={handleBack} />
        <PageTitle>店舗詳細</PageTitle>
        {shopData.id !== shopId || loading ? (
          <LoadingContainer />
        ) : (
          <ShopDetailContext.Provider value={contextValue}>
            <ShopDetail />
          </ShopDetailContext.Provider>
        )}
      </Page>
    </Auth>
  );
});

export default withRouter(ShopDetailTemplate);
