import { cancel, fork, join, put, select } from "redux-saga/effects";
import { ApiKey } from "../../../apis";
import { MaintenanceInfo } from "../../../records/MaintenanceInfo";
// import { IUpdateBuildPayload } from "../../../records/UpdateBuildPayload";
import { ReduxModel } from "../../../reducer";
import { APICache } from "../../../utils/APICache";
// import { Utility } from "../../../utils/Utility";
import { userAccessedMaintenancePage } from "../../user/actions";
import {
  setMaintenanceInfo,
  // setUpdateBuildPayload,
  systemFinishedConnectApi,
  // systemOpenedModal,
  systemStartedConnectApi,
} from "../actions";
// import { AppModel } from "../model";
import { UserModel } from "../../user/model";
import { commonApiFailedSaga } from "./commonApiFailedSaga";
import { commonApiSuccessSaga } from "./commonApiSuccessSaga";

// const STATUS_CODE_SHOULD_UPDATE_BUILD = 202;
const STATUS_CODE_MAINTENANCE = 401;

/**
 * apiの形式の種別
 * FILE => ローカルのjsonファイルをGETで取得する
 * RPC => ベースとなるendPointにリクエストを投げる
 * METHOD => ベースとなるendPoint/method名にリクエストを投げる
 */
enum ApiType {
  FILE = "file",
  RPC = "rpc",
  METHOD = "method",
}

/**
 * fetchメソッドに渡すRequestオブジェクトを生成する
 */
export function createRequest(
  method: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
  common: CommonParams,
  isDebug: boolean = false,
) {
  const apiTypeEnv = process.env.REACT_APP_API_TYPE;
  const apiType = typeof apiTypeEnv !== "undefined" ? apiTypeEnv : ApiType.FILE;
  console.log(apiType);

  const basePathEnv = process.env.REACT_APP_API_BASE_PATH;
  const debugBasePathEnv = process.env.REACT_APP_API_BASE_PATH_DEBUG;
  console.log(basePathEnv);

  const basePath =
    isDebug && typeof debugBasePathEnv !== "undefined"
      ? debugBasePathEnv
      : typeof basePathEnv !== "undefined"
      ? basePathEnv
      : "";

  console.log(params);

  switch (apiType) {
    case ApiType.RPC:
      return new Request(`${basePath}?method=${method}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          method,
          params,
          common_params: common,
          id: 0,
        }),
      });
    case ApiType.METHOD:
      return new Request(`${basePath}/${method}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          params,
          common_params: common,
          id: 0,
        }),
      });
    default:
      return new Request(`${process.env.PUBLIC_URL}/api/${method}.json`);
  }
}

type CommonParams = {
  token: string | null;
};

export interface APIOptions {
  fallback?: boolean;
  ignoreCache?: boolean;
}

const DEFAULT_API_OPTIONS: APIOptions = {
  fallback: false,
  ignoreCache: false,
};

export function createCommonParamsFromStore(state: ReduxModel): CommonParams {
  return {
    token: UserModel.getLoginToken(state.user),
  };
}

/**
 * @param method
 * @param params
 * @param fallback エラーハンドリングを呼び出し元で行いたい場合はtrueを指定
 */
export function* commonApiSaga(
  method: ApiKey,
  params: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  options: APIOptions = DEFAULT_API_OPTIONS,
) {
  try {
    if (!options.ignoreCache && APICache.shouldUseCache(method, params)) {
      return APICache.getCache(method, params);
    }

    // const selector = (state: ReduxModel) => ({
    //   isDebugUser: state.user.isDebugUser(),
    // });
    // const { isDebugUser }: ReturnType<typeof selector> = yield select<typeof selector>(selector);

    const common: ReturnType<typeof createCommonParamsFromStore> = yield select(
      createCommonParamsFromStore,
    );

    const request = createRequest(method, params, common);
    yield put(systemStartedConnectApi(method));
    const response: Response = yield fetch(request);
    yield put(systemFinishedConnectApi(method));

    // アプリの更新が必要な場合
    // if (response.status === STATUS_CODE_SHOULD_UPDATE_BUILD) {
    //   const {
    //     result: shouldUpdateBuildPayload,
    //   }: { result: IUpdateBuildPayload } = yield response.json();
    //   yield put(setUpdateBuildPayload(shouldUpdateBuildPayload));
    //   yield put(systemOpenedModal("SHOULD_UPDATE_BUILD", {}));
    //   yield cancel();
    // }

    if (response.status >= 400) {
      throw response;
    } else {
      const task = yield fork(() => commonApiSuccessSaga(response, method, params));
      yield join(task);
      if (task.isCancelled()) {
        yield cancel();
      } else {
        return task.result();
      }
    }
  } catch (exception) {
    yield put(systemFinishedConnectApi(method));
    if (!(exception instanceof Response)) {
      return { error: exception };
    }
    // メンテナンス時のリダイレクト指定
    if (exception.status === STATUS_CODE_MAINTENANCE) {
      const maintenanceData: any = yield exception.json(); // eslint-disable-line @typescript-eslint/no-explicit-any
      if ("result" in maintenanceData) {
        yield put(setMaintenanceInfo(maintenanceData.result as MaintenanceInfo));
      }
      yield put(userAccessedMaintenancePage());
      yield cancel();
    }

    if (options.fallback) {
      return { error: exception };
    }
    const task = yield fork(() => commonApiFailedSaga(exception as Response));
    yield join(task);
    if (task.isCancelled()) {
      yield cancel();
    } else {
      return task.result();
    }
  }
}
