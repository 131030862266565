import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback, useState } from "react";
import { connect, batch } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  ShopItemRegisterForm,
  initialShopItemRegisterForm,
  shopItemRegisterFormValidationSchema,
} from "../../forms/shopItemRegister";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userSubmittedShopItemRegister,
  systemInitDraftShopItemImage,
  userSubmittedDraftShopItemImage,
  userAccessedToPageThatNeedsShopItemList,
  userAccessedToPageThatNeedsOptionSetList,
} from "../../modules/shop/actions";
import { ShopModel } from "../../modules/shop/model";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Paragraph } from "../atoms/Paragraph";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";
import { ShopItemFormFields } from "../organisms/ShopItemFormFields";

const REG_EXP_STR = "\n※こちらは調理が必要な商品となっております";
const REG_EXP = new RegExp(`${REG_EXP_STR}$`);

const mapStateToProps = (state: ReduxModel, ownProps: RouteComponentProps<{ shopId: string }>) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  return {
    shopId,
    shopItemCategoryList: state.shop.shopItemCategoryList,
    optionSetList: state.shop.shopItemOptionSetList,
    existsShopItemOptionSet: ShopModel.existsShopItemOptionSet(state.shop),
    draftShopItemImage: state.shop.draftShopItemImage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopItemRegisterForm) {
    dispatch(userSubmittedShopItemRegister(formValue));
  },
  handleBack(id: number) {
    dispatch(push(`/shopItem/${id}`));
  },
  userAccessedShopItemRegisterPage(id: number) {
    batch(() => {
      dispatch(systemInitDraftShopItemImage(""));
      dispatch(userAccessedToPageThatNeedsShopItemList(id));
      dispatch(userAccessedToPageThatNeedsOptionSetList(id));
    });
  },
  updateDraftShopItemImage(files: FileList | null) {
    dispatch(userSubmittedDraftShopItemImage(files));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopItemRegisterForm> &
  PropsBase & {
    children?: never;
  };

const ShopItemRegisterTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    shopItemCategoryList,
    optionSetList,
    existsShopItemOptionSet,
    draftShopItemImage,
    handleBack,
    userAccessedShopItemRegisterPage,
    updateDraftShopItemImage,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    const [requireCooking, setRequireCooking] = useState<boolean>(false);

    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    const handleFileChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        updateDraftShopItemImage(files);
      },
      [updateDraftShopItemImage],
    );

    const handleRequireCookingCheckChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequireCooking(event.target.checked);
      },
      [],
    );

    const handleSubmitWrap = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (requireCooking) {
          if (!REG_EXP.test(values.description)) {
            setFieldValue("description", values.description + REG_EXP_STR);
          }
        }
        handleSubmit();
      },
      [values, handleSubmit, setFieldValue, requireCooking],
    );

    useEffect(() => {
      userAccessedShopItemRegisterPage(shopId);
    }, [userAccessedShopItemRegisterPage, shopId]);

    useEffect(() => {
      setFieldValue("image_path", draftShopItemImage);
    }, [draftShopItemImage, setFieldValue]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="商品一覧に戻る" handleClick={handleClickBack} />
          <PageTitle>商品の登録</PageTitle>

          <Paragraph>
            ここではデリバリー･テイクアウトで販売する商品の編集を行います。商品の追加･変更は、弊社による審査を行うため、即時反映にはなりませんのでご留意ください。
          </Paragraph>

          <Paragraph>※初回公開時には店舗審査と商品審査を同時に行わせていただきます。</Paragraph>

          <FormContainer onSubmit={handleSubmitWrap}>
            <input type="hidden" name="shop_id" value={values.shop_id} />

            <FormInner>
              <ShopItemFormFields
                shopId={shopId}
                shopItemCategoryList={shopItemCategoryList}
                existsShopItemOptionSet={existsShopItemOptionSet}
                optionSetList={optionSetList}
                handleFileChange={handleFileChange}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleRequireCookingCheckChange={handleRequireCookingCheckChange}
              />
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                更新
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopItemRegisterForm>({
  enableReinitialize: true,
  mapPropsToValues: (): ShopItemRegisterForm => initialShopItemRegisterForm,
  validationSchema: shopItemRegisterFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopItemRegisterTemplate)),
);
