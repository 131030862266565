import { DailySalesReports } from "./DailySalesReports";

export type DailySales = Readonly<{
  target_month: string;
  shop_id: number;
  sales: DailySalesReports;
}>;

const initialState: DailySales = {
  target_month: "",
  shop_id: -1,
  sales: DailySalesReports.create(),
};

const create = (args: Partial<DailySales> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const DailySales = Object.freeze({
  initialState,
  create,
});
