import { FormikProps, withFormik, FieldArray } from "formik";
import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import { ShopBusiness, BusinessType, displayableBusinessType } from "../../records/ShopBusiness";
import {
  BusinessTimeEditForm,
  getInitialValues,
  businessTimeEditFormValidationSchema,
  getUpdatedBusinessTimesFieldsArray,
} from "../../forms/businessTimeEdit";
import { DateUtils } from "../../utils/DateUtils";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userAccessedToPageThatNeedsCurrentShopData,
  userSubmittedBusinessTime,
} from "../../modules/shop/actions";
import { colorsConst, fontWeightsConst } from "../../styles/const";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { BusinessTypeRadioField } from "../molecules/BusinessTypeRadioField";
import { Auth } from "../organisms/Auth";
import { BusinessTimeField } from "../organisms/BusinessTimeField";

const WeekTimeContainer = styled.div`
  padding: 0.5em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  & + & {
    margin-top: 1em;
  }
`;

const DayOfWeek = styled.div`
  margin-bottom: 0.5em;
  font-weight: ${fontWeightsConst.BOLD};
`;

const BusinessTypeContainer = styled.div``;

const BusinessTypeHead = styled.div`
  margin-bottom: 0.25em;
  color: ${colorsConst.TEXT.SECONDARY};
`;

const BusinessTypeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.5em;
`;

const BusinessTypeFieldContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  & + & {
    margin-left: 1em;
  }
`;

const BusinessTimeContainer = styled.div``;

const mapStateToProps = (state: ReduxModel, ownProps: RouteComponentProps<{ shopId: string }>) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  return {
    shopId,
    shopData: state.shop.currentShopData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: BusinessTimeEditForm) {
    dispatch(userSubmittedBusinessTime(formValue));
  },
  handleBack(id: number) {
    dispatch(push(`/shopBusiness/${id}`));
  },
  updateCurrentShopData(shopId: number) {
    dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<BusinessTimeEditForm> &
  PropsBase & {
    children?: never;
  };

const BusinessTimeEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    shopData,
    handleBack,
    updateCurrentShopData,
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    isValid,
  }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopData.id);
    }, [handleBack, shopData]);

    const handleBusinessTypeChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, weekIndex: number) => {
        const targetValue = parseInt(e.currentTarget.value, 10);
        if (Number.isNaN(targetValue)) {
          return;
        }
        setFieldValue(e.currentTarget.name, targetValue);
        const updatedArray = getUpdatedBusinessTimesFieldsArray(
          targetValue as BusinessType,
          values.week_time[weekIndex].business_times,
        );
        setFieldValue(`week_time[${weekIndex}].business_times`, updatedArray);
      },
      [setFieldValue, values],
    );

    useEffect(() => {
      if (shopData.id !== shopId) {
        updateCurrentShopData(shopId);
      }
    }, [shopId, shopData, updateCurrentShopData]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="営業時間設定に戻る" handleClick={handleClickBack} />
          <PageTitle>曜日別営業時間</PageTitle>

          <FormContainer onSubmit={handleSubmit}>
            <input type="hidden" id="shop_id" name="shop_id" value={values.shop_id} />

            <FormInner>
              <FieldArray
                name="week_time"
                render={() => (
                  <>
                    {values.week_time.map((weekTime) => (
                      <WeekTimeContainer key={weekTime.week}>
                        <DayOfWeek>{DateUtils.getDayOfWeekString(weekTime.week)}</DayOfWeek>
                        <BusinessTypeContainer>
                          <BusinessTypeHead>営業タイプを設定してください</BusinessTypeHead>
                          <BusinessTypeRow>
                            {displayableBusinessType.map((businessType) => (
                              <BusinessTypeFieldContainer key={businessType}>
                                <BusinessTypeRadioField
                                  index={weekTime.week}
                                  handleChange={handleBusinessTypeChange}
                                  name={`week_time[${weekTime.week}].business_type`}
                                  id={`week_time[${weekTime.week}]_business_type_${businessType}`}
                                  value={businessType}
                                  checked={
                                    values.week_time[weekTime.week].business_type === businessType
                                  }
                                >
                                  {ShopBusiness.getPresentationBusinessTypeString(businessType)}
                                </BusinessTypeRadioField>
                              </BusinessTypeFieldContainer>
                            ))}
                          </BusinessTypeRow>
                        </BusinessTypeContainer>
                        <BusinessTimeContainer>
                          {weekTime.business_times.map((businessTime, index) => (
                            <BusinessTimeField
                              key={`week_time[${weekTime.week}]_business_time[${index}]`}
                              index={index}
                              week={weekTime.week}
                              businessTime={businessTime}
                              handleChange={handleChange}
                            />
                          ))}
                        </BusinessTimeContainer>
                      </WeekTimeContainer>
                    ))}
                  </>
                )}
              />
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                保存
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, BusinessTimeEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopData }): BusinessTimeEditForm => getInitialValues(shopData),
  mapPropsToErrors: ({ shopData }) =>
    getInitialErrors(businessTimeEditFormValidationSchema, getInitialValues(shopData)),
  validationSchema: businessTimeEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(BusinessTimeEditTemplate)),
);
