import * as Yup from "yup";
import { ShopItem } from "../records/ShopItem";
import { LICENSE_FLAG, LicenseFlagType } from "./shopItemRegister";

export type ShopItemEditForm = {
  shop_item_category_id: number;
  item_name: string;
  is_alcohol: LicenseFlagType;
  is_meat: LicenseFlagType;
  description: string;
  price: number;
  delivery_price: number;
  options: number[];
  cooking_time: number;
  image_path: string;
  shop_id: number;
  shop_item_id: number;
  pending_shop_item_id: number;
};

export const initialShopItemEditForm: ShopItemEditForm = {
  shop_item_category_id: 0,
  item_name: "",
  is_alcohol: LICENSE_FLAG.FALSE,
  is_meat: LICENSE_FLAG.FALSE,
  description: "",
  price: 0,
  delivery_price: 0,
  options: [],
  cooking_time: 0,
  image_path: "",
  shop_id: 0,
  shop_item_id: 0,
  pending_shop_item_id: 0,
};

export const getInitialValues = (shop_id: number, shopItem?: ShopItem): ShopItemEditForm => {
  return typeof shopItem !== "undefined"
    ? {
        shop_item_category_id: shopItem.shop_item_category_id,
        item_name: shopItem.item_name,
        is_alcohol: shopItem.is_alcohol ? LICENSE_FLAG.TRUE : LICENSE_FLAG.FALSE,
        is_meat: shopItem.is_meat ? LICENSE_FLAG.TRUE : LICENSE_FLAG.FALSE,
        description: shopItem.description,
        price: shopItem.unit_price,
        delivery_price: shopItem.delivery_price,
        options: shopItem.option_set_list.map((i) => i.option_set_id),
        cooking_time: shopItem.cooking_time,
        image_path: shopItem.image_path,
        shop_id,
        shop_item_id: shopItem.shop_item_id,
        pending_shop_item_id: shopItem.pending_shop_item_id,
      }
    : initialShopItemEditForm;
};

export const shopItemEditFormValidationSchema = Yup.object({
  shop_item_category_id: Yup.number().notOneOf([0], "商品カテゴリを選択してください"),
  item_name: Yup.string().required("入力してください"),
  is_alcohol: Yup.mixed<LicenseFlagType>().oneOf(Object.values(LICENSE_FLAG)),
  is_meat: Yup.mixed<LicenseFlagType>().oneOf(Object.values(LICENSE_FLAG)),
  description: Yup.string().required("入力してください"),
  price: Yup.number().min(0, "不正な値です").required("入力してください"),
  delivery_price: Yup.number().min(0, "不正な値です").required("入力してください"),
  options: Yup.array().of(Yup.number()),
  cooking_time: Yup.number().min(0, "不正な値です").required("入力してください"),
  image_path: Yup.string().required("画像を選択してください"),
  shop_id: Yup.number(),
  shop_item_id: Yup.number(),
  pending_shop_item_id: Yup.number(),
})
  .defined()
  .strict(true)
  .noUnknown();
