import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import {
  ShopOptionEditForm,
  initialShopOptionEditForm,
  getInitialValues,
  shopOptionEditFormValidationSchema,
} from "../../forms/shopOptionEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userSubmittedShopOptionEdit,
  userAccessedToPageThatNeedsOptionSetList,
} from "../../modules/shop/actions";
import { ShopModel } from "../../modules/shop/model";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { Auth } from "../organisms/Auth";

const OptionPriceFieldRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin: 1em 0;
`;

const OptionPriceField = styled(FormField)`
  flex-grow: 1;
  flex-shrink: 1;
  & + & {
    margin-left: 1em;
  }
`;

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ shopId: string; optionSetId: string; optionId: string }>,
) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  const optionSetId = parseInt(ownProps.match.params.optionSetId, 10);
  const optionId = parseInt(ownProps.match.params.optionId, 10);
  return {
    shopId,
    optionSetId,
    optionId,
    optionSet: ShopModel.getOptionSetById(state.shop, optionSetId),
    option: ShopModel.getOptionById(state.shop, optionId),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopOptionEditForm) {
    dispatch(userSubmittedShopOptionEdit(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopOptionSet/${shopId}`));
  },
  userAccessedOptionEditPage(shopId: number) {
    dispatch(userAccessedToPageThatNeedsOptionSetList(shopId));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopOptionEditForm> &
  PropsBase & {
    children?: never;
  };

const ShopOptionEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    optionSet,
    option,
    handleBack,
    userAccessedOptionEditPage,
    values,
    handleSubmit,
    handleChange,
    isValid,
  }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    const canDisplay = useMemo(() => {
      return typeof optionSet !== "undefined" && typeof option !== "undefined";
    }, [optionSet, option]);

    useEffect(() => {
      userAccessedOptionEditPage(shopId);
    }, [shopId, userAccessedOptionEditPage]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer
            linkText="オプションセット一覧に戻る"
            handleClick={handleClickBack}
          />
          <PageTitle>オプションの編集</PageTitle>
          {canDisplay ? (
            <FormContainer onSubmit={handleSubmit}>
              <input type="hidden" name="shop_id" value={values.shop_id} />
              <input type="hidden" name="shop_option_set_id" value={values.shop_option_set_id} />
              <input type="hidden" name="shop_option_id" value={values.shop_option_id} />

              <FormInner>
                <FormFieldGroup>
                  <FormField readOnly>
                    <FormLabel htmlFor="shop_option_set_name">オプションセット名</FormLabel>
                    <TextField
                      type="text"
                      name="shop_option_set_name"
                      id="shop_option_set_name"
                      value={values.shop_option_set_name}
                      readOnly
                    />
                  </FormField>
                </FormFieldGroup>

                <FormFieldGroup>
                  <FormField>
                    <FormLabel>オプションセットテスト のオプション一覧</FormLabel>
                    {
                      /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                      optionSet!.option_list.map((item) => (
                        <div key={item.shop_option_id}>{item.option_name}</div>
                      ))
                    }
                  </FormField>
                </FormFieldGroup>

                <FormFieldGroup>
                  <FormField>
                    <FormLabel htmlFor="shop_option_name" required>
                      オプション名
                    </FormLabel>
                    <TextField
                      type="text"
                      name="shop_option_name"
                      id="shop_option_name"
                      onChange={handleChange}
                      value={values.shop_option_name}
                    />
                  </FormField>
                  <OptionPriceFieldRow>
                    <OptionPriceField>
                      <FormLabel htmlFor="price" required>
                        テイクアウト価格
                      </FormLabel>
                      <TextField
                        type="number"
                        name="price"
                        id="price"
                        onChange={handleChange}
                        value={values.price}
                      />
                    </OptionPriceField>
                    <OptionPriceField>
                      <FormLabel htmlFor="delivery_price" required>
                        デリバリー価格
                      </FormLabel>
                      <TextField
                        type="number"
                        name="delivery_price"
                        id="delivery_price"
                        onChange={handleChange}
                        value={values.delivery_price}
                      />
                    </OptionPriceField>
                  </OptionPriceFieldRow>
                  <FormField>
                    <FormLabel htmlFor="option_sort" required>
                      オプション表示順
                    </FormLabel>
                    <TextField
                      type="number"
                      name="option_sort"
                      id="option_sort"
                      onChange={handleChange}
                      value={values.option_sort}
                    />
                  </FormField>
                  <FormNotice>
                    大きいほどアプリ上で上位に表示されます。
                    <br />
                    0〜1000の間で入力してください。
                  </FormNotice>
                </FormFieldGroup>
              </FormInner>

              <ButtonContainer>
                <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                  更新
                </Button>
              </ButtonContainer>
            </FormContainer>
          ) : (
            <div>指定したオプションは存在しません。</div>
          )}
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopOptionEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopId, optionSet, option }): ShopOptionEditForm =>
    getInitialValues(shopId, optionSet, option),
  mapPropsToErrors: () =>
    getInitialErrors(shopOptionEditFormValidationSchema, initialShopOptionEditForm),
  validationSchema: shopOptionEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopOptionEditTemplate)),
);
