import { LoginForm } from "src/forms/login";
import { UserData } from "src/records/UserData";

export enum ActionTypes {
  U_SUBMITTED_LOGIN = "user/U_SUBMITTED_LOGIN",
  U_LOGGED_IN = "user/U_LOGGED_IN",
  U_ACCESSED_MAINTENANCE_PAGE = "user/U_ACCESSED_MAINTENANCE_PAGE",

  S_GET_USER_DATA_BY_TOKEN = "user/S_GET_USER_DATA_BY_TOKEN",

  R_UPDATE_LOGIN_TOKEN = "user/R_UPDATE_LOGIN_TOKEN",
  R_UPDATE_USER = "user/R_UPDATE_USER",
}

export const userSubmittedLogin = (payload: LoginForm) =>
  ({
    type: ActionTypes.U_SUBMITTED_LOGIN,
    payload,
  } as const);
export type UserSubmittedLoginAction = ReturnType<typeof userSubmittedLogin>;

export const userLoggedIn = () =>
  ({
    type: ActionTypes.U_LOGGED_IN,
  } as const);
export type UserLoggedInAction = ReturnType<typeof userLoggedIn>;

export const userAccessedMaintenancePage = () =>
  ({
    type: ActionTypes.U_ACCESSED_MAINTENANCE_PAGE,
  } as const);
export type UserAccessedMaintenancePageAction = ReturnType<typeof userAccessedMaintenancePage>;

export const systemGetUserDataByToken = () =>
  ({
    type: ActionTypes.S_GET_USER_DATA_BY_TOKEN,
  } as const);
export type SystemGetUserDataByTokenAction = ReturnType<typeof systemGetUserDataByToken>;

export const updateLoginToken = (token: string | null) =>
  ({
    type: ActionTypes.R_UPDATE_LOGIN_TOKEN,
    payload: {
      token,
    },
  } as const);
export type UpdateLoginTokenAction = ReturnType<typeof updateLoginToken>;

export const updateUser = (data: UserData) =>
  ({
    type: ActionTypes.R_UPDATE_USER,
    payload: {
      data,
    },
  } as const);
export type UpdateUserAction = ReturnType<typeof updateUser>;

export type UserActions =
  | UserSubmittedLoginAction
  | UserLoggedInAction
  | UserAccessedMaintenancePageAction
  | SystemGetUserDataByTokenAction
  | UpdateLoginTokenAction
  | UpdateUserAction;
