import { useField } from "formik";
import React, { useCallback } from "react";
import styled from "styled-components";
import { colorsConst } from "../../../styles/const";

export type RadioFiledProps = Readonly<{
  label: string;
  value: string | number;
}>;

const Container = styled.div``;
const InputContainer = styled.div``;
const ErrorContainer = styled.div`
  color: ${colorsConst.TEXT.DANGER};
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  values: RadioFiledProps[];
  isNum?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RadioField: React.FC<Props> = React.memo(({ name, values, isNum, handleChange }) => {
  const [input, meta, helper] = useField(name);

  const handleChangeWrap = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof handleChange !== "undefined") {
        handleChange(event);
      }
      if (!isNum) {
        helper.setValue(event.currentTarget.value);
      } else {
        const targetValue = parseInt(event.currentTarget.value, 10);
        if (Number.isNaN(targetValue)) {
          return;
        }
        helper.setValue(targetValue);
      }
    },
    [isNum, helper, handleChange],
  );

  return (
    <Container>
      <InputContainer>
        {values.map((value) => (
          <label htmlFor={`${name}_${value.value}`} key={value.value}>
            <input
              type="radio"
              name={name}
              id={`${name}_${value.value}`}
              value={value.value}
              onChange={handleChangeWrap}
              checked={input.value === value.value}
            />
            {value.label}
          </label>
        ))}
      </InputContainer>
      {meta.touched && meta.error ? <ErrorContainer>{meta.error}</ErrorContainer> : undefined}
    </Container>
  );
});
