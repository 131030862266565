import React, { useMemo, useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { API_KEY } from "../../apis";
import { isConnectedApiSelectorFactory } from "../../modules/app/selectors";
import { currentShopDataSelector } from "../../modules/shop/selectors";
import { userAccessedToPageThatNeedsCurrentShopData } from "../../modules/shop/actions";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { LoadingContainer } from "../molecules/LoadingContainer";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";
import { ShopBusiness } from "../organisms/ShopBusiness";
import {
  ShopBusinessContext,
  ShopBusinessContextValue,
} from "../organisms/ShopBusiness/ShopBusinessContext";

const loadingSelector = isConnectedApiSelectorFactory(API_KEY.GET_SHOP_DATA);

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const ShopBusinessTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const shopData = useSelector(currentShopDataSelector);
  const loading = useSelector(loadingSelector);

  const contextValue = useMemo<ShopBusinessContextValue>(
    () => ({
      shopId,
      shopBusiness: shopData.shop_business,
    }),
    [shopId, shopData],
  );

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(push(`/shopDetail/${shopId}`));
  }, [dispatch, shopId]);

  useEffect(() => {
    if (shopData.id !== shopId) {
      dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
    }
  }, [dispatch, shopId, shopData]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="店舗詳細に戻る" handleClick={handleBack} />
        <PageTitle>営業時間</PageTitle>
        {typeof shopData === "undefined" || loading ? (
          <LoadingContainer />
        ) : (
          <ShopBusinessContext.Provider value={contextValue}>
            <ShopBusiness />
          </ShopBusinessContext.Provider>
        )}
      </Page>
    </Auth>
  );
});

export default withRouter(ShopBusinessTemplate);
