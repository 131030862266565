import * as Yup from "yup";
import { ShopData } from "../records/ShopData";

export type CookingTimeEditForm = {
  shop_id: number;
  reserve_count: number;
  cooking_time: number;
  crow_order_count: number;
};

export const initialCookingTimeEditForm: CookingTimeEditForm = {
  shop_id: 0,
  reserve_count: 0,
  cooking_time: 0,
  crow_order_count: 0,
};

export const getInitialValues = (shopData?: ShopData): CookingTimeEditForm => {
  return typeof shopData !== "undefined"
    ? {
        ...initialCookingTimeEditForm,
        shop_id: shopData.id,
        cooking_time: shopData.shop_business.cooking_time,
        crow_order_count: shopData.shop_business.crow_order_count,
      }
    : initialCookingTimeEditForm;
};

export const cookingTimeEditFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  reserve_count: Yup.number(),
  cooking_time: Yup.number().required("入力してください"),
  crow_order_count: Yup.number().required("入力してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
