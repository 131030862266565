import { ShopBusiness } from "./ShopBusiness";
import { ShopLicense, ShopLicenseType, SHOP_LICENSE_TYPE } from "./ShopLicense";

export const RELEASE_STATE_TYPE = {
  NOT_RELEASE: 0, // 新規作成
  RELEASE_STANDBY: 1, // 一時停止
  RELEASE: 2, // 配信中
  LIMITED_RELEASE: 9, // 限定配信
  CANCEL: 99, // 解約
} as const;

type ReleaseStateType = ValueOf<typeof RELEASE_STATE_TYPE>;

export const DRAFT_STATE_TYPE = {
  WAITING_DRAFT: 1, // 入稿待ち
  WHILE_DRAFTING: 2, // 入稿中
  WHILE_JUDGING: 3, // 審査中
  WAITING_RELEASE: 4, // 公開待ち
  COMPLETE_RELEASE_CONDITION: 5, // 公開済
} as const;

export type DraftStateType = ValueOf<typeof DRAFT_STATE_TYPE>;

export const displayableDraftStateType: ReadonlyArray<DraftStateType> = [
  DRAFT_STATE_TYPE.WAITING_DRAFT,
  DRAFT_STATE_TYPE.WHILE_DRAFTING,
  DRAFT_STATE_TYPE.WHILE_JUDGING,
  DRAFT_STATE_TYPE.WAITING_RELEASE,
  DRAFT_STATE_TYPE.COMPLETE_RELEASE_CONDITION,
] as const;

export const DRAFTING_STATE_TYPE = {
  BUSINESS_TIME: "business_time",
  SHOP_ITEM: "shop_item",
  PREFERRED_OPEN_DATE: "preferred_open_date",
} as const;

export type DraftingStateType = ValueOf<typeof DRAFTING_STATE_TYPE>;

export const displayableDraftingStateType: ReadonlyArray<DraftingStateType> = [
  DRAFTING_STATE_TYPE.BUSINESS_TIME, // 営業時間
  DRAFTING_STATE_TYPE.SHOP_ITEM, // 商品
  DRAFTING_STATE_TYPE.PREFERRED_OPEN_DATE, // 公開日時
] as const;

export type DraftingState = Readonly<
  {
    [K in DraftingStateType]: boolean;
  }
>;

export type PriceRangeType = 1 | 2 | 3 | 4;

export const displayablePriceRangeType: ReadonlyArray<PriceRangeType> = [1, 2, 3, 4] as const;

export const BANK_ACCOUNT_TYPE = {
  SAVINGS_ACCOUNT: 1, // 普通,
  CHECKING_ACCOUNT: 2, // 当座,
  DEPOSIT_ACCOUNT: 4, // 貯蓄預金,
  OTHER_ACCOUNT: 9, // その他,
} as const;

export type BankAccountType = ValueOf<typeof BANK_ACCOUNT_TYPE>;

export const displayableBankAccountType: ReadonlyArray<BankAccountType> = [
  BANK_ACCOUNT_TYPE.SAVINGS_ACCOUNT,
  BANK_ACCOUNT_TYPE.CHECKING_ACCOUNT,
  BANK_ACCOUNT_TYPE.DEPOSIT_ACCOUNT,
  BANK_ACCOUNT_TYPE.OTHER_ACCOUNT,
] as const;

export const NOTIFICATION_FLAG = {
  TRUE: 1,
  FALSE: 0,
} as const;

export type NotificationFlagType = ValueOf<typeof NOTIFICATION_FLAG>;

export type ShopData = Readonly<{
  id: number;
  shop_name: string;
  shop_name_ruby: string;
  mail: string;
  tel: string;
  address: string;
  category_tag: number[];
  latest_access: string;
  is_today_access: boolean;
  release_state: ReleaseStateType;
  draft_state: DraftStateType;
  draft_state_text: string;
  drafting_state: DraftingState;
  reject_reason: string;
  is_available_shop_judge: boolean;
  latitude: string;
  longitude: string;
  header_image_path: string;
  person_in_charge_name: string;
  person_in_charge_name_ruby: string;
  tel_notification_flag: NotificationFlagType;
  is_set_price_range: boolean; // 価格帯が設定されているか
  price_range: PriceRangeType; // 設定した価格帯
  is_set_recommend_item: boolean; // おすすめ商品が設定されているか
  lowest_price: number; // おすすめ商品の最安値
  recommend_item_price_range: PriceRangeType; // おすすめ商品の価格帯
  is_set_delivery_price_range: boolean; // おすすめ商品にデリバリー価格が設定されているか
  preferred_open_date: string;
  shop_license: {
    [K in ShopLicenseType]: ShopLicense;
  };
  shop_business: ShopBusiness;
  bank_code: string;
  bank_name: string;
  branch_code: string;
  branch_name: string;
  bank_type: BankAccountType;
  bank_account_name: string;
  bank_number: string;
}>;

const initialState: ShopData = {
  id: 0,
  shop_name: "",
  shop_name_ruby: "",
  mail: "",
  tel: "",
  address: "",
  category_tag: [],
  latest_access: "1970-01-01 09:00:00",
  is_today_access: false,
  release_state: RELEASE_STATE_TYPE.NOT_RELEASE,
  draft_state: DRAFT_STATE_TYPE.WAITING_DRAFT,
  draft_state_text: "",
  drafting_state: {
    [DRAFTING_STATE_TYPE.BUSINESS_TIME]: false,
    [DRAFTING_STATE_TYPE.SHOP_ITEM]: false,
    [DRAFTING_STATE_TYPE.PREFERRED_OPEN_DATE]: false,
  },
  reject_reason: "",
  is_available_shop_judge: false,
  latitude: "0",
  longitude: "0",
  header_image_path: "",
  person_in_charge_name: "",
  person_in_charge_name_ruby: "",
  tel_notification_flag: NOTIFICATION_FLAG.FALSE,
  is_set_price_range: false,
  price_range: 1,
  is_set_recommend_item: false,
  lowest_price: 0,
  recommend_item_price_range: 1,
  is_set_delivery_price_range: false,
  preferred_open_date: "1970-01-01",
  shop_license: {
    [SHOP_LICENSE_TYPE.FOOD]: ShopLicense.create(),
    [SHOP_LICENSE_TYPE.MEAT]: ShopLicense.create(),
    [SHOP_LICENSE_TYPE.ALCOHOL]: ShopLicense.create(),
  },
  shop_business: ShopBusiness.create(),
  bank_code: "",
  bank_name: "",
  branch_code: "",
  branch_name: "",
  bank_type: BANK_ACCOUNT_TYPE.SAVINGS_ACCOUNT,
  bank_account_name: "",
  bank_number: "",
};

const create = (args: Partial<ShopData> = {}) => {
  return {
    ...initialState,
    shop_business: args.shop_business
      ? ShopBusiness.create(args.shop_business)
      : ShopBusiness.create(),
    shop_license: {
      [SHOP_LICENSE_TYPE.FOOD]:
        args.shop_license && args.shop_license[SHOP_LICENSE_TYPE.FOOD]
          ? ShopLicense.create(args.shop_license[SHOP_LICENSE_TYPE.FOOD])
          : ShopLicense.create(),
      [SHOP_LICENSE_TYPE.MEAT]:
        args.shop_license && args.shop_license[SHOP_LICENSE_TYPE.MEAT]
          ? ShopLicense.create(args.shop_license[SHOP_LICENSE_TYPE.MEAT])
          : ShopLicense.create(),
      [SHOP_LICENSE_TYPE.ALCOHOL]:
        args.shop_license && args.shop_license[SHOP_LICENSE_TYPE.ALCOHOL]
          ? ShopLicense.create(args.shop_license[SHOP_LICENSE_TYPE.ALCOHOL])
          : ShopLicense.create(),
    },
    ...args,
  };
};

const getPresentationReleaseStateText = (releaseState: ReleaseStateType) => {
  switch (releaseState) {
    case RELEASE_STATE_TYPE.NOT_RELEASE:
      return "新規作成";
    case RELEASE_STATE_TYPE.RELEASE_STANDBY:
      return "一時停止";
    case RELEASE_STATE_TYPE.RELEASE:
      return "配信中";
    case RELEASE_STATE_TYPE.LIMITED_RELEASE:
      return "限定配信";
    case RELEASE_STATE_TYPE.CANCEL:
      return "解約";
    // skip default case
  }
};

const getPresentationDraftStateText = (draftState: DraftStateType) => {
  switch (draftState) {
    case DRAFT_STATE_TYPE.WAITING_DRAFT:
      return "入稿待ち";
    case DRAFT_STATE_TYPE.WHILE_DRAFTING:
      return "入稿中";
    case DRAFT_STATE_TYPE.WHILE_JUDGING:
      return "審査中";
    case DRAFT_STATE_TYPE.WAITING_RELEASE:
      return "公開待ち";
    case DRAFT_STATE_TYPE.COMPLETE_RELEASE_CONDITION:
      return "公開済";
    // skip default case
  }
};

const getDraftingStateLink = (draftingState: DraftingStateType): { link: string; text: string } => {
  switch (draftingState) {
    case "business_time":
      return { link: "shopBusiness", text: "営業時間" };
    case "shop_item":
      return { link: "shopItem", text: "商品登録" };
    case "preferred_open_date":
      return { link: "shopReservationOpenEdit", text: "公開希望日" };
    // skip default case
  }
};

const getPresentationPriceRangeText = (priceRange: PriceRangeType) => {
  const yen = Array(priceRange)
    .fill("")
    .map(() => String.fromCodePoint(165))
    .join("");
  switch (priceRange) {
    case 1:
      return `${yen}（899円以下）`;
    case 2:
      return `${yen}（1,299円以下）`;
    case 3:
      return `${yen}（1,699円以下）`;
    case 4:
      return `${yen}（1,700円以上）`;
    // skip default case
  }
};

const getPresentationBankAccountTypeText = (accountType: BankAccountType): string => {
  switch (accountType) {
    case BANK_ACCOUNT_TYPE.SAVINGS_ACCOUNT:
      return "普通預金";
    case BANK_ACCOUNT_TYPE.CHECKING_ACCOUNT:
      return "当座預金";
    case BANK_ACCOUNT_TYPE.DEPOSIT_ACCOUNT:
      return "貯蓄預金";
    case BANK_ACCOUNT_TYPE.OTHER_ACCOUNT:
      return "その他";
    // skip default case
  }
};

const isNotLoggedIn = (latestAccess: string) => {
  return latestAccess === "1970-01-01 09:00:00";
};

const isNotCompleteReleaseCondition = (draftState: DraftStateType) => {
  return draftState !== DRAFT_STATE_TYPE.COMPLETE_RELEASE_CONDITION;
};

const existsDraftStateText = (draftDtateText: string) => {
  return draftDtateText !== "";
};

const existsRejectReason = (reject_reason: string) => {
  return reject_reason !== "";
};

const isDisplayableRejectReason = (shopData: ShopData) => {
  return (
    (shopData.draft_state === DRAFT_STATE_TYPE.WAITING_DRAFT ||
      shopData.draft_state === DRAFT_STATE_TYPE.WHILE_DRAFTING) &&
    existsRejectReason(shopData.reject_reason)
  );
};

const existsImage = (imagePath: string) => {
  return imagePath !== "" && imagePath !== "/image/no_image.png";
};

const existsCategoryTag = (shopData: ShopData) => {
  return shopData.category_tag.length > 0;
};

const isReleased = (releaseState: ReleaseStateType) => {
  return releaseState === RELEASE_STATE_TYPE.RELEASE;
};

const existsPreferredOpenDate = (preferredOpenDate: string) => {
  return preferredOpenDate !== "1970-01-01";
};

const existsLicenseExpirationDate = (expirationDate: string) => {
  return expirationDate !== "";
};

const existsBankCode = (bankCode: string) => {
  return bankCode !== "";
};

const existsBankName = (bankName: string) => {
  return bankName !== "";
};

const existsBranchCode = (branchCode: string) => {
  return branchCode !== "";
};

const existsBranchName = (branchName: string) => {
  return branchName !== "";
};

const existsBankType = (bankType: number) => {
  return bankType !== 0;
};

const existsBankAccountName = (bankAccountName: string) => {
  return bankAccountName !== "";
};

const existsBankNumber = (bankNumber: string) => {
  return bankNumber !== "";
};

const getLicenseByType = (shopData: ShopData, licenseType: ShopLicenseType) => {
  return shopData.shop_license[licenseType];
};

export const ShopData = Object.freeze({
  initialState,
  create,
  isNotLoggedIn,
  getPresentationReleaseStateText,
  getPresentationDraftStateText,
  getDraftingStateLink,
  getPresentationPriceRangeText,
  getPresentationBankAccountTypeText,
  isNotCompleteReleaseCondition,
  existsDraftStateText,
  isDisplayableRejectReason,
  existsImage,
  existsCategoryTag,
  isReleased,
  existsPreferredOpenDate,
  existsLicenseExpirationDate,
  existsBankCode,
  existsBankName,
  existsBranchCode,
  existsBranchName,
  existsBankType,
  existsBankAccountName,
  existsBankNumber,
  getLicenseByType,
});
