import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { connect, batch } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import {
  ShopItemEditForm,
  initialShopItemEditForm,
  getInitialValues,
  shopItemEditFormValidationSchema,
} from "../../forms/shopItemEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userSubmittedShopItemEdit,
  systemInitDraftShopItemImage,
  userSubmittedDraftShopItemImage,
  userAccessedToPageThatNeedsCurrentShopData,
  userAccessedToPageThatNeedsShopItemList,
  userAccessedToPageThatNeedsOptionSetList,
  userTouchedChangeItemReleaseStateButton,
} from "../../modules/shop/actions";
import { ShopModel } from "../../modules/shop/model";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Paragraph } from "../atoms/Paragraph";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { ShopItemStatus } from "../molecules/ShopItemStatus";
import { LoadingContainer } from "../molecules/LoadingContainer";
import { Auth } from "../organisms/Auth";
import { ShopItemFormFields } from "../organisms/ShopItemFormFields";

const REG_EXP_STR = "\n※こちらは調理が必要な商品となっております";
const REG_EXP = new RegExp(`${REG_EXP_STR}$`);

const PaegTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 1em;
`;
const PageTitleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const StatusButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const mapStateToProps = (
  state: ReduxModel,
  ownProps: RouteComponentProps<{ shopId: string; itemId: string }>,
) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  const itemId = parseInt(ownProps.match.params.itemId, 10);
  return {
    shopId,
    itemId,
    shopItemCategoryList: state.shop.shopItemCategoryList,
    currentShopItem: ShopModel.getShopItemById(state.shop, itemId),
    optionSetList: state.shop.shopItemOptionSetList,
    existsShopItemOptionSet: ShopModel.existsShopItemOptionSet(state.shop),
    draftShopItemImage: state.shop.draftShopItemImage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopItemEditForm) {
    dispatch(userSubmittedShopItemEdit(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopItem/${shopId}`));
  },
  userAccessedShopItemEditPage(shopId: number) {
    batch(() => {
      dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
      dispatch(userAccessedToPageThatNeedsShopItemList(shopId));
      dispatch(userAccessedToPageThatNeedsOptionSetList(shopId));
    });
  },
  initDraftShopItemImage(imagePath: string) {
    dispatch(systemInitDraftShopItemImage(imagePath));
  },
  updateDraftShopItemImage(files: FileList | null) {
    dispatch(userSubmittedDraftShopItemImage(files));
  },
  changeItemReleaseState(shopId: number, categoryId: number, itemId: number) {
    dispatch(userTouchedChangeItemReleaseStateButton(shopId, categoryId, itemId));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopItemEditForm> &
  PropsBase & {
    children?: never;
  };

const ShopItemEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    itemId,
    shopItemCategoryList,
    optionSetList,
    existsShopItemOptionSet,
    currentShopItem,
    draftShopItemImage,
    handleBack,
    userAccessedShopItemEditPage,
    initDraftShopItemImage,
    updateDraftShopItemImage,
    changeItemReleaseState,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    const [requireCooking, setRequireCooking] = useState<boolean>(false);

    const handleClickBack = useCallback(() => {
      handleBack(shopId);
    }, [handleBack, shopId]);

    const handleFileChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        updateDraftShopItemImage(files);
      },
      [updateDraftShopItemImage],
    );

    const handleChangeItemReleaseState = useCallback(() => {
      if (typeof currentShopItem !== "undefined") {
        changeItemReleaseState(shopId, currentShopItem.shop_item_category_id, itemId);
      }
    }, [shopId, itemId, currentShopItem, changeItemReleaseState]);

    const handleRequireCookingCheckChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequireCooking(event.target.checked);
      },
      [],
    );

    const handleSubmitWrap = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (requireCooking) {
          if (!REG_EXP.test(values.description)) {
            setFieldValue("description", values.description + REG_EXP_STR);
          }
        }
        handleSubmit();
      },
      [values, handleSubmit, setFieldValue, requireCooking],
    );

    useEffect(() => {
      userAccessedShopItemEditPage(shopId);
    }, [userAccessedShopItemEditPage, shopId]);

    useEffect(() => {
      if (typeof currentShopItem === "undefined") {
        return;
      }
      initDraftShopItemImage(currentShopItem.image_path);
    }, [currentShopItem, initDraftShopItemImage]);

    useEffect(() => {
      setFieldValue("image_path", draftShopItemImage);
    }, [draftShopItemImage, setFieldValue]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="商品一覧に戻る" handleClick={handleClickBack} />
          <PaegTitleRow>
            <PageTitleContainer>
              <PageTitle>商品の編集</PageTitle>
            </PageTitleContainer>
            {typeof currentShopItem !== "undefined" && (
              <StatusButtonContainer>
                <ShopItemStatus
                  shopItem={currentShopItem}
                  handleClick={handleChangeItemReleaseState}
                />
              </StatusButtonContainer>
            )}
          </PaegTitleRow>

          <Paragraph>
            ここではデリバリー･テイクアウトで販売する商品の編集を行います。商品の追加･変更は、弊社による審査を行うため、即時反映にはなりませんのでご留意ください。
          </Paragraph>

          <Paragraph>※初回公開時には店舗審査と商品審査を同時に行わせていただきます。</Paragraph>

          {typeof currentShopItem !== "undefined" ? (
            <FormContainer onSubmit={handleSubmitWrap}>
              <input type="hidden" name="shop_id" value={values.shop_id} />
              <input type="hidden" name="shop_item_id" value={values.shop_item_id} />
              <input
                type="hidden"
                name="pending_shop_item_id"
                value={values.pending_shop_item_id}
              />

              <FormInner>
                <ShopItemFormFields
                  shopId={shopId}
                  shopItemCategoryList={shopItemCategoryList}
                  existsShopItemOptionSet={existsShopItemOptionSet}
                  optionSetList={optionSetList}
                  handleFileChange={handleFileChange}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleRequireCookingCheckChange={handleRequireCookingCheckChange}
                />
              </FormInner>

              <ButtonContainer>
                <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                  更新
                </Button>
              </ButtonContainer>
            </FormContainer>
          ) : (
            <LoadingContainer />
          )}
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopItemEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopId, currentShopItem }): ShopItemEditForm =>
    getInitialValues(shopId, currentShopItem),
  mapPropsToErrors: () =>
    getInitialErrors(shopItemEditFormValidationSchema, initialShopItemEditForm),
  validationSchema: shopItemEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopItemEditTemplate)),
);
