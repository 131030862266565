import { DirectionType, DIRECTION_TYPE } from "../modules/shop/model";

export class Utility {
  public static validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i;
  public static validTelRegex = /^\d*$/;
  public static validPasswordRegex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,}$/;
  public static formatter = new Intl.NumberFormat("ja-JP");

  public static getImageDomain(): string {
    const env = process.env.REACT_APP_IMAGE_BASE_PATH;
    return typeof env !== "undefined" ? env : "";
  }

  public static getFullImagePath(imagePath: string): string {
    return `${Utility.getImageDomain()}${
      imagePath.charAt(0) === "/" ? imagePath : `/${imagePath}`
    }`;
  }

  public static getGoogleMapsApiKey() {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    return typeof googleMapsApiKey !== "undefined" ? googleMapsApiKey : "";
  }

  public static getPresentationPrice(value: number) {
    return `${Utility.formatter.format(value)}円`;
  }

  public static sortIds(id: number, ids: number[], direction: DirectionType) {
    const index = ids.indexOf(id);
    const sortedIds = ids.slice();
    if (index >= 0) {
      switch (direction) {
        case DIRECTION_TYPE.UP:
          sortedIds.splice(index - 1, 2, ids[index], ids[index - 1]);
          break;
        case DIRECTION_TYPE.DOWN:
          sortedIds.splice(index, 2, ids[index + 1], ids[index]);
          break;
        // skip default case
      }
    }
    return sortedIds;
  }

  public static zeroPadding(value: number, length: number) {
    return `${Array(length).join("0")}${value}`.slice(-length);
  }

  public static validPassword(password: string): boolean {
    const regExp = this.validPasswordRegex;
    return regExp.test(password);
  }
}
