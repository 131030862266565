import { put, fork, join, cancel, cancelled } from "redux-saga/effects";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";

function createFetch(file: File) {
  const formData = new FormData();
  formData.append("image", file);
  const uploadPath = process.env.REACT_APP_FILE_UPLOAD_PATH as string;
  return fetch(uploadPath, {
    mode: "cors",
    method: "POST",
    body: formData,
  });
}

function* fileUploadSuccessSaga(response: Response) {
  try {
    const data = yield response.json();
    return { result: data.path };
  } catch (error) {
    // エラー時の処理
    console.error(error);
    throw error;
  } finally {
    if (yield cancelled()) {
      // ここにキャンセルされた時の処理を記述
      console.log("fileUploadSuccessSaga is cancelled");
    }
  }
}

function* fileUploadFailedSaga(response: Response) {
  try {
    const data = yield response.json();
    return { error: data.error };
  } catch (error) {
    // エラー時の処理
    console.error(error);
    throw error;
  } finally {
    if (yield cancelled()) {
      // ここにキャンセルされた時の処理を記述
      console.log("fileUploadFailedSaga is cancelled");
    }
  }
}

export function* fileUploadSaga(file: File) {
  try {
    const response: Response = yield createFetch(file);
    if (response.status >= 400) {
      const task = yield fork(fileUploadFailedSaga, response);
      yield join(task);
      if (task.isCancelled()) {
        yield cancel();
      } else {
        return task.result();
      }
    } else {
      const task = yield fork(fileUploadSuccessSaga, response);
      yield join(task);
      if (task.isCancelled()) {
        yield cancel();
      } else {
        return task.result();
      }
    }
  } catch (error) {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: ["画像のアップロードが失敗しました。"],
      }),
    );
  }
}
