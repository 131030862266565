import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { OptionSet } from "../../records/OptionSet";
import { DIRECTION_TYPE, DirectionType } from "../../modules/shop/model";
import { colorsConst } from "../../styles/const";
import { LinkButton } from "../atoms/LinkButton";
import { SortButton } from "./SortButton";

const Container = styled.div`
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  & + & {
    margin-top: 1em;
  }
`;
const OptionSetContaier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1em 0.5em;
`;
const OptionSetName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const EditButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;
const SortButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const OptionContaier = styled.div`
  padding: 0.5em;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;
const OptionListItem = styled.div`
  margin-bottom: 0.5em;
`;

type Props = {
  shopId: number;
  optionSet: OptionSet;
  index: number;
  length: number;
  handleSort: (optionSetId: number, direction: DirectionType) => void;
};

export const OptionSetListItem: React.FC<Props> = React.memo(
  ({ shopId, optionSet, index, length, handleSort }) => {
    const handleClickSortButton = useCallback(
      (direction: DirectionType) => {
        handleSort(optionSet.option_set_id, direction);
      },
      [optionSet, handleSort],
    );

    return (
      <Container key={optionSet.option_set_id}>
        <OptionSetContaier>
          <OptionSetName>{optionSet.option_set_name}</OptionSetName>
          <EditButtonContainer>
            <LinkButton
              to={`/shopOptionSetEdit/${shopId}/${optionSet.option_set_id}`}
              appearance="primary"
              size="sm"
            >
              編集
            </LinkButton>
          </EditButtonContainer>
          <SortButtonContainer>
            <SortButton
              index={index}
              length={length}
              direction={DIRECTION_TYPE.UP}
              handleClick={handleClickSortButton}
            />
            <SortButton
              index={index}
              length={length}
              direction={DIRECTION_TYPE.DOWN}
              handleClick={handleClickSortButton}
            />
          </SortButtonContainer>
        </OptionSetContaier>
        <OptionContaier>
          {OptionSet.hasOption(optionSet.option_list) && (
            <>
              {optionSet.option_list.map((option) => (
                <OptionListItem key={`${optionSet.option_set_id}-${option.shop_option_id}`}>
                  <Link
                    to={`/shopOptionEdit/${shopId}/${optionSet.option_set_id}/${option.shop_option_id}`}
                  >
                    {option.option_name}
                  </Link>
                </OptionListItem>
              ))}
            </>
          )}
          <LinkButton
            appearance="primary"
            to={`/shopOptionRegister/${shopId}/${optionSet.option_set_id}`}
            size="sm"
            display="inline"
          >
            オプション登録
          </LinkButton>
        </OptionContaier>
      </Container>
    );
  },
);
