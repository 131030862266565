import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colorsConst } from "../../styles/const";

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  border-top: 1px solid ${colorsConst.BORDER.THIN};
`;

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    display: block;
    padding: 10px;
  }
`;

type Props = {
  handleClose: () => void;
};

export const Navigation: React.FC<Props> = React.memo(({ handleClose }) => {
  return (
    <Container>
      <Inner>
        <List>
          <ListItem>
            <Link to="/home" onClick={handleClose}>
              店舗一覧
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/dailySalesReport" onClick={handleClose}>
              日別レポート
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/monthlySalesReport" onClick={handleClose}>
              店舗別月別レポート
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/orderSalesReport" onClick={handleClose}>
              注文別レポート
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/shopApplicationLink" onClick={handleClose}>
              店舗アプリのインストール
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/storeManagerManual" onClick={handleClose}>
              ストアマネージャーの使い方
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/shopApplicationManual" onClick={handleClose}>
              店舗アプリの使い方
            </Link>
          </ListItem>
        </List>
      </Inner>
    </Container>
  );
});
