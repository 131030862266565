import { Option } from "./Option";

export const OPTION_SELECT_TYPE = {
  REQUIRED: 1, // 必須
  OPTIONAL: 2, // 任意
} as const;

export type OptionSelectType = ValueOf<typeof OPTION_SELECT_TYPE>;

export const displayableOptionSelectType: ReadonlyArray<OptionSelectType> = [
  OPTION_SELECT_TYPE.REQUIRED,
  OPTION_SELECT_TYPE.OPTIONAL,
] as const;

export type OptionSet = Readonly<{
  option_set_id: number;
  option_set_name: string;
  note: string;
  description: string;
  description_en: string;
  option_select_type: OptionSelectType;
  select_count_min: number;
  select_count_max: number;
  sort: number;
  option_list: Option[];
}>;

const initialState: OptionSet = {
  option_set_id: 0,
  option_set_name: "",
  note: "",
  description: "",
  description_en: "",
  option_select_type: OPTION_SELECT_TYPE.OPTIONAL,
  select_count_min: 0,
  select_count_max: 0,
  sort: 0,
  option_list: [],
};

const create = (args: Partial<OptionSet> = {}) => {
  return {
    ...initialState,
    option_list: args.option_list ? args.option_list.map((i) => Option.create(i)) : [],
    ...args,
  };
};

const hasOption = (option_list: Option[]) => {
  return option_list.length > 0;
};

const getPresentationOptionSelectTypeText = (selectType: OptionSelectType) => {
  switch (selectType) {
    case OPTION_SELECT_TYPE.REQUIRED:
      return "必須";
    case OPTION_SELECT_TYPE.OPTIONAL:
      return "任意";
    // skip default case
  }
};

export const OptionSet = Object.freeze({
  initialState,
  create,
  hasOption,
  getPresentationOptionSelectTypeText,
});
