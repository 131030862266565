import { createGlobalStyle } from "styled-components";
import { colorsConst, fontWeightsConst, fontFamilyConst } from "../const";
import { fontSize } from "../mixins";

export const BaseStyle = createGlobalStyle`
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap'); */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html, body, #root {
  height: 100%;
}
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#root {
  display: flex;
  flex-direction: column;
  align-items: streach;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
body > #root {
  height: auto;
}
body {
  ${fontSize.DEFAULT};
  font-weight: ${fontWeightsConst.REGULAR};
  line-height: 1.5;
  font-family: ${fontFamilyConst};
  color: ${colorsConst.TEXT.PRIMARY};
  backface-visibility: hidden;
  font-feature-settings: 'palt';
  -webkit-font-smoothing: antialiased;
}
a {
  color: ${colorsConst.TEXT.LINK};
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
button {
  font-family: ${fontFamilyConst};
}
`;
