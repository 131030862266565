import React from "react";
import styled from "styled-components";
import { Button } from "../atoms/Button";

const Container = styled.div`
  margin-bottom: 1em;
`;

type Props = {
  linkText: string;
  handleClick: () => void;
};

export const BackButtonContainer: React.FC<Props> = React.memo(({ linkText, handleClick }) => {
  return (
    <Container>
      <Button invert appearance="primary" display="responsive" onClick={handleClick}>
        {linkText}
      </Button>
    </Container>
  );
});
