import React from "react";
import styled from "styled-components";
import { Loading } from "../atoms/Loading";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {};

export const LoadingContainer: React.FC<Props> = React.memo(() => {
  return (
    <Container>
      <Loading size="4x" />
    </Container>
  );
});
