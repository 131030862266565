import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import { ShopData, displayableBankAccountType } from "../../records/ShopData";
import {
  BankAccountEditForm,
  initialBankAccountEditForm,
  getInitialValues,
  bankAccountEditFormValidationSchema,
} from "../../forms/bankAccountEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  userAccessedToPageThatNeedsCurrentShopData,
  userChangedDraftBankCode,
  userSubmittedBankAccount,
} from "../../modules/shop/actions";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { SelectField } from "../atoms/FormParts/SelectField";
import { Auth } from "../organisms/Auth";

const REG_EXP_STR = "\\d{4}";
const REG_EXP = new RegExp(REG_EXP_STR);

const mapStateToProps = (state: ReduxModel, ownProps: RouteComponentProps<{ shopId: string }>) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  return {
    shopId,
    shopData: state.shop.currentShopData,
    draftBankName: state.shop.draftBankName,
    draftBranchList: state.shop.draftBranchList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: BankAccountEditForm) {
    dispatch(userSubmittedBankAccount(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopDetail/${shopId}`));
  },
  updateCurrentShopData(shopId: number) {
    dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
  },
  updateDraftBank(bankCode: string) {
    dispatch(userChangedDraftBankCode(bankCode));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<BankAccountEditForm> &
  PropsBase & {
    children?: never;
  };

const BankAccountEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    shopData,
    draftBankName,
    draftBranchList,
    handleBack,
    updateCurrentShopData,
    updateDraftBank,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopData.id);
    }, [handleBack, shopData]);

    useEffect(() => {
      if (shopData.id !== shopId) {
        updateCurrentShopData(shopId);
      }
    }, [shopId, shopData, updateCurrentShopData]);

    useEffect(() => {
      if (REG_EXP.test(values.bank_code)) {
        updateDraftBank(values.bank_code);
      }
    }, [values.bank_code, updateDraftBank]);

    useEffect(() => {
      if (draftBankName !== "") {
        setFieldValue("bank_name", draftBankName);
      }
    }, [setFieldValue, draftBankName]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="店舗詳細に戻る" handleClick={handleClickBack} />
          <PageTitle>口座情報の編集</PageTitle>

          <FormContainer onSubmit={handleSubmit}>
            <input type="hidden" id="shop_id" name="shop_id" value={values.shop_id} />
            <FormInner>
              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="bank_code" required>
                    金融機関コード
                  </FormLabel>
                  <TextField
                    type="text"
                    name="bank_code"
                    id="bank_code"
                    maxLength={4}
                    pattern={REG_EXP_STR}
                    onChange={handleChange}
                    value={values.bank_code}
                  />
                </FormField>
                <FormNotice>半角数字4文字</FormNotice>
              </FormFieldGroup>

              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="bank_name">金融機関名</FormLabel>
                  <TextField
                    type="text"
                    name="bank_name"
                    id="bank_name"
                    value={draftBankName}
                    readOnly
                  />
                </FormField>
                <FormNotice>自動で入力されます</FormNotice>
              </FormFieldGroup>

              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="branch_code" required>
                    支店コード
                  </FormLabel>
                  <SelectField name="branch_code" id="branch_code" value={values.branch_code}>
                    {draftBranchList.map((branch, index) => (
                      <option value={`${branch.branch_code}`} key={index}>
                        {`${branch.branch_code}：${branch.branch_name}`}
                      </option>
                    ))}
                  </SelectField>
                </FormField>
                <FormNotice>金融機関コードを入力すると選択できます</FormNotice>
              </FormFieldGroup>

              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="account_type" required>
                    口座種別
                  </FormLabel>
                  <SelectField
                    name="account_type"
                    id="account_type"
                    value={values.account_type}
                    isNum
                  >
                    {displayableBankAccountType.map((type) => (
                      <option value={type} key={type}>
                        {ShopData.getPresentationBankAccountTypeText(type)}
                      </option>
                    ))}
                  </SelectField>
                </FormField>
              </FormFieldGroup>

              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="account_number" required>
                    口座番号
                  </FormLabel>
                  <TextField
                    type="text"
                    name="account_number"
                    id="account_number"
                    maxLength={7}
                    pattern="\d{7}"
                    onChange={handleChange}
                    value={values.account_number}
                  />
                </FormField>
                <FormNotice>半角数字7文字</FormNotice>
              </FormFieldGroup>

              <FormFieldGroup>
                <FormField>
                  <FormLabel htmlFor="account_name" required>
                    口座名義
                  </FormLabel>
                  <TextField
                    type="text"
                    name="account_name"
                    id="account_name"
                    onChange={handleChange}
                    value={values.account_name}
                  />
                </FormField>
              </FormFieldGroup>
            </FormInner>
            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                更新
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, BankAccountEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopData }): BankAccountEditForm => getInitialValues(shopData),
  mapPropsToErrors: () =>
    getInitialErrors(bankAccountEditFormValidationSchema, initialBankAccountEditForm),
  validationSchema: bankAccountEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(BankAccountEditTemplate)),
);
