import { fork, join, cancel, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { UpdateShopDataRequest, UpdateShopDataResponse } from "../../../apis/updateShopData";
import { UserSubmittedShopRegisterAction } from "../actions";

export function* userSubmittedShopRegisterSaga(action: UserSubmittedShopRegisterAction) {
  try {
    const params: UpdateShopDataRequest = action.payload;
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.UPDATE_SHOP_DATA, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: UpdateShopDataResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(replace(`/shopDetail/${result.shop_data.id}`));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "店舗情報の更新に失敗しました。",
      }),
    );
  }
}
