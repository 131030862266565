import React from "react";
import styled from "styled-components";
import { DateUtils } from "../../utils/DateUtils";
import { colorsConst, fontWeightsConst } from "../../styles/const";
import { fontSize } from "../../styles/mixins";
import { ShopBusiness, BusinessWeekTime } from "../../records/ShopBusiness";
import { Text } from "../atoms/Text";

const Container = styled.div`
  padding: 0.5em 0.75em;
  & + & {
    border-top: 1px solid ${colorsConst.BORDER.DEFAULT};
  }
`;

const HeadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const DayOfWeek = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  ${fontSize.LARGE};
  font-weight: ${fontWeightsConst.MEDIUM};
`;
const BusinessType = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
const BusinessTypeKey = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  color: ${colorsConst.TEXT.SECONDARY};
`;
const BusinessTypeVal = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 1em;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0.5em;
`;
const BusinessTimeKey = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  color: ${colorsConst.TEXT.SECONDARY};
`;
const BusinessTimeValList = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
`;
const BusinessTimeVal = styled.div``;

type Props = {
  weekTime: BusinessWeekTime;
};

export const ShopBusinessWeekRow: React.FC<Props> = React.memo(({ weekTime }) => {
  return (
    <Container>
      <HeadRow>
        <DayOfWeek>{DateUtils.getDayOfWeekString(weekTime.week)}</DayOfWeek>
        <BusinessType>
          <BusinessTypeKey>営業タイプ</BusinessTypeKey>
          <BusinessTypeVal>
            {ShopBusiness.isHoliday(weekTime.business_type) ? (
              <Text textColor="DANGER">
                {ShopBusiness.getPresentationBusinessTypeString(weekTime.business_type)}
              </Text>
            ) : (
              ShopBusiness.getPresentationBusinessTypeString(weekTime.business_type)
            )}
          </BusinessTypeVal>
        </BusinessType>
      </HeadRow>
      <BottomRow>
        <BusinessTimeKey>営業時間</BusinessTimeKey>
        <BusinessTimeValList>
          {weekTime.business_times.map((businessTime, index) => (
            <BusinessTimeVal key={index}>
              {String.fromCodePoint(9312 + index)}
              {`${businessTime.start.hour}:${businessTime.start.minute}〜${businessTime.end.hour}:${businessTime.end.minute}`}
            </BusinessTimeVal>
          ))}
        </BusinessTimeValList>
      </BottomRow>
    </Container>
  );
});
