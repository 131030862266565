import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { SortShopItemRequest, SortShopItemResponse } from "../../../apis/sortShopItem";
import { updateShopItemCategoryList, UserTouchedSortShopItemButtonAction } from "../actions";
import { Utility } from "../../../utils/Utility";

export function* userTouchedSortShopItemButtonSaga(action: UserTouchedSortShopItemButtonAction) {
  try {
    const { shopId, categoryId, itemId, direction, itemIds } = action.payload;
    const params: SortShopItemRequest = {
      shop_id: shopId,
      shop_item_category_id: categoryId,
      shop_item_id_list: Utility.sortIds(itemId, itemIds, direction),
    };
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.SORT_SHOP_ITEM, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: SortShopItemResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopItemCategoryList(result.item_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "商品の並び替えが失敗しました。",
      }),
    );
  }
}
