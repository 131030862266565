import React from "react";
import { ShopRejectReason } from "./ShopRejectReason";
import { ShopReservationOpen } from "./ShopReservationOpen";
import { ShopInfo } from "./ShopInfo";
import { ShopLocation } from "./ShopLocation";
import { ShopLicense } from "./ShopLicense";
import { ShopBusinessTime } from "./ShopBusinessTime";
import { ShopBankAccount } from "./ShopBankAccount";
import { ShopStaff } from "./ShopStaff";

type Props = {};

export const ShopDetail: React.FC<Props> = React.memo(() => {
  return (
    <>
      <ShopRejectReason />
      <ShopReservationOpen />
      <ShopInfo />
      <ShopLocation />
      <ShopLicense />
      <ShopBusinessTime />
      <ShopBankAccount />
      <ShopStaff />
    </>
  );
});
