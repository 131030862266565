import { FormikProps, withFormik } from "formik";
import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInitialErrors } from "../../forms/validations/getInitialErrors";
import {
  ShopEditForm,
  initialShopEditForm,
  getInitialValues,
  shopEditFormValidationSchema,
} from "../../forms/shopEdit";
import { ReduxAction, ReduxModel } from "../../reducer";
import {
  systemInitDraftShopImage,
  userSubmittedShopEdit,
  userSubmittedDraftShopImage,
  userAccessedToPageThatNeedsCurrentShopData,
  userAccessedToPageThatNeedsCategoryTagList,
} from "../../modules/shop/actions";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Button } from "../atoms/Button";
import { FormContainer, FormInner, ButtonContainer } from "../atoms/FormParts/FormContainer";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { Input } from "../atoms/FormParts/TextInput";
import { Auth } from "../organisms/Auth";
import { ShopFormFields } from "../organisms/ShopFormFields";

const mapStateToProps = (state: ReduxModel, ownProps: RouteComponentProps<{ shopId: string }>) => {
  const shopId = parseInt(ownProps.match.params.shopId, 10);
  return {
    shopId,
    shopData: state.shop.currentShopData,
    categoryTagList: state.shop.categoryTagList,
    draftShopImage: state.shop.draftShopImage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => ({
  dispatchSubmit(formValue: ShopEditForm) {
    dispatch(userSubmittedShopEdit(formValue));
  },
  handleBack(shopId: number) {
    dispatch(push(`/shopDetail/${shopId}`));
  },
  updateCurrentShopData(shopId: number) {
    dispatch(userAccessedToPageThatNeedsCurrentShopData(shopId));
  },
  updateCategoryTagList() {
    dispatch(userAccessedToPageThatNeedsCategoryTagList());
  },
  initDraftShopImage(imagePath: string) {
    dispatch(systemInitDraftShopImage(imagePath));
  },
  updateDraftShopImage(files: FileList | null) {
    dispatch(userSubmittedDraftShopImage(files));
  },
});

type PropsBase = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type Props = FormikProps<ShopEditForm> &
  PropsBase & {
    children?: never;
  };

const ShopEditTemplate: React.FC<Props> = React.memo(
  ({
    shopId,
    shopData,
    categoryTagList,
    draftShopImage,
    updateCurrentShopData,
    updateCategoryTagList,
    initDraftShopImage,
    updateDraftShopImage,
    handleBack,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
  }) => {
    const handleClickBack = useCallback(() => {
      handleBack(shopData.id);
    }, [handleBack, shopData]);

    const handleFileChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { files } = event.target;
        updateDraftShopImage(files);
      },
      [updateDraftShopImage],
    );

    useEffect(() => {
      updateCategoryTagList();
      if (shopData.id !== shopId) {
        updateCurrentShopData(shopId);
      }
    }, [shopId, shopData, updateCurrentShopData, updateCategoryTagList]);

    useEffect(() => {
      initDraftShopImage(shopData.header_image_path);
    }, [shopData, initDraftShopImage]);

    useEffect(() => {
      setFieldValue("header_image_path", draftShopImage);
    }, [draftShopImage, setFieldValue]);

    return (
      <Auth>
        <Page header>
          <BackButtonContainer linkText="店舗詳細に戻る" handleClick={handleClickBack} />
          <PageTitle>商品の登録・編集</PageTitle>

          <FormContainer onSubmit={handleSubmit}>
            <FormInner>
              <FormFieldGroup>
                <FormField readOnly>
                  <FormLabel>ID</FormLabel>
                  <Input type="text" name="id" value={values.id} readOnly />
                </FormField>
              </FormFieldGroup>

              <ShopFormFields
                values={values}
                isEdit
                handleChange={handleChange}
                shopData={shopData}
                categoryTagList={categoryTagList}
                handleFileChange={handleFileChange}
              />
            </FormInner>

            <ButtonContainer>
              <Button type="submit" appearance="primary" display="responsive" disabled={!isValid}>
                更新
              </Button>
            </ButtonContainer>
          </FormContainer>
        </Page>
      </Auth>
    );
  },
);

const connectForm = withFormik<PropsBase, ShopEditForm>({
  enableReinitialize: true,
  mapPropsToValues: ({ shopData }): ShopEditForm => getInitialValues(shopData),
  mapPropsToErrors: () => getInitialErrors(shopEditFormValidationSchema, initialShopEditForm),
  validationSchema: shopEditFormValidationSchema,
  handleSubmit: (values, { props }) => {
    props.dispatchSubmit(values);
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(connectForm(ShopEditTemplate)),
);
