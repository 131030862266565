import { fork, join, cancel, put, call } from "redux-saga/effects";
import { FileUtils } from "../../../utils/FileUtils";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { updateDraftShopItemImage, UserSubmittedDraftShopItemImageAction } from "../actions";
import { fileUploadSaga } from "./fileUploadSaga";

export function* userSubmittedDraftShopItemImageSaga(
  action: UserSubmittedDraftShopItemImageAction,
) {
  const { files } = action.payload;
  try {
    if (files === null) {
      updateDraftShopItemImage();
    } else {
      const base64 = yield call(FileUtils.fileToBase64, files[0]);
      const file = new File([FileUtils.base64ToBlob(base64)], `${new Date().getTime()}.jpg`, {
        type: "image/jpeg",
      });
      const task = yield fork(fileUploadSaga, file);
      if (task.isCancelled()) {
        yield cancel();
      }
      const { result, error } = yield join(task);
      if (result && !error) {
        yield put(updateDraftShopItemImage(result));
      } else {
        throw error;
      }
    }
  } catch (error) {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "画像のアップロードが失敗しました。",
      }),
    );
  }
}
