import * as Yup from "yup";
import {
  ShopLicense,
  ShopLicenseType,
  SHOP_LICENSE_TYPE,
  ShopLicenseExpireType,
  SHOP_LICENSE_EXPIRE_TYPE,
} from "../records/ShopLicense";

export type ShopLicenseEditForm = {
  shop_id: number;
  license_id: number;
  license_type: ShopLicenseType;
  expire: ShopLicenseExpireType;
  expire_date: string;
  image_path: string;
};

export const initialShopLicenseEditForm: ShopLicenseEditForm = {
  shop_id: 0,
  license_id: 0,
  license_type: SHOP_LICENSE_TYPE.FOOD,
  expire: SHOP_LICENSE_EXPIRE_TYPE.LIMITED,
  expire_date: "",
  image_path: "",
};

export const getInitialValues = (
  shopId: number,
  licenseType: ShopLicenseType,
  license?: ShopLicense,
): ShopLicenseEditForm => {
  return typeof license !== "undefined"
    ? {
        ...initialShopLicenseEditForm,
        shop_id: shopId,
        license_type: licenseType,
        license_id: license.license_id,
        expire: license.expire_type,
        expire_date: license.expiration_date,
      }
    : {
        ...initialShopLicenseEditForm,
        shop_id: shopId,
        license_type: licenseType,
      };
};

export const shopLicenseEditFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  license_id: Yup.number(),
  license_type: Yup.mixed<ShopLicenseType>().oneOf(Object.values(SHOP_LICENSE_TYPE)),
  expire: Yup.mixed<ShopLicenseExpireType>().oneOf(Object.values(SHOP_LICENSE_EXPIRE_TYPE)),
  expire_date: Yup.string(),
  image_path: Yup.string().required("画像を選択してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
