import React, { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  size?: number;
  children?: never;
};

const ratio = 46 / 164;

export const SvgLogo: React.FC<Props> = ({ size = 164, ...rest }) => {
  return (
    <svg width={size} height={size * ratio} viewBox="0 0 164 46" fill="none" {...rest}>
      <path
        d="M99.3,37.8c-3,0-6-1.1-8.3-3.4c-4.6-4.5-4.6-11.8,0-16.3c4.6-4.5,12-4.5,16.5,0l1.7,1.7L96.4,32.5c2.6,1.2,5.7,0.7,7.8-1.4 c0.7-0.6,1.2-1.4,1.5-2.2l4.4,1.8c-0.6,1.4-1.4,2.7-2.6,3.8C105.3,36.7,102.3,37.8,99.3,37.8z M99.3,19.5c-1.8,0-3.5,0.7-4.9,2 c-2.1,2.1-2.6,5.2-1.4,7.7l9.2-9.1C101.3,19.7,100.3,19.5,99.3,19.5z"
        fill="#000"
      />
      <path
        d="M75,14.8c-2.1,0-4,0.8-5.4,2.2c-1.4-1.4-3.3-2.2-5.5-2.2c-4.3,0-7.8,3.5-7.8,7.7v14.8h4.7V22.5c0-1.7,1.4-3,3.1-3 c1.7,0,3.1,1.4,3.1,3v14.8H72V22.5c0-1.7,1.4-3,3.1-3s3.1,1.4,3.1,3v14.8h4.7V22.5C82.9,18.3,79.4,14.8,75,14.8z"
        fill="#000"
      />
      <path
        d="M136.6,37.4h-4.7V25.7c0-3.4-2.8-6.2-6.3-6.2s-6.3,2.8-6.3,6.2v11.7h-4.7V25.7c0-6,4.9-10.9,11-10.9c6.1,0,11,4.9,11,10.9 V37.4z"
        fill="#000"
      />
      <path
        d="M152.4,37.9c-6.1,0-11-4.9-11-10.9V15.3h4.7V27c0,3.4,2.8,6.2,6.3,6.2s6.3-2.8,6.3-6.2V15.3h4.7V27 C163.4,33,158.4,37.9,152.4,37.9z"
        fill="#000"
      />
      <path
        fill="#ffffff"
        stroke="#dcdddd"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M46.8,35.6c0,5.5-4.6,10.1-10.2,10.1H11.1c-5.6,0-10.2-4.5-10.2-10.1V10.4c0-5.5,4.6-10.1,10.2-10.1h25.5 c5.6,0,10.2,4.5,10.2,10.1V35.6z"
      />
      <path
        fill="#ff3008"
        d="M30.6,10.8l-19.8,3.5l4.8,27l19.8-3.5c1.1-0.2,1.9-1.3,1.7-2.4L33,12.5C32.8,11.4,31.8,10.6,30.6,10.8z"
      />
      <path
        fill="#80b927"
        d="M25.4,10.1l-0.7-3.8c0,0,0,0,0,0v0c0-0.2-0.1-0.4-0.3-0.6c-0.4-0.6-1.3-0.7-1.8-0.2l-9,6.6L25.4,10.1z"
      />
      <path
        fill="#ffffff"
        d="M22.4,26.1c-0.5,0.1-1.1,0-1.5-0.3c-0.9-0.6-1.1-1.9-0.5-2.8c0.6-0.9,1.9-1.1,2.8-0.5l0.3,0.2l-1.8,2.6 c0.5,0.1,1,0,1.3-0.5c0.1-0.1,0.2-0.3,0.2-0.4l0.8,0.2c-0.1,0.3-0.2,0.5-0.3,0.7C23.4,25.7,22.9,26,22.4,26.1z M21.8,22.9 c-0.3,0.1-0.6,0.2-0.8,0.5c-0.3,0.4-0.3,1,0,1.4l1.3-1.8C22.2,22.9,22,22.9,21.8,22.9z"
      />
      <path
        fill="#ffffff"
        d="M17.5,22.9c-0.4,0.1-0.7,0.3-0.9,0.5c-0.3-0.2-0.6-0.3-1-0.2c-0.7,0.1-1.2,0.8-1.1,1.6l0.5,2.6l0.8-0.1 l-0.5-2.6c-0.1-0.3,0.1-0.6,0.4-0.6c0.3-0.1,0.6,0.1,0.6,0.4l0.5,2.6l0.8-0.1l-0.5-2.6c-0.1-0.3,0.1-0.6,0.4-0.6 c0.3-0.1,0.6,0.1,0.6,0.4l0.5,2.6l0.8-0.1L19.1,24C19,23.2,18.2,22.7,17.5,22.9z"
      />
      <path
        fill="#ffffff"
        d="M28.8,24.9L28,25l-0.4-2c-0.1-0.6-0.7-1-1.3-0.9c-0.6,0.1-1,0.7-0.9,1.3l0.4,2L25,25.6l-0.4-2 c-0.2-1,0.5-2,1.6-2.2c1-0.2,2,0.5,2.2,1.5L28.8,24.9z"
      />
      <path
        fill="#ffffff"
        d="M31.5,24.5c-1,0.2-2-0.5-2.2-1.5l-0.4-2l0.8-0.1l0.4,2c0.1,0.6,0.7,1,1.3,0.9c0.6-0.1,1-0.7,0.9-1.3l-0.4-2 l0.8-0.1l0.4,2C33.3,23.3,32.6,24.3,31.5,24.5z"
      />
      <path
        d="M133.8,9.6c-0.9-0.6-1.9-1.1-3.1-1.7c-1.1,1.6-2.8,2.7-5.2,3.1V9.4c1.6-0.4,2.8-1.1,3.6-2.2c-1-0.4-2-0.7-3.1-1V4.5 c1.2,0.3,2.4,0.7,3.9,1.2c0.4-1,0.6-2,0.7-3.2h1.8c-0.1,1.4-0.4,2.7-0.9,3.9c0.9,0.4,1.7,0.8,2.3,1.2L133.8,9.6L133.8,9.6z M136,10.6V9h8.7v1.7L136,10.6z M136.7,4.8V3.1h7.1v1.7H136.7z M156.1,7.6V6h7.2v1.7H156.1z M152.8,9.1l0.3-2 c0.1-0.7-0.1-1.3-0.5-1.8c-0.4-0.5-1.1-0.8-1.7-0.8h-2.7v1.5h2.7c0.2,0,0.4,0.1,0.6,0.2c0.1,0.2,0.2,0.4,0.2,0.6l-0.3,2.2h-4.3v1.5 h7.6V9.1L152.8,9.1L152.8,9.1z"
        fill="#000"
      />
    </svg>
  );
};
