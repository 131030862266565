import { ActionTypes, SalesActions } from "./actions";
import { SalesState, SalesModel } from "./model";

export default function reducer(state: SalesState = SalesModel.initialState, action: SalesActions) {
  switch (action.type) {
    case ActionTypes.R_UPDATE_DAILY_SALES:
      return SalesModel.updateDailySales(state, action.payload.dailySales);
    case ActionTypes.R_UPDATE_MONTHLY_SALES:
      return SalesModel.updateMonthlySales(state, action.payload.monthlySales);
    case ActionTypes.R_UPDATE_ORDER_SALES:
      return SalesModel.updateOrderSales(state, action.payload.orderSales);
    default:
      return state;
  }
}
