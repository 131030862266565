export const BUSINESS_TYPE = {
  ONCE_A_DAY: 11, // 1日1回営業
  TWICE_A_DAY: 12, // 1日2回営業
  HOLIDAY: 21, // 休業日
} as const;

export type BusinessType = ValueOf<typeof BUSINESS_TYPE>;

export const displayableBusinessType: ReadonlyArray<BusinessType> = [
  BUSINESS_TYPE.ONCE_A_DAY,
  BUSINESS_TYPE.TWICE_A_DAY,
  BUSINESS_TYPE.HOLIDAY,
] as const;

export type HourMinute = Readonly<{
  hour: string;
  minute: string;
}>;

export const initialHourMinute: HourMinute = {
  hour: "06",
  minute: "00",
} as const;

export type BusinessTime = {
  start: HourMinute;
  end: HourMinute;
};

export type BusinessWeekTime = Readonly<{
  week: number;
  business_type: BusinessType;
  business_times: BusinessTime[];
}>;

type BusinessBusyTime = Readonly<{
  week: number;
  business_times: BusinessTime[];
}>;

export const DEFAULT_CROW_ORDER_COUNT = 5;

export type ShopBusiness = Readonly<{
  cooking_time: number;
  crow_order_count: number;
  business_week_time: BusinessWeekTime[];
  business_busy_time: BusinessBusyTime[];
  holiday_list: string[];
  fastest_cooking_time: number;
}>;

const initialState: ShopBusiness = {
  cooking_time: 0,
  crow_order_count: 0,
  business_week_time: [],
  business_busy_time: [],
  holiday_list: [],
  fastest_cooking_time: 0,
};

const create = (args: Partial<ShopBusiness> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

const getPresentationBusinessTypeString = (businessType: BusinessType) => {
  switch (businessType) {
    case BUSINESS_TYPE.ONCE_A_DAY:
      return "1日1回営業";
    case BUSINESS_TYPE.TWICE_A_DAY:
      return "1日2回営業";
    case BUSINESS_TYPE.HOLIDAY:
      return "休業日";
    // skip default case
  }
};

const getPresentationBusinessTimeString = (businessTime: string) => {
  return `${businessTime.slice(0, 2)}:${businessTime.slice(2, 4)}`;
};

const isHoliday = (businessType: BusinessType) => {
  return businessType === BUSINESS_TYPE.HOLIDAY;
};

const getPresentationCookingTimeString = (cookingTime: number) => {
  return `${cookingTime + 3}分 （設定時間：${cookingTime}分）`;
};

const existsCrowOrderCount = (count: number) => {
  return count > 0;
};

const existsHoliday = (holidayList: string[]) => {
  return holidayList.length > 0;
};

export const ShopBusiness = Object.freeze({
  initialState,
  create,
  getPresentationBusinessTypeString,
  getPresentationBusinessTimeString,
  isHoliday,
  getPresentationCookingTimeString,
  existsCrowOrderCount,
  existsHoliday,
});
