import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LinkButton } from "../atoms/LinkButton";

const Container = styled.div``;

type Props = {};

export const Intoroduction: React.FC<Props> = React.memo(() => {
  return (
    <Container>
      <p>
        menuへのお申込み、誠にありがとうございます。
        <br />
        このページは、お客様の店舗情報、商品情報をmenuアプリ上に登録し、管理するためのものです。
        <br />
        <Link to="/storeManagerManual">こちら</Link>
        から操作マニュアルをダウンロード頂き、必ずマニュアルに沿って登録をお進めください。
      </p>
      <LinkButton to="/shopRegister" appearance="primary" display="responsive">
        はじめる
      </LinkButton>
    </Container>
  );
});
