import { AppState } from "../modules/app/model";

const DEFAULT_MESSAGE = `『menu』をご愛顧いただき誠にありがとうございます。
ただいまの時間はサーバーメンテナンスを行なっております。
メンテナンス終了まで今しばらくお待ち下さい。
`;

export type MaintenanceInfo = Readonly<{
  start_date: string;
  end_date: string;
  message: string;
}>;

export const initialState: MaintenanceInfo = {
  start_date: "",
  end_date: "",
  message: DEFAULT_MESSAGE,
};

const getMessage = (state: AppState) => {
  return state.maintenanceInfo.message;
};

export const MaintenanceInfo = Object.freeze({
  initialState,
  getMessage,
});
