import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ShopData, DraftStateType, displayableDraftStateType } from "../../records/ShopData";
import { colorsConst } from "../../styles/const";
import { fontSize, media } from "../../styles/mixins";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 1em;
  padding-bottom: 1.5em;
  padding-left: 1em;
`;

const Item = styled.div<{ index: number }>`
  flex-grow: ${(p) => (p.index > 0 ? 1 : 0)};
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Number = styled.div<{ active: boolean }>`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.WHITE};
  background-color: ${(p) =>
    p.active ? colorsConst.BACKGROUND.BLACK : colorsConst.BACKGROUND.DISABLED};
  ${media.tablet} {
    width: 24px;
    height: 24px;
  }
`;

const Text = styled.div<{ active: boolean }>`
  position: absolute;
  top: 24px;
  ${media.tablet} {
    top: 32px;
  }
  left: 50%;
  transform: translateX(-50%);
  max-width: 4em;
  color: ${(p) => (p.active ? colorsConst.TEXT.BLACK : colorsConst.TEXT.TERTIARY)};
  text-align: center;
  white-space: nowrap;
`;

const Line = styled.div<{ active: boolean }>`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 2px;
  &::after {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 4px;
    content: "";
    background: radial-gradient(
      circle farthest-side,
      ${colorsConst.BORDER.THICK},
      ${colorsConst.BORDER.THICK} 50%,
      transparent 50%,
      transparent
    );
    background-size: 4px 4px;
  }
  ${(p) =>
    p.active
      ? `
    background-color: #000;
    &:after {
      content: none;
    }
  `
      : ""}
`;

type Props = {
  state: DraftStateType;
};

export const ShopDraftStateProgress: React.FC<Props> = React.memo(({ state }) => {
  return (
    <Container>
      {displayableDraftStateType.map((s, i) => (
        <Item key={i} index={i}>
          {i !== 0 && <Line active={state >= s} />}
          <div>
            <Number active={state >= s}>
              {state > s ? <FontAwesomeIcon icon={faCheck} /> : i + 1}
              <Text active={state >= s}>{ShopData.getPresentationDraftStateText(s)}</Text>
            </Number>
          </div>
        </Item>
      ))}
    </Container>
  );
});
