import * as Yup from "yup";
import { ShopData } from "../records/ShopData";
import {
  BUSINESS_TYPE,
  BusinessType,
  BusinessWeekTime,
  BusinessTime,
  HourMinute,
  initialHourMinute,
} from "../records/ShopBusiness";

export type BusinessTimeEditForm = {
  shop_id: number;
  week_time: BusinessWeekTime[];
};

export const initialBusinessTimeEditForm: BusinessTimeEditForm = {
  shop_id: 0,
  week_time: Array(7)
    .fill("")
    .map((_, index) => {
      return {
        week: index,
        business_type: BUSINESS_TYPE.ONCE_A_DAY,
        business_times: [
          {
            start: initialHourMinute,
            end: initialHourMinute,
          },
        ],
      };
    }),
};

export const getUpdatedBusinessTimesFieldsArray = (value: BusinessType, array: BusinessTime[]) => {
  const updatedArray = array.slice();
  switch (value) {
    case BUSINESS_TYPE.ONCE_A_DAY:
      if (updatedArray.length > 1) {
        while (updatedArray.length > 1) {
          updatedArray.pop();
        }
      } else {
        while (updatedArray.length < 1) {
          updatedArray.push({
            start: initialHourMinute,
            end: initialHourMinute,
          });
        }
      }
      break;
    case BUSINESS_TYPE.TWICE_A_DAY:
      while (updatedArray.length < 2) {
        updatedArray.push({
          start: initialHourMinute,
          end: initialHourMinute,
        });
      }
      break;
    case BUSINESS_TYPE.HOLIDAY:
      while (updatedArray.length > 0) {
        updatedArray.pop();
      }
      break;
    // skip default case
  }
  return updatedArray;
};

export const getInitialValues = (shopData?: ShopData): BusinessTimeEditForm => {
  return typeof shopData !== "undefined"
    ? {
        ...initialBusinessTimeEditForm,
        shop_id: shopData.id,
        week_time: Array(7)
          .fill("")
          .map((_, index) => {
            const data = shopData.shop_business.business_week_time.find((i) => i.week === index);
            return typeof data !== "undefined"
              ? data
              : {
                  week: index,
                  business_type: BUSINESS_TYPE.ONCE_A_DAY,
                  business_times: [
                    {
                      start: initialHourMinute,
                      end: initialHourMinute,
                    },
                  ],
                };
          }),
      }
    : initialBusinessTimeEditForm;
};

const businessTimeSchema = Yup.object({
  start: Yup.mixed<HourMinute>(),
  /* eslint-disable-next-line func-names */
  end: Yup.mixed<HourMinute>().test("test", "正しい時間帯を選択してください", function (
    endHourMinute: HourMinute,
  ) {
    const startHourMinute: HourMinute = this.parent.start;
    const startHourMinuteNum = parseInt(`${startHourMinute.hour}${startHourMinute.minute}`, 10);
    const endHourMinuteNum = parseInt(`${endHourMinute.hour}${endHourMinute.minute}`, 10);
    if (Number.isNaN(startHourMinuteNum) || Number.isNaN(endHourMinuteNum)) {
      return false;
    }
    return startHourMinuteNum < endHourMinuteNum;
  }),
});

const weekTimeSchema = Yup.object({
  week: Yup.number(),
  business_type: Yup.mixed<BusinessType>().oneOf(Object.values(BUSINESS_TYPE)),
  business_times: Yup.array().of(businessTimeSchema),
});

export const businessTimeEditFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  week_time: Yup.array(weekTimeSchema),
})
  .defined()
  .strict(true)
  .noUnknown();
