export class LocalStorage {
  public static getItem(key: string, def: string = ""): string {
    const value = localStorage.getItem(key);
    return value !== null ? value : def;
  }

  public static setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  public static hasItem(key: string) {
    return localStorage.getItem(key) !== null;
  }
}
