import styled from "styled-components";
import { spaceConst } from "../../../styles/const";
import { media } from "../../../styles/mixins/media";

export const FormFieldGroup = styled.div`
  & + & {
    margin-top: ${`${spaceConst.GUTTER.INPUT_FIELD.DEFAULT}px`};
    ${media.tablet} {
      margin-top: ${`${spaceConst.GUTTER.INPUT_FIELD.TABLET}px`};
    }
  }
`;
