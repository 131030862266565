export type UserData = Readonly<{
  id: string;
}>;

const initialState: UserData = {
  id: "",
};

const create = (args: Partial<UserData> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const UserData = Object.freeze({
  initialState,
  create,
});
