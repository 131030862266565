import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { GetShopListRequest, GetShopListResponse } from "../../../apis/getShopList";
import { updateShopList } from "../actions";

export function* userAccessedToPageThatNeedsShopListSaga() {
  try {
    const params: GetShopListRequest = {};
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.GET_SHOP_LIST, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: GetShopListResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopList(result.shop_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "店舗一覧の取得に失敗しました。",
      }),
    );
  }
}
