export type BankBranch = Readonly<{
  branch_code: string;
  branch_name: string;
}>;

const initialState: BankBranch = {
  branch_code: "",
  branch_name: "",
};

const create = (args: Partial<BankBranch> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const BankBranch = Object.freeze({
  initialState,
  create,
});
