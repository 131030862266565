export type TextColors =
  | "PRIMARY"
  | "SECONDARY"
  | "TERTIARY"
  | "DANGER"
  | "WHITE"
  | "BLACK"
  | "LINK";

const baseColors = {
  PRIMARY: "#323232",
  SECONDARY: "#808080",
  TERTIARY: "#c8c8c8",
  DANGER: "#ff3008",
  WHITE: "#fff",
  BLACK: "#000",
};

export const colorsConst = {
  TEXT: {
    PRIMARY: baseColors.PRIMARY,
    SECONDARY: baseColors.SECONDARY,
    TERTIARY: baseColors.TERTIARY,
    DANGER: baseColors.DANGER,
    WHITE: baseColors.WHITE,
    BLACK: baseColors.BLACK,
    LINK: "#069edf",
  },
  BUTTON: {
    TEXT: {
      PRIMARY: baseColors.WHITE,
      SECONDARY: baseColors.TERTIARY,
    },
    BACKGROUND: {
      DEFAULT: baseColors.SECONDARY,
      PRIMARY: "#069edf",
      SECONDARY: "#ff866c",
      DISABLED: baseColors.TERTIARY,
      DANGER: baseColors.DANGER,
    },
  },
  BORDER: {
    DEFAULT: "#e3e3e3",
    THICK: baseColors.TERTIARY,
    THIN: "#f0f0f0",
    DANGER: baseColors.DANGER,
  },
  BACKGROUND: {
    PRIMARY: baseColors.WHITE,
    SECONDARY: "#f0f0f0",
    DISABLED: baseColors.TERTIARY,
    SUCCESS: "#ebffe2",
    WARNING: "#fffce2",
    DANGER: "#f8d7da",
    OVERLAY: "rgba(0, 0, 0, 0.5)",
    DARK: baseColors.PRIMARY,
    BLACK: baseColors.BLACK,
  },
  DANGER: baseColors.DANGER,
  WHITE: baseColors.WHITE,
  BLACK: baseColors.BLACK,
} as const;
