import { fork, join, cancel, put } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import {
  UpdateShopItemCategoryRequest,
  UpdateShopItemCategoryResponse,
} from "../../../apis/updateShopItemCategory";
import { updateShopItemCategoryList, UserSubmittedShopItemCategoryEditAction } from "../actions";

export function* userSubmittedShopItemCategoryEditSaga(
  action: UserSubmittedShopItemCategoryEditAction,
) {
  try {
    const params: UpdateShopItemCategoryRequest = action.payload;
    const commonApiTask = yield fork(() =>
      commonApiSaga(API_KEY.UPDATE_SHOP_ITEM_CATEGORY, params),
    );
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const {
      result,
      error,
    }: { result: UpdateShopItemCategoryResponse; error: unknown } = yield join(commonApiTask);
    if (result && !error) {
      yield put(updateShopItemCategoryList(result.item_list));
      yield put(replace(`/shopItemCategory/${action.payload.shop_id}`));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "店舗情報の更新に失敗しました。",
      }),
    );
  }
}
