import React, { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement> & {
  size?: number;
  children?: never;
};

const ratio = 65 / 104;

export const SvgNoImage: React.FC<Props> = ({ size = 104, ...rest }) => {
  return (
    <svg width={size} height={size * ratio} viewBox="0 0 104 65" fill="none" {...rest}>
      <path
        d="M53.3376 64.4085C70.9502 64.4085 85.228 50.1849 85.228 32.6391C85.228 15.0933 70.9502 0.869629 53.3376 0.869629C35.7251 0.869629 21.4473 15.0933 21.4473 32.6391C21.4473 50.1849 35.7251 64.4085 53.3376 64.4085Z"
        fill="#E7E7E7"
      />
      <path
        d="M101.977 33.5086V4.61826C101.977 4.28817 101.758 3.99584 101.431 3.89093C101.105 3.78603 100.354 3.84473 100.019 4.26885C98.1399 6.64385 96.6837 9.28181 95.6914 12.1086C94.7007 14.9354 94.1973 17.8811 94.1973 20.8631L94.1973 31.4345C94.1973 32.7102 95.7992 33.6344 97.1299 33.6344H97.9485C97.0161 42.9261 96.3974 55.7732 96.3974 57.3929C96.3974 59.8588 97.9996 61.8632 99.968 61.8632C101.936 61.8632 103.539 59.8574 103.539 57.3929C103.54 55.7662 102.917 42.81 101.977 33.5086Z"
        fill="#E7E7E7"
      />
      <path
        d="M10.796 26.3101C12.843 25.0751 14.0636 22.9171 14.0636 20.5292L13.1106 5.01478C13.0709 4.35845 12.5224 3.8457 11.8636 3.8457C11.518 3.8457 11.1974 3.98341 10.9592 4.23246C10.721 4.48151 10.5989 4.80821 10.6165 5.15249L11.2357 17.5889C11.2357 18.2189 10.721 18.7316 10.0886 18.7316C9.45625 18.7316 8.94155 18.2189 8.94155 17.586L8.63273 5.14809C8.61361 4.41705 8.02538 3.8457 7.29156 3.8457C6.55774 3.8457 5.9695 4.41705 5.95186 5.14809L5.64304 17.5889C5.64304 18.2189 5.12833 18.7316 4.49598 18.7316C3.86363 18.7316 3.34893 18.2189 3.34893 17.5948L3.96804 5.15249C3.98569 4.80821 3.86363 4.48151 3.6254 4.23246C3.38716 3.98341 3.06511 3.8457 2.71952 3.8457C2.0607 3.8457 1.51217 4.35845 1.47247 5.01478L0.519531 20.5365C0.519531 22.9171 1.74158 25.0751 3.78716 26.3101C4.99304 27.0382 5.67098 28.4094 5.51657 29.807C4.44745 39.3999 3.69305 55.3802 3.69305 57.2143C3.69305 59.7971 5.30774 61.8965 7.29156 61.8965C9.27537 61.8965 10.8901 59.7957 10.8901 57.2143C10.8901 55.3802 10.1342 39.4014 9.06655 29.807C8.91067 28.4094 9.59007 27.0382 10.796 26.3101Z"
        fill="#E7E7E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.3392 53.7103C65.0225 53.7103 74.4937 44.275 74.4937 32.636C74.4937 20.997 65.0225 11.5618 53.3392 11.5618C41.6559 11.5618 32.1847 20.997 32.1847 32.636C32.1847 44.275 41.6559 53.7103 53.3392 53.7103ZM53.3392 55.4703C65.9982 55.4703 76.2604 45.2471 76.2604 32.636C76.2604 20.025 65.9982 9.80176 53.3392 9.80176C40.6801 9.80176 30.418 20.025 30.418 32.636C30.418 45.2471 40.6801 55.4703 53.3392 55.4703Z"
        fill="#F3F3F3"
      />
    </svg>
  );
};
