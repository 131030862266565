import { ModalKey, ModalPayload } from "src/records/ModalManager";
import { ApiKey } from "src/apis";
import { ProcessingFlag } from "./model";
import { MaintenanceInfo } from "../../records/MaintenanceInfo";
import { AlertProps } from "../../records/Alert";

export enum ActionTypes {
  S_LAUNCHED_APP = "app/S_LAUNCHED_APP",
  S_ADDED_ALERT = "app/S_ADDED_ALERT",
  S_INIT_ALERT = "app/S_INIT_ALERT",
  S_REMOVED_ALERT = "app/S_REMOVED_ALERT",
  S_STARTED_CONNECT_API = "app/S_STARTED_CONNECT_API",
  S_FINISHED_CONNECT_API = "app/S_FINISHED_CONNECT_API",
  S_AUTO_LOGIN = "app/S_AUTO_LOGIN",
  S_DETECTED_RESOURCE_UPDATE = "app/S_DETECTED_RESOURCE_UPDATE",
  S_OPENED_MODAL = "app/S_OPENED_MODAL",
  S_CLOSED_MODAL = "app/S_CLOSED_MODAL",
  R_OPEN_MODAL = "app/R_OPEN_MODAL",
  R_CLOSE_MODAL = "app/R_CLOSE_MODAL",
  R_UPDATE_ALERT = "app/R_UPDATE_ALERT",
  R_SET_PROCESSING_FLAG = "app/R_SET_PROCESSING_FLAG",
  R_REMOVE_PROCESSING_FLAG = "app/R_REMOVE_PROCESSING_FLAG",
  R_SET_MAINTENANCE_INFO = "app/R_SET_MAINTENANCE_INFO",
  R_SET_SHOULD_UPDATE_RESOURCE_FLAG = "app/R_SET_SHOULD_UPDATE_RESOURCE_FLAG",
}

export const systemLaunchedApp = () =>
  ({
    type: ActionTypes.S_LAUNCHED_APP,
  } as const);
export type SystemLaunchedAppAction = ReturnType<typeof systemLaunchedApp>;

export const systemAddedAlert = (alertProps: AlertProps) =>
  ({
    type: ActionTypes.S_ADDED_ALERT,
    payload: {
      alertProps,
    },
  } as const);
export type SystemAddedAlertAction = ReturnType<typeof systemAddedAlert>;

export const systemInitAlert = () =>
  ({
    type: ActionTypes.S_INIT_ALERT,
  } as const);
export type SystemInitAlertAction = ReturnType<typeof systemInitAlert>;

export const systemRemovedAlert = (id: string) =>
  ({
    type: ActionTypes.S_REMOVED_ALERT,
    payload: {
      id,
    },
  } as const);
export type SystemRemovedAlertAction = ReturnType<typeof systemRemovedAlert>;

export const systemStartedConnectApi = (apiKey: ApiKey) =>
  ({
    type: ActionTypes.S_STARTED_CONNECT_API,
    payload: {
      apiKey,
    },
  } as const);
export type SystemStartedConnectApiAction = ReturnType<typeof systemStartedConnectApi>;

export const systemFinishedConnectApi = (apiKey: ApiKey) =>
  ({
    type: ActionTypes.S_FINISHED_CONNECT_API,
    payload: {
      apiKey,
    },
  } as const);
export type SystemFinishedConnectApiAction = ReturnType<typeof systemFinishedConnectApi>;

export const systemAutoLogin = () =>
  ({
    type: ActionTypes.S_AUTO_LOGIN,
  } as const);
export type SystemAutoLoginAction = ReturnType<typeof systemAutoLogin>;

export const systemDetectedResourceUpdate = () =>
  ({
    type: ActionTypes.S_DETECTED_RESOURCE_UPDATE,
  } as const);
export type SystemDetectedResourceUpdateAction = ReturnType<typeof systemDetectedResourceUpdate>;

export const systemOpenedModal = <T extends ModalKey>(modalKey: T, modalPayload: ModalPayload<T>) =>
  ({
    type: ActionTypes.S_OPENED_MODAL,
    payload: {
      modalKey,
      modalPayload,
    },
  } as const);
export type SystemOpenedModalAction = ReturnType<typeof systemOpenedModal>;

export const systemClosedModal = (modalKey: ModalKey) =>
  ({
    type: ActionTypes.S_CLOSED_MODAL,
    payload: {
      modalKey,
    },
  } as const);
export type SystemClosedModalAction = ReturnType<typeof systemClosedModal>;

export const openModal = <T extends ModalKey>(key: T, payload: ModalPayload<T>) =>
  ({
    type: ActionTypes.R_OPEN_MODAL,
    payload: {
      key,
      payload,
    },
  } as const);
export type OpenModalAction = ReturnType<typeof openModal>;

export const closeModal = (key: ModalKey) =>
  ({
    type: ActionTypes.R_CLOSE_MODAL,
    payload: {
      key,
    },
  } as const);
export type CloseModalAction = ReturnType<typeof closeModal>;

export const updateAlert = (alertProps: AlertProps) =>
  ({
    type: ActionTypes.R_UPDATE_ALERT,
    payload: {
      alertProps,
    },
  } as const);
export type UpdateAlertAction = ReturnType<typeof updateAlert>;

export const setProcessingFlag = (key: ProcessingFlag) =>
  ({
    type: ActionTypes.R_SET_PROCESSING_FLAG,
    payload: {
      key,
    },
  } as const);
export type SetProcessingFlagAction = ReturnType<typeof setProcessingFlag>;

export const removeProcessingFlag = (key: ProcessingFlag) =>
  ({
    type: ActionTypes.R_REMOVE_PROCESSING_FLAG,
    payload: {
      key,
    },
  } as const);
export type RemoveProcessingFlagAction = ReturnType<typeof removeProcessingFlag>;

export const setMaintenanceInfo = (info: MaintenanceInfo) =>
  ({
    type: ActionTypes.R_SET_MAINTENANCE_INFO,
    payload: {
      info,
    },
  } as const);
export type SetMaintenanceInfoAction = ReturnType<typeof setMaintenanceInfo>;

export const setShouldUpdateResourceFlag = () =>
  ({
    type: ActionTypes.R_SET_SHOULD_UPDATE_RESOURCE_FLAG,
  } as const);
export type SetShouldUpdateResourceFlagAction = ReturnType<typeof setShouldUpdateResourceFlag>;

export type AppActions =
  | SystemLaunchedAppAction
  | SystemAddedAlertAction
  | SystemInitAlertAction
  | SystemRemovedAlertAction
  | SystemStartedConnectApiAction
  | SystemFinishedConnectApiAction
  | SystemAutoLoginAction
  | SystemDetectedResourceUpdateAction
  | SystemOpenedModalAction
  | SystemClosedModalAction
  | OpenModalAction
  | CloseModalAction
  | UpdateAlertAction
  | SetProcessingFlagAction
  | RemoveProcessingFlagAction
  | SetMaintenanceInfoAction
  | SetShouldUpdateResourceFlagAction;
