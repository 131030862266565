import React from "react";
import styled from "styled-components";
import { colorsConst } from "../../styles/const";
import { Notice } from "../atoms/Notice";
import { Paragraph } from "../atoms/Paragraph";

const Container = styled(Notice)`
  margin-top: 1em;
  padding-bottom: 0;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
`;

type Props = {};

export const HomeInfomation: React.FC<Props> = React.memo(() => {
  return (
    <Container>
      <Paragraph>
        手数料について、
        <a href="/pdf/plan.pdf" target="_blank">
          こちら
        </a>
        を必ずご確認ください。
        <br />
        （掲載条件を満たすことで、実質手数料無料で開始できます）
      </Paragraph>
      <Paragraph>
        食中毒の防止と対策について
        <a href="/pdf/rouse_food_poisoning.pdf" target="_blank">
          こちら
        </a>
        をご確認ください。
      </Paragraph>
    </Container>
  );
});
