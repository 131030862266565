import React, { useContext } from "react";
import styled from "styled-components";
import { ShopData } from "../../../records/ShopData";
import { Text } from "../../atoms/Text";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import {
  ShopDetailSection,
  ShopDetailRow,
  ShopDetailKey,
  ShopDetailVal,
} from "../../molecules/ShopDetailSection";
import { ShopDetailContext } from "./ShopDetailContext";

const Container = styled.div``;

type Props = {};

export const ShopReservationOpen: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);
  return (
    <Container>
      <ShopDetailSectionTitle title="公開日情報" link={`/shopReservationOpenEdit/${shopData.id}`} />
      <ShopDetailSection>
        <ShopDetailRow>
          <ShopDetailKey>公開予定日</ShopDetailKey>
          <ShopDetailVal>
            {ShopData.existsPreferredOpenDate(shopData.preferred_open_date) ? (
              `${shopData.preferred_open_date}以降`
            ) : (
              <Text textColor="DANGER">未設定</Text>
            )}
          </ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>現在の公開ステータス</ShopDetailKey>
          <ShopDetailVal>
            {ShopData.isReleased(shopData.release_state) ? (
              "配信中"
            ) : (
              <Text textColor="DANGER">配信停止</Text>
            )}
          </ShopDetailVal>
        </ShopDetailRow>
      </ShopDetailSection>
    </Container>
  );
});
