import { DateUtils } from "../utils/DateUtils";

export const SHOP_LICENSE_TYPE = {
  FOOD: 1, // 食品
  MEAT: 2, // 生肉
  ALCOHOL: 3, // アルコール
} as const;

export type ShopLicenseType = ValueOf<typeof SHOP_LICENSE_TYPE>;

export const displayableShopLicenseType: ReadonlyArray<ShopLicenseType> = [
  SHOP_LICENSE_TYPE.ALCOHOL,
  SHOP_LICENSE_TYPE.MEAT,
  SHOP_LICENSE_TYPE.FOOD,
] as const;

export const SHOP_LICENSE_EXPIRE_TYPE = {
  LIMITED: 1,
  NEVER: 2,
} as const;

export type ShopLicenseExpireType = ValueOf<typeof SHOP_LICENSE_EXPIRE_TYPE>;

export const displayableShopLicenseExpireType: ReadonlyArray<ShopLicenseExpireType> = [
  SHOP_LICENSE_EXPIRE_TYPE.LIMITED,
  SHOP_LICENSE_EXPIRE_TYPE.NEVER,
] as const;

export type ShopLicensePresentationTextType = Readonly<{
  pageTitle: string;
  introduction: string[];
  discription: string;
}>;

export type ShopLicense = Readonly<{
  license_id: number;
  expire_type: ShopLicenseExpireType;
  expiration_date: string;
  image_path: string;
}>;

const initialState: ShopLicense = {
  license_id: 0,
  expire_type: SHOP_LICENSE_EXPIRE_TYPE.NEVER,
  expiration_date: "",
  image_path: "",
};

const create = (args: Partial<ShopLicense> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

const getPresentationLicenseTypeText = (type: ShopLicenseType) => {
  switch (type) {
    case SHOP_LICENSE_TYPE.FOOD:
      return "食品営業許可書";
    case SHOP_LICENSE_TYPE.MEAT:
      return "食肉販売業許可書";
    case SHOP_LICENSE_TYPE.ALCOHOL:
      return "酒類の販売業免許";
    // skip default case
  }
};

const getPresentationLicenseExpireTypeText = (type: ShopLicenseExpireType) => {
  switch (type) {
    case SHOP_LICENSE_EXPIRE_TYPE.LIMITED:
      return "有効期限あり";
    case SHOP_LICENSE_EXPIRE_TYPE.NEVER:
      return "有効期限なし";
    // skip default case
  }
};

const getPresentationLicenseEditPageText = (
  type: ShopLicenseType,
): ShopLicensePresentationTextType => {
  switch (type) {
    case SHOP_LICENSE_TYPE.FOOD:
      return {
        pageTitle: "食品営業許可",
        introduction: [],
        discription: "食品の調理・販売・製造の際に必要となる許可です。",
      };
    case SHOP_LICENSE_TYPE.MEAT:
      return {
        pageTitle: "生肉の取り扱い",
        introduction: [
          "生肉販売を行う際は販売許可証が必要です。",
          "予めご用意の上、店舗名がわかるように撮影した写真をご登録ください。",
        ],
        discription: "生肉を販売する営業を始めるときに必要な許可です。",
      };
    case SHOP_LICENSE_TYPE.ALCOHOL:
      return {
        pageTitle: "アルコールの取り扱い",
        introduction: [
          "アルコール類の販売を行う際は販売許可証が必要です。",
          "予めご用意の上、店舗名がわかるように撮影した写真をご登録ください。",
        ],
        discription: "",
      };
    // skip default case
  }
};

const existsExpirationDate = (license: ShopLicense) => {
  return license.expiration_date !== "";
};

const isValid = (license: ShopLicense) => {
  return (
    license.license_id !== 0 &&
    (license.expire_type === SHOP_LICENSE_EXPIRE_TYPE.NEVER ||
      (existsExpirationDate(license) && !DateUtils.isPastDate(license.expiration_date)))
  );
};

export const ShopLicense = Object.freeze({
  initialState,
  create,
  getPresentationLicenseTypeText,
  getPresentationLicenseExpireTypeText,
  getPresentationLicenseEditPageText,
  existsExpirationDate,
  isValid,
});
