import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { media } from "../../../styles/mixins";

const defaultStyle = (): FlattenSimpleInterpolation => {
  return css`
    ${media.mobile} {
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
  `;
};

export const FormContainer = styled.form`
  ${defaultStyle}
`;

export const FormDivContainer = styled.div`
  ${defaultStyle}
`;

export const FormInner = styled.div`
  ${media.mobile} {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2em;
  ${media.mobile} {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
