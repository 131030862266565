import React from "react";
import styled from "styled-components";
import { colorsConst, fontWeightsConst } from "../../styles/const";
import { fontSize } from "../../styles/mixins";
import { ShopItem } from "../../records/ShopItem";

const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 1em;
  border: none;
  border-radius: 50px;
  appearance: none;
  background-color: ${colorsConst.BACKGROUND.WARNING};
`;

const LabelText = styled.div`
  margin-right: 0.5em;
  padding-right: 0.5em;
  text-align: left;
  ${fontSize.SMALL};
  color: ${colorsConst.TEXT.SECONDARY};
  border-right: 1px solid ${colorsConst.BORDER.THICK};
`;

const StatusText = styled.div`
  ${fontSize.XLARGE};
  color: ${colorsConst.TEXT.LINK};
  font-weight: ${fontWeightsConst.BOLD};
`;

type Props = {
  shopItem: ShopItem;
  handleClick: () => void;
};

export const ShopItemStatus: React.FC<Props> = React.memo(({ shopItem, handleClick }) => {
  return (
    <Container onClick={handleClick} type="button">
      <LabelText>
        現在の
        <br />
        ステータス
      </LabelText>
      <StatusText>{shopItem.is_released ? "受付中" : "受付停止"}</StatusText>
    </Container>
  );
});
