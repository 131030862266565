import React, { useCallback } from "react";
import styled from "styled-components";
import { media } from "../../styles/mixins/media";
import { colorsConst } from "../../styles/const";
import { ShopStaff } from "../../records/ShopStaff";
import { Button } from "../atoms/Button";
import { ShopStaffPasswordField } from "./ShopStaffPasswordField";

const Container = styled.div`
  display: table-row;
  ${media.mobile} {
    margin-top: 1em;
    display: block;
    padding: 0.5em;
    background-color: ${colorsConst.BACKGROUND.PRIMARY};
    border-radius: 4px;
  }
`;
const Cell = styled.div`
  display: table-cell;
  padding: 0.5em;
  white-space: nowrap;
  ${media.mobile} {
    display: block;
    &[data-label] {
      &::before {
        content: attr(data-label);
        display: block;
        margin-bottom: 0.25em;
        padding-bottom: 0.25em;
        color: ${colorsConst.TEXT.SECONDARY};
        border-bottom: 1px solid ${colorsConst.BORDER.THIN};
      }
    }
  }
`;

type Props = {
  shopStaff: ShopStaff;
  handleSubmitPassward: (staffId: number, passward: string) => void;
  handleDelete: (staffId: number) => void;
};

export const ShopStaffListItem: React.FC<Props> = React.memo(
  ({ shopStaff, handleSubmitPassward, handleDelete }) => {
    const handleDeleteWrap = useCallback(() => {
      handleDelete(shopStaff.id);
    }, [handleDelete, shopStaff]);

    return (
      <Container key={shopStaff.id}>
        <Cell data-label="アカウント名">{shopStaff.display_name}</Cell>
        <Cell data-label="ログインID">{shopStaff.login_id}</Cell>
        <Cell data-label="パスワード変更">
          <ShopStaffPasswordField staffId={shopStaff.id} handleSubmit={handleSubmitPassward} />
        </Cell>
        <Cell>
          <Button
            type="button"
            appearance="secondary"
            display="responsive"
            size="sm"
            onClick={handleDeleteWrap}
          >
            削除
          </Button>
        </Cell>
      </Container>
    );
  },
);
