import { cancel, fork, put, /* select, */ spawn } from "redux-saga/effects";
import { AlertType, AlertProps, ALERT_TYPE } from "../../../records/Alert";
// import { ReduxModel } from "../../../reducer";
// import { systemRequestedFetchCartList } from "../../order/actions";
// import { resetUser, updateLoginToken } from "../../user/actions";
import { systemAddedAlert /* , systemReadyForUserOperation */ } from "../actions";
// import { AppState } from "../model";

// 320~は汎用エラー
// 321~は個別エラー
export const ERROR_CODE = {
  ALREADY_EXISTS_EXCEPTION: -32007,
  RUNTIME: -32099,
  INVALID_TOKEN: -32100,
  // UPDATE_CREDIT_CARD_INFO: -32101,
  // EXECUTE_CREDIT_CARD_INFO: -32102,
  // USER_NOT_FOUND: -32103,
  // LOGIN_FAILED: -32104,
  // SEND_MAIL: -32105,
  // CODE_AUTH_FAILED: -32106,
  // ORDER_STOP: -32107,
  // REGISTER_ID: -32108,
  // ORDER_EXPIRE: -32110,
  // ADD_CART_LIMIT: -32112,
  // CREDIT_CARD_INPUT_LIMIT: -32113,
  // ORDER_EXCLUSION_CONTROL: -32114,
  // OUT_OF_RECEPTION_TIME_EXCEPTION: -32115,
  // SOLD_OUT_ITEM: -32116,
  // DISPLAY_DIFFERENT_PRICE: -32118,
  // SHOP_ITEM_LIMIT_OVER_EXCEPTION: -32119,
  // ORDER_VALIDATION_EXCEPTION: -32120,
  // MAIL_ADDRESS_NOT_REGISTERED_EXCEPTION: -32121,
  // NOT_EXIST_PHONE_NUMBER_EXCEPTION: -32122,
  // OUT_OF_ITEM_RECEPTION_TIME_EXCEPTION: -32123,
  // INVALID_PHONE_NUMBER_EXCEPTION: -32124,
  // NOT_ENOUGH_LOGIN_INFO_EXCEPTION: -32125,
  // PAGE_NOT_FOUND_EXCEPTION: -32126,
  // BENEFITS_EMPLOYEE_EXCEPTION: -32127,
  // AUTH_SMS_FAILED_EXCEPTION: -32128,
  // SITE_HEAVY_EXCEPTION: -32098,
} as const;

export type ErrorCode = ValueOf<typeof ERROR_CODE>;

// ダイアログを表示する処理
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* generateAlertSaga(error: any, aleartType: AlertType) {
  const errorTitle =
    error.title !== "undefined" && error.title !== null && error.title !== ""
      ? (error.title as string)
      : "エラー";
  const errorMessage =
    error.message !== "undefined" && error.message !== null && Array.isArray(error.message)
      ? error.message.length > 0
        ? (error.message as string[])
        : undefined
      : error.message !== ""
      ? (error.message as string)
      : undefined;
  const alert: AlertProps = {
    type: aleartType,
    title: errorTitle,
    message: errorMessage,
  };
  yield put(systemAddedAlert(alert));
}

// リダイレクト後にダイアログを表示する処理
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* generateAlertAndRedirectSaga(error: any) {
  // yield put(resetUser());
  // yield put(updateLoginToken(null));

  // redirectToLoginTemplate();

  const errorAlert: AlertProps = {
    type: ALERT_TYPE.DANGER,
    title:
      error.title !== "undefined" && error.title !== null && error.title !== ""
        ? error.title
        : "エラー",
    message: `ログイン処理に失敗しました。
再度ログインを行ってください。`,
  };
  yield put(systemAddedAlert(errorAlert));

  // const app: AppState = yield select<(state: ReduxModel) => AppState>((state) => state.app);
  // if (!app.completedToReadyForUserOperation()) {
  //   yield put(systemReadyForUserOperation());
  // }
}

// 不明なエラーとして扱い、リダイレクト後にダイアログを表示する処理
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* generateUnknownErrorAlertAndRedireactSaga(error: any, status: number) {
  console.debug("commonApiFailedSaga > generateUnknownErrorAlertAndRedireactSaga", error);
  const defaultTitle = "エラー";
  const defaultMessage = `不明なエラーが発生しました。\n（code: ${
    "code" in error ? error.code : "-"
  }）`;
  const unknownAlert: AlertProps = {
    type: ALERT_TYPE.DANGER,
    title: status !== 500 ? error.title || defaultTitle : defaultTitle,
    message: status !== 500 ? error.message || defaultMessage : defaultMessage,
  };

  // navigate("HomeTemplate");

  yield put(systemAddedAlert(unknownAlert));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* errorHandlingSaga(error: any, status: number) {
  switch (error.code) {
    case ERROR_CODE.ALREADY_EXISTS_EXCEPTION:
    case ERROR_CODE.RUNTIME:
      yield fork(() => generateAlertSaga(error, ALERT_TYPE.DANGER));
      break;
    case ERROR_CODE.INVALID_TOKEN:
      yield fork(() => generateAlertAndRedirectSaga(error));
      break;
    default:
      yield fork(() => generateUnknownErrorAlertAndRedireactSaga(error, status));
  }
}

export function* commonApiFailedSaga(response: Response) {
  try {
    const data = yield response.json();
    const { error } = data;
    if (Array.isArray(error)) {
      for (const errorItem of error) {
        yield spawn(errorHandlingSaga, errorItem, response.status);
      }
    } else {
      yield spawn(errorHandlingSaga, error, response.status);
    }
    yield cancel();
  } catch (e) {
    // エラー時の処理
  }
}
