import * as React from "react";
import styled from "styled-components";
import { colorsConst } from "../../styles/const";

const Container = styled.div`
  position: relative;
  width: 100%;
  background: ${colorsConst.BACKGROUND.PRIMARY};
  border-radius: 8px;
`;

const DialogInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-height: calc(
    100vh - (constant(safe-area-inset-top) + constant(safe-area-inset-bottom) + 2em)
  );
  max-height: calc(100vh - (env(safe-area-inset-top) + env(safe-area-inset-bottom) + 2em));
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const DialogHeader = styled.div`
  flex: 0 0 auto;
  padding: 1em;
  text-align: center;
  border-bottom: 1px solid ${colorsConst.BORDER.THIN};
`;

const DialogBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1em;
  text-align: center;
`;

const DialogFooter = styled.div`
  flex: 0 0 auto;
  padding: 1em;
  text-align: center;
`;

type Props = {
  children: React.ReactNode;
};

const Dialog: React.FC<Props> = React.memo(({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
});

export { Dialog, DialogInner, DialogHeader, DialogBody, DialogFooter };
