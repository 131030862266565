export const spaceConst = {
  PADDING: {
    PAGE: {
      HORIZONTAL: {
        DEFAULT: 10,
        TABLET: 60,
        DESKTOP: 60,
      },
      VERTICAL: {
        DEFAULT: 15,
        TABLET: 40,
        DESKTOP: 40,
      },
    },
  },
  GUTTER: {
    INPUT_FIELD: {
      DEFAULT: 20,
      TABLET: 25,
    },
  },
};
