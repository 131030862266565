import * as Yup from "yup";
import { OptionSet } from "../records/OptionSet";

export type ShopOptionRegisterForm = {
  shop_id: number;
  shop_option_set_id: number;
  shop_option_set_name: string;
  shop_option_name: string;
  price: number;
  delivery_price: number;
  option_sort: number;
};

export const initialShopOptionRegisterForm: ShopOptionRegisterForm = {
  shop_id: 0,
  shop_option_set_id: 0,
  shop_option_set_name: "",
  shop_option_name: "",
  price: 0,
  delivery_price: 0,
  option_sort: 0,
};

export const getInitialValues = (shopId: number, optionSet?: OptionSet): ShopOptionRegisterForm => {
  return typeof optionSet !== "undefined"
    ? {
        ...initialShopOptionRegisterForm,
        shop_id: shopId,
        shop_option_set_id: optionSet.option_set_id,
        shop_option_set_name: optionSet.option_set_name,
      }
    : {
        ...initialShopOptionRegisterForm,
        shop_id: shopId,
      };
};

export const shopOptionRegisterFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  shop_option_set_id: Yup.number(),
  shop_option_set_name: Yup.string(),
  shop_option_name: Yup.string().required("入力してください"),
  price: Yup.number().required("入力してください"),
  delivery_price: Yup.number().required("入力してください"),
  option_sort: Yup.number().required("入力してください"),
})
  .defined()
  .strict(true)
  .noUnknown();
