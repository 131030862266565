import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { darken } from "polished";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { media } from "../../styles/mixins/media";
import { spaceConst, fontWeightsConst } from "../../styles/const";
import { Alert as TAlert, AlertType } from "../../records/Alert";
import { systemInitAlert } from "../../modules/app/actions";
import { existsAlertSelector, getAlertSelector } from "../../modules/app/selectors";

const Container = styled.div<{ type: AlertType }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${`1em ${spaceConst.PADDING.PAGE.HORIZONTAL.DEFAULT}px`};
  ${media.tablet} {
    padding: ${`1em ${spaceConst.PADDING.PAGE.HORIZONTAL.TABLET}px`};
  }
  color: ${(p) => darken(0.8, TAlert.getAlertBackgroundColor(p.type))};
  background-color: ${(p) => TAlert.getAlertBackgroundColor(p.type)};
`;

const AlertViewContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const AlertTitle = styled.div`
  margin-bottom: 0.25em;
  font-weight: ${fontWeightsConst.BOLD};
`;

const AlertMessage = styled.div`
  & + & {
    margin-top: 0.25em;
  }
`;

const IconContainer = styled.div``;

type Props = {};

export const Alert: React.FC<Props> = React.memo(({ children }) => {
  const existsAlert = useSelector(existsAlertSelector);
  const alert = useSelector(getAlertSelector);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(systemInitAlert());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <>
      {existsAlert ? (
        <Container type={alert.type}>
          <AlertViewContainer>
            {TAlert.existsTitle(alert) ? <AlertTitle>{alert.title}</AlertTitle> : undefined}
            {TAlert.existsMessage(alert) ? (
              <>
                {Array.isArray(alert.message) ? (
                  <>
                    {alert.message.map((text, index) => (
                      <AlertMessage key={index}>{text}</AlertMessage>
                    ))}
                  </>
                ) : (
                  <AlertMessage>{alert.message}</AlertMessage>
                )}
              </>
            ) : undefined}
          </AlertViewContainer>
          <IconContainer onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconContainer>
        </Container>
      ) : undefined}
    </>
  );
});
