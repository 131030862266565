import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { GetOptionSetListRequest, GetOptionSetListResponse } from "../../../apis/getOptionSetList";
import {
  updateShopItemOptionSetList,
  UserAccessedToPageThatNeedsOptionSetListAction,
} from "../actions";

export function* userAccessedToPageThatNeedsOptionSetListSaga(
  action: UserAccessedToPageThatNeedsOptionSetListAction,
) {
  try {
    const params: GetOptionSetListRequest = { id: action.payload.shopId };
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.GET_OPTION_SET_LIST, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: GetOptionSetListResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopItemOptionSetList(result.option_set_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "商品情報の取得に失敗しました。",
      }),
    );
  }
}
