import React, { useContext } from "react";
import styled from "styled-components";
import { Notes } from "../../atoms/Notes";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import {
  ShopDetailSection,
  ShopDetailRow,
  ShopDetailKey,
  ShopDetailVal,
} from "../../molecules/ShopDetailSection";
import { ShopDetailContext } from "./ShopDetailContext";
import MapViewer from "../MapViewer";

const Container = styled.div``;

type Props = {};

export const ShopLocation: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);
  return (
    <Container>
      <ShopDetailSectionTitle title="店舗所在地" />
      <ShopDetailSection>
        <ShopDetailRow>
          <ShopDetailKey>緯度</ShopDetailKey>
          <ShopDetailVal>{shopData.latitude}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>経度</ShopDetailKey>
          <ShopDetailVal>{shopData.longitude}</ShopDetailVal>
        </ShopDetailRow>
        <ShopDetailRow>
          <ShopDetailKey>
            マップアイコン
            <br />
            <Notes>※アプリ上で表示されるお店の位置です</Notes>
          </ShopDetailKey>
          <ShopDetailVal>
            <MapViewer
              latitude={parseFloat(shopData.latitude)}
              longitude={parseFloat(shopData.longitude)}
            />
          </ShopDetailVal>
        </ShopDetailRow>
      </ShopDetailSection>
    </Container>
  );
});
