import * as React from "react";
import styled from "styled-components";
import { media } from "../../styles/mixins";
import { colorsConst, zIndexConst } from "../../styles/const";

const Wrapper = styled("div")<{
  isOpen: boolean;
}>`
  position: fixed;
  z-index: ${zIndexConst.OVERLAY + 1};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${(p) => (p.isOpen ? "flex" : "none")};
  opacity: ${(p) => (p.isOpen ? "1" : "0")};
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.15s linear;
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: ${zIndexConst.OVERLAY + 1};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colorsConst.BACKGROUND.OVERLAY};
`;

const Content = styled.div`
  position: relative;
  z-index: ${zIndexConst.OVERLAY + 2};
  width: 100%;
  padding: calc(1em + constant(safe-area-inset-top)) 1em
    calc(1em + constant(safe-area-inset-bottom));
  padding: calc(1em + env(safe-area-inset-top)) 1em calc(1em + env(safe-area-inset-bottom));
  ${media.tablet} {
    margin-right: auto;
    margin-left: auto;
    max-width: 500px;
  }
`;

type Props = {
  isOpen: boolean;
  handleClickBackdrop?: (event: React.MouseEvent<HTMLElement>) => void;
  contentStyle?: React.CSSProperties;
};

export const Modal: React.FC<Props> = React.memo(
  ({ isOpen, handleClickBackdrop, contentStyle, children }) => {
    return (
      <Wrapper isOpen={isOpen}>
        <Backdrop onClick={handleClickBackdrop} />
        <Content style={contentStyle}>{children}</Content>
      </Wrapper>
    );
  },
);
