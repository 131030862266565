import * as Yup from "yup";
import { passwordValidation } from "./validations";

export type ShopStaffRegisterForm = {
  shop_id: number;
  display_name: string;
  login_id: string;
  password: string;
};

export const initialShopStaffRegisterForm: ShopStaffRegisterForm = {
  shop_id: 0,
  display_name: "",
  login_id: "",
  password: "",
};

export const getInitialValues = (shopId: number): ShopStaffRegisterForm => {
  return {
    ...initialShopStaffRegisterForm,
    shop_id: shopId,
  };
};

export const shopStaffRegisterFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  display_name: Yup.string().required("入力してください"),
  login_id: Yup.string().required("入力してください"),
  password: passwordValidation,
})
  .defined()
  .strict(true)
  .noUnknown();
