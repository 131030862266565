import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Utility } from "../../utils/Utility";
import { DateUtils } from "../../utils/DateUtils";
import { dailySalesSelector, existsDailySalesSelector } from "../../modules/sales/selectors";
import { userTouchedUpdateDailySalesButton } from "../../modules/sales/actions";
import { shopListSelector, existsShopListSelector } from "../../modules/shop/selectors";
import { userAccessedToPageThatNeedsShopList } from "../../modules/shop/actions";
import { colorsConst } from "../../styles/const";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import {
  SalesReportContainer,
  SalesReportHeader,
  SalesReportHeaderRow,
  SalesReportHeaderKey,
  SalesReportHeaderInput,
  SalesReportBody,
  SalesReportTable,
  SalesReportRow,
  RowHeadColumn,
  SalesColumn,
  SalesHeader,
  SalesRow,
  SalesCell,
  SalesVal,
} from "../atoms/SalesReport";
import { Button } from "../atoms/Button";
import { Input, Select } from "../atoms/FormParts/TextInput";
import { Auth } from "../organisms/Auth";

const INITIAL_DATE = new Date();
INITIAL_DATE.setDate(INITIAL_DATE.getDate() - 1);
const INITIAL_DATE_STRING = DateUtils.getYearMonthString(INITIAL_DATE);
const INITIAL_SHOP_ID = -1;

const DateInput = styled(Input)`
  border: 1px solid ${colorsConst.BORDER.THICK};
`;
const ShopSelect = styled(Select)`
  border: 1px solid ${colorsConst.BORDER.THICK};
`;
const ButtonContainer = styled.div``;

type Props = {};

export const DailySalesReportTemplate: React.FC<Props> = React.memo(() => {
  const shopList = useSelector(shopListSelector);
  const existsShopList = useSelector(existsShopListSelector);
  const dailySales = useSelector(dailySalesSelector);
  const existsDailySales = useSelector(existsDailySalesSelector);

  const [draftTargetMonth, setDraftTargetMonth] = useState<string>(INITIAL_DATE_STRING);
  const [draftTargetShopId, setDraftTargetShopId] = useState<number>(INITIAL_SHOP_ID);

  const canSubmit = useMemo(() => {
    return draftTargetMonth !== "" && draftTargetShopId !== 0;
  }, [draftTargetMonth, draftTargetShopId]);

  const dispatch = useDispatch();

  const handleMonthChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDraftTargetMonth(event.target.value);
  }, []);

  const handleShopChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setDraftTargetShopId(parseInt(event.target.value, 10));
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(userTouchedUpdateDailySalesButton(draftTargetMonth, draftTargetShopId));
  }, [dispatch, draftTargetMonth, draftTargetShopId]);

  const initDailySales = useCallback(() => {
    dispatch(userTouchedUpdateDailySalesButton(INITIAL_DATE_STRING, INITIAL_SHOP_ID));
  }, [dispatch]);

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopList());
  }, [dispatch]);

  useEffect(() => {
    initDailySales();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Auth>
      <Page header>
        <PageTitle>日別レポート</PageTitle>
        <SalesReportContainer>
          <SalesReportHeader>
            <SalesReportHeaderRow>
              <SalesReportHeaderKey>集計月</SalesReportHeaderKey>
              <SalesReportHeaderInput>
                <DateInput type="month" onChange={handleMonthChange} value={draftTargetMonth} />
              </SalesReportHeaderInput>
            </SalesReportHeaderRow>
            <SalesReportHeaderRow>
              <SalesReportHeaderKey>店舗</SalesReportHeaderKey>
              <SalesReportHeaderInput>
                <ShopSelect onChange={handleShopChange} value={draftTargetShopId}>
                  <option value="-1">全店舗</option>
                  {existsShopList &&
                    shopList.map((shop) => (
                      <option key={shop.id} value={shop.id}>
                        {shop.shop_name}
                      </option>
                    ))}
                </ShopSelect>
              </SalesReportHeaderInput>
            </SalesReportHeaderRow>
            <ButtonContainer>
              <Button
                onClick={handleSubmit}
                appearance="primary"
                display="responsive"
                disabled={!canSubmit}
              >
                集計
              </Button>
            </ButtonContainer>
          </SalesReportHeader>
          <SalesReportBody>
            {existsDailySales ? (
              <SalesReportTable>
                <SalesReportRow>
                  <RowHeadColumn header>日付</RowHeadColumn>
                  <SalesColumn>
                    <SalesHeader>取扱高</SalesHeader>

                    <SalesRow>
                      <SalesCell header>テイクアウト</SalesCell>
                      <SalesCell header>デリバリー</SalesCell>
                      <SalesCell header>合計</SalesCell>
                    </SalesRow>
                  </SalesColumn>
                </SalesReportRow>

                <SalesReportRow>
                  <RowHeadColumn>合計</RowHeadColumn>
                  <SalesColumn>
                    <SalesRow>
                      <SalesCell data-label="デリバリー">
                        <SalesVal>
                          {Utility.getPresentationPrice(dailySales.sales.total.delivery)}
                        </SalesVal>
                      </SalesCell>
                      <SalesCell data-label="テイクアウト">
                        <SalesVal>
                          {Utility.getPresentationPrice(dailySales.sales.total.takeout)}
                        </SalesVal>
                      </SalesCell>
                      <SalesCell data-label="合計">
                        <SalesVal>
                          {Utility.getPresentationPrice(dailySales.sales.total.total)}
                        </SalesVal>
                      </SalesCell>
                    </SalesRow>
                  </SalesColumn>
                </SalesReportRow>
                {dailySales.sales.reports.map((report) => (
                  <SalesReportRow key={report.day}>
                    <RowHeadColumn>{DateUtils.getMonthDayString(report.day)}</RowHeadColumn>
                    <SalesColumn>
                      <SalesRow>
                        <SalesCell data-label="デリバリー">
                          <SalesVal>{Utility.getPresentationPrice(report.delivery)}</SalesVal>
                        </SalesCell>
                        <SalesCell data-label="テイクアウト">
                          <SalesVal>{Utility.getPresentationPrice(report.takeout)}</SalesVal>
                        </SalesCell>
                        <SalesCell data-label="合計">
                          <SalesVal>{Utility.getPresentationPrice(report.total)}</SalesVal>
                        </SalesCell>
                      </SalesRow>
                    </SalesColumn>
                  </SalesReportRow>
                ))}
              </SalesReportTable>
            ) : (
              <div>レポートがありません</div>
            )}
          </SalesReportBody>
        </SalesReportContainer>
      </Page>
    </Auth>
  );
});
