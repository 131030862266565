import React, { useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { colorsConst } from "../../styles/const";
import { media } from "../../styles/mixins/media";
import { API_KEY } from "../../apis";
import { isConnectedApiSelectorFactory } from "../../modules/app/selectors";
import {
  userAccessedToPageThatNeedsShopStaffList,
  userSubmittedUpdateShopStaffPassword,
  userSubmittedDeleteShopStaff,
} from "../../modules/shop/actions";
import { shopStaffListSelector, existsShopStaffSelector } from "../../modules/shop/selectors";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { BackButtonContainer } from "../molecules/BackButtonContainer";
import { Auth } from "../organisms/Auth";
import { LinkButton } from "../atoms/LinkButton";
import { LoadingContainer } from "../molecules/LoadingContainer";
import { ShopStaffListItem } from "../molecules/ShopStaffListItem";

const Container = styled.div`
  ${media.tablet} {
    width: 100%;
    margin-top: 1em;
    padding: 1em;
    background-color: ${colorsConst.BACKGROUND.PRIMARY};
    border-radius: 4px;
    overflow: auto;
  }
`;
const Table = styled.div`
  display: table;
  width: 100%;
`;
const TableHeader = styled.div`
  display: none;
  ${media.tablet} {
    display: table-header-group;
  }
`;
const TableHeaderRow = styled.div`
  ${media.tablet} {
    display: table-row;
  }
`;
const TableBody = styled.div`
  ${media.tablet} {
    display: table-row-group;
  }
`;
const TableCell = styled.div`
  ${media.tablet} {
    display: table-cell;
  }
  padding: 0.25em;
  white-space: nowrap;
`;
const AccountName = styled(TableCell)`
  ${media.tablet} {
    width: 6em;
  }
`;
const LoginId = styled(TableCell)`
  ${media.tablet} {
    width: 100%;
  }
`;
const Password = styled(TableCell)`
  ${media.tablet} {
    width: 12em;
  }
`;
const Delete = styled(TableCell)``;

const loadingSelector = isConnectedApiSelectorFactory(API_KEY.GET_SHOP_STAFF_LIST);

type Props = RouteComponentProps<{ shopId: string }> & {
  children?: never;
};

const ShopStaffTemplate: React.FC<Props> = React.memo(({ match }) => {
  const shopId = parseInt(match.params.shopId, 10);
  const shopStaffList = useSelector(shopStaffListSelector);
  const existsShopStaff = useSelector(existsShopStaffSelector);
  const loading = useSelector(loadingSelector);

  const dispatch = useDispatch();

  const handleBack = useCallback(() => {
    dispatch(push(`/shopDetail/${shopId}`));
  }, [dispatch, shopId]);

  const handleSubmitPassward = useCallback(
    (staffId: number, passward: string) => {
      dispatch(userSubmittedUpdateShopStaffPassword(shopId, staffId, passward));
    },
    [dispatch, shopId],
  );

  const handleDelete = useCallback(
    (staffId: number) => {
      dispatch(userSubmittedDeleteShopStaff(shopId, staffId));
    },
    [dispatch, shopId],
  );

  useEffect(() => {
    dispatch(userAccessedToPageThatNeedsShopStaffList(shopId));
  }, [dispatch, shopId]);

  return (
    <Auth>
      <Page header>
        <BackButtonContainer linkText="店舗詳細に戻る" handleClick={handleBack} />
        <PageTitle>ログインアカウント情報</PageTitle>
        <LinkButton to={`/shopStaffRegister/${shopId}`} appearance="primary" display="responsive">
          ＋アカウント追加
        </LinkButton>
        {loading ? (
          <LoadingContainer />
        ) : (
          <>
            {existsShopStaff ? (
              <Container>
                <Table>
                  <TableHeader>
                    <TableHeaderRow>
                      <AccountName>アカウント名</AccountName>
                      <LoginId>ログインID</LoginId>
                      <Password>パスワード変更</Password>
                      <Delete>削除</Delete>
                    </TableHeaderRow>
                  </TableHeader>
                  <TableBody>
                    {shopStaffList.map((staff) => (
                      <ShopStaffListItem
                        key={staff.id}
                        shopStaff={staff}
                        handleSubmitPassward={handleSubmitPassward}
                        handleDelete={handleDelete}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Container>
            ) : (
              <div>スタッフが存在しません</div>
            )}
          </>
        )}
      </Page>
    </Auth>
  );
});

export default withRouter(ShopStaffTemplate);
