import styled, { css } from "styled-components";
import { fontFamilyConst } from "../../../styles/const";
import { fontSize } from "../../../styles/mixins";

export const inputStyle = css`
  font-family: ${fontFamilyConst};
  ${fontSize.DEFAULT};
  width: 100%;
  height: auto;
  padding: 2px;
  border: none;
  background-color: inherit;
`;

export const Input = styled.input`
  ${inputStyle};
  min-height: 1.5em;
`;

export const Select = styled.select`
  ${inputStyle};
  border: 0;
  padding: 2px;
`;

export const Textarea = styled.textarea`
  ${inputStyle};
  min-height: 3em;
`;
