import * as Yup from "yup";

export type ShopItemCategoryRegisterForm = {
  shop_id: number;
  category_name: string;
  category_name_en: string;
};

export const initialShopItemCategoryRegisterForm: ShopItemCategoryRegisterForm = {
  shop_id: 0,
  category_name: "",
  category_name_en: "",
};

export const getInitialValues = (shop_id: number): ShopItemCategoryRegisterForm => {
  return {
    ...initialShopItemCategoryRegisterForm,
    shop_id,
  };
};

export const shopItemCategoryRegisterFormValidationSchema = Yup.object({
  shop_id: Yup.number(),
  category_name: Yup.string().required("入力してください"),
  category_name_en: Yup.string(),
})
  .defined()
  .strict(true)
  .noUnknown();
