import produce from "immer";
import { UserData } from "../../records/UserData";
import { LocalStorage } from "../../utils/LocalStorage";

export type UserState = Readonly<{
  loginToken: string | null;
  userData: UserData;
}>;

const initialState: UserState = {
  loginToken: null,
  userData: UserData.create(),
};

const getLoginToken = (state: UserState) => {
  if (LocalStorage.hasItem("loginToken")) {
    return LocalStorage.getItem("loginToken");
  }
  return state.loginToken;
};

const updateLoginToken = (state: UserState, value: string | null) => {
  if (value === null) {
    LocalStorage.removeItem("loginToken");
  } else {
    LocalStorage.setItem("loginToken", value);
  }
  return produce(state, (draft) => {
    draft.loginToken = value;
  });
};

const updateUserData = (state: UserState, value: UserData) => {
  return produce(state, (draft) => {
    draft.userData = UserData.create(value);
  });
};

const isLoggedIn = (state: UserState) => {
  return state.userData.id !== "";
};

export const UserModel = {
  initialState,
  getLoginToken,
  updateLoginToken,
  updateUserData,
  isLoggedIn,
};
