import React from "react";
import styled from "styled-components";
import { media } from "../../styles/mixins/media";
import { colorsConst, spaceConst } from "../../styles/const";
import { LoadingOverlay } from "../molecules/LoadingOverlay";
import { Header } from "../organisms/Header";
import { Alert } from "../organisms/Alert";
import { ShouldUpdateResourceModal } from "../organisms/ShouldUpdateResourceModal";

const Container = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  background-color: ${colorsConst.BACKGROUND.SECONDARY};
  ${media.desktop} {
    flex-direction: row;
  }
`;

const ContentsContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
`;

const AlertContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

const Contents = styled.div<{ canScroll: boolean }>`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  ${(p) =>
    !p.canScroll
      ? `
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  `
      : ""}/* 
*/
`;

const ScrollContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${`${spaceConst.PADDING.PAGE.VERTICAL.DEFAULT}px ${spaceConst.PADDING.PAGE.HORIZONTAL.DEFAULT}px`};
  ${media.tablet} {
    padding: ${`${spaceConst.PADDING.PAGE.VERTICAL.TABLET}px ${spaceConst.PADDING.PAGE.HORIZONTAL.TABLET}px`};
  }
  ${media.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`;

const ContentsInner = styled.div`
  max-width: 768px;
  margin-right: auto;
  ${media.mobile} {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }
`;

type Props = {
  header?: boolean;
  children?: React.ReactNode;
  loadingOverlay?: boolean;
  canScroll?: boolean;
};

export const Page: React.FC<Props> = React.memo(
  ({ header, children, loadingOverlay, canScroll = true, ...rest }) => {
    return (
      <Container {...rest}>
        {header && <Header />}
        <ContentsContainer>
          <AlertContainer>
            <Alert />
          </AlertContainer>
          <Contents canScroll={canScroll}>
            {canScroll ? (
              <ScrollContainer>
                <ContentsInner>{children}</ContentsInner>
              </ScrollContainer>
            ) : (
              <>{children}</>
            )}
          </Contents>
        </ContentsContainer>
        {typeof loadingOverlay !== "undefined" ? (
          <LoadingOverlay active={loadingOverlay} />
        ) : undefined}
        <ShouldUpdateResourceModal />
      </Container>
    );
  },
);
