import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { userSubmittedDeleteBusinessHoliday } from "../../../modules/shop/actions";
import { ShopBusiness } from "../../../records/ShopBusiness";
import { ShopBusinessContext } from "./ShopBusinessContext";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import { ShopDetailSection, ShopDetailRow } from "../../molecules/ShopDetailSection";
import { HolidayRow } from "./HolidayRow";

const Container = styled.div``;

type Props = {};

export const Holiday: React.FC<Props> = React.memo(() => {
  const { shopId, shopBusiness } = useContext(ShopBusinessContext);

  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (holiday: string) => {
      dispatch(userSubmittedDeleteBusinessHoliday(shopId, holiday));
    },
    [dispatch, shopId],
  );
  return (
    <Container>
      <ShopDetailSectionTitle title="臨時休業日等の設定" link={`/businessHolidayEdit/${shopId}`} />
      {ShopBusiness.existsHoliday(shopBusiness.holiday_list) ? (
        <ShopDetailSection>
          {shopBusiness.holiday_list.map((holiday) => (
            <ShopDetailRow key={holiday}>
              <HolidayRow holiday={holiday} handleClick={handleDelete} />
            </ShopDetailRow>
          ))}
        </ShopDetailSection>
      ) : undefined}
    </Container>
  );
});
