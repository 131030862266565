import React, { useContext } from "react";
import styled from "styled-components";
import { ShopData } from "../../../records/ShopData";
import { ShopDetailContext } from "./ShopDetailContext";
import { colorsConst, fontWeightsConst } from "../../../styles/const";

const Container = styled.div`
  margin-bottom: 1em;
  background-color: #fffce2;
  padding: 5px 10px;
`;

const Title = styled.div`
  color: ${colorsConst.TEXT.DANGER};
  font-weight: ${fontWeightsConst.BOLD};
`;

const Text = styled.div`
  font-weight: ${fontWeightsConst.BOLD};
`;

type Props = {};

export const ShopRejectReason: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);
  return (
    <>
      {ShopData.isDisplayableRejectReason(shopData) ? (
        <Container>
          <Title>店舗情報の申請が差し戻しされました。</Title>
          <Text>{shopData.reject_reason}</Text>
        </Container>
      ) : undefined}
    </>
  );
});
