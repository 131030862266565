import React, { useContext } from "react";
import styled from "styled-components";
import { ShopDetailContext } from "./ShopDetailContext";
import { BusinessTimeList } from "../../molecules/BusinessTimeList";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import { ShopDetailSection } from "../../molecules/ShopDetailSection";

const Container = styled.div``;

type Props = {};

export const ShopBusinessTime: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);
  return (
    <Container>
      <ShopDetailSectionTitle title="営業時間" link={`/shopBusiness/${shopData.id}`} />
      <ShopDetailSection>
        <BusinessTimeList weekTimes={shopData.shop_business.business_week_time} />
      </ShopDetailSection>
    </Container>
  );
});
