import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { media } from "../../styles/mixins/media";
import { sizesConst, zIndexConst, colorsConst } from "../../styles/const";
import { Logo } from "../atoms/Logo";
import { Navigation } from "./Navigation";

const Container = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
`;

const HeaderLogoContainer = styled.div`
  padding: 12px 0;
  ${media.desktop} {
    display: none;
  }
`;

const NavigationContainer = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: ${zIndexConst.ABSOLUTE};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: ${`${sizesConst.NAVIGATION_WIDTH * -1}px`};
  ${(p) => (p.active ? `margin-left: 0;` : "")}
  width: 254px;
  height: 100%;
  padding-top: 100px;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
  transition: margin-left 250ms ease;
  ${media.desktop} {
    position: static;
    margin-left: 0;
    padding-top: 0;
    transition: none;
  }
`;

const NavigationButtonContainer = styled.button`
  position: absolute;
  z-index: ${zIndexConst.ABSOLUTE + 1};
  top: 5px;
  left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 6px;
  border: none;
  background: none;
  appearance: none;
  ${media.desktop} {
    display: none;
  }
`;

const NavigationIconContainer = styled.div`
  font-size: 2rem;
`;

const NavigationButtonText = styled.div`
  font-size: 1.2rem;
`;

const NavigationLogoContainer = styled.div`
  display: none;
  ${media.desktop} {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px 0;
    display: block;
  }
`;

const Backdrop = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: ${zIndexConst.ABSOLUTE - 1};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${(p) => (p.active ? "block" : "none")};
  background-color: rgba(0, 0, 0, 0.5);
  ${media.desktop} {
    display: none !important;
  }
`;

type Props = {};

export const Header: React.FC<Props> = React.memo(() => {
  const [active, setActive] = useState<boolean>(false);

  const handleToggleMenu = useCallback(() => {
    setActive(!active);
  }, [active, setActive]);

  const handleCloseMenu = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <Container>
      <HeaderLogoContainer>
        <Logo size={90} fontSize={10} />
      </HeaderLogoContainer>
      <NavigationButtonContainer onClick={handleToggleMenu}>
        <NavigationIconContainer>
          {active ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
        </NavigationIconContainer>
        <NavigationButtonText>メニュー</NavigationButtonText>
      </NavigationButtonContainer>
      <NavigationContainer active={active}>
        <NavigationLogoContainer>
          <Logo size={120} fontSize={12} />
        </NavigationLogoContainer>
        <Navigation handleClose={handleCloseMenu} />
      </NavigationContainer>
      <Backdrop active={active} onClick={handleCloseMenu} />
    </Container>
  );
});
