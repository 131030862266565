export type CategoryTag = Readonly<{
  id: number;
  tag_name: string;
  image_path: string;
}>;

const initialState: CategoryTag = {
  id: 0,
  tag_name: "",
  image_path: "",
};

const create = (args: Partial<CategoryTag> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const CategoryTag = Object.freeze({
  initialState,
  create,
});
