import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { OptionSet } from "../../records/OptionSet";
import { ShopItemCategory } from "../../records/ShopItemCategory";
import { ShopItemEditForm } from "../../forms/shopItemEdit";
import { ShopItemRegisterForm } from "../../forms/shopItemRegister";
import { Text } from "../atoms/Text";
import { Paragraph } from "../atoms/Paragraph";
import { FormFieldGroup } from "../atoms/FormParts/FormFieldGroup";
import { FormNotice } from "../atoms/FormParts/FormNotice";
import { FormField } from "../atoms/FormParts/FormField";
import { FormLabel } from "../atoms/FormParts/FormLabel";
import { TextField } from "../atoms/FormParts/TextField";
import { TextareaField } from "../atoms/FormParts/TextareaField";
import { SelectField } from "../atoms/FormParts/SelectField";
import { UploadButton } from "../atoms/FormParts/UploadButton";
import { ImageField } from "../molecules/ImageField";

const REG_EXP_STR = "【アルコール】";
const REG_EXP = new RegExp(`^${REG_EXP_STR}`);

const UploadButtonContainer = styled.div`
  margin-bottom: 1em;
`;
const ImageFieldContainer = styled.div`
  margin-bottom: 1em;
`;

type Props = {
  shopId: number;
  shopItemCategoryList: ShopItemCategory[];
  existsShopItemOptionSet: boolean;
  optionSetList: OptionSet[];
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  values: ShopItemRegisterForm | ShopItemEditForm;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  handleChange: (event: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  /* eslint-enable  @typescript-eslint/no-explicit-any */
  handleRequireCookingCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ShopItemFormFields: React.FC<Props> = React.memo(
  ({
    shopId,
    shopItemCategoryList,
    existsShopItemOptionSet,
    optionSetList,
    handleFileChange,
    values,
    handleChange,
    setFieldValue,
    handleRequireCookingCheckChange,
  }) => {
    const handleCheckChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(event.target.name, event.target.checked ? 1 : 0);
      },
      [setFieldValue],
    );

    const handleAlcoholCheckChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          if (!REG_EXP.test(values.item_name)) {
            setFieldValue("item_name", REG_EXP_STR + values.item_name);
          }
        } else {
          setFieldValue("item_name", values.item_name.replace(REG_EXP, ""));
        }
        setFieldValue(event.target.name, event.target.checked ? 1 : 0);
      },
      [values, setFieldValue],
    );

    return (
      <>
        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="shop_item_category_id" required>
              商品カテゴリ
            </FormLabel>
            <SelectField
              isNum
              name="shop_item_category_id"
              id="shop_item_category_id"
              value={values.shop_item_category_id}
            >
              <option value={0}>選択してください</option>
              {shopItemCategoryList.map((category) => (
                <option key={category.category_id} value={category.category_id}>
                  {category.category_name}
                </option>
              ))}
            </SelectField>
          </FormField>
          <FormNotice>
            商品は商品カテゴリで分かれて表示されます。
            <br />
            選択肢を増やしたい場合は「
            <Link to={`/shopItemCategory/${shopId}`}>商品カテゴリ一覧</Link>
            」で追加する必要があります。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="item_name" required>
              商品名
            </FormLabel>
            <TextField
              type="text"
              name="item_name"
              id="item_name"
              onChange={handleChange}
              value={values.item_name}
            />
          </FormField>
          <FormNotice>
            都道府県から正しくご入力をお願いいたします。誤った住所を入力すると、公開が大きく遅れる場合がございます。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <Paragraph>この商品は以下に当てはまる場合は、チェックをしてください。</Paragraph>
          <div>
            <label htmlFor="is_alcohol">
              <input
                type="checkbox"
                name="is_alcohol"
                id="is_alcohol"
                onChange={handleAlcoholCheckChange}
                checked={values.is_alcohol === 1}
              />
              アルコール商品が含まれている
            </label>
          </div>
          <div>
            <label htmlFor="is_meat">
              <input
                type="checkbox"
                name="is_meat"
                id="is_meat"
                onChange={handleCheckChange}
                checked={values.is_meat === 1}
              />
              生肉商品が含まれている
            </label>
          </div>
          <div>
            <label htmlFor="require_cooking">
              <input
                type="checkbox"
                id="require_cooking"
                onChange={handleRequireCookingCheckChange}
              />
              調理が必要（どんな調理が必要か説明をお願いします）
            </label>
          </div>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="description" required>
              商品説明
            </FormLabel>
            <TextareaField
              name="description"
              id="description"
              onChange={handleChange}
              value={values.description}
            />
          </FormField>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="price" required>
              テイクアウト価格
            </FormLabel>
            <TextField
              type="number"
              name="price"
              id="price"
              onChange={handleChange}
              value={values.price}
            />
          </FormField>
          <FormNotice>
            こちらは必ず<Text textColor="DANGER">店内価格と同等かそれ以下</Text>
            で設定してください。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="delivery_price" required>
              デリバリー価格
            </FormLabel>
            <TextField
              type="number"
              name="delivery_price"
              id="delivery_price"
              onChange={handleChange}
              value={values.delivery_price}
            />
          </FormField>
          <FormNotice>
            他デリバリーサービスをご利用の場合、
            <Text textColor="DANGER">他サービスでの価格と同等かそれ以下</Text>
            で設定してください。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="options">オプションセット</FormLabel>
            {existsShopItemOptionSet ? (
              <>
                {optionSetList.map((optionSet) => (
                  <div key={optionSet.option_set_id}>
                    <label htmlFor={`optionSet-${optionSet.option_set_id}`}>
                      <input
                        type="checkbox"
                        name="options[]"
                        id={`optionSet-${optionSet.option_set_id}`}
                        onChange={handleChange}
                        value={optionSet.option_set_id}
                      />
                      {optionSet.option_set_name}
                    </label>
                  </div>
                ))}
              </>
            ) : (
              <div>オプションは存在しません。</div>
            )}
          </FormField>
          <FormNotice>
            選択肢を増やしたい場合は「
            <Link to={`/shopOptionSet/${shopId}`}>オプション一覧</Link>
            」で追加する必要があります。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <FormField>
            <FormLabel htmlFor="cooking_time" required>
              単品調理時間（分）
            </FormLabel>
            <TextField
              type="number"
              name="cooking_time"
              id="cooking_time"
              onChange={handleChange}
              value={values.cooking_time}
            />
          </FormField>
          <FormNotice>
            この商品が単品で注文された場合の調理時間です。未入力の場合は平均調理時間が設定されます。
          </FormNotice>
        </FormFieldGroup>

        <FormFieldGroup>
          <UploadButtonContainer>
            <UploadButton htmlFor="upload_field">
              <input
                type="file"
                id="upload_field"
                name="upload_field"
                accept="image/*"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
              />
              画像をアップロードする
            </UploadButton>
          </UploadButtonContainer>
          <ImageFieldContainer>
            <ImageField imagePath={values.image_path} />
          </ImageFieldContainer>
          <FormField>
            <TextField type="text" name="image_path" value={values.image_path} readOnly />
          </FormField>
          <FormNotice>
            ファイル選択を行うと自動で入力されます。変更の必要はございません。
          </FormNotice>
        </FormFieldGroup>
      </>
    );
  },
);
