import React, { useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { shopStaffListSelector, existsShopStaffSelector } from "../../../modules/shop/selectors";
import { ShopDetailSectionTitle } from "../../molecules/ShopDetailSectionTitle";
import { ShopDetailContext } from "./ShopDetailContext";
import { ShopDetailSection, ShopDetailRow, ShopDetailVal } from "../../molecules/ShopDetailSection";

const Container = styled.div``;

type Props = {};

export const ShopStaff: React.FC<Props> = React.memo(() => {
  const { shopData } = useContext(ShopDetailContext);
  const shopStaffList = useSelector(shopStaffListSelector);
  const existsShopStaff = useSelector(existsShopStaffSelector);
  return (
    <Container>
      <ShopDetailSectionTitle title="ログインアカウント情報" link={`/shopStaff/${shopData.id}`} />
      {existsShopStaff ? (
        <ShopDetailSection>
          {shopStaffList.map((staff) => (
            <ShopDetailRow key={staff.login_id}>
              <ShopDetailVal>
                <div>{staff.login_id}</div>
                <div>{staff.display_name}</div>
              </ShopDetailVal>
            </ShopDetailRow>
          ))}
        </ShopDetailSection>
      ) : (
        <div>ログインアカウントがありません。</div>
      )}
    </Container>
  );
});
