import React from "react";
import { Page } from "../atoms/Page";
import { PageTitle } from "../atoms/PageTitle";
import { Auth } from "../organisms/Auth";
import qrAndroid from "../../assets/qr/menu_shop_android.png";
import qrIos from "../../assets/qr/menu_shop_ios.png";

type Props = {};

export const ShopApplicationLinkTemplate: React.FC<Props> = React.memo(() => {
  return (
    <Auth>
      <Page header>
        <PageTitle>店舗アプリのインストール</PageTitle>
        <p>以下から店舗アプリのインストールをお願いします。</p>
        <div>
          <p>
            iOSは
            <a
              href="https://apps.apple.com/jp/app/menu-%E5%8A%A0%E7%9B%9F%E5%BA%97%E7%94%A8/id1443470371"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
          </p>
          <img src={qrIos} alt="" />
        </div>
        <div>
          <p>
            Androidは
            <a
              href="https://play.google.com/store/apps/details?id=co.tapgo.shop&amp;hl=ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
          </p>
          <img src={qrAndroid} alt="" />
        </div>
      </Page>
    </Auth>
  );
});
