export const API_KEY = {
  LOGIN: "login",
  GET_USER_DATA: "getUserData",
  GET_SHOP_LIST: "getShopList",
  GET_SHOP_DATA: "getShopData",
  GET_SHOP_ITEM: "getShopItem",
  GET_CATEGORY_TAG_LIST: "getCategoryTagList",
  GET_OPTION_SET_LIST: "getOptionSetList",
  SHOP_JUDGE_REQUEST: "shopJudgeRequest",
  UPDATE_SHOP_DATA: "updateShopData",
  UPDATE_SHOP_ITEM_CATEGORY: "updateShopItemCategory",
  UPDATE_SHOP_ITEM: "updateShopItem",
  UPDATE_SHOP_ITEM_RELEASE_STATE: "updateShopItemReleaseState",
  UPDATE_SHOP_OPTION_SET: "updateShopOptionSet",
  UPDATE_SHOP_OPTION: "updateShopOption",
  UPDATE_COOKING_TIME: "updateCookingTime",
  UPDATE_BUSINESS_TIME: "updateBusinessTime",
  UPDATE_BUSINESS_HOLIDAY: "updateBusinessHoliday",
  DELETE_BUSINESS_HOLIDAY: "deleteBusinessHoliday",
  UPDATE_BANK_ACCOUNT: "updateBankAccount",
  GET_DAILY_SALES: "getDailySales",
  GET_MONTHLY_SALES: "getMonthlySales",
  GET_ORDER_SALES: "getOrderSales",
  GET_BANK_INFO: "getBankInfo",
  SORT_SHOP_ITEM: "sortShopItem",
  SORT_SHOP_ITEM_CATEGORY: "sortShopItemCategory",
  SORT_SHOP_OPTION_SET: "sortShopOptionSet",
  GET_SHOP_STAFF_LIST: "getShopStaffList",
  UPDATE_SHOP_STAFF_PASSWORD: "updateShopStaffPassword",
  REGISTER_SHOP_STAFF: "registerShopStaff",
  DELETE_SHOP_STAFF: "deleteShopStaff",
};

export type ApiKey = ValueOf<typeof API_KEY>;
