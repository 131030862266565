import React, { useCallback } from "react";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { DateUtils } from "../../utils/DateUtils";
import { ShopData, displayableDraftingStateType } from "../../records/ShopData";
import { userTouchedShopJudgeRequestButton } from "../../modules/shop/actions";
import { ShopDraftStateProgress } from "../molecules/ShopDraftStateProgress";
import { Button } from "../atoms/Button";
import { Text } from "../atoms/Text";
import { Notice } from "../atoms/Notice";
import { colorsConst } from "../../styles/const";
import { media } from "../../styles/mixins";

const Container = styled.div`
  margin-top: 1em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
`;

const ShopListItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.25em 1em;
  background-color: ${colorsConst.BACKGROUND.DARK};
`;

const ShopListItemInner = styled.div`
  padding: 0.5em 1em 1em;
`;

const ShopDraftStateProgressContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const RejectReasonContainer = styled.div`
  margin-top: 1em;
`;

const ShopDraftStateHead = styled.div`
  position: relative;
  height: 1px;
  background-color: ${colorsConst.BORDER.DEFAULT};
`;

const ShopDraftStateHeadText = styled.div`
  position: absolute;
  top: -0.75em;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  padding: 0 0.5em;
  background-color: ${colorsConst.BACKGROUND.PRIMARY};
`;

const DraftingStateButtonList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0.5em;
`;

const DraftingStateButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 0.5em;
  margin-right: 0.5em;
`;

const ButtonContainer = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const ButtonListContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const ButtonListItem = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  & + & {
    margin-left: 0.5em;
  }
  ${media.tablet} {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
const NoticeContainer = styled(Notice)`
  margin-bottom: 1em;
`;

type Props = {
  shop: ShopData;
};

export const ShopListItem: React.FC<Props> = React.memo(({ shop }) => {
  const dispatch = useDispatch();

  const handleLink = useCallback(
    (url: string) => {
      dispatch(push(url));
    },
    [dispatch],
  );

  const handleRequestJudge = useCallback(() => {
    dispatch(userTouchedShopJudgeRequestButton(shop.id));
  }, [dispatch, shop]);

  return (
    <Container>
      <ShopListItemHeader>
        <div>
          <Text size="SMALL" textColor="TERTIARY">
            {`店舗ID `}
          </Text>
          <Text size="SMALL" textColor="WHITE">{`${shop.id}`}</Text>
        </div>
        <div>
          {ShopData.isNotLoggedIn(shop.latest_access) ? (
            <Text size="SMALL" textColor="DANGER">
              {`最終アクセス ${
                ShopData.isNotLoggedIn(shop.latest_access)
                  ? "未ログイン"
                  : DateUtils.getDateString(shop.latest_access)
              }`}
            </Text>
          ) : (
            <>
              <Text size="SMALL" textColor="TERTIARY">
                {`最終アクセス `}
              </Text>
              <Text size="SMALL" textColor="WHITE">
                {DateUtils.getDateString(shop.latest_access)}
              </Text>
            </>
          )}
        </div>
      </ShopListItemHeader>
      <ShopListItemInner>
        <Text size="LARGE" textColor="PRIMARY" weight="BOLD">
          {shop.shop_name}
        </Text>
      </ShopListItemInner>
      {ShopData.isNotCompleteReleaseCondition(shop.draft_state) && (
        <>
          <ShopDraftStateHead>
            <ShopDraftStateHeadText>
              <Text size="SMALL" textColor="SECONDARY">
                入稿ステータス
              </Text>
            </ShopDraftStateHeadText>
          </ShopDraftStateHead>

          <ShopListItemInner>
            <ShopDraftStateProgressContainer>
              <ShopDraftStateProgress state={shop.draft_state} />
            </ShopDraftStateProgressContainer>

            {ShopData.isDisplayableRejectReason(shop) && (
              <RejectReasonContainer>
                {`店舗情報の申請が差し戻しされました `}
                <Link to={`/shopDetail/${shop.id}`}>確認する</Link>
              </RejectReasonContainer>
            )}
            {ShopData.existsDraftStateText(shop.draft_state_text) && <>{shop.draft_state_text}</>}

            <DraftingStateButtonList>
              {displayableDraftingStateType.map((draftingState) => {
                return (
                  shop.drafting_state[draftingState] && (
                    <DraftingStateButtonContainer key={draftingState}>
                      <Button
                        invert
                        appearance="primary"
                        size="sm"
                        onClick={() =>
                          handleLink(
                            `${ShopData.getDraftingStateLink(draftingState).link}/${shop.id}`,
                          )
                        }
                      >
                        {ShopData.getDraftingStateLink(draftingState).text}
                      </Button>
                    </DraftingStateButtonContainer>
                  )
                );
              })}
            </DraftingStateButtonList>

            <ButtonContainer>
              <Button
                appearance="secondary"
                display="responsive"
                disabled={shop.is_available_shop_judge}
                onClick={handleRequestJudge}
              >
                審査申請
              </Button>
            </ButtonContainer>

            <NoticeContainer>
              ご提出内容による初期費用の割引キャンペーンは、初回ご提出時の審査でのみ適用されます。
              <br />
              審査後の修正は適用外となりますので、申請時にはご注意ください。
            </NoticeContainer>

            <ButtonListContainer>
              <ButtonListItem>
                <Button
                  invert
                  appearance="primary"
                  size="sm"
                  display="responsive"
                  onClick={() => handleLink(`shopDetail/${shop.id}`)}
                >
                  店舗情報の編集
                </Button>
              </ButtonListItem>
              <ButtonListItem>
                <Button
                  invert
                  appearance="primary"
                  size="sm"
                  display="responsive"
                  onClick={() => handleLink(`shopItem/${shop.id}`)}
                >
                  商品の登録・編集
                </Button>
              </ButtonListItem>
            </ButtonListContainer>
          </ShopListItemInner>
        </>
      )}
    </Container>
  );
});
