import loadImage from "blueimp-load-image";

export class FileUtils {
  public static fileToBlob = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const image = new Image();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target !== null && e.target.result !== null) {
          image.onload = () => {
            const ratio = image.height / image.width;
            const width = 750;
            const height = 750 * ratio;

            const canvas = document.createElement("canvas") as HTMLCanvasElement;
            canvas.style.cssText = `
              position: absolute;
              z-index: -1;
              visibility: hidden;
            `;

            // const canvas: any = document.getElementById('mainImgCanvas');
            // const parentElem = canvas.parentElement;
            // const parentWidth = parentElem.clientWidth;
            // const parentHeight = parentElem.clientHeight;

            canvas.setAttribute("width", width.toString());
            canvas.setAttribute("height", height.toString());

            document.body.appendChild(canvas);

            // const moveX = (width-parentWidth)/-2;
            // const moveY = (height-parentHeight)/-2;
            // let scale;
            // if(width > height) {
            //   scale = parentHeight/height;
            // } else {
            //   scale = parentWidth/width;
            // }
            // const styles = 'transform:matrix('+scale+',0,0,'+scale+','+moveX+','+moveY+');';

            // canvas.setAttribute('style',styles);
            const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
            // canvasに既に描画されている画像をクリア
            ctx.clearRect(0, 0, width, height);
            // canvasにサムネイルを描画
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
            // canvasからbase64画像データを取得
            const base64 = canvas.toDataURL("image/jpeg");
            // document.body.removeChild(canvas);
            // ここで返すとbase64
            resolve(base64);
          };
          image.onerror = reject;
          image.src = e.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    });
  };

  public static base64ToBlob = (base64: string) => {
    // base64からBlobデータを作成
    const bin = atob(base64.split("base64,")[1]);
    const len = bin.length;
    const barr = new Uint8Array(len);
    let i = 0;
    while (i < len) {
      barr[i] = bin.charCodeAt(i);
      i += 1;
    }
    const blob = new Blob([barr], { type: "image/jpeg" });
    // ここで返すとblob
    return blob;
  };

  public static fileToBase64 = async (file: File) => {
    return new Promise((resolve) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      loadImage.parseMetaData(file, (data: any) => {
        const options: loadImage.LoadImageOptions = {
          maxWidth: 750,
          canvas: true,
          orientation: data.exif ? data.exif.get("Orientation") : false,
        };
        loadImage(
          file,
          (canvas) => {
            const dataUri = (canvas as HTMLCanvasElement).toDataURL("image/jpeg");
            resolve(dataUri);
          },
          options,
        );
      });
    });
  };

  // crossOrigin未対応
  public static imgToBlob = (src: string) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const ratio = image.height / image.width;
        const width = 750;
        const height = 750 * ratio;

        const canvas = document.createElement("canvas");

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        if (ctx) {
          // ctx.clearRect(0,0,width,height);
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);

          const base64 = canvas.toDataURL("image/jpeg");
          resolve(base64);
        }
      };
      image.crossOrigin = "anonymous";
      image.onerror = reject;
      image.src = src;
    });
  };

  public static blobToDataUrl = (blob: ArrayBuffer | Blob | null) => {
    if (!blob) {
      return false;
    }
    const objectUrl = URL.createObjectURL(blob);
    console.log(objectUrl);
    return objectUrl;
  };
}
