import { MonthlySalesReports } from "./MonthlySalesReports";

export type MonthlySales = Readonly<{
  target_month: string;
  sales: MonthlySalesReports;
}>;

const initialState: MonthlySales = {
  target_month: "",
  sales: MonthlySalesReports.create(),
};

const create = (args: Partial<MonthlySales> = {}) => {
  return {
    ...initialState,
    ...args,
  };
};

export const MonthlySales = Object.freeze({
  initialState,
  create,
});
