import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { DeleteShopStaffRequest, DeleteShopStaffResponse } from "../../../apis/deleteShopStaff";
import { updateShopStaffList, UserSubmittedDeleteShopStaffAction } from "../actions";

export function* userSubmittedDeleteShopStaffSaga(action: UserSubmittedDeleteShopStaffAction) {
  try {
    const params: DeleteShopStaffRequest = {
      shop_id: action.payload.shopId,
      staff_id: action.payload.staffId,
    };
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.DELETE_SHOP_STAFF, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: DeleteShopStaffResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopStaffList(result.staff_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "アカウントの削除に失敗しました。",
      }),
    );
  }
}
