import moment from "moment";
import { /* API_KEY, */ ApiKey } from "../apis";

type CacheKey = string;

export class APICache {
  public static shouldUseCache(method: ApiKey, params: {}) {
    if (!APICache.cacheEnabled(method)) {
      return false;
    }
    if (typeof APICache.getCache(method, params) === "undefined") {
      return false;
    }
    if (APICache.expired(method, params)) {
      return true;
    }
    return false;
  }

  public static expired(method: ApiKey, params: {}) {
    const cacheKey = APICache.generateCacheKey(method, params);
    const ttl = APICache.ttlMap.get(cacheKey);
    if (typeof ttl === "undefined") {
      return true;
    }
    return moment().isBefore(ttl);
  }

  public static setCache(method: ApiKey, params: {}, responseBody: {}) {
    if (!APICache.cacheEnabled(method)) {
      return;
    }
    const cacheKey = APICache.generateCacheKey(method, params);
    // MEMO: inject field to judge data whether from cache
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-explicit-any
    (responseBody as any).fromCache = true;
    APICache.cacheMap.set(cacheKey, responseBody);
    APICache.ttlMap.set(cacheKey, APICache.getTTL(method));
  }

  public static getCache(method: ApiKey, params: {}): {} | undefined {
    const cacheKey = APICache.generateCacheKey(method, params);
    return APICache.cacheMap.get(cacheKey);
  }

  private static cacheMap: Map<CacheKey, {}> = new Map();
  private static ttlMap: Map<CacheKey, Date> = new Map();

  private static generateCacheKey(method: ApiKey, params: {}) {
    return `${method}-${JSON.stringify(params)}`;
  }

  private static getTTL(method: ApiKey) {
    switch (method) {
      // case API_KEY.GET_CATEGORY_TAG_LIST:
      // case API_KEY.GET_ORDER_HISTORY:
      //   return moment().add(5, "minute").toDate();
      default:
        return new Date(0);
    }
  }

  private static cacheEnabled(method: ApiKey) {
    switch (method) {
      // case ApiKey.GET_CATEGORY_TAG_LIST:
      // case ApiKey.GET_ORDER_HISTORY:
      //   return true;
      default:
        return false;
    }
  }
}
