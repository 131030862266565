import React, { useMemo } from "react";
import styled from "styled-components";
import { ShopData } from "../../../records/ShopData";
import {
  ShopLicense,
  ShopLicenseType,
  SHOP_LICENSE_EXPIRE_TYPE,
} from "../../../records/ShopLicense";
import { LinkButton } from "../../atoms/LinkButton";
import { ShopDetailRow, ShopDetailKey, ShopDetailVal } from "../../molecules/ShopDetailSection";

const Container = styled(ShopDetailRow)`
  align-items: center;
`;

const LicenseContainer = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: flex-start;
`;

const LicenseState = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;

type Props = {
  shopData: ShopData;
  licenseType: ShopLicenseType;
};

export const ShopLicenseRow: React.FC<Props> = React.memo(({ shopData, licenseType }) => {
  const license = useMemo(() => {
    return ShopData.getLicenseByType(shopData, licenseType);
  }, [shopData, licenseType]);

  return (
    <Container>
      <ShopDetailKey>{ShopLicense.getPresentationLicenseTypeText(licenseType)}</ShopDetailKey>
      <ShopDetailVal>
        <LicenseContainer>
          <LicenseState>
            {ShopLicense.isValid(license) ? (
              <>
                {license.expire_type === SHOP_LICENSE_EXPIRE_TYPE.LIMITED
                  ? `提出済（期限：${license.expiration_date}）`
                  : "提出済（期限なし）"}
              </>
            ) : (
              "提出なし"
            )}
          </LicenseState>
          <ButtonContainer>
            <LinkButton
              to={`/shopLicenseEdit/${shopData.id}/${licenseType}`}
              appearance="primary"
              size="sm"
              display="block"
            >
              編集
            </LinkButton>
          </ButtonContainer>
        </LicenseContainer>
      </ShopDetailVal>
    </Container>
  );
});
