import React, { useEffect, useCallback } from "react";
import { replace } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { systemGetUserDataByToken } from "../../modules/user/actions";
import { loginTokenSelector, isLoggedInSelector } from "../../modules/user/selectors";

type Props = {
  children: React.ReactNode;
};

export const Auth: React.FC<Props> = React.memo(({ children }) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userToken = useSelector(loginTokenSelector);

  const dispatch = useDispatch();

  const redirectIfNotLoggedIn = useCallback(() => {
    if (!isLoggedIn) {
      if (userToken !== null) {
        dispatch(systemGetUserDataByToken());
      } else {
        dispatch(replace("/"));
      }
    }
  }, [isLoggedIn, userToken, dispatch]);

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, [redirectIfNotLoggedIn]);

  return <>{children}</>;
});
