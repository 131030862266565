import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { userTouchedSortShopOptionSetButton } from "../../modules/shop/actions";
import { DirectionType } from "../../modules/shop/model";
import { OptionSet } from "../../records/OptionSet";
import { OptionSetListItem } from "../molecules/OptionSetListItem";

const Container = styled.div``;

type Props = {
  shopId: number;
  list: OptionSet[];
};

export const OptionSetList: React.FC<Props> = React.memo(({ shopId, list }) => {
  const optionSetIds = useMemo(() => {
    return list.map((optionSet) => optionSet.option_set_id);
  }, [list]);

  const dispatch = useDispatch();

  const handleSort = useCallback(
    (optionSetId: number, direction: DirectionType) => {
      dispatch(userTouchedSortShopOptionSetButton(shopId, optionSetId, direction, optionSetIds));
    },
    [dispatch, shopId, optionSetIds],
  );

  return (
    <Container>
      {list.map((optionSet, index) => (
        <OptionSetListItem
          key={optionSet.option_set_id}
          shopId={shopId}
          optionSet={optionSet}
          index={index}
          length={list.length}
          handleSort={handleSort}
        />
      ))}
    </Container>
  );
});
