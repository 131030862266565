import { fork, join, cancel, put } from "redux-saga/effects";
import { commonApiSaga } from "../../app/sagas/commonApiSaga";
import { API_KEY } from "../../../apis";
import { ALERT_TYPE } from "../../../records/Alert";
import { systemAddedAlert } from "../../app/actions";
import { ShopJudgeRequestRequest, ShopJudgeRequestResponse } from "../../../apis/shopJudgeRequest";
import { updateShopList, UserTouchedShopJudgeRequestButtonAction } from "../actions";

export function* userTouchedShopJudgeRequestButtonSaga(
  action: UserTouchedShopJudgeRequestButtonAction,
) {
  try {
    const params: ShopJudgeRequestRequest = {
      id: action.payload.shopId,
    };
    const commonApiTask = yield fork(() => commonApiSaga(API_KEY.SHOP_JUDGE_REQUEST, params));
    if (commonApiTask.isCancelled()) {
      yield cancel();
    }
    const { result, error }: { result: ShopJudgeRequestResponse; error: unknown } = yield join(
      commonApiTask,
    );
    if (result && !error) {
      yield put(updateShopList(result.shop_list));
    } else {
      throw error;
    }
  } catch {
    yield put(
      systemAddedAlert({
        type: ALERT_TYPE.DANGER,
        title: "エラー",
        message: "店舗審査の申請が失敗しました。",
      }),
    );
  }
}
